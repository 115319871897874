import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Typography from '../Typography';
import PingNotification from '../PingNotification';
import BvaInfo from './components/BvaInfo';
import BvaGraph from './components/BvaGraph';
import BvaTable from './components/BvaTable';
import { IDateValue, IForecastedValues } from '~/services/parallel/bva.types';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import { useSelect } from '~/components/Select';

const BvaDropdown = ({
  mode,
  forecast,
  actuals,
}: {
  mode: 'headcount' | 'expense' | 'revenue';
  forecast?: IForecastedValues;
  actuals: IDateValue[];
}): React.ReactNode => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const forecastAsOfOptions = forecast?.map(({ monthsAgo }) => ({
    label: monthsAgo === 1 ? 'Last Month' : `${monthsAgo} Months Ago`,
    value: `${monthsAgo}`,
  }));

  const [forecastAsOf, setForecastAsOf] = useSelect({
    options: forecastAsOfOptions ?? [],
    selected: forecastAsOfOptions?.[0],
  });

  const [forecastValues, setForecastValues] = useState<IDateValue[]>([]);

  useEffect(() => {
    if (forecast && forecastAsOf.selected) {
      const forecastValues = forecast.find(
        ({ monthsAgo }) => monthsAgo === Number(forecastAsOf.selected?.value),
      )?.values;

      setForecastValues(forecastValues ?? []);
    }
  }, [forecast, forecastAsOf]);

  useEffect(() => {
    const checkIsTableVisible = (): void => {
      if (dropdownRef.current) {
        setIsTableVisible(dropdownRef.current.offsetWidth > 862);
      }
    };

    checkIsTableVisible();

    window.addEventListener('resize', checkIsTableVisible);
    return () => {
      window.removeEventListener('resize', checkIsTableVisible);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`relative flex flex-col my-4 rounded-2xl border border-neutral-50 ${
        isOpen || mode === 'revenue' ? 'bg-white' : 'bg-neutral-15'
      }`}
    >
      <div className="absolute top-1 right-0 z-20">
        <Button
          fill="clear"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? 'Hide' : 'View'}
        </Button>
      </div>
      <div className="absolute top-[17px] left-4">
        <PingNotification />
      </div>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`transition-all cursor-pointer duration-500 ease-in-out ${isOpen ? 'max-h-0 opacity-0 px-0 py-0' : 'max-h-12 py-3 px-10 opacity-100 '}`}
      >
        <Typography>{camelCaseToNormal(mode)} Off-track Review</Typography>
      </div>
      <div
        className={`flex flex-col w-full transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-[320px] h-[320px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="flex flex-row w-full h-full">
          <BvaInfo mode={mode} />
          <div className="w-[70%] pt-3 border-l border-neutral-50 px-2 flex pb-3 min-h-0 h-full">
            <BvaGraph forecast={forecastValues} actuals={actuals} xAxisPadding={{ left: 48, right: 48 }} />
          </div>
        </div>
        {isTableVisible && (
          <BvaTable
            forecast={forecastValues}
            actuals={actuals}
            forecastAsOf={forecastAsOf}
            setForecastAsOf={setForecastAsOf}
          />
        )}
      </div>
    </div>
  );
};

export default BvaDropdown;
