import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Typography from '~/components/Typography';
import { IScenario, scenariosToDisplay } from '~/services/parallel/scenarios.types';
import Button from '~/components/Button';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { update, updateScenarioLoadingState } from '~/store/scenarioSlice';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';
import logger from '~/utils/logger';
import ScenarioButton from './ScenarioButton';
import EmptyScenarios from '~/assets/emptyScenarios.svg';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import Spinner from '../Spinner';
import { useAppDispatch } from '~/utils/hooks/useAppDispatch';
import posthog from 'posthog-js';

const ScenarioDropdown = ({ reload }: { reload?: () => void }): React.ReactElement => {
  const dispatch = useAppDispatch();
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);
  const { data: scenarios, isLoading: isLoadingScenarios } = scenariosApi.useListScenariosQuery({
    orgUuid: organizationUuid,
    purpose: Object.values(scenariosToDisplay),
  });

  const [createScenario] = scenariosApi.useCreateScenarioMutation();
  const user = useSelector((state: State) => state.user);

  const dynamicScenarios = scenarios?.filter((scenario) => scenario.type === 'dynamic') ?? [];

  const handleCreateScenario = async (type: 'dynamic' | 'static', title?: string): Promise<void> => {
    if (type === 'dynamic') {
      dispatch(updateScenarioLoadingState('creating'));
    }

    try {
      posthog.capture('create_scenario_clicked', {
        type,
        userUuid: user.uuid,
        organizationUuid,
        title,
      });

      const scenarioData = await createScenario({
        orgUuid: organizationUuid,
        body: {
          type,
          changeDescription: title,
        },
      }).unwrap();

      dispatch(
        update({
          activeScenarioUuid: type === 'dynamic' ? scenarioData.uuid : null,
          activeScenarioData: scenarioData,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          scenarioMode: 'creating',
          scenarioLoadingState: 'idle',
          scenarios: [scenarioData, ...(scenarios ?? [])],
        }),
      );
      if (reload) {
        await reload();
      }
    } catch (error) {
      logger.error(new Error('Failed to create scenario'));
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  const handleEditScenario = async (scenario: IScenario): Promise<void> => {
    await dispatch(updateScenarioLoadingState('entering'));
    try {
      const fetchedScenario = await dispatch(
        scenariosApi.endpoints.fetchScenario.initiate(
          {
            orgUuid: scenario.organizationUuid,
            scenarioUuid: scenario.uuid,
          },
          { forceRefetch: true },
        ),
      ).unwrap();

      const filteredSelectedScenarioUuids = selectedScenarioUuids.filter((uuid) => uuid !== scenario.uuid);

      dispatch(
        update({
          activeScenarioUuid: fetchedScenario.uuid,
          activeScenarioData: fetchedScenario,
          scenarioLoadingState: 'idle',
          scenarioMode: 'editing',
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids: filteredSelectedScenarioUuids,
          scenarios:
            scenarios?.map((scenario) => {
              if (scenario.uuid === fetchedScenario.uuid) {
                return fetchedScenario;
              }
              return scenario;
            }) ?? [],
        }),
      );

      if (reload) {
        await reload();
      }

      posthog.capture('edit_scenario_clicked', {
        scenarioUuid: scenario.uuid,
        userUuid: user.uuid,
        organizationUuid: scenario.organizationUuid,
      });
    } catch (error) {
      logger.error(error as Error);
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  const propagateClick = (): void => {
    document.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
  };

  return (
    <>
      <Popover className="relative">
        {({ close }) => (
          <>
            <Popover.Button
              id="scenario-dropdown-button"
              data-testid="scenario-dropdown-button"
              onClick={propagateClick}
              className="focus:outline-none focus:ring-0"
            >
              <div className="flex text-green-400 border border-green-50 hover:bg-green-15 hover:border-green-75 stroke-green-400 hover:text-green-500 hover:stroke-green-500 !w-fit !py-1.5 !px-3.5 rounded-lg gap-1 items-center justify-center">
                <div className="text-sm">Scenarios</div>
                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 13H11" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M1.99998 11C6.95787 11 10 8 12 6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="3 3"
                  />
                  <circle cx="14" cy="3" r="1" strokeLinecap="round" strokeLinejoin="round" />
                  <circle cx="14" cy="13" r="1" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute z-10 transform left-0 mt-1 above`}>
                <div
                  className={`flex flex-col lg:flex-row bg-white overflow-hidden rounded-lg shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.20)] ring-1 ring-black ring-opacity-5`}
                >
                  <div className={`flex-grow py-3 flex flex-col min-h-[100px] lg:w-[448px]`}>
                    <div className="flex items-center justify-between pt-1 px-7 w-full">
                      <div className="flex items-center gap-1">
                        <Typography size="sm" weight="bold">
                          Scenarios
                        </Typography>
                        <HoverPopover
                          buttonContent={<InformationCircleIcon className="size-5 text-gray-500 pointer-events-auto" />}
                          panelContent={
                            <div className="bg-black text-white w-[295px] flex flex-col gap-2 px-5 py-3 rounded-lg text-left">
                              <Typography color="white">
                                {`Scenarios allow you visualize the financial impact of business decisions without altering your base model`}
                              </Typography>
                            </div>
                          }
                          anchor="top"
                          panelClassName="z-[100] shadow rounded-lg"
                          hoverDelay={300}
                        />
                      </div>
                      <Button
                        className="flex items-center justify-end gap-1 cursor-pointer !w-fit !p-0 max-sm:hidden stroke-blue-400 hover:stroke-blue-500 disabled:stroke-neutral-100"
                        onClick={async () => {
                          close();
                          await handleCreateScenario('dynamic');
                        }}
                        fill="clearBlue"
                        disabled={!!activeScenarioUuid}
                        id="new-scenario-button"
                      >
                        New Scenario
                      </Button>
                    </div>
                    {isLoadingScenarios ? (
                      <div className="flex-grow flex flex-row items-center justify-center gap-2 mt-2">
                        <Typography>Loading scenarios</Typography>
                        <Spinner size={20} strokeColor="#95AFBB" strokeWidth={4} />
                      </div>
                    ) : dynamicScenarios.length ? (
                      <div
                        className={`flex flex-col flex-grow max-h-[600px] gap-2 mt-4 px-3 ${dynamicScenarios.length > 4 && 'overflow-y-auto'} overflow-x-clip`}
                      >
                        {dynamicScenarios.map((scenario) => (
                          <ScenarioButton
                            reload={reload}
                            scenario={scenario}
                            key={scenario.uuid}
                            onClick={handleEditScenario}
                            closePopover={close}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="flex-grow flex flex-col items-center justify-center gap-2">
                        <img src={EmptyScenarios} alt="Empty Scenarios" className="mt-4" />
                        <Typography weight="bold" className="mt-2">{`Create 'What if' Scenarios`}</Typography>
                        <Typography color="secondary" className="text-center max-w-[295px] pb-6">
                          Adjust roles to model alternate outcomes, view potential impact, and make informed decisions
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};
export default ScenarioDropdown;
