import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Editor } from '@tiptap/react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Typography from '~/components/Typography';

interface Props {
  departmentUuidsString: string;
  editor: Editor;
  viewOnly?: boolean;
  updateCalculationModifier: (modifier: string) => void;
}

const DepartmentCalculationModifier = ({
  departmentUuidsString,
  editor,
  viewOnly,
  updateCalculationModifier,
}: Props): React.ReactElement => {
  const departments = useSelector((state: State) => state.organization.departments);
  const departmentUuids = departmentUuidsString.split(',');

  const departmentNames = departmentUuids.map((uuid) => {
    if (uuid === 'ALL') return 'All Departments';
    const department = departments.find((d) => d.departmentUuid === uuid);
    return department?.name;
  });

  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            as="div"
            className={`flex px-2 flex-row gap-x-1 items-center cursor-pointer !w-fit text-neutral-200 ${
              viewOnly ? '!cursor-default' : 'hover:text-neutral-400'
            }`}
            onClick={(e) => {
              if (viewOnly) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (!editor.isFocused) {
                editor.commands.focus();
              }
            }}
          >
            {departmentNames.join(', ')}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? 'block' : 'hidden'
            } absolute bg-white shadow-md rounded-md z-[15] border-none outline-none`}
          >
            {departments.map((department) => {
              return (
                <div
                  key={department.uuid}
                  onClick={() => {
                    if (departmentUuids[0] !== department.departmentUuid) {
                      updateCalculationModifier(department.departmentUuid);
                      editor.commands.focus();
                    }
                  }}
                  className="py-2 pr-4 pl-3 hover:bg-green-100 cursor-pointer flex flex-row gap-1 items-center justify-start"
                >
                  {departmentUuids[0] === department.departmentUuid ? (
                    <CheckIcon className="size-5" />
                  ) : (
                    <div className="size-5" />
                  )}
                  <Typography>{department.name}</Typography>
                </div>
              );
            })}
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default DepartmentCalculationModifier;
