import React from 'react';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { setHelpCenterEditing } from '~/store/drawerContentSlice';
import Typography from '~/components/Typography';
import { IPostWithReactions } from '~/services/parallel/api/helpCenter/requestSchemas';

interface Props {
  onCreateNew: () => void;
  onPublish: () => void;
}

const HelpCenterMenu: React.FC<Props> = ({ onCreateNew, onPublish }) => {
  const dispatch = useDispatch();
  const { currentPageId, isEditing } = useSelector((state: State) => state.drawerContent.helpCenter);
  const post = useSelector((state: State) => {
    if (!currentPageId) return null;
    const data = state.helpCenterApi.queries[`getPost("${currentPageId}")`]?.data as IPostWithReactions | undefined;
    return data;
  });

  const handleEdit = (): void => {
    dispatch(setHelpCenterEditing(true));
  };

  return (
    <Menu as="div" className="relative">
      <MenuButton className="p-1.5 rounded-lg transition-colors hover:bg-neutral-50">
        <EllipsisVerticalIcon className="h-5 w-5" />
      </MenuButton>

      <MenuItems className="absolute right-0 mt-1 w-56 bg-white rounded-lg shadow-lg border border-neutral-50 focus:outline-none z-50">
        <div className="p-1">
          <MenuItem>
            {({ active }) => (
              <button
                onClick={onCreateNew}
                className={`${active ? 'bg-neutral-50' : ''} flex w-full items-center rounded-md px-3 py-2 text-left`}
              >
                <Typography>Create new post</Typography>
              </button>
            )}
          </MenuItem>

          {currentPageId && !isEditing && (
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={handleEdit}
                  className={`${active ? 'bg-neutral-50' : ''} flex w-full items-center rounded-md px-3 py-2 text-left`}
                >
                  <Typography>Edit post</Typography>
                </button>
              )}
            </MenuItem>
          )}

          {currentPageId && post && !post.publishedAt && (
            <MenuItem>
              {({ active }) => (
                <button
                  onClick={onPublish}
                  className={`${active ? 'bg-neutral-50' : ''} flex w-full items-center rounded-md px-3 py-2 text-left`}
                >
                  <Typography>Publish post</Typography>
                </button>
              )}
            </MenuItem>
          )}
        </div>
      </MenuItems>
    </Menu>
  );
};

export default HelpCenterMenu;
