import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IPositionDetails } from './entity/types';
import { useSelect } from '~/components/Select';
import { bvaApi } from '~/services/parallel/api/bvaApi';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { useInput } from '~/components/Input/InputWrapper';
import CreatePositionModal from './components/CreatePositionModal/CreatePositionModal';
import Header from '~/components/Header';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import UserDateRange from '~/components/UserDateRange';
import HeadcountPageBody from './HeadcountPageBody';
import UpdatePositionModal from './components/UpdatePositionModal/UpdatePositionModal';
import DeletePositionModal from './components/DeletePositionModal';
import { ZPositionDetails } from './entity/schemas';
import { isEqual } from 'lodash';
import { z } from 'zod';
import UpdateCompensationModal from './components/UpdateCompensationModal/UpdateCompensationModal';
import EditTermDateModal from './components/EditTermDate/EditTermDateModal';
import { useDebounce } from '~/utils/hooks/useDebounce';

interface IExistingPositionModalState {
  isOpen: boolean;
  position: IPositionDetails | null;
}

const HeadcountPage = (): React.ReactNode => {
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);

  const [headcountView, setHeadcountView] = useState<string>('table');

  const [createPositionModalIsOpen, setCreatePositionModalIsOpen] = useState(false);
  const [updatePositionModalState, setUpdatePositionModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [deletePositionModalState, setDeletePositionModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [updateCompensationModalState, setUpdateCompensationModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [editTermDateModalState, setEditTermDateModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });

  const [departments, setDepartments] = useSelect({
    options: [],
    selected: { label: 'All Departments', value: null },
  });

  const [search, setSearch] = useInput({
    validation: /.*/,
  });
  const [positionIndexes, setPositionIndexes] = useState<Record<string, number>>({});
  const [hideTermedPositions, setHideTermedPositions] = useState(false);
  const [isCreatingInline, setIsCreatingInline] = useState(false);
  const previousParsedPositionsRef = useRef<IPositionDetails[]>([]);

  const debouncedSearch = useDebounce(search.value, 200);
  const debouncedDepartmentUuid = useDebounce(departments.selected?.value ?? null, 200);

  const listParams = useMemo(
    () => ({
      orgUuid: organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
      includeFullyBurdened: true,
      search: debouncedSearch,
      departmentUuids: debouncedDepartmentUuid ? [debouncedDepartmentUuid] : undefined,
      excludeTerminated: hideTermedPositions,
    }),
    [debouncedSearch, debouncedDepartmentUuid, hideTermedPositions, organizationUuid, activeScenarioUuid],
  );

  const { data: bvaResult } = bvaApi.useGetBvaQuery();
  const {
    data: positions,
    isLoading: isLoadingPositions,
    isFetching: isFetchingPositions,
  } = positionsApi.useListPositionsQuery(listParams, {
    skip: !defaultGraphStartDate || !defaultGraphEndDate || !organizationUuid,
  });

  const previousParams = useRef(listParams);

  const getChangedFilters = useMemo(() => {
    if (!isFetchingPositions) return { departments: false, search: false, hideTerms: false };

    const changedFilters = {
      departments: previousParams.current.departmentUuids !== listParams.departmentUuids,
      search: previousParams.current.search !== listParams.search,
      hideTerms: previousParams.current.excludeTerminated !== listParams.excludeTerminated,
    };

    previousParams.current = listParams;
    return changedFilters;
  }, [isFetchingPositions]);

  const memoizedParsedPositions = useMemo(() => {
    // If data is present, parse it; otherwise, return an empty array.
    const parsed = positions?.length ? z.array(ZPositionDetails).parse(positions) : [];
    // Optionally, compare with previous parsed data to decide if a new array is needed.
    if (isEqual(previousParsedPositionsRef.current, parsed)) {
      return previousParsedPositionsRef.current;
    } else {
      previousParsedPositionsRef.current = parsed;
      return parsed;
    }
  }, [positions]);

  useEffect(() => {
    if (
      memoizedParsedPositions.length &&
      memoizedParsedPositions
        .filter((position) => !position.positionUuid.startsWith('temp-'))
        .some((position) => typeof positionIndexes[position.positionUuid] !== 'number')
    ) {
      setPositionIndexes(
        memoizedParsedPositions
          .sort((a, b) => a.hireDate.localeCompare(b.hireDate))
          .reduce(
            (acc, position, index) => {
              acc[position.positionUuid] = index;
              return acc;
            },
            {} as Record<string, number>,
          ),
      );
    }
  }, [memoizedParsedPositions]);

  const isFiltering = Boolean(debouncedSearch || hideTermedPositions || debouncedDepartmentUuid);

  return (
    <div className="max-sm:min-h-screen max-sm:pb-32">
      <CreatePositionModal
        isOpen={createPositionModalIsOpen}
        onClose={() => setCreatePositionModalIsOpen(false)}
        orgUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        listParams={listParams}
        setPositionIndexes={setPositionIndexes}
      />
      <UpdatePositionModal
        isOpen={updatePositionModalState.isOpen}
        onClose={() => setUpdatePositionModalState((prev) => ({ ...prev, isOpen: false }))}
        orgUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        position={updatePositionModalState.position}
        listParams={listParams}
      />
      <DeletePositionModal
        isOpen={deletePositionModalState.isOpen}
        onClose={() => setDeletePositionModalState((prev) => ({ ...prev, isOpen: false }))}
        position={deletePositionModalState.position}
        orgUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        setPositionIndexes={setPositionIndexes}
        listParams={listParams}
      />
      <UpdateCompensationModal
        isOpen={updateCompensationModalState.isOpen}
        onClose={() => setUpdateCompensationModalState({ isOpen: false, position: null })}
        position={updateCompensationModalState.position}
        organizationUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        listParams={listParams}
      />
      <EditTermDateModal
        isOpen={editTermDateModalState.isOpen}
        onClose={() => setEditTermDateModalState((prev) => ({ ...prev, isOpen: false }))}
        position={editTermDateModalState.position}
        orgUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        listParams={listParams}
      />
      <Header
        title="Headcount"
        zIndex="z-30"
        startChildren={
          <div className="flex flex-row gap-2">
            <ScenarioDropdown />
          </div>
        }
        endChildren={
          <div className="flex w-full justify-end items-center gap-2">
            {!isLoadingPositions && <UserDateRange pickerAlignment="right" />}
          </div>
        }
      />
      <HeadcountPageBody
        organizationUuid={organizationUuid}
        scenarioUuid={activeScenarioUuid}
        positions={memoizedParsedPositions}
        isLoadingPositions={isLoadingPositions}
        bvaResult={bvaResult}
        positionIndexes={positionIndexes}
        setPositionIndexes={setPositionIndexes}
        search={search}
        setSearch={setSearch}
        departments={departments}
        setDepartments={setDepartments}
        headcountView={headcountView}
        setHeadcountView={setHeadcountView}
        setCreatePositionModalIsOpen={setCreatePositionModalIsOpen}
        setUpdatePositionModalState={setUpdatePositionModalState}
        setDeletePositionModalState={setDeletePositionModalState}
        setUpdateCompensationModalState={setUpdateCompensationModalState}
        setEditTermDateModalState={setEditTermDateModalState}
        hideTermedPositions={hideTermedPositions}
        setHideTermedPositions={setHideTermedPositions}
        isCreatingInline={isCreatingInline}
        setIsCreatingInline={setIsCreatingInline}
        isFiltering={isFiltering}
        listParams={listParams}
        isFetchingPositions={isFetchingPositions}
        changedFilters={getChangedFilters}
      />
    </div>
  );
};

export default HeadcountPage;
