import React from 'react';
import Typography from '~/components/Typography';
import { IDateValue } from '~/services/parallel/bva.types';
import formatCurrency from '~/utils/formatCurrency';
import formatPercent from '~/utils/formatPercent';
import { SelectState } from '~/components/Select/Select.types';
import Select from '~/components/Select';
import * as stringDate from '~/utils/stringDate';

const getPercentChange = (value: number, previousValue: number): number | null => {
  if (previousValue === 0 && value === 0) {
    return 0;
  }
  return (value - previousValue) / previousValue;
};

const getPercentChangeColor = (percentChange: number): 'percentGreen' | 'warning' | 'empty' => {
  if (percentChange > 0) {
    return 'percentGreen';
  } else if (percentChange < 0) {
    return 'warning';
  }
  return 'empty';
};

const getPercentChangeBgColor = (percentChange: number): string => {
  if (percentChange > 0) {
    return 'bg-green-15';
  } else if (percentChange < 0) {
    return 'bg-[#FBF3F3]';
  }
  return 'bg-neutral-15';
};

const BvaTable = ({
  forecast,
  actuals,
  forecastAsOf,
  setForecastAsOf,
}: {
  forecast: IDateValue[];
  actuals: IDateValue[];
  forecastAsOf: SelectState;
  setForecastAsOf: React.Dispatch<React.SetStateAction<SelectState>>;
}): React.ReactNode => {
  const sortedForecast = forecast.length > 0 ? [...forecast].sort((a, b) => stringDate.sortAsc(a.date, b.date)) : [];
  const sortedActuals = actuals.length > 0 ? [...actuals].sort((a, b) => stringDate.sortAsc(a.date, b.date)) : [];

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row border-t border-neutral-50 w-full">
        <div className="px-10 w-[30%] flex flex-row max-h-[52px] items-center relative">
          <Typography color="secondary">Forecasted</Typography>
          <div className="absolute top-[4.5px] right-4 z-20">
            <Select
              className="max-w-[168px] !py-1 !text-neutral-400"
              state={forecastAsOf}
              setState={setForecastAsOf}
              id="forecast-as-of"
              placeholder="Select forecast"
            />
          </div>
        </div>
        <div className="flex w-[70%]">
          {sortedForecast.map(({ date, value }, index) => {
            const isFirst = index === 0;
            const previousValue = sortedForecast[index - 1]?.value;
            let percentChange;
            if (!isFirst && typeof previousValue === 'number' && typeof value === 'number') {
              percentChange = getPercentChange(value, previousValue);
            }

            const actualValue = sortedActuals.find((actual) =>
              stringDate.isSameMonth({ date1: actual.date, date2: date }),
            )?.value;
            const isSameValue =
              forecastAsOf.selected?.value === '1' && value === actualValue && index !== sortedForecast.length - 1;
            return (
              <div
                className={`${isSameValue ? 'bg-neutral-25' : ''} flex-1 text-end py-[14px] pr-10 pl-2 border-l border-neutral-50`}
                key={`${date}-budget`}
              >
                {isSameValue ? (
                  <div>
                    <Typography color="disabled">-</Typography>
                  </div>
                ) : (
                  <div
                    className={`flex ${typeof percentChange === 'number' ? 'flex-row justify-between' : 'justify-end'}`}
                  >
                    {typeof percentChange === 'number' && (
                      <div className={`${getPercentChangeBgColor(percentChange)} rounded-md px-1 !w-fit`}>
                        <Typography color={getPercentChangeColor(percentChange)}>
                          {percentChange > 0 ? '+' : ''}
                          {Math.abs(percentChange) >= 0.1 || percentChange === 0
                            ? formatPercent({ value: percentChange, decimalToRound: 0 })
                            : formatPercent({ value: percentChange, decimalToRound: 1 })}
                        </Typography>
                      </div>
                    )}
                    <Typography color="empty">
                      {typeof value === 'number' ? formatCurrency(value * 100) : '-'}
                    </Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row border-t border-neutral-50 w-full rounded-b-2xl bg-neutral-15">
        <div className="py-[14px] px-10  w-[30%] flex flex-row items-center gap-2 ">
          <Typography color="orange">Actuals</Typography>
        </div>
        <div className="flex w-[70%]">
          {sortedActuals.map(({ date, value }, index) => {
            const isFirst = index === 0;
            const previousValue = sortedActuals[index - 1]?.value;
            let percentChange;
            if (!isFirst && typeof previousValue === 'number' && typeof value === 'number') {
              percentChange = getPercentChange(value, previousValue);
            }
            return (
              <div
                className="flex-1 justify-between text-end py-[14px] pr-10 pl-2 border-l border-neutral-50 relative"
                key={`${date}-actual`}
              >
                <div
                  className={`flex ${typeof percentChange === 'number' ? 'flex-row justify-between' : 'justify-end'}`}
                >
                  {typeof percentChange === 'number' && (
                    <div className={`${percentChange >= 0 ? 'bg-white' : 'bg-[#FBF3F3]'} rounded-md px-1 !w-fit`}>
                      <Typography color={getPercentChangeColor(percentChange)}>
                        {percentChange > 0 ? '+' : ''}
                        {Math.abs(percentChange) >= 0.1 || percentChange === 0
                          ? formatPercent({ value: percentChange, decimalToRound: 0 })
                          : formatPercent({ value: percentChange, decimalToRound: 1 })}
                      </Typography>
                    </div>
                  )}
                  <Typography color="orange">
                    {typeof value === 'number' ? formatCurrency(value * 100) : '-'}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BvaTable;
