import React from 'react';
import { IPositionDetails } from '../../entity/types';
import { useDispatch } from 'react-redux';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { Controller, useForm } from 'react-hook-form';
import { IEditTermDateForm, ZEditTermDateForm } from '~/services/parallel/headcount.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import DatePicker from '~/components/DatePicker';
import Button from '~/components/Button';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import toast from 'react-hot-toast';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

const EditTermDateForm = ({
  orgUuid,
  scenarioUuid,
  position,
  onClose,
  awaitCalculations,
  createScenario,
  reloadDashboard,
  listParams,
}: {
  orgUuid: string;
  scenarioUuid?: string | null;
  position: IPositionDetails | null;
  onClose: () => void;
  awaitCalculations?: boolean;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const [updatePosition, { isLoading: isUpdatingPosition }] = positionsApi.useUpdatePositionMutation();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    formState: { errors },
    reset,
  } = useForm<IEditTermDateForm>({
    defaultValues: {
      termDate: position?.termDate ?? undefined,
    },
    resolver: zodResolver(ZEditTermDateForm),
    mode: 'all',
    shouldFocusError: false,
  });

  const onSubmitForm = handleSubmit(async (data) => {
    if (!position) return;
    if (!scenarioUuid && createScenario) {
      dispatch(updateScenarioLoadingState('creating'));
    }
    try {
      const updateData: {
        termDate?: string | null;
      } = {};
      if (data.termDate !== position.termDate) updateData.termDate = data.termDate ?? null;

      const query: {
        scenarioUuid?: string;
        awaitCalculations?: boolean;
        createScenario?: boolean;
      } = {};
      if (scenarioUuid) query.scenarioUuid = scenarioUuid;
      if (!scenarioUuid && createScenario) query.createScenario = true;
      if (awaitCalculations) query.awaitCalculations = true;

      const response = await updatePosition({
        params: {
          orgUuid,
          positionUuid: position.positionUuid,
        },
        query,
        body: updateData,
        listParams,
      }).unwrap();

      if (!scenarioUuid && createScenario && response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      toast.success('Position updated successfully');
      onClose();
      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      setError('root', {
        message: 'Failed to update position. Please try again.',
      });
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  });

  const onDeleteTerm = async (): Promise<void> => {
    setValue('termDate', null);
    await onSubmitForm();
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <Controller
        control={control}
        name="termDate"
        render={({ field, fieldState }) => {
          const defaultDatePickerState: IDatePickerState = {
            value: { startDate: field.value ?? null, endDate: field.value ?? null },
            minDate: position?.hireDate,
            valid: !fieldState.error,
            errorMessage: fieldState.error?.message,
            touched: !!fieldState.error,
            pristine: !fieldState.error,
          };

          const customSetDatePickerState: React.Dispatch<React.SetStateAction<IDatePickerState>> = (action) => {
            const newState = typeof action === 'function' ? action(defaultDatePickerState) : action;
            field.onChange(newState.value.startDate);
            trigger('termDate');
          };

          return (
            <DatePicker
              id="inline-hireDate"
              state={defaultDatePickerState}
              setState={customSetDatePickerState}
              label="Termination Date"
            />
          );
        }}
      />
      <div className="flex flex-col gap-4 mt-2">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex flex-row justify-between mt-2">
          <Button
            fill="clear"
            id="cancel-edit-term-date"
            onClick={() => {
              onClose();
              reset({
                termDate: position?.termDate ?? undefined,
              });
            }}
            className="!w-fit !px-0 !py-2"
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-8">
            {position?.termDate && (
              <Button id="delete-term-date" fill="destructiveClear" className="!w-fit !px-0" onClick={onDeleteTerm}>
                Delete Term
              </Button>
            )}
            <Button
              id="update-term-date"
              className="!w-fit !px-4 !py-2"
              onClick={onSubmitForm}
              loading={isUpdatingPosition}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTermDateForm;
