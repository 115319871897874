import { store } from '~/store';
import serverRequest from '~/utils/request';
import { dashboardConfigToFormulasToFetch } from './utils/dashboardConfigToFormulasToFetch';
import { IAPIResponse } from '~/utils/types';
import { ZGraphData } from '~/services/parallel/reports.types';

export default async (signal: AbortSignal): Promise<unknown> => {
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const { activeScenarioUuid, selectedScenarioUuids } = store.getState().scenario;
  const {
    uuid: organizationUuid,
    configuration: { dashboardConfiguration },
  } = store.getState().organization;

  const formulasToFetch = dashboardConfigToFormulasToFetch({ dashboardConfiguration });
  const additionalReports = dashboardConfiguration.metrics.includes('Runway') ? ['Runway'] : [];

  const reportsResponse = (await serverRequest({
    url: `/reports/consolidate`,
    method: 'GET',
    params: {
      scenarioUuid: activeScenarioUuid,
      formulaUuids: formulasToFetch,
      scenariosToInclude: selectedScenarioUuids,
      startDate,
      endDate,
      additionalReports,
    },
    headers: { 'Organization-Uuid': organizationUuid },
    signal,
  })) as IAPIResponse;

  const parsedReportsResult = ZGraphData.parse(reportsResponse.data.data);

  return {
    reportData: parsedReportsResult,
  };
};
