import { StatusCodes } from 'http-status-codes';
import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Select, { useSelect } from '~/components/Select';
import { SelectType } from '~/components/Select/Select.types';
import { State } from '~/store';
import logger from '~/utils/logger';
import request from '~/utils/request';
import { update } from '~/store/organizationSlice';
const SelectAutomaticPullDay = (): React.ReactNode => {
  const dispatch = useDispatch();
  const organization = useSelector((state: State) => state.organization);
  const organizationConfiguration = useSelector((state: State) => state.organization.configuration);
  const optionsArray = Array.from({ length: 28 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));
  const [selectedAutomaticPullDay, setSelectedAutomaticPullDay] = useSelect({
    options: optionsArray,
    selected: {
      label: organizationConfiguration.autoPullDay.toString(),
      value: organizationConfiguration.autoPullDay.toString(),
    },
  });

  const onBlur = async (value: SelectType | undefined): Promise<void> => {
    try {
      if (value?.value && value.value !== organizationConfiguration.autoPullDay.toString()) {
        const response = await request({
          method: 'PATCH',
          url: `/organizations/${organization.uuid}/settings`,
          body: {
            autoPullDay: Number(value.value),
          },
        });
        if (response.status === StatusCodes.OK) {
          dispatch(
            update({
              ...organization,
              configuration: { ...organizationConfiguration, autoPullDay: Number(value.value) },
            }),
          );
        }
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to update automatic pull day');
      setSelectedAutomaticPullDay((prev) => ({
        ...prev,
        selected: {
          label: organizationConfiguration.autoPullDay.toString(),
          value: organizationConfiguration.autoPullDay.toString(),
        },
      }));
    }
  };

  return (
    <div className="w-[120px] mt-4">
      <Select
        id="automatic-pull-day"
        state={selectedAutomaticPullDay}
        setState={setSelectedAutomaticPullDay}
        label="Select Pull Date"
        placeholder="Day"
        onBlur={onBlur}
      />
    </div>
  );
};

export default SelectAutomaticPullDay;
