import React, { createContext, useEffect, useMemo, useState } from 'react';
import * as api from '~/services/parallel';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { IConversation, IMessageRoleEnum } from '../entity/types';
import { IBusinessContext } from '../components/Settings/entity/types';
import objectHash from 'object-hash';

interface IChatContext {
  allConversations: IConversation[];
  selectedConversationUuid: string | null;
  setSelectedConversationUuid: (conversationUuid: string | null) => Promise<void>;
  allConversationsDict: Record<string, IConversation>;
  setAllConversationsDict: React.Dispatch<React.SetStateAction<Record<string, IConversation>>>;
  sendMessage: ({
    conversationUuid,
    message,
    isRetry,
  }: {
    conversationUuid: string | null;
    message: string;
    isRetry?: boolean;
  }) => Promise<void>;
  conversationUuidsLoading: string[];
  focusTextInput: number;
  isLoading: boolean;
  businessContext: IBusinessContext;
  setBusinessContext: React.Dispatch<React.SetStateAction<IBusinessContext>>;
  showBusinessContextModal: boolean;
  setShowBusinessContextModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContext: IChatContext = {
  allConversations: [],
  selectedConversationUuid: null,
  setSelectedConversationUuid: () => Promise.resolve(),
  allConversationsDict: {},
  setAllConversationsDict: () => {},
  sendMessage: () => Promise.resolve(),
  conversationUuidsLoading: [],
  focusTextInput: 0,
  isLoading: true,
  businessContext: {
    updatedAt: new Date(),
    updatedBy: null,
    context: {
      summary: '',
      companyUrl: '',
      companyDescription: '',
      keyGoals: '',
      offeringDetails: '',
      fundingHistory: '',
      location: '',
    },
  },
  setBusinessContext: () => {},
  showBusinessContextModal: false,
  setShowBusinessContextModal: () => {},
};

export const ChatContext = createContext<IChatContext>(initialContext);

const PLACEHOLDER_CONVERSATION = {
  uuid: 'PLACEHOLDER',
  title: 'Placeholder',
  isRead: false,
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  messages: [],
  status: 'ok' as const,
};

export const ChatContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [selectedConversationUuid, setSelectedConversationUuid] = useState<string | null>(null);
  const [allConversationsDict, setAllConversationsDict] = useState<Record<string, IConversation>>({});
  const [conversationUuidsLoading, setConversationUuidsLoading] = useState<string[]>([]);
  const [focusTextInput, setFocusTextInput] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [businessContext, setBusinessContext] = useState<IBusinessContext>(initialContext.businessContext);
  const [showBusinessContextModal, setShowBusinessContextModal] = useState<boolean>(false);

  const fetchAllConversations = async (organizationUuid: string): Promise<void> => {
    try {
      setIsLoading(true);
      const conversations = await api.chat.getConversations({ organizationUuid });
      const conversationDict = conversations.reduce((acc: Record<string, IConversation>, conversation) => {
        acc[conversation.uuid] = conversation;
        return acc;
      }, {});
      setAllConversationsDict(conversationDict);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch conversations');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBusinessContext = async (organizationUuid: string): Promise<void> => {
    try {
      const businessContext = await api.businessContext.getBusinessContext({ organizationUuid });
      setBusinessContext(businessContext);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch business context');
    }
  };

  useEffect(() => {
    fetchAllConversations(organizationUuid);
    fetchBusinessContext(organizationUuid);
  }, [organizationUuid]);

  const fetchSelectedConversation = async (conversationUuid: string | null): Promise<void> => {
    try {
      if (!conversationUuid) {
        setSelectedConversationUuid(null);
      }

      if (conversationUuid && conversationUuid in allConversationsDict) {
        setSelectedConversationUuid(conversationUuid);
      } else if (conversationUuid) {
        const conversation = await api.chat.getConversation({ organizationUuid, conversationUuid });
        setAllConversationsDict((prev) => ({ ...prev, [conversationUuid]: conversation }));
        setSelectedConversationUuid(conversationUuid);
      }
      setFocusTextInput((prev) => prev + 1);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch conversation');
    }
  };

  const sendMessage = async ({
    conversationUuid,
    message,
    isRetry = false,
  }: {
    conversationUuid: string | null;
    message: string;
    isRetry?: boolean;
  }): Promise<void> => {
    const isNewConversation = !conversationUuid;
    const workingUuid = isNewConversation ? `new-${new Date().getTime()}-${objectHash(message)}` : conversationUuid;
    try {
      setConversationUuidsLoading((prev) => [...prev, workingUuid]);

      const messageToAdd = {
        role: IMessageRoleEnum.User,
        content: message,
      };

      let optimisticConversation;

      if (isNewConversation) {
        optimisticConversation = {
          ...PLACEHOLDER_CONVERSATION,
          uuid: workingUuid,
          title: message,
          messages: [messageToAdd],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
      } else {
        const currentConversation = allConversationsDict[conversationUuid];
        optimisticConversation = {
          ...currentConversation,
          messages: [
            ...currentConversation.messages,
            // Only add the message if not retrying
            ...(!isRetry ? [messageToAdd] : []),
          ],
        };
      }

      // Apply optimistic update
      setAllConversationsDict((prev) => ({
        ...prev,
        [workingUuid]: optimisticConversation,
      }));
      setSelectedConversationUuid(workingUuid);

      const updatedConversation = await api.chat.sendMessage({ organizationUuid, conversationUuid, message, isRetry });

      setAllConversationsDict((prev) => {
        if (isNewConversation) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [workingUuid]: _removed, ...rest } = prev;
          return {
            ...rest,
            [updatedConversation.uuid]: updatedConversation,
          };
        }

        // For existing conversations, just update the conversation
        return {
          ...prev,
          [updatedConversation.uuid]: updatedConversation,
        };
      });

      if (isNewConversation) {
        setSelectedConversationUuid((currentSelectedUuid) => {
          // Update the optimistic selected conversation to the new real uuid if it's still selected
          if (currentSelectedUuid === workingUuid) {
            return updatedConversation.uuid;
          }
          return currentSelectedUuid;
        });
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
    } finally {
      setConversationUuidsLoading((prev) => prev.filter((uuid) => uuid !== workingUuid));
    }
  };

  const allConversations = useMemo(() => {
    return Object.values(allConversationsDict);
  }, [allConversationsDict]);

  return (
    <ChatContext.Provider
      value={{
        allConversations,
        selectedConversationUuid,
        setSelectedConversationUuid: fetchSelectedConversation,
        allConversationsDict,
        setAllConversationsDict,
        sendMessage,
        conversationUuidsLoading,
        focusTextInput,
        isLoading,
        businessContext,
        setBusinessContext,
        showBusinessContextModal,
        setShowBusinessContextModal,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
