import React from 'react';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import Typography from '~/components/Typography';
import Button from '~/components/Button';

const UnusedExpenseDatasourcesTable = ({
  unusedExpenseMappings,
  onAction,
}: {
  unusedExpenseMappings: IIntegrationMapping[];
  onAction: (modalType: 'link' | 'create', datasourceUuid: string) => void;
}): React.ReactNode => {
  return (
    <div className="flex flex-col bg-white">
      <div className="sticky top-0 z-10 bg-white rounded-t-lg py-2 px-4 border-b border-neutral-50">
        <div className="flex justify-between items-center">
          <Typography color="empty">GL</Typography>
          <Typography color="empty">Actions</Typography>
        </div>
      </div>
      <div className="flex flex-col">
        {unusedExpenseMappings
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map((mapping) => {
            return (
              <div
                className="flex justify-between items-center py-2 px-4 border-t border-neutral-50"
                key={mapping.uuid}
              >
                <Typography>{mapping.name}</Typography>
                <div className="flex gap-4">
                  <Button className="!w-fit !px-5 !py-2" fill="outline" onClick={() => onAction('link', mapping.uuid)}>
                    Link with Existing
                  </Button>
                  <Button
                    className="!w-fit !px-5 !py-2"
                    onClick={() => {
                      onAction('create', mapping.uuid);
                    }}
                  >
                    Create Expense
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UnusedExpenseDatasourcesTable;
