import React, { useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import { type IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import Button from '~/components/Button';
import * as stringDate from '~/utils/stringDate';
import UnusedExpenseDatasourcesTable from './UnusedExpenseDatasourcesTable';

const UnusedExpenseDatasources = ({
  expenseIntegrationsWithMappings,
  onAction,
}: {
  expenseIntegrationsWithMappings: IIntegrationWithMappings[];
  onAction: (modalType: 'link' | 'create', datasourceUuid: string) => void;
}): React.ReactNode => {
  const [showUnusedExpenseMappings, setShowUnusedExpenseMappings] = useState(false);

  const unusedExpenseMappings = useMemo(() => {
    return expenseIntegrationsWithMappings
      .filter((integration) => integration.mappings.some((mapping) => !mapping.currentlyInUse))
      .flatMap((integration) => integration.mappings.filter((mapping) => !mapping.currentlyInUse));
  }, [expenseIntegrationsWithMappings]);

  const newExpenseMappings = useMemo(() => {
    return unusedExpenseMappings.filter((mapping) => {
      return stringDate.isAfter({
        comparison: stringDate.subtractDays(stringDate.getStringDate(new Date()), 14),
        dateToCheck: stringDate.getStringDate(new Date(mapping.createdAt)),
      });
    });
  }, [unusedExpenseMappings]);

  if (unusedExpenseMappings.length === 0) {
    return null;
  }

  return (
    <div className="relative flex flex-col mt-4 mx-10 px-10 py-3 bg-green-15 rounded-2xl">
      <div className="absolute top-1 right-0">
        <Button
          fill="clear"
          onClick={() => {
            setShowUnusedExpenseMappings(!showUnusedExpenseMappings);
          }}
        >
          {showUnusedExpenseMappings ? 'Hide' : 'View/Connect'}
        </Button>
      </div>
      <div
        className={`flex flex-row gap-2 transition-all duration-500 ease-in-out ${showUnusedExpenseMappings ? 'max-h-0 opacity-0' : 'max-h-6 opacity-100 '} `}
      >
        <Typography className="!text-green-400">{unusedExpenseMappings.length} Unlinked Data Sources</Typography>
        {!!newExpenseMappings.length && (
          <div className="bg-green-25 rounded-full px-2.5">
            <Typography color="lightGreen">{newExpenseMappings.length} New</Typography>
          </div>
        )}
      </div>
      <div
        className={`transition-all duration-500 ease-in-out ${
          showUnusedExpenseMappings ? 'max-h-[660px] opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="flex flex-col py-5">
          <Typography weight="bold">Connect Data Sources to Expense</Typography>
          <Typography color="secondary">
            Choose to add new expenses to your forecast, merge them with an existing forecasted expense, or exclude it
            from future projections
          </Typography>

          <div className="max-h-[550px] overflow-auto no-scrollbar my-2 rounded-lg border border-neutral-50">
            <UnusedExpenseDatasourcesTable onAction={onAction} unusedExpenseMappings={unusedExpenseMappings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnusedExpenseDatasources;
