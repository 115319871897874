import React, { useMemo } from 'react';
import { DndContext, PointerSensor, useSensor, useSensors, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from '~/components/Button';
import DraggableItem from '~/components/Formulas/FormulasTable/DraggableItem';
import { useState, useRef, useEffect } from 'react';
import Typography from '~/components/Typography';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import HoverPopover from '~/components/HoverPopover';
import { TrashIcon } from '@heroicons/react/24/outline';
import ContentEditableInput from '~/components/Input/ContentEditable';
import { useInput } from '~/components/Input';
import useTableContext from '../../../components/Formulas/context/useFormulaContext';
import { v4 as uuid } from 'uuid';
import { IGroupType } from '~/components/Formulas/context/types';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';
import DragHandle from '../../../components/Formulas/DraggableLabel/DragHandle';

interface IProps {
  onClose: () => void;
}

const EditableGroupName = ({
  groupName,
  onUpdate,
  isLatest,
}: {
  groupName: string;
  onUpdate: (name: string) => void;
  isLatest: boolean;
}): React.ReactNode => {
  const [state, setState] = useInput({ value: groupName });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isLatest && inputRef.current) {
      inputRef.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(inputRef.current);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }, [isLatest]);

  const handleBlur = (): void => {
    onUpdate(state.value);
  };

  return (
    <ContentEditableInput
      ref={inputRef}
      id={groupName}
      state={state}
      setState={setState}
      onBlur={handleBlur}
      className="py-[5px]"
    />
  );
};

const ManageGroups = ({ onClose }: IProps): React.ReactNode => {
  const [groupsToCreate, setGroupsToCreate] = useState<IGroupType[]>([]);
  const [groupsToUpdate, setGroupsToUpdate] = useState<IGroupType[]>([]);
  const [groupsToDelete, setGroupsToDelete] = useState<string[]>([]);

  // Could go right into ModelBuilderContext but this is easier for now as it is the pattern
  const { allFormulasData, updateFormulaGroups } = useTableContext();

  // Type check allFormulasData
  if (!allFormulasData.every((group): group is IGroupType => group.type === IRecordTypeEnum.Group))
    throw new Error('allFormulasData must only contain groups');

  const latestGroupRef = useRef<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
  );

  const handleDeleteGroup = (groupUuid: string): void => {
    setGroupsToUpdate((prevState) => prevState.filter((group) => group.formulaGroupUuid !== groupUuid));
    setGroupsToCreate((prevState) => prevState.filter((group) => group.formulaGroupUuid !== groupUuid));
    setGroupsToDelete((prevState) => [...prevState, groupUuid]);
  };

  const getUniqueGroupName = (groupNames: string[]): string => {
    let newName = 'New Group';
    let counter = 0;
    while (groupNames.includes(newName)) {
      counter += 1;
      newName = `New Group (${counter})`;
    }

    return newName;
  };

  const handleUpdateGroup = (formulaGroupUuid: string, name: string, sortOrder: number): void => {
    // Check if group exists in groupsToCreate first ("updating" a group that has not been created yet)
    const existingCreateIndex = groupsToCreate.findIndex((item) => item.formulaGroupUuid === formulaGroupUuid);
    if (existingCreateIndex >= 0) {
      setGroupsToCreate((prevState) => {
        const newState = [...prevState];
        newState[existingCreateIndex] = {
          ...newState[existingCreateIndex],
          name,
          sortOrder,
        };
        return newState;
      });
    } else {
      setGroupsToUpdate((prevState) => {
        const groupExistsInUpdate = prevState.find((item) => item.formulaGroupUuid === formulaGroupUuid);

        if (groupExistsInUpdate) {
          return prevState.map((item) => {
            if (item.formulaGroupUuid === formulaGroupUuid) {
              return { ...item, name, sortOrder };
            }
            return item;
          });
        } else {
          const groupToUpdate = allFormulasData.find((item) => item.formulaGroupUuid === formulaGroupUuid);
          if (!groupToUpdate) throw new Error('Group not found in allFormulasData');
          return [...prevState, { ...groupToUpdate, name, sortOrder }];
        }
      });
    }
  };

  const groupsToRender = useMemo(() => {
    return allFormulasData
      .filter((group) => !groupsToDelete.includes(group.formulaGroupUuid))
      .map((group) => {
        const groupToUpdate = groupsToUpdate.find((update) => update.formulaGroupUuid === group.formulaGroupUuid);
        if (groupToUpdate) {
          return {
            ...group,
            name: groupToUpdate.name,
            sortOrder: groupToUpdate.sortOrder,
          };
        }
        return group;
      })
      .reduce(
        (acc, group) => {
          acc.push(group);
          return acc;
        },
        [...groupsToCreate],
      )
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }, [allFormulasData, groupsToCreate, groupsToUpdate, groupsToDelete]);

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const activeGroup = groupsToRender.find((item) => item.formulaGroupUuid === active.id);
      const overGroup = groupsToRender.find((item) => item.formulaGroupUuid === over.id);

      if (!activeGroup || !overGroup) return;

      // const activeIndex = groupsToRender.findIndex((item) => item.formulaGroupUuid === active.id);
      const overIndex = groupsToRender.findIndex((item) => item.formulaGroupUuid === over.id);

      // Calculate new sort order
      let newSortOrder: number;
      // Check if dragging up or down
      const activeIndex = groupsToRender.findIndex((item) => item.formulaGroupUuid === active.id);
      const isDraggingUp = activeIndex > overIndex;
      const dragDirectionModifier = isDraggingUp ? 0 : 1;

      const previousGroup = groupsToRender[overIndex - 1 + dragDirectionModifier];
      const afterGroup = groupsToRender[overIndex + dragDirectionModifier];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (previousGroup && !afterGroup) {
        // End of the list
        newSortOrder = previousGroup.sortOrder + 10;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (!previousGroup && afterGroup) {
        // Start of the list
        newSortOrder = afterGroup.sortOrder - 10;
      } else {
        // Middle of the list
        newSortOrder = previousGroup.sortOrder + (afterGroup.sortOrder - previousGroup.sortOrder) / 2;
      }

      // Reuse handleUpdateGroup to update the sort order
      handleUpdateGroup(activeGroup.formulaGroupUuid, activeGroup.name, newSortOrder);
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full mt-3">
      <div className="flex flex-col gap-2">
        <DndContext sensors={sensors} onDragOver={handleDragOver} modifiers={[restrictToVerticalAxis]}>
          <SortableContext
            items={groupsToRender.map((group) => group.formulaGroupUuid)}
            strategy={verticalListSortingStrategy}
          >
            {groupsToRender.map((group) => {
              const hasAttributes = group.formulas.length > 0;
              const DeleteButtonContent = ({ disabled }: { disabled?: boolean }): React.ReactNode => {
                return (
                  <Button
                    className="!w-auto !px-1"
                    fill="clear"
                    onClick={() => handleDeleteGroup(group.formulaGroupUuid)}
                    disabled={disabled}
                  >
                    <TrashIcon
                      className={`size-[18px] ${
                        hasAttributes ? 'text-neutral-50' : 'text-neutral-200 hover/sortableitem:text-red-500'
                      }`}
                    />
                  </Button>
                );
              };
              return (
                <DraggableItem
                  key={group.formulaGroupUuid}
                  id={group.formulaGroupUuid}
                  iconAlwaysVisible
                  className="bg-white border border-neutral-50 rounded-md"
                  liveUpdate
                >
                  <DragHandle />
                  <div className="flex justify-between items-center w-full py-[3px]">
                    <div className="flex">
                      <EditableGroupName
                        groupName={group.name}
                        onUpdate={(value) => handleUpdateGroup(group.formulaGroupUuid, value, group.sortOrder)}
                        isLatest={group.formulaGroupUuid === latestGroupRef.current}
                      />
                    </div>
                    <div className="pr-2">
                      {!hasAttributes ? (
                        <DeleteButtonContent />
                      ) : (
                        <HoverPopover
                          buttonContent={<DeleteButtonContent disabled />}
                          panelContent={
                            <div className="p-3 bg-white w-[300px] flex flex-col justify-start items-start gap-4">
                              <Typography>Unable to delete a group unless it has no attributes</Typography>
                            </div>
                          }
                          panelClassName="shadow-lg rounded-xl"
                          hoverDelay={500}
                        />
                      )}
                    </div>
                  </div>
                </DraggableItem>
              );
            })}
          </SortableContext>
        </DndContext>
        <Button
          className="w-full !px-3 text-left !justify-start !bg-green-15 hover:!bg-green-25 text-green border-none"
          onClick={() => {
            setGroupsToCreate((prevState) => [
              ...prevState,
              {
                uuid: uuid(),
                formulaGroupUuid: uuid(),
                name: getUniqueGroupName(prevState.map((group) => group.name)),
                sortOrder: groupsToRender[groupsToRender.length - 1].sortOrder + 10,
                formulas: [],
                type: IRecordTypeEnum.Group,
                isCollapsed: false,
              },
            ]);
          }}
        >
          Add Group
        </Button>
      </div>
      <div className="w-full flex justify-between gap-2">
        <Button className="!w-auto !px-0" fill="clear" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="!w-auto"
          onClick={() => {
            // Only trigger an update if the groups have changed
            if (groupsToCreate.length || groupsToUpdate.length || groupsToDelete.length) {
              updateFormulaGroups({
                create: groupsToCreate,
                update: groupsToUpdate,
                delete: groupsToDelete,
              });
              setGroupsToCreate([]);
              setGroupsToUpdate([]);
              setGroupsToDelete([]);
            }
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ManageGroups;
