import React, { ReactElement } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Header, Row, Table, flexRender } from '@tanstack/react-table';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Typography from '~/components/Typography';
import { getCommonPinningStyles } from './getCommonPinningStyles';
import { SortableItem } from './SortableItem';
import Button from '~/components/Button';
import { format, isValid, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const TableRow = ({
  table,
  row,
  styles,
  updateGroup,
  deleteGroup,
  setDragDisabled,
  dragMode,
}: {
  table: Table<Record<string, unknown>>;
  row: Row<Record<string, unknown>>;
  styles?: {
    tRow?: string;
    td?: string;
  };
  updateGroup?: (groupName: string) => void;
  deleteGroup?: (groupName: string) => void;
  setDragDisabled: (dragDisabled: boolean) => void;
  dragMode: {
    isDragging: boolean;
    isGroup: boolean;
  };
}): ReactElement => {
  const columnCount = table.getHeaderGroups().reduce((acc, headerGroup) => headerGroup.headers.length, 0);

  const disableTextSelection = (): void => {
    document.body.style.userSelect = 'none';
  };

  const enableTextSelection = (): void => {
    setDragDisabled(false);
    document.body.style.userSelect = '';
  };

  return (
    <SortableItem id={row.id} className={styles?.tRow}>
      {row
        .getVisibleCells()
        .filter((cell, index) => {
          // Only render a single cell for the top level group row
          if (row.depth === 0) {
            return index === 0;
          }
          return true;
        })
        .map((cell) => {
          let associatedHeader: Header<Record<string, unknown>, unknown> | undefined;
          if (cell.column.getCanResize()) {
            const headerGroups = table.getHeaderGroups();
            associatedHeader = headerGroups[0].headers.find((header) => header.column.id === cell.column.id);
          }

          const groupName = row.original.groupName as string;
          const subRows = row.original.subRows as unknown[];

          if (row.depth === 0) {
            return (
              <React.Fragment key={`${cell.id}-${row.id}`}>
                <td
                  className={`${styles?.td ? ` ${styles.td}` : ''} left-0 z-10 sticky${dragMode.isDragging ? ' !cursor-move' : ''}`}
                  colSpan={2}
                >
                  <div className={`ml-4 flex items-center gap-2`}>
                    {row.getIsExpanded() ? (
                      <>
                        <Button
                          fill="clear"
                          className="!w-auto flex items-center gap-2 !px-0"
                          onClick={row.getToggleExpandedHandler()}
                        >
                          <ChevronUpIcon className="size-5 cursor-pointer" />
                          <Typography weight="bold">{groupName}</Typography>
                        </Button>
                        <div className="bg-neutral-25 size-6 flex items-center justify-center rounded-full">
                          <Typography color="lightGray" className="!no-underline">
                            {subRows.length}
                          </Typography>
                        </div>
                        {row.original.groupName === 'Ungrouped Attributes' ? null : (
                          <EllipsisDropdown
                            color="text-neutral-100"
                            id={`expense-ellipsis-dropdown-${groupName}`}
                            options={[
                              {
                                label: 'Rename',
                                onClick: (): void => updateGroup && updateGroup(groupName),
                                className: 'max-sm:hidden',
                              },
                              {
                                label: 'Delete',
                                onClick: (): void => deleteGroup && deleteGroup(groupName),
                                className: 'text-red-500 max-sm:hidden',
                              },
                            ]}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          fill="clear"
                          className="!w-auto flex items-center gap-2 !px-0"
                          onClick={row.getToggleExpandedHandler()}
                        >
                          <ChevronDownIcon className="size-5 cursor-pointer" />
                          <Typography weight="bold">{groupName}</Typography>
                        </Button>
                        <div className="bg-neutral-25 size-6 flex items-center justify-center rounded-full">
                          <Typography color="lightGray" className="!no-underline">
                            {subRows.length}
                          </Typography>
                        </div>
                        {row.original.groupName === 'Ungrouped Attributes' ? null : (
                          <EllipsisDropdown
                            color="text-neutral-100"
                            id={`expense-ellipsis-dropdown-${groupName}`}
                            options={[
                              {
                                label: 'Rename',
                                onClick: (): void => updateGroup && updateGroup(groupName),
                                className: 'max-sm:hidden',
                              },
                              {
                                label: 'Delete',
                                onClick: (): void => deleteGroup && deleteGroup(groupName),
                                className: 'text-red-500 max-sm:hidden',
                              },
                            ]}
                          />
                        )}
                      </>
                    )}
                  </div>
                </td>
                <td className={`${styles?.td ? ` ${styles.td}` : ''}`} colSpan={columnCount - 2} />
              </React.Fragment>
            );
          }

          return (
            <td
              key={`${cell.id}-${row.id}`}
              className={`${styles?.td ? ` ${styles.td}` : ''} relative${dragMode.isDragging ? ' !cursor-move' : ''} ${isValid(parseISO(cell.column.id)) && format(toZonedTime(cell.column.id, 'UTC'), 'MMM') === 'Dec' ? 'border-r border-gray-200' : ''}`}
              style={
                cell.column.getCanResize()
                  ? {
                      ...getCommonPinningStyles({
                        column: cell.column,
                        type: 'cell',
                      }),
                      width: cell.column.getSize(),
                      maxWidth: cell.column.getSize(),
                      minWidth: cell.column.getSize(),
                    }
                  : undefined
              }
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
              {cell.column.getCanResize() && (
                <div
                  onMouseDown={(e) => {
                    setDragDisabled(true);
                    associatedHeader?.getResizeHandler()(e);
                    disableTextSelection();
                    document.addEventListener('mouseup', enableTextSelection, {
                      once: true,
                    });
                  }}
                  onTouchStart={(e) => {
                    associatedHeader?.getResizeHandler()(e);
                    disableTextSelection();
                    document.addEventListener('touchend', enableTextSelection, {
                      once: true,
                    });
                  }}
                  className="absolute flex pl-1 w-[5px] h-full right-0 top-0 cursor-col-resize"
                >
                  <div
                    className="bg-gray-200 h-full w-[1px] cursor-col-resize"
                    data-testid={`${cell.column.id}-${cell.getValue().formulaTitle}-resize-handle`}
                  />
                </div>
              )}
            </td>
          );
        })}
    </SortableItem>
  );
};
