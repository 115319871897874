import Header from '~/components/Header';
import Divider from '~/components/Divider';
import OrganizationSettings from './OrganizationSettings';
import React from 'react';
import Departments from './Departments';
import Users from './Users';
import Integrations from './Integrations';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';

const SettingsContainer = (): React.ReactNode => {
  const userRole = useSelector((state: State) => state.user.role);

  return (
    <div className="w-full h-full flex flex-col">
      <Header title="Settings" />
      <div className="flex w-full h-full justify-center px-10 mb-10 pb-40">
        <div className="max-w-[880px] w-full flex flex-col !items-start">
          <div className="w-full py-12 flex flex-col gap-6">
            {userRole === 'support' && <OrganizationSettings />}
            <Departments />
            <Integrations />
            <Divider />
            <Users />
            <Link to="/auth/logout" className="!w-fit mt-4">
              <Button fill="outline" className="!w-fit !px-5">
                Log Out
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
