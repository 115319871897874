import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../baseQuery';
import { type IScenario } from '../../scenarios.types';
import { formulasApi } from '../formulas/formulasApi';
import {
  ICancelScenarioParams,
  ICreateScenarioParams,
  IDeleteScenarioParams,
  IFetchScenarioParams,
  IListScenariosParams,
  IMergeScenarioParams,
  IRestoreScenarioParams,
  IUpdateScenarioParams,
} from './scenariosRequestSchemas';
import { changeLogApi } from '../changeLogApi';

export const scenariosApi = createApi({
  reducerPath: 'scenariosApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Scenario', 'Formula'],
  endpoints: (builder) => ({
    listScenarios: builder.query<IScenario[], IListScenariosParams>({
      query: ({ orgUuid, purpose }) => ({
        url: `organizations/${orgUuid}/scenarios`,
        params: {
          purpose,
        },
      }),
      transformResponse: (response: { data: IScenario[] }) => {
        return response.data;
      },
      keepUnusedDataFor: 300, // 5 minutes
      providesTags: ['Scenario'],
    }),
    fetchScenario: builder.query<IScenario, IFetchScenarioParams>({
      query: ({ orgUuid, scenarioUuid }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: IScenario }) => {
        return response.data;
      },
      providesTags: ['Scenario'],
    }),
    createScenario: builder.mutation<IScenario, ICreateScenarioParams>({
      query: ({ orgUuid, params, body }) => ({
        url: `organizations/${orgUuid}/scenarios`,
        method: 'POST',
        params,
        body,
      }),
      transformResponse: (response: { data: IScenario }) => {
        return response.data;
      },
      invalidatesTags: ['Scenario'],
    }),
    updateScenario: builder.mutation<IScenario, IUpdateScenarioParams>({
      query: ({ orgUuid, scenarioUuid, scenarioTitle, updateEditModeStart }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}`,
        method: 'PATCH',
        body: { changeDescription: scenarioTitle, updateEditModeStart },
      }),
      transformResponse: (response: { data: IScenario }) => {
        return response.data;
      },
      invalidatesTags: ['Scenario'],
    }),
    cancelScenario: builder.mutation<void, ICancelScenarioParams>({
      query: ({ orgUuid, scenarioUuid }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}/cancel`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Scenario'],
    }),
    restoreScenario: builder.mutation<IScenario, IRestoreScenarioParams>({
      query: ({ orgUuid, scenarioUuid }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}/restore`,
        method: 'PATCH',
      }),
      transformResponse: (response: { data: IScenario }) => {
        return response.data;
      },
      invalidatesTags: ['Scenario'],
    }),
    deleteScenario: builder.mutation<void, IDeleteScenarioParams>({
      query: ({ orgUuid, scenarioUuid }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Scenario'],
    }),
    mergeScenario: builder.mutation<void, IMergeScenarioParams>({
      query: ({ orgUuid, scenarioUuid, scenariosToAwait }) => ({
        url: `organizations/${orgUuid}/scenarios/${scenarioUuid}/merge`,
        method: 'POST',
        params: {
          scenariosToAwait,
        },
      }),
      invalidatesTags: ['Scenario'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(formulasApi.util.invalidateTags(['Formula']));
        dispatch(changeLogApi.util.invalidateTags(['ChangeLog']));
      },
    }),
  }),
});

export const {
  useListScenariosQuery,
  useCreateScenarioMutation,
  useFetchScenarioQuery,
  useMergeScenarioMutation,
  useUpdateScenarioMutation,
  useCancelScenarioMutation,
  useRestoreScenarioMutation,
  useDeleteScenarioMutation,
} = scenariosApi;
