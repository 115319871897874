import React, { useContext } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import { ChatContext } from '../../context/ChatContext';

const NextPrompts = ({
  nextPrompts,
  conversationUuid,
}: {
  nextPrompts: string[];
  conversationUuid: string | null;
}): React.ReactElement => {
  const { sendMessage } = useContext(ChatContext);

  return (
    <div className="flex flex-col w-full">
      <Typography weight="extralight" color="empty">
        RELATED
      </Typography>
      {nextPrompts.map((prompt, index) => (
        <div
          key={prompt}
          className={`flex items-center justify-between py-2 cursor-pointer ${
            index !== nextPrompts.length - 1 && 'border-b border-neutral-50'
          } group/next`}
          onClick={() => sendMessage({ conversationUuid, message: prompt })}
        >
          <Typography size="sm" color="lightGray" className="max-w-[95%] group-hover/next:!text-green-400">
            {prompt}
          </Typography>
          <PlusIcon className="size-4 text-green-400 transition-transform duration-800 group-hover/next:!text-green-500 group-hover/next:!rotate-90" />
        </div>
      ))}
    </div>
  );
};

export default NextPrompts;
