import React from 'react';
import Typography from '~/components/Typography';

const TargetDateIcon = (): React.ReactElement => {
  return (
    <div className="flex items-center w-full">
      <div className="flex flex-col w-[130px]">
        <Typography weight="bold">Target Date</Typography>
        <Typography color="secondary">Has a date & value specified</Typography>
      </div>
      <div className="w-[50%] flex items-end justify-end">
        <svg width="97" height="77" viewBox="0 0 97 77" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="49" cy="38.2505" r="38.2505" fill="#EAEFE8" />
          <path
            d="M3.21924 21.1767C3.21924 18.133 5.68669 15.6655 8.73045 15.6655H91.2429C94.2866 15.6655 96.7541 18.133 96.7541 21.1767V61.3595C96.7541 64.4033 94.2866 66.8707 91.2429 66.8707H8.73045C5.68669 66.8707 3.21924 64.4033 3.21924 61.3595V21.1767Z"
            fill="#DFE5DC"
          />
          <path
            d="M0.832732 19.1285C0.832732 16.275 3.14597 13.9618 5.9995 13.9618H88.5119C91.3654 13.9618 93.6787 16.275 93.6787 19.1285V59.3113C93.6787 62.1648 91.3654 64.4781 88.5119 64.4781H5.99949C3.14597 64.4781 0.832732 62.1648 0.832732 59.3113V19.1285Z"
            fill="white"
            stroke="#C6C6C6"
            strokeWidth="0.688902"
          />
          <line x1="19.6047" y1="24.2575" x2="84.4647" y2="24.2575" stroke="#F3F3F3" strokeWidth="0.56733" />
          <line x1="19.6047" y1="38.5949" x2="84.4647" y2="38.5949" stroke="#F3F3F3" strokeWidth="0.56733" />
          <line x1="19.6047" y1="54.2978" x2="84.4647" y2="54.2978" stroke="#F3F3F3" strokeWidth="0.56733" />
          <rect x="7.99854" y="23.6556" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <rect x="7.99854" y="27.3838" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <rect x="7.99854" y="31.112" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <path
            d="M24.2761 52.9733C38.596 52.9733 38.2588 41.1283 57.432 39.1911C75.0333 37.4127 73.8053 25.8535 85.676 24.5197"
            stroke="#BFC9BA"
            strokeWidth="1.13466"
            strokeLinecap="round"
          />
          <path
            d="M61.5632 30.8231C59.5779 28.8377 59.5779 25.6188 61.5632 23.6335C63.5486 21.6481 66.7674 21.6481 68.7528 23.6335C70.7381 25.6188 70.7381 28.8377 68.7528 30.8231L65.158 34.4178L61.5632 30.8231Z"
            fill="white"
          />
          <path
            d="M61.5632 30.8231C59.5779 28.8377 59.5779 25.6188 61.5632 23.6335C63.5486 21.6481 66.7674 21.6481 68.7528 23.6335C70.7381 25.6188 70.7381 28.8377 68.7528 30.8231L65.158 34.4178L61.5632 30.8231Z"
            stroke="#7F9376"
            strokeWidth="0.638312"
          />
          <g clipPath="url(#clip0_1627_21626)">
            <path
              d="M65.023 25.0977C65.0711 24.9822 65.2347 24.9822 65.2827 25.0977L65.8142 26.3755C65.8344 26.4242 65.8802 26.4575 65.9328 26.4617L67.3124 26.5723C67.437 26.5823 67.4876 26.7379 67.3926 26.8193L66.3415 27.7196C66.3015 27.7539 66.284 27.8078 66.2962 27.8591L66.6173 29.2053C66.6464 29.327 66.514 29.4231 66.4073 29.3579L65.2262 28.6365C65.1812 28.609 65.1246 28.609 65.0796 28.6365L63.8985 29.3579C63.7917 29.4231 63.6593 29.327 63.6884 29.2053L64.0095 27.8591C64.0217 27.8078 64.0042 27.7539 63.9642 27.7196L62.9131 26.8193C62.8181 26.7379 62.8687 26.5823 62.9934 26.5723L64.3729 26.4617C64.4255 26.4575 64.4713 26.4242 64.4915 26.3755L65.023 25.0977Z"
              fill="#7F9376"
            />
          </g>
          <defs>
            <clipPath id="clip0_1627_21626">
              <rect width="6" height="6" fill="white" transform="translate(62.1528 24.223)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default TargetDateIcon;
