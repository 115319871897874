import React, { useState, useEffect } from 'react';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { createInvitation, editPermissions, revokeAccess } from '~/utils/userPermissionRequests';
import toast from 'react-hot-toast';
import useUserFormState from './UserPermissions/useUserFormState';
import UserPermissions from './UserPermissions';
import RevokeAccess from './RevokeAccess';
import FormatTableData from './FormatTableData';
import ReactivateUser from './ReactivateUser';
import logger from '~/utils/logger';
import { IUserRole } from '~/utils/waitForStoreRehydration';

export interface IUser {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  role: IUserRole;
  organizations: {
    name: string;
  }[];
  permissions: {
    organizationUuid: string;
    departmentAccessList: string[];
    uuid: string;
    userUuid: string;
    role: string;
    isActive: boolean;
    receiveAutomatedEmails: boolean;
  }[];
}

interface IInvitation {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  role: string;
  organizationUuid: string;
  acceptedAt: string | null;
}

interface IInvitationResponse {
  data: { data?: IInvitation[] };
  status: number;
}

interface IUserResponse {
  data: { data?: IUser[] };
  status: number;
}

const filterInvitedUsers = (users: IUser[], invitations: IInvitation[]): IUser[] => {
  const invitationEmails = new Set(invitations.map((invitation) => invitation.email));
  const filteredUsers = users.filter((user) => !invitationEmails.has(user.email));
  return filteredUsers;
};

const Users = (): React.ReactNode => {
  const { queryParams, setQueryParams } = useQueryParams();
  const [revokeUserAccessModal, setRevokeUserAccessModal] = useState(queryParams.get('revokeUserAccess') ?? null);
  const [revokeInvitationAccessModal, setRevokeInvitationAccessModal] = useState(
    queryParams.get('revokeInvitationAccess') ?? null,
  );
  const [editUserModal, setEditUserModal] = useState(queryParams.get('editUser') ?? null);
  const [editInvitationModal, setEditInvitationModal] = useState(queryParams.get('editInvitation') ?? null);
  const [isLoading, setIsLoading] = useState(true);
  const [invitations, setInvitations] = useState<IInvitation[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [addUser, setAddUser] = useState(false);
  const [fromReactivate, setFromReactivate] = useState<boolean>(false);
  const [showConfirmReactivate, setShowConfirmReactivate] = useState<boolean>(false);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const userUuid = useSelector((state: State) => state.user.uuid);
  const currentUserEmail = useSelector((state: State) => state.user.email);
  const { userName, setUserName, userEmail, setUserEmail, resetFormState } = useUserFormState();

  const getPageData = async (): Promise<void> => {
    setIsLoading(true);

    let invitationsData: IInvitation[] = [];
    let usersData: IUser[] = [];

    const invitationsResponse = (await request({
      url: `/organizations/${organizationUuid}/invitations`,
      method: 'GET',
    })) as IInvitationResponse;

    if (invitationsResponse.status === 200 && invitationsResponse.data.data) {
      invitationsData = invitationsResponse.data.data;
    } else {
      logger.error(new Error('Failed to fetch invitations'));
    }

    const usersResponse = (await request({
      url: `/organizations/${organizationUuid}/users`,
      method: 'GET',
    })) as IUserResponse;

    if (usersResponse.status === 200 && usersResponse.data.data) {
      usersData = filterInvitedUsers(usersResponse.data.data, invitationsData);
    } else {
      logger.error(new Error('Failed to fetch users'));
    }

    setInvitations(invitationsData);
    setUsers(usersData);

    setIsLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, [organizationUuid]);

  useEffect(() => {
    const activeUserExists = users.find(
      (user) =>
        user.email === userEmail.value &&
        user.permissions.find((permission) => permission.organizationUuid === organizationUuid && permission.isActive),
    );
    if (activeUserExists && addUser) {
      const updatedUserEmail = { ...userEmail };
      updatedUserEmail.errorMessage = 'An account with this email already exists';
      updatedUserEmail.valid = false;
      updatedUserEmail.pristine = false;
      updatedUserEmail.touched = true;
      setUserEmail(updatedUserEmail);
    } else {
      const updatedUserEmail = { ...userEmail };
      updatedUserEmail.errorMessage = 'Email is required';
      setUserEmail(updatedUserEmail);
    }
  }, [userEmail.value]);

  useEffect(() => {
    const newQueryParams: {
      revokeUserAccess?: string;
      revokeInvitationAccess?: string;
      editUser?: string;
      editInvitation?: string;
    } = {};

    if (revokeUserAccessModal) {
      newQueryParams.revokeUserAccess = revokeUserAccessModal;
    }

    if (revokeInvitationAccessModal) {
      newQueryParams.revokeInvitationAccess = revokeInvitationAccessModal;
    }

    if (editUserModal) {
      newQueryParams.editUser = editUserModal;
    }

    if (editInvitationModal) {
      newQueryParams.editInvitation = editInvitationModal;
    }

    setQueryParams(newQueryParams);
  }, [revokeUserAccessModal, revokeInvitationAccessModal, editUserModal]);

  const handleEdit = ({
    type,
    editUuid,
    editName,
    editEmail,
  }: {
    type?: 'user' | 'invitation';
    editUuid: string;
    editName: string;
    editEmail: string;
  }): void => {
    if (type === 'user') setEditUserModal(editUuid);
    else setEditInvitationModal(editUuid);

    setUserName({
      ...userName,
      value: editName,
    });
    setUserEmail({
      ...userEmail,
      value: editEmail,
    });
  };

  const createNewInvitation = async (): Promise<void> => {
    await createInvitation({
      organizationUuid,
      name: userName.value,
      email: userEmail.value,
    });
    setAddUser(false);
    resetFormState();
    getPageData();
  };

  const revokeAccessDisplay = !!revokeUserAccessModal || !!revokeInvitationAccessModal;

  const setRevokeAccessModal = (): void => {
    if (revokeUserAccessModal) {
      setRevokeUserAccessModal(null);
    }

    if (revokeInvitationAccessModal) {
      setRevokeInvitationAccessModal(null);
    }
  };

  const handleRevokeAccess = async (): Promise<void> => {
    try {
      if (revokeUserAccessModal) {
        await revokeAccess({
          organizationUuid,
          uuid: revokeUserAccessModal,
          type: 'user',
        });
        setRevokeUserAccessModal(null);
      }

      if (revokeInvitationAccessModal) {
        await revokeAccess({
          organizationUuid,
          uuid: revokeInvitationAccessModal,
          type: 'invitation',
        });
        setRevokeInvitationAccessModal(null);
      }

      toast.success('Access revoked successfully');

      getPageData();
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to revoke access');
    }
  };

  const isOpen = addUser || !!editUserModal || !!editInvitationModal;
  const setModal = (): void => {
    if (addUser) {
      setAddUser(false);
    }

    if (editUserModal) {
      setEditUserModal(null);
    }

    if (editInvitationModal) {
      setEditInvitationModal(null);
    }

    if (showConfirmReactivate) {
      setShowConfirmReactivate(false);
    }

    resetFormState();
  };

  const handleUserPermissions = async (): Promise<void> => {
    const userExists = users.find((user) => user.email === userEmail.value);

    if (addUser && !userExists) {
      await createNewInvitation();
      return;
    }

    if (addUser && userExists) {
      if (
        showConfirmReactivate &&
        !userExists.permissions.find(
          (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
        )
      ) {
        await createNewInvitation();
        setShowConfirmReactivate(false);
      } else if (
        !userExists.permissions.find(
          (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
        )
      ) {
        setShowConfirmReactivate(true);
      }
      return;
    }

    const orgPermission = userExists?.permissions.find(
      (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
    );

    if (userExists && !orgPermission) {
      if (showConfirmReactivate || fromReactivate) {
        await createNewInvitation();
        setShowConfirmReactivate(false);
      } else {
        setShowConfirmReactivate(true);
      }
    } else {
      let editType: 'users' | 'invitations';

      if (editUserModal) editType = 'users';
      else editType = 'invitations';

      await editPermissions({
        organizationUuid,
        type: editType,
        uuid: editUserModal ?? editInvitationModal,
        name: userName.value,
        email: userEmail.value,
        isActive: orgPermission?.isActive ?? false,
      });
      toast.success('Permissions updated successfully');
    }

    getPageData();
    setEditInvitationModal(null);
    setEditUserModal(null);
    setFromReactivate(false);
    resetFormState();
    setModal();
  };

  return (
    <div className="w-full">
      <UserPermissions
        isOpen={isOpen}
        setModal={setModal}
        createInvitation={() => handleUserPermissions()}
        userName={userName}
        setUserName={setUserName}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        resetFormState={resetFormState}
      />
      <RevokeAccess isOpen={revokeAccessDisplay} setModal={setRevokeAccessModal} submitFunction={handleRevokeAccess} />
      <ReactivateUser
        isOpen={showConfirmReactivate}
        close={() => setShowConfirmReactivate(false)}
        reactivate={handleUserPermissions}
      />
      <div className="w-full flex flex-row items-center justify-between mt-2">
        <Typography weight="bold">User Permissions</Typography>
        <Button id="add-new-user" fill="outline" className="!w-auto" onClick={() => setAddUser(true)}>
          Add User
        </Button>
      </div>
      {FormatTableData(
        [
          ...invitations.map((invitation) => {
            const dropdownOptions = [
              {
                label: 'Resend Invitation',
                onClick: () =>
                  createInvitation({
                    organizationUuid,
                    name: invitation.name,
                    email: invitation.email,
                  }),
                className: 'text-md',
              },
              {
                label: 'Edit Information',
                onClick: () =>
                  handleEdit({
                    type: 'invitation',
                    editUuid: invitation.uuid,
                    editName: invitation.name,
                    editEmail: invitation.email,
                  }),
                className: 'text-md',
              },
              {
                label: 'Revoke Access',
                onClick: () => setRevokeInvitationAccessModal(invitation.uuid),
                className: 'text-red-400 text-md',
              },
            ];

            return {
              uuid: invitation.uuid,
              name: invitation.name,
              email: invitation.email,
              status: 'Pending Approval',
              options: dropdownOptions,
              receiveMonthlyEmails: true,
            };
          }),
          ...users.map((user) => {
            const dropdownOptions =
              user.uuid === userUuid
                ? []
                : user.permissions.find(
                      (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
                    )
                  ? [
                      {
                        label: 'Edit User',
                        onClick: (): void =>
                          handleEdit({
                            type: 'user',
                            editUuid: user.uuid,
                            editName: user.name,
                            editEmail: user.email,
                          }),
                        className: 'text-md',
                      },
                      {
                        label: 'Revoke Access',
                        onClick: (): void => setRevokeUserAccessModal(user.uuid),
                        className: 'text-md text-red-400',
                      },
                    ]
                  : [
                      {
                        label: 'Reactivate User',
                        onClick: (): void => {
                          setFromReactivate(true);
                          handleEdit({
                            type: 'user',
                            editUuid: user.uuid,
                            editName: user.name,
                            editEmail: user.email,
                          });
                        },
                        className: 'text-md',
                      },
                    ];

            return {
              name: user.name,
              email: user.email,
              status: user.permissions.find(
                (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
              )
                ? 'Active'
                : 'Revoked',
              uuid: user.permissions[0].uuid,
              options: dropdownOptions,
              isUser: user.email === currentUserEmail,
              receiveMonthlyEmails:
                user.permissions.find(
                  (permission) => permission.organizationUuid === organizationUuid && permission.isActive,
                )?.receiveAutomatedEmails ?? false,
              userUuid: user.uuid,
            };
          }),
        ],
        isLoading,
        getPageData,
      )}
    </div>
  );
};

export default Users;
