import React, { useEffect } from 'react';
import Authenticated from '../ShareLink/components/Authenticated';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import request from '~/utils/request';
import { dashboardConfigToFormulasToFetch } from '../Dashboard/utils/dashboardConfigToFormulasToFetch';
import { IAPIResponse } from '~/utils/types';
import { StatusCodes } from 'http-status-codes';
import * as stringDate from '~/utils/stringDate';
import { IConsolidatedGraphData, IGraphData } from '~/services/parallel/reports.types';

const PreviewShareLink = (): React.ReactElement => {
  const [queryParams] = useSearchParams();
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const scenarioUuid = queryParams.get('scenarioUuid');
  const organizationName = queryParams.get('organizationName');
  const includeFinancialModel = queryParams.get('includeFinancialModel') === 'true';
  const [reportData, setReportData] = React.useState<IGraphData>({ consolidated: {}, static: {} });

  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  const fetchReportData = async (): Promise<void> => {
    try {
      const response = (await request({
        url: `/reports/consolidate`,
        method: 'GET',
        headers: { 'Organization-Uuid': organizationUuid },
        params: {
          startDate,
          endDate,
          formulaUuids: dashboardConfigToFormulasToFetch({ dashboardConfiguration }),
          additionalReports: dashboardConfiguration.metrics.includes('Runway') ? ['Runway'] : [],
          shareLinkScenario: scenarioUuid && scenarioUuid !== 'false' ? scenarioUuid : undefined,
        },
      })) as IAPIResponse<IGraphData>;

      if (response.status === StatusCodes.OK) {
        /** Filter goals from share link */
        const filteredData = {
          consolidated: response.data.data.consolidated
            ? Object.entries(response.data.data.consolidated).reduce((acc, [key, value]) => {
                acc[key] = { ...value, companyGoals: [] };
                return acc;
              }, {} as IConsolidatedGraphData)
            : {},
          static: response.data.data.static,
        };
        setReportData(filteredData);
      } else {
        toast.error('Failed to fetch dashboard data');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch dashboard data');
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  return (
    <Authenticated
      preview
      previewReportData={reportData}
      previewStartDate={startDate ?? stringDate.getStringDate()}
      previewEndDate={endDate ?? stringDate.getStringDate()}
      previewScenarioUuid={scenarioUuid ?? undefined}
      previewOrganizationName={organizationName ?? undefined}
      includeFinancialModel={includeFinancialModel}
    />
  );
};

export default PreviewShareLink;
