import React, { useEffect, useState, useRef } from 'react';
import Modal from '../Modal';
import { IChangeLogActionEnum, IChangeLogElementTypeEnum, IChangeLogElementWithIndex } from './entity/types';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ChangeLogElement from './components/ChangeLogElement';
import Skeleton from 'react-loading-skeleton';
import { IFormula } from '~/services/parallel/formulas.types';
import Select, { useSelect } from '../Select';
import Input, { useInput } from '../Input/InputWrapper';
import { filterChangeLog } from './utils/filterChangeLog';
import { changeLogApi } from '~/services/parallel/api/changeLogApi';
import { IVersionsToRemove } from '~/services/parallel/api/organization/requestSchemas';
import { organizationApi } from '~/services/parallel/api/organization/organizationApi';

const ChangeLogModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { ratiosEnabled, contractsEnabled } = useSelector((state: State) => state.organization.configuration);
  const [rollback] = organizationApi.useRollbackMutation();
  const scenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { data, isLoading, refetch } = changeLogApi.useGetChangeLogQuery(
    { scenarioUuid },
    { skip: !organizationUuid || !isOpen },
  );
  const [companyChangeLog, setCompanyChangeLog] = useState<IChangeLogElementWithIndex[]>([]);
  const [formulaDictionary, setFormulaDictionary] = useState<Record<string, IFormula>>({});
  const [filteredChangeLog, setFilteredChangeLog] = useState<IChangeLogElementWithIndex[]>([]);
  const [searchTitle, setSearchTitle] = useInput({
    validation: /.*/,
  });
  const [changeTypeFilter, setChangeTypeFilter] = useSelect({
    options: [
      { label: 'All', value: 'all' },
      { label: 'Created', value: IChangeLogActionEnum.Created },
      { label: 'Updated', value: IChangeLogActionEnum.Updated },
      { label: 'Deleted', value: IChangeLogActionEnum.Deleted },
    ],
  });
  const [entityTypeFilter, setEntityTypeFilter] = useSelect({
    options: [
      { label: 'All', value: 'all' },
      { label: 'Formula', value: IChangeLogElementTypeEnum.Formula },
      { label: 'Expense', value: IChangeLogElementTypeEnum.Expense },
      { label: 'Department', value: IChangeLogElementTypeEnum.Department },
      { label: 'Position', value: IChangeLogElementTypeEnum.Position },
      ...(ratiosEnabled ? [{ label: 'Ratio', value: IChangeLogElementTypeEnum.Ratio }] : []),
      ...(contractsEnabled ? [{ label: 'Contract', value: IChangeLogElementTypeEnum.Contract }] : []),
    ],
  });
  const [displayedItems, setDisplayedItems] = useState<number>(50);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (): void => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollHeight - scrollTop - clientHeight < 100) {
      setDisplayedItems((prev) => Math.min(prev + 50, filteredChangeLog.length));
    }
  };

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isLoading && isOpen && organizationUuid) {
      if (!data) {
        toast.error('Failed to fetch company change log');
        return;
      }
      setCompanyChangeLog(data.changeLog.map((changeLog, index) => ({ ...changeLog, index })));
      setFormulaDictionary(data.formulasDict);
    }
  }, [data, isLoading, isOpen, organizationUuid]);

  useEffect(() => {
    setFilteredChangeLog(
      filterChangeLog({
        changeLog: companyChangeLog,
        changeTypeFilter: changeTypeFilter.selected?.value ?? 'all',
        entityTypeFilter: entityTypeFilter.selected?.value ?? 'all',
        searchTitle: searchTitle.value,
      }),
    );
  }, [companyChangeLog, changeTypeFilter, entityTypeFilter, searchTitle]);

  useEffect(() => {
    setEntityTypeFilter((prev) => ({
      ...prev,
      options: [
        ...prev.options.filter(
          (option) =>
            option.value !== IChangeLogElementTypeEnum.Ratio && option.value !== IChangeLogElementTypeEnum.Contract,
        ),
        ...(ratiosEnabled ? [{ label: 'Ratio', value: IChangeLogElementTypeEnum.Ratio }] : []),
        ...(contractsEnabled ? [{ label: 'Contract', value: IChangeLogElementTypeEnum.Contract }] : []),
      ],
    }));
  }, [ratiosEnabled, contractsEnabled]);

  useEffect(() => {
    setDisplayedItems(50);
  }, [companyChangeLog, changeTypeFilter, entityTypeFilter, searchTitle]);

  const loadingSkeleton = (
    <>
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[56px] w-full rounded-xl'} baseColor="#F8F9F6" />
    </>
  );

  const handleRollback = async ({
    rollbackIndex,
    rollbackDate,
  }: {
    rollbackIndex: number;
    rollbackDate: string;
  }): Promise<void> => {
    try {
      const logsToDelete = companyChangeLog.filter(
        (changeLog) => typeof changeLog.index === 'number' && changeLog.index <= rollbackIndex,
      );

      const versionsToRemove: IVersionsToRemove = {
        formulas: [],
        positions: [],
        expenses: [],
        departments: [],
        contracts: [],
        ratios: [],
        changeLogGroups: [],
        scenariosToRestore: [],
      };

      const assignVersionsToRemove = (log: IChangeLogElementWithIndex): void => {
        switch (log.type) {
          case IChangeLogElementTypeEnum.Formula:
            versionsToRemove.formulas.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.Position:
            versionsToRemove.positions.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.Expense:
            versionsToRemove.expenses.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.Department:
            versionsToRemove.departments.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.Contract:
            versionsToRemove.contracts.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.Ratio:
            versionsToRemove.ratios.push(log.newVersionUuid);
            break;
          case IChangeLogElementTypeEnum.ChangeLogGroup:
            if (log.subElements.length) {
              log.subElements.map(assignVersionsToRemove);
            }
            if (log.mergedResourceUuid) {
              versionsToRemove.scenariosToRestore.push(log.mergedResourceUuid);
            }
            versionsToRemove.changeLogGroups.push(log.newVersionUuid);
            break;
        }
      };

      logsToDelete.map((log) => {
        assignVersionsToRemove(log);
      });

      await rollback({
        params: {
          organizationUuid,
        },
        body: {
          rollbackDate,
          versionsToRemove,
        },
      }).unwrap();

      window.location.reload();
    } catch (error) {
      toast.error('Failed to rollback');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Change History" showClose size="xl">
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className="flex flex-col w-full px-4 py-2 bg-neutral-15 rounded-xl  h-[60vh] overflow-y-scroll mt-4"
      >
        <div className="flex gap-2 mb-4">
          <div className="w-[250px] p-1">
            <Input
              state={searchTitle}
              setState={setSearchTitle}
              id="search-title"
              placeholder="Search by title"
              type="search"
            />
          </div>
          <div className="w-[250px] p-1">
            <Select
              state={changeTypeFilter}
              setState={setChangeTypeFilter}
              id="change-type-filter"
              placeholder="Filter by type"
            />
          </div>
          <div className="w-[250px] p-1">
            <Select
              state={entityTypeFilter}
              setState={setEntityTypeFilter}
              id="entity-type-filter"
              placeholder="Filter by entity"
            />
          </div>
        </div>
        <div className="flex flex-col max-h-[95%]">
          {isLoading
            ? loadingSkeleton
            : filteredChangeLog
                .slice(0, displayedItems)
                .map((changeLog) => (
                  <ChangeLogElement
                    key={changeLog.originalVersionUuid + changeLog.newVersionUuid + changeLog.action}
                    changeLogElement={changeLog}
                    formulaDictionary={formulaDictionary}
                    handleRollback={handleRollback}
                  />
                ))}
          {!isLoading && displayedItems < filteredChangeLog.length && (
            <div className="h-10">
              <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChangeLogModal;
