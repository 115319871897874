import React, { useRef } from 'react';
import { IUpdatePositionRequestBody } from '~/services/parallel/api/positions/positionsRequestSchemas';
import Input, { useInput } from '~/components/Input/InputWrapper';
import toast from 'react-hot-toast';
import { IPositionDetails } from '~/pages/Headcount/entity/types';

const CellInput = React.memo(
  ({
    id,
    value,
    isScenario,
    isActive,
    positionUuid,
    inputValidation,
    onUpdate,
  }: {
    id: string;
    value?: string | null;
    isScenario: boolean;
    isActive: boolean;
    positionUuid: string;
    inputValidation: RegExp;
    onUpdate: ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
    }: {
      positionUuid: string;
      positionUpdates: IUpdatePositionRequestBody;
      awaitCalculations?: boolean;
    }) => Promise<{ data: IPositionDetails; headers: Record<string, string> }>;
  }): React.ReactNode => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useInput({
      value: value ?? '',
      validation: inputValidation,
      disabled: !isActive,
    });

    const onBlur = async (): Promise<void> => {
      if (inputValue.value !== value && inputValue.valid) {
        try {
          await onUpdate({
            positionUuid,
            positionUpdates: { [id]: inputValue.value },
          });
        } catch (error) {
          toast.error('Failed to update value');
          setInputValue((prev) => ({
            ...prev,
            value: value ?? '',
          }));
        }
      }
    };

    return (
      <div className={`min-w-36 ${isScenario ? 'bg-blue-15' : ''}`}>
        <Input
          id={`${id}-${positionUuid}`}
          type="text"
          onBlur={onBlur}
          state={inputValue}
          setState={setInputValue}
          textRef={inputRef}
          className={`
          !border-transparent ${isActive && 'hover:!border hover:!border-neutral-100'} !shadow-none 
          ${isScenario && 'bg-blue-15 text-blue-400'}
        `}
        />
      </div>
    );
  },
);

CellInput.displayName = 'CellInput';

export default CellInput;
