import React, { useEffect, useState } from 'react';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import parallelWordmarkLogo from '~/assets/parallelWordmarkLogo.svg';
import {
  ComputerDesktopIcon,
  MegaphoneIcon,
  PuzzlePieceIcon,
  ServerStackIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { IActiveSelectionEnum, type ISegmentState } from '../types';
import { ExpenseSection } from './sections/ExpenseSection';
import { FinalizeSection } from './sections/FinalizeSection';
import { InitialSection } from './sections/InitialSection';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import { useSelector } from 'react-redux';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import type { State } from '~/store';
import { Segment } from './navigation/Segment';
import { ExpenseModal } from '../ExpenseForm/ExpenseModal';
import { FooterControls } from './navigation/FooterControls';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';

interface ModalState {
  type: 'edit' | null;
  formulaUuid: string | null;
}

const CloseGenerateExpensesModal = ({ onClose }: { onClose: () => void }): React.ReactNode => {
  return (
    <button
      onClick={onClose}
      type="button"
      data-testid="close-auto-generate-expenses"
      className="absolute top-2 right-4"
    >
      <XMarkIcon className="size-6 text-neutral-100 hover:text-neutral-200" />
    </button>
  );
};

export const GenerateExpensesModal = ({
  isOpen,
  onClose,
  integrationsWithMappings,
}: {
  isOpen: boolean;
  onClose: () => void;
  integrationsWithMappings: IIntegrationWithMappings[];
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [activeSection, setActiveSection] = useState<IActiveSelectionEnum>(IActiveSelectionEnum.INITIAL);

  const [modalState, setModalState] = useState<ModalState>({ type: null, formulaUuid: null });

  const initialSegmentState: ISegmentState = {
    disabled: true,
    touched: false,
    selected: false,
  };
  const [peopleAndFacilitiesSegmentState, setPeopleAndFacilitiesSegmentState] =
    useState<ISegmentState>(initialSegmentState);
  const [cogsSegmentState, setCogsSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [softwareExpensesSegmentState, setSoftwareExpensesSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [marketingSegmentState, setMarketingSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [otherSegmentState, setOtherSegmentState] = useState<ISegmentState>(initialSegmentState);

  const {
    data: scenarios,
    isLoading: isLoadingScenarios,
    error: scenariosError,
  } = scenariosApi.useListScenariosQuery({
    orgUuid: organizationUuid,
    purpose: ['expenseGen'],
  });

  const scenarioUuid = scenarios?.[0]?.uuid;

  const {
    data: expenseFormulas,
    isLoading: isLoadingExpenseFormulas,
    error: formulasError,
  } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: scenarioUuid ?? undefined,
      types: [IFormulaTypeEnum.Expense, IFormulaTypeEnum.Headcount],
    },
    { skip: !scenarioUuid },
  );

  const isLoading = isLoadingScenarios || isLoadingExpenseFormulas;
  const error = scenariosError || formulasError;

  useEffect(() => {
    const updateSegmentState = (
      setSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>,
      isActive: boolean,
    ): void => {
      setSegmentState((prev) => ({
        ...prev,
        selected: isActive,
        touched: isActive || prev.touched,
      }));
    };

    updateSegmentState(
      setPeopleAndFacilitiesSegmentState,
      activeSection === IActiveSelectionEnum.PEOPLE_AND_FACILITIES,
    );
    updateSegmentState(setCogsSegmentState, activeSection === IActiveSelectionEnum.COGS);
    updateSegmentState(setSoftwareExpensesSegmentState, activeSection === IActiveSelectionEnum.SOFTWARE);
    updateSegmentState(setMarketingSegmentState, activeSection === IActiveSelectionEnum.MARKETING);
    updateSegmentState(setOtherSegmentState, activeSection === IActiveSelectionEnum.OTHER);
  }, [activeSection]);

  const segments = (
    <div className="flex flex-col gap-4">
      <Segment
        segmentState={peopleAndFacilitiesSegmentState}
        icon={UserGroupIcon}
        label="People & Facilities"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.PEOPLE_AND_FACILITIES)}
      />
      <Segment
        segmentState={cogsSegmentState}
        icon={ServerStackIcon}
        label="Cost of Goods Sold"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.COGS)}
      />
      <Segment
        segmentState={softwareExpensesSegmentState}
        icon={ComputerDesktopIcon}
        label="Software"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.SOFTWARE)}
      />
      <Segment
        segmentState={marketingSegmentState}
        icon={MegaphoneIcon}
        label="Marketing"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.MARKETING)}
      />
      <Segment
        segmentState={otherSegmentState}
        icon={PuzzlePieceIcon}
        label="Other"
        setAsActiveSegment={() => setActiveSection(IActiveSelectionEnum.OTHER)}
      />
    </div>
  );

  const renderContent = (): React.ReactNode => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center w-full h-screen">
          <div className="flex flex-col items-center gap-4">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
            <Typography>Loading data...</Typography>
          </div>
        </div>
      );
    }

    if (error || !scenarioUuid) {
      return (
        <div className="flex items-center justify-center w-full h-screen">
          <div className="flex flex-col items-center gap-4 max-w-md text-center">
            <Typography>
              {error instanceof Error ? error.message : 'Failed to load necessary data. Please try again.'}
            </Typography>
            <button
              onClick={onClose}
              className="mt-4 px-4 py-2 bg-primary-500 text-white rounded-md hover:bg-primary-600"
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-row relative w-full">
        <CloseGenerateExpensesModal onClose={onClose} />
        <div className="flex flex-col gap-4 h-full px-12 bg-green-15">
          <div>
            <img src={parallelWordmarkLogo} alt="Parallel" className="h-6 mb-6 mt-14" />
          </div>
          {segments}
        </div>
        <ExpenseModal
          isOpen={['create', 'edit'].includes(modalState.type ?? '')}
          onClose={() => setModalState({ type: null, formulaUuid: null })}
          formulaUuid={modalState.formulaUuid ?? ''}
          scenarioUuid={scenarioUuid}
          integrationsWithMappings={integrationsWithMappings}
          onDeleteExpense={() => {}}
        />
        <div className="flex-1 flex flex-col min-h-screen">
          <div className="flex-1">
            {((): React.ReactNode => {
              switch (activeSection) {
                case IActiveSelectionEnum.INITIAL:
                  return <InitialSection />;
                case IActiveSelectionEnum.FINALIZE:
                  return <FinalizeSection />;
                default:
                  return (
                    <ExpenseSection
                      activeSection={activeSection}
                      expenseFormulas={expenseFormulas ?? []}
                      scenarioUuid={scenarioUuid}
                      integrationsWithMappings={integrationsWithMappings}
                      setModalState={(formulaUuid) => setModalState({ type: 'edit', formulaUuid })}
                    />
                  );
              }
            })()}
          </div>
          {scenarioUuid && (
            <FooterControls
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              scenarioUuid={scenarioUuid}
              onClose={onClose}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      size="full"
      height="full"
      isOpen={isOpen}
      onClose={onClose}
      styles={{
        headerClassName: '!h-0',
        panelDivClassName: '!p-0 !min-h-[100vh]',
        childrenClassName: ' !min-h-[100vh]',
      }}
    >
      {renderContent()}
    </Modal>
  );
};

export default GenerateExpensesModal;
