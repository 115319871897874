import React, { useEffect } from 'react';
import SegmentedControlUnderlined from '~/components/SegmentedControlUnderlined';
import logo from '~/assets/parallelLogoIcon.svg';
import Button from '~/components/Button';
import { ChartBarIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IFormulaData } from '~/components/Formulas/context/types';
import { convertModelToCsvData } from '~/pages/FinancialModel/utils/convertModelToCsvData';
import { getFilteredFormulaGroups } from '../../utils/getFilteredFormulaGroups';
import useScreenWidth from '~/utils/hooks/useScreenWidth';
import { IStringDate } from '~/utils/stringDate/types';

const ShareLinkHeader = ({
  selectedView,
  setSelectedView,
  permissionedFormulaUuids,
  startDate,
  endDate,
  organizationName,
  includeFinancialModel,
}: {
  selectedView: string;
  setSelectedView: (value: string) => void;
  permissionedFormulaUuids: string[];
  startDate: IStringDate;
  endDate: IStringDate;
  organizationName: string;
  includeFinancialModel?: boolean;
}): React.ReactElement => {
  const screenWidth = useScreenWidth();
  const { allFormulasData } = useFormulaContext();

  useEffect(() => {
    if (screenWidth < 775 && selectedView !== 'dashboard') {
      setSelectedView('dashboard');
    }
  }, [screenWidth]);

  const handleExportCSV = ({
    allFormulasData,
    permissionedFormulaUuids,
  }: {
    allFormulasData: IFormulaData[];
    permissionedFormulaUuids: string[];
  }): void => {
    const filteredFormulasData = getFilteredFormulaGroups({ formulaGroups: allFormulasData, permissionedFormulaUuids });
    const csvData = convertModelToCsvData({
      modelData: filteredFormulasData,
      startDate,
      endDate,
    });
    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${organizationName} - Financial Model Export.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleSetSelectView = (value: string): void => {
    setSelectedView(value);
  };

  const dashboardLabel = (
    <div className="flex gap-2 justify-center items-center">
      <ChartBarIcon className="size-5" />
      <span>Dashboard</span>
    </div>
  );

  const spreadsheetLabel = (
    <div className="flex gap-2 justify-center items-center">
      <TableCellsIcon className="size-5" />
      <span>Spreadsheet</span>
    </div>
  );

  return (
    <div className="w-full flex items-center justify-between px-7 py-5">
      <a
        href="https://getparallel.com"
        className={`${screenWidth >= 775 ? 'w-[158px]' : 'w-fit'}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={logo} alt="Parallel" className="h-6" />
      </a>
      {screenWidth >= 775 && includeFinancialModel && (
        <>
          <div className="w-[360px]">
            <SegmentedControlUnderlined
              name="selectInteractiveSection"
              segments={[
                { value: 'dashboard', label: dashboardLabel },
                { value: 'spreadsheet', label: spreadsheetLabel },
              ]}
              value={selectedView}
              setValue={handleSetSelectView}
              includedActiveBackgroundColor
            />
          </div>
          <Button
            fill="outlineSolid"
            className="!w-fit"
            onClick={() => handleExportCSV({ allFormulasData, permissionedFormulaUuids })}
          >
            Download CSV
          </Button>
        </>
      )}
      {/* If the financial model is not included, we only show the dashboard view. Need to add an empty div to keep proper alignment. */}
      {screenWidth >= 775 && !includeFinancialModel && (
        <>
          <div className="w-[360px]">
            <SegmentedControlUnderlined
              name="selectInteractiveSection"
              segments={[{ value: 'dashboard', label: dashboardLabel }]}
              value={selectedView}
              setValue={handleSetSelectView}
              includedActiveBackgroundColor
            />
          </div>
          <div></div>
        </>
      )}
    </div>
  );
};

export default ShareLinkHeader;
