import React, { Fragment } from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import Typography from '~/components/Typography';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Divider from '~/components/Divider';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ComparisonItem from './ComparisonItem';
import BaseModelAsOfItem from './BaseModelAsOfItem';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import Spinner from '~/components/Spinner';
import { scenariosToDisplay } from '~/services/parallel/scenarios.types';

const ComparisonDropdown = (): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { data: scenarios, isLoading: isLoadingScenarios } = scenariosApi.useListScenariosQuery({
    orgUuid: organizationUuid,
    purpose: Object.values(scenariosToDisplay),
  });
  const compareHistoricalModel = useFeatureFlagHarness('compareHistoricalModel');

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            id="scenario-dropdown-button"
            data-testid="scenario-dropdown-button"
            className="focus:outline-none focus:ring-0 focus:border-transparent"
          >
            <div className="flex text-green-400 hover:text-green-500 !w-fit !py-2 !pl-0 !pr-1 gap-1 items-center justify-center">
              Compare
              {open ? (
                <ChevronUpIcon width={16} height={16} strokeWidth={2.125} />
              ) : (
                <ChevronDownIcon width={16} height={16} strokeWidth={2.125} />
              )}
            </div>
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className={`absolute z-20 transform left-0 mt-1 above`}>
              <div className="flex flex-col w-[440px] bg-white rounded-lg shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.20)] max-h-[320px] pb-2 overflow-y-auto">
                <Typography weight="bold" className="mt-4 px-6">
                  Compare
                </Typography>
                <Divider className="my-2.5 px-6" />
                {isLoadingScenarios ? (
                  <div className="flex flex-row items-center justify-center h-full gap-2">
                    <Typography>Loading scenarios</Typography>
                    <Spinner size={20} strokeColor="#95AFBB" strokeWidth={4} />
                  </div>
                ) : (
                  <>
                    {compareHistoricalModel && <BaseModelAsOfItem />}
                    {scenarios?.map((scenario) => <ComparisonItem key={scenario.uuid} scenario={scenario} />)}
                  </>
                )}
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ComparisonDropdown;
