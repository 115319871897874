import { IRoundingInstructions } from '~/components/Formulas/context/types';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';

const roundValue = ({
  value,
  isMin,
  roundingInstructions,
  isStraightLine,
}: {
  value: number;
  isMin: boolean;
  roundingInstructions?: IRoundingInstructions;
  isStraightLine: boolean;
}): number => {
  if (!roundingInstructions) return value;
  const scaledPrecision = roundingInstructions.precision * CENTS_PER_DOLLAR;
  const SCALE_MULTIPLIER = isStraightLine ? 10 : 1;

  if (isMin) {
    return Math.floor(value / scaledPrecision) * scaledPrecision * SCALE_MULTIPLIER;
  } else {
    return Math.ceil(value / scaledPrecision) * scaledPrecision * SCALE_MULTIPLIER;
  }
};

export const getMinMaxValue = ({
  consolidatedGraphData,
  formatting,
}: {
  consolidatedGraphData: IConsolidatedGraphBody;
  formatting: IFormattingEnum;
}): { minValue: number; maxValue: number } => {
  let isStraightLine = true;
  const initialValue = consolidatedGraphData.data[0].workingModel;
  const { minGraphValue, maxGraphValue } = consolidatedGraphData.data.reduce(
    (acc: { minGraphValue: number; maxGraphValue: number }, item: Record<string, number>) => {
      Object.keys(item).forEach((key) => {
        if (key !== 'date') {
          const value = item[key];
          if (typeof value === 'number') {
            if (value < acc.minGraphValue) {
              acc.minGraphValue = value;
            }
            if (value > acc.maxGraphValue) {
              acc.maxGraphValue = value;
            }
          }
          if (isStraightLine && value !== initialValue) {
            isStraightLine = false;
          }
        }
      });
      return acc;
    },
    {
      minGraphValue: Number.MAX_SAFE_INTEGER,
      maxGraphValue: Number.MIN_SAFE_INTEGER,
    },
  );
  let minMaxMultiplier = 0.2; // Arbitrary multiplier to set the graph min and max y values to give 20% cushion from the data min and max
  const maxValue = maxGraphValue < 0 ? 0 : maxGraphValue;
  const minValue = minGraphValue > 0 ? 0 : minGraphValue;
  /*
   * If the max & min absolute value is less than 1,000 (as the user sees it) then
   * scaling the graph to be double the max value makes more sense
   */
  if (Math.abs(maxValue) < 100000 && Math.abs(maxValue) > 1000) {
    minMaxMultiplier = 1;
  }

  // These should handle percentages
  let maxRangeIfZero = 100;
  let minRangeIfZero = -10;

  if (formatting !== IFormattingEnum.Percent) {
    maxRangeIfZero = 1000;
    minRangeIfZero = -1000;
  }

  /*
   * If min and max value are 0, set the y min and max to -100 and 100 because the minMaxMultiplier logic
   * will make all values 0. This provides a buffer
   */
  if (minValue === 0 && maxValue === 0) {
    return {
      minValue: roundValue({
        value: minRangeIfZero,
        isMin: true,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
      maxValue: roundValue({
        value: maxRangeIfZero,
        isMin: false,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
    };
    // Zero on a small scaled graph is rough with autoscaling up and down so I'm giving it a buffer
  } else if (maxGraphValue === 0) {
    return {
      minValue: roundValue({
        value: minValue - Math.abs(minValue) * minMaxMultiplier,
        isMin: true,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
      maxValue: roundValue({
        value: formatting === IFormattingEnum.Percent ? 1 : Math.abs(minValue) * 0.1,
        isMin: false,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
    };
  } else if (minGraphValue === 0) {
    return {
      minValue: roundValue({
        value: formatting === IFormattingEnum.Percent ? -1 : -Math.abs(maxValue) * 0.1,
        isMin: true,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
      maxValue: roundValue({
        value: maxValue + Math.abs(maxValue) * minMaxMultiplier,
        isMin: false,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
    };
  } else {
    return {
      minValue: roundValue({
        value: minValue - Math.abs(minValue) * minMaxMultiplier,
        isMin: true,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
      maxValue: roundValue({
        value: maxValue + Math.abs(maxValue) * minMaxMultiplier,
        isMin: false,
        roundingInstructions: consolidatedGraphData.roundingInstructions,
        isStraightLine,
      }),
    };
  }
};
