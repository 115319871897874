import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '~/components/Button';
import InputWrapper from '~/components/Input/InputWrapper';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import FormulaBuilderInput from './FormulaBuilderInput';
import { IRoundDirectionEnum, IVariables } from '../../entity/types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { IFormattingEnum, VariableTypeEnum } from '../../entity/schemas';
import validateFormula from '../../utils/validateFormula';
import isEqual from 'lodash.isequal';
import toast from 'react-hot-toast';
import Checkbox from '~/components/Checkbox';
import SegmentedControl from '~/components/SegmentedControl';
import Select from '~/components/Select';
import { FormulaBuilderContext, FormulaBuilderProvider } from './FormulaBuilderContext';
import logger from '~/utils/logger';
import HoverPopover from '~/components/HoverPopover';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { IIntegrationMapping } from '~/utils/schemas/integrations';
import SelectMultiple from '~/components/SelectMultiple';
import { IFormula } from '~/services/parallel/formulas.types';
import { IRoundingInstructions } from '~/components/Formulas/context/types';

interface IProps {
  state: {
    isOpen: boolean;
    mode: 'create' | 'edit';
    formulaTitle?: string;
    formulaUuid?: string;
    formulaData?: {
      topLevelFormulaUuid?: string;
      formula: string;
      variables: IVariables;
      formulaList: IFormula[];
      editable?: boolean;
      isProtected?: boolean;
    };
    dataSourceUuids: string[];
    variables?: IVariables;
    roundingInstructions?: IRoundingInstructions;
    formatting?: IFormattingEnum | null;
  };
  cancel: () => void;
  confirm: () => void;
  parsedFormulas: {
    list: IFormula[];
    sorting: {
      name: string;
      sortOrder: string[];
    }[];
  };
  revalidate: () => void;
  revalidateLoading: boolean;
  dataSources?: IIntegrationMapping[];
}

const FormulaBuilderContainer = ({
  state,
  cancel,
  confirm,
  parsedFormulas,
  revalidate,
  revalidateLoading,
  dataSources,
}: IProps): React.ReactNode => {
  const {
    updatedFormula,
    setUpdatedFormula,
    roundingPrecisionState,
    setRoundingPrecisionState,
    roundingDirection,
    setRoundingDirection,
    showRounding,
    setShowRounding,
    displayFormulaError,
    setDisplayFormulaError,
    variables,
    setVariables,
    formula,
    setFormula,
    value,
    setValue,
    attributeTitle,
    setAttributeTitle,
    resetForm,
    isLoading,
    setIsLoading,
    dataSourceState,
    setDataSourceState,
    formatting,
    setFormatting,
  } = useContext(FormulaBuilderContext);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [showDataSourceOptions, setShowDataSourceOptions] = useState(Boolean(dataSourceState.selected?.length));

  const linkedFormulas = useMemo(() => {
    return parsedFormulas.list.filter((formula) => {
      return Object.values(formula.recipe.variables).some((variable) => {
        return variable.formulaUuid === state.formulaUuid;
      });
    });
  }, [parsedFormulas, state.formulaUuid]);

  useEffect(() => {
    setIsLoading(revalidateLoading);
  }, [revalidateLoading]);

  useEffect(() => {
    if (formatting === IFormattingEnum.Currency) {
      setRoundingPrecisionState((prev) => ({
        ...prev,
        options: prev.options.filter((option) => parseFloat(option.value ?? '0') >= 1),
      }));
    } else {
      setRoundingPrecisionState((prev) => ({
        ...prev,
        options: [
          ...prev.options.filter((option) => parseFloat(option.value ?? '0') >= 1),
          { label: '1 Decimal Place', value: '0.1' },
          { label: '2 Decimal Places', value: '0.01' },
        ],
      }));
    }
  }, [formatting]);

  useEffect(() => {
    if (state.roundingInstructions && state.mode === 'edit') {
      if (state.roundingInstructions.direction === IRoundDirectionEnum.Up) {
        setRoundingDirection('up');
      } else if (state.roundingInstructions.direction === IRoundDirectionEnum.Down) {
        setRoundingDirection('down');
      } else {
        setRoundingDirection('nearest');
      }

      let selectedPrecision = { label: 'Whole Number', value: '1' };

      if (state.roundingInstructions.precision === 10) {
        selectedPrecision = { label: 'Nearest 10', value: '10' };
      } else if (state.roundingInstructions.precision === 100) {
        selectedPrecision = { label: 'Nearest 100', value: '100' };
      } else if (state.roundingInstructions.precision === 1000) {
        selectedPrecision = { label: 'Nearest 1000', value: '1000' };
      } else if (state.roundingInstructions.precision === 10000) {
        selectedPrecision = { label: 'Nearest 10000', value: '10000' };
      } else if (state.roundingInstructions.precision === 100000) {
        selectedPrecision = { label: 'Nearest 100000', value: '100000' };
      } else if (state.roundingInstructions.precision === 0.1) {
        selectedPrecision = { label: '1 Decimal Place', value: '0.1' };
      } else if (state.roundingInstructions.precision === 0.01) {
        selectedPrecision = { label: '2 Decimal Places', value: '0.01' };
      }

      setRoundingPrecisionState((prev) => ({
        ...prev,
        selected: selectedPrecision,
      }));

      setShowRounding(true);
    } else {
      setRoundingDirection('nearest');
      setRoundingPrecisionState((prev) => ({
        ...prev,
        selected: { label: 'Whole Number', value: '1' },
      }));
      setShowRounding(false);
    }
  }, [state.isOpen]);

  useEffect(() => {
    if (state.formatting === 'number') {
      setFormatting(IFormattingEnum.Number);
    } else if (state.formatting === 'currency') {
      setFormatting(IFormattingEnum.Currency);
    } else if (state.formatting === 'percent') {
      setFormatting(IFormattingEnum.Percent);
    }
  }, [state.isOpen]);

  useEffect(() => {
    if (!revalidateLoading) {
      setIsLoading(false);
      confirm();
      resetForm();
    }
  }, [revalidateLoading]);

  useEffect(() => {
    if (state.isOpen) {
      setAttributeTitle((prev) => ({
        ...prev,
        value: state.formulaTitle ?? '',
        valid: prev.validation.test(state.formulaTitle ?? ''),
        disabled: state.formulaData?.isProtected,
      }));
      if (dataSources) {
        setDataSourceState((prevState) => {
          const matchingDataSources = dataSources.filter((ds) => state.dataSourceUuids.includes(ds.uuid));
          return {
            ...prevState,
            options: [
              ...dataSources.map((ds) => ({
                label: ds.name,
                value: ds.uuid,
                disabled: ds.currentlyInUse && !state.dataSourceUuids.includes(ds.uuid),
              })),
            ],
            selected: matchingDataSources.length
              ? matchingDataSources.map((ds) => ({
                  label: ds.name,
                  value: ds.uuid,
                }))
              : [],
          };
        });
      }
    }
  }, [state.isOpen, dataSources]);

  const cancelFormulation = (): void => {
    setShowDataSourceOptions(false);
    cancel();
    resetForm();
  };

  const handleDelete = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = (await request({
        url: `/formulas/${state.formulaUuid}`,
        method: 'DELETE',
        headers: { 'Organization-Uuid': organizationUuid },
        params: { scenarioUuid: activeScenarioUuid ?? undefined },
      })) as IAPIResponse;
      if (response.status < 400) {
        revalidate();
      } else {
        toast.error('Failed to delete formula');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const isNotReferencedInOtherFormulas = useMemo(() => {
    return !parsedFormulas.list.some((formula) => {
      return Object.values(formula.recipe.variables).some((variable) => {
        return variable.formulaUuid === state.formulaUuid;
      });
    });
  }, [parsedFormulas, state.formulaUuid]);

  const handleSaveFormulation = async (): Promise<void> => {
    setIsLoading(true);
    const recipeName = attributeTitle.value.trim();
    const formulaToValidate = [...updatedFormula];
    let expression = updatedFormula.map((f) => f.textValue).join('');
    const recipeVariables: IVariables = { ...variables };
    if (value.length) {
      expression += `$${Number(Object.keys(variables).length) + 1}`;
      formulaToValidate.push({
        element: <span key={value}>{value}</span>,
        ref: null,
        textValue: `$${Number(Object.keys(variables).length) + 1}`,
        type: 'constant',
      });
      recipeVariables[`$${Number(Object.keys(variables).length) + 1}`] = {
        type: VariableTypeEnum.Constant,
        constantValue: Number(value),
        formulaUuid: null,
        timeModifier: {},
        calculationType: null,
      };
    }
    const { validated, errorMessage } = validateFormula({
      formulaToValidate: formulaToValidate,
      formulaList: parsedFormulas.list,
      expression,
      recipeVariables,
      formulaUuid: state.formulaUuid,
    });
    const isFormulaTitleDuplicated = parsedFormulas.list.some(
      (f) => f.recipe.name === recipeName && f.formulaUuid !== state.formulaUuid,
    );
    try {
      if (!validated) throw new Error('Invalid formula');
      if (!attributeTitle.valid || isFormulaTitleDuplicated) throw new Error('Invalid attribute title');
      let response: IAPIResponse;
      if (state.mode === 'edit') {
        const requestObject: {
          name?: string;
          expression?: string;
          variables?: IVariables;
          roundingInstructions?: {
            precision: number;
            direction: string;
          } | null;
        } = {};
        if (showRounding) {
          requestObject.roundingInstructions = {
            precision: parseFloat(roundingPrecisionState.selected?.value ?? '1'),
            direction: roundingDirection,
          };
        } else {
          requestObject.roundingInstructions = null;
        }
        recipeName !== state.formulaTitle && (requestObject.name = recipeName);
        expression !== state.formulaData?.formula && (requestObject.expression = expression);
        !isEqual(recipeVariables, state.formulaData?.variables) && (requestObject.variables = recipeVariables);
        if (Object.keys(requestObject).length === 0) {
          confirm();
          resetForm();
          setIsLoading(false);
          return;
        } else {
          response = (await request({
            url: `/formulas/${state.formulaUuid}`,
            method: 'PATCH',
            body: {
              recipe: requestObject,
              dataSourceUuids: dataSourceState.selected?.map((ds) => ds.value),
              formatting: formatting,
            },
            headers: { 'Organization-Uuid': organizationUuid },
            params: { scenarioUuid: activeScenarioUuid ?? undefined },
          })) as IAPIResponse;
        }
      } else {
        response = (await request({
          url: `/formulas`,
          method: 'POST',
          body: {
            recipe: {
              name: recipeName,
              expression,
              variables: recipeVariables,
              roundingInstructions: showRounding
                ? {
                    precision: parseFloat(roundingPrecisionState.selected?.value ?? '1'),
                    direction: roundingDirection,
                  }
                : undefined,
            },
            dataSourceUuids: dataSourceState.selected?.map((ds) => ds.value),
            formatting: formatting,
          },
          params: { scenarioUuid: activeScenarioUuid ?? undefined },
          headers: { 'Organization-Uuid': organizationUuid },
        })) as IAPIResponse;
      }
      if (response.status < 400) {
        revalidate();
        resetForm();
        setShowDataSourceOptions(false);
      } else {
        toast.error('Failed to save formula');
        setIsLoading(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      setIsLoading(false);
      setAttributeTitle((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
        valid: isFormulaTitleDuplicated ? false : prev.valid,
        errorMessage: isFormulaTitleDuplicated ? 'Formula title already in use' : prev.errorMessage,
      }));
      if (!validated) {
        setDisplayFormulaError({
          isDisplayed: true,
          message: errorMessage,
        });
      }
    }
  };

  const handleFormattingChange = (value: string): void => {
    if (value === 'number') {
      setFormatting(IFormattingEnum.Number);
    } else if (value === 'currency') {
      setFormatting(IFormattingEnum.Currency);
    } else if (value === 'percent') {
      setFormatting(IFormattingEnum.Percent);
    }
  };

  const handleShowDataSourceOptions = (): void => {
    setShowDataSourceOptions(true);
  };

  const modalOptions = [];

  if (!state.dataSourceUuids.length) {
    modalOptions.push({
      label: 'Add Data Source',
      onClick: handleShowDataSourceOptions,
    });
  }

  return (
    <Modal isOpen={state.isOpen} title="Attribute" size="xxl" id="formula-builder-modal" modalOptions={modalOptions}>
      <div className="w-full mt-2">
        <div className="flex flex-row items-end gap-4 mb-3">
          <div
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <div className="flex gap-3 items-center">
              <div className="!w-[280px] flex flex-col gap-1">
                <div className="w-full flex flex-row justify-between">
                  <Typography>Attribute Title</Typography>
                  {linkedFormulas.length ? (
                    <HoverPopover
                      buttonContent={
                        <Typography color="empty" className="italic">
                          View Linked Attributes
                        </Typography>
                      }
                      panelContent={
                        <div className="flex flex-col text-nowrap justify-start">
                          {linkedFormulas
                            .sort((a, b) => {
                              if (a.recipe.name.toLowerCase() < b.recipe.name.toLowerCase()) return -1;
                              if (a.recipe.name.toLowerCase() > b.recipe.name.toLowerCase()) return 1;
                              return 0;
                            })
                            .map((formula) => (
                              <Typography color="white" key={formula.uuid}>
                                {formula.recipe.name}
                              </Typography>
                            ))}
                        </div>
                      }
                      panelClassName="!bg-black !shadow !rounded-md !py-2 !px-4"
                      anchor="top"
                    />
                  ) : (
                    <Typography color="empty" className="italic">
                      No Linked Attributes
                    </Typography>
                  )}
                </div>
                <InputWrapper
                  id="formula-attribute-title"
                  state={attributeTitle}
                  setState={setAttributeTitle}
                  className="!w-[280px]"
                />
              </div>
              <div className="flex flex-col justify-between gap-1.5">
                <div className="flex flex-row gap-1 items-center">
                  <Typography>Formatting</Typography>
                  <HoverPopover
                    anchor="top"
                    buttonContent={<InformationCircleIcon className="size-4 text-neutral-400" />}
                    panelClassName="!bg-black !shadow-md !rounded-md !py-2 !px-4 w-[170px]"
                    panelContent={<Typography color="white">{`This will reformat the formula's output`}</Typography>}
                  />
                </div>
                <SegmentedControl
                  name="attribute-type"
                  segments={[
                    { value: 'number', label: '#' },
                    { value: 'currency', label: '$' },
                    { value: 'percent', label: '%' },
                  ]}
                  value={formatting}
                  setValue={handleFormattingChange}
                  backgroundColor="gray"
                />
              </div>
              {(showDataSourceOptions || state.dataSourceUuids.length > 0) && (
                <SelectMultiple
                  id="connected-data-source"
                  state={dataSourceState}
                  setState={setDataSourceState}
                  label="Connected Data Source"
                  className="!w-[280px]"
                />
              )}
            </div>
          </div>
          {state.mode === 'edit' && !!attributeTitle.value.length && attributeTitle.value !== state.formulaTitle && (
            <Typography
              color="secondary"
              className={`w-[250px] ${!attributeTitle.valid && !attributeTitle.pristine && attributeTitle.touched ? 'mb-[29px]' : ''}`}
              id="edit-formula-title-note"
            >
              Note: All instances of this attribute within functions will be updated
            </Typography>
          )}
        </div>
        <FormulaBuilderInput
          formulaState={state.formulaData}
          variablesState={state.variables}
          formulaUuid={state.formulaUuid}
          formulaList={parsedFormulas.list}
          formulaTitle={state.formulaTitle}
          inputAttributeTitle={attributeTitle.value}
          variables={variables}
          setVariables={setVariables}
          formula={formula}
          setFormula={setFormula}
          updatedFormula={updatedFormula}
          setUpdatedFormula={setUpdatedFormula}
          isOpen={state.isOpen}
          displayFormulaError={displayFormulaError}
          setDisplayFormulaError={setDisplayFormulaError}
          value={value}
          setValue={setValue}
        />
        <div className="w-full mt-4">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={showRounding}
              toggleValue={() => setShowRounding(!showRounding)}
              id="show-rounding-checkbox"
            />
            <Typography>Round the Results</Typography>
          </div>
          {showRounding && (
            <div className="flex items-center mt-2 gap-2">
              <div className="w-[243px]">
                <SegmentedControl
                  name="rounding"
                  segments={[
                    { value: 'nearest', label: 'Nearest' },
                    { value: 'up', label: 'Up' },
                    { value: 'down', label: 'Down' },
                  ]}
                  value={roundingDirection}
                  setValue={setRoundingDirection}
                />
              </div>
              <div className="w-[200px]">
                <Select
                  id="select-precision"
                  state={roundingPrecisionState}
                  setState={setRoundingPrecisionState}
                  placeholder="Whole Number"
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-between mt-5">
          <Button
            fill="clear"
            onClick={cancelFormulation}
            className="!w-fit !px-0"
            disabled={isLoading}
            id="cancel-formula-button"
          >
            Cancel
          </Button>
          <div className="flex">
            {isNotReferencedInOtherFormulas && !state.formulaData?.isProtected && state.mode === 'edit' && (
              <Button
                fill="destructiveClear"
                onClick={handleDelete}
                className="!w-auto"
                disabled={isLoading}
                id="delete-formula-button"
              >
                Delete
              </Button>
            )}
            <Button onClick={handleSaveFormulation} className="!w-auto" loading={isLoading} id="save-formula-button">
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const FormulaBuilder = ({
  state,
  cancel,
  confirm,
  parsedFormulas,
  dataSources,
  revalidate,
  revalidateLoading,
}: IProps): React.ReactNode => {
  return (
    <FormulaBuilderProvider>
      <FormulaBuilderContainer
        state={state}
        cancel={cancel}
        confirm={confirm}
        parsedFormulas={parsedFormulas}
        dataSources={dataSources}
        revalidate={revalidate}
        revalidateLoading={revalidateLoading}
      />
    </FormulaBuilderProvider>
  );
};

export default FormulaBuilder;
