import React from 'react';
import Typography from '../Typography';
import { SPACE_PER_MONTH } from './utils/constants';
import * as stringDate from '~/utils/stringDate';
import { IStringDate } from '~/utils/stringDate/types';

const TodayIndicator = ({ startDate }: { startDate: IStringDate }): React.ReactElement => {
  const monthsDifference = stringDate.preciseDifferenceInMonths({
    startDate,
    endDate: stringDate.getStringDate(),
  });

  /**
   * If today isn't represented on the timeline, don't show the today indicator
   */
  if (stringDate.isAfter({ dateToCheck: startDate, comparison: stringDate.getStringDate() })) {
    return <></>;
  }

  return (
    <div
      className={`absolute z-0 h-full w-[1px] top-0 bg-neutral-100`}
      style={{ left: `${monthsDifference * SPACE_PER_MONTH}rem` }}
    >
      <div className="absolute top-1 -left-6 bg-neutral-15">
        <Typography size="xs" color="disabled" weight="medium">
          TODAY
        </Typography>
      </div>
    </div>
  );
};

export default TodayIndicator;
