import React, { useState, useEffect, useMemo } from 'react';
import Button from '~/components/Button';
import PeriodPicker from '~/components/PeriodPicker';
import Divider from '~/components/Divider';
import { Select } from '~/components/UncontrolledComponents/Select';
import { SelectMultiple } from '~/components/UncontrolledComponents/SelectMultiple';
import {
  ExpenseDriverEnum,
  ExpenseFrequencyEnum,
  IFormula,
  IFormulaTypeEnum,
  IRecipeVariables,
  ZExpenseTag,
  type ExpenseTag,
} from '~/services/parallel/formulas.types';
import { z } from 'zod';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { IDepartment } from '~/services/parallel/departments.types';
import { RadioInputTile } from '~/components/UncontrolledComponents/RadioInputTile';
import Input from '~/components/UncontrolledComponents/Input';
import { SegmentedControl } from '~/components/UncontrolledComponents/SegmentedControl';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { transformExpenseFormForSubmission } from './transformExpenseFormForSubmission';
import {
  CATEGORY_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  EXPENSE_TYPE_OPTIONS,
  getCurrencyLabel,
  getFrequencyOptionsFromSegmentType,
  getSegmentTypeFromDriver,
  HEADCOUNT_DRIVER_OPTIONS,
} from './formConstants';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import FormulaBuilderInput from '~/pages/FinancialModelDeprecated/components/FormulaBuilder/FormulaBuilderInput';
import { IFormulaSegment } from '~/pages/FinancialModelDeprecated/entity/types';
import { IEmploymentType } from '~/services/parallel/headcount.types';
import Typography from '~/components/Typography';
import Checkbox from '~/components/Checkbox';

export type SegmentType = 'setCost' | 'headcountDriven' | 'custom';

export interface IFormulaState {
  topLevelFormulaUuid?: string;
  formula: string;
  variables: IRecipeVariables;
  formulaList: IFormula[];
  editable?: boolean;
}

export interface IFormulaInputState {
  formulaState?: IFormulaState;
  variables?: IRecipeVariables;
  formulaTitle?: string;
  formulaUuid?: string;
  isCustom: boolean;
}

const ZExpenseForm = z
  .object({
    name: z.string().min(1, 'Expense name is required'),
    category: ZExpenseTag,
    departments: z.array(z.string()),
    dataSourceUuids: z.array(z.string()),
    segmentType: z.enum(['setCost', 'headcountDriven', 'custom']),
    expenseDriver: z.nativeEnum(ExpenseDriverEnum).nullable(),
    amount: z.string().nullable(),
    percentage: z.string().nullable(),
    employmentTypes: z.array(z.string()),
    frequency: z.nativeEnum(ExpenseFrequencyEnum).optional(),
    startDate: z.string(),
    endDate: z.string().nullable().optional(),
    min: z.string().nullable().optional(),
    max: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      if (data.segmentType === 'headcountDriven') {
        return data.employmentTypes.length > 0;
      }
      return true;
    },
    {
      message: 'Employment type is required for headcount driven expenses',
      path: ['employmentTypes'], // This will make the error show up on the employmentTypes field
    },
  )
  .refine(
    (data) => {
      if (data.category === 'Cost of Goods Sold') {
        return data.departments.length === 0;
      }
      return data.departments.length > 0;
    },
    {
      message: 'Department is required for non-COGS expenses',
      path: ['departments'],
    },
  )
  .superRefine((data, ctx) => {
    if (data.min && data.max) {
      if (!(parseFloat(data.min) <= parseFloat(data.max))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['min'],
          message: 'Min must be less than max',
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['max'],
          message: 'Max must be greater than min',
        });
      }
    }
  });

export type IExpenseFormData = z.infer<typeof ZExpenseForm>;

interface Props {
  onClose: ({ successfulSave }: { successfulSave: boolean }) => void;
  isDuplicating?: boolean;
  formOverrides?: {
    datasourceUuid?: string[] | null;
    category?: ExpenseTag;
  };
  initialFormulaData?: Partial<IFormula>;
  scenarioUuid: string | null;
  expenseIntegrationsWithMappings: IIntegrationWithMappings[];
  departments: IDepartment[];
  formulaList: IFormula[];
  onDeleteExpense: () => void;
}

export const ExpenseForm = ({
  onClose,
  isDuplicating,
  formOverrides,
  initialFormulaData,
  scenarioUuid,
  expenseIntegrationsWithMappings,
  departments,
  formulaList,
  onDeleteExpense,
}: Props): React.ReactNode => {
  const initialExpenseContext = initialFormulaData?.context?.expenseContext;

  // State for FormulaBuilderInput
  const [variables, setVariables] = useState<IRecipeVariables>({});
  const [formula, setFormula] = useState<IFormulaSegment[]>([]);
  const [updatedFormula, setUpdatedFormula] = useState<IFormulaSegment[]>([]);
  const [formulaValue, setFormulaValue] = useState<string>('');
  const [displayFormulaError, setDisplayFormulaError] = useState<{
    isDisplayed: boolean;
    message: string;
  }>({
    isDisplayed: false,
    message: '',
  });
  const usedIntegrations = useMemo(() => {
    return formulaList.reduce((acc: Set<string>, formula) => {
      if (formula.dataSourceUuids.length) {
        formula.dataSourceUuids.forEach((uuid) => {
          acc.add(uuid);
        });
      }
      return acc;
    }, new Set<string>());
  }, [formulaList]);

  const initialHasMinMax = Boolean(initialFormulaData?.recipe?.min || initialFormulaData?.recipe?.max);
  const [addMinMax, setAddMinMax] = useState<boolean>(initialHasMinMax);

  const {
    control,
    handleSubmit,
    watch,
    register,
    setError,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm<IExpenseFormData>({
    defaultValues: {
      name: isDuplicating ? `${initialFormulaData?.recipe?.name} (Copy)` : initialFormulaData?.recipe?.name ?? '',
      category: formOverrides?.category ?? initialExpenseContext?.tag ?? undefined,
      departments: initialExpenseContext?.departments.includes('ALL')
        ? departments.map((dept) => dept.departmentUuid)
        : initialExpenseContext?.departments ?? [],
      dataSourceUuids: formOverrides?.datasourceUuid ?? initialFormulaData?.dataSourceUuids ?? [],
      segmentType: getSegmentTypeFromDriver(initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost),
      expenseDriver: initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost,
      amount: initialExpenseContext?.amount ? (initialExpenseContext.amount / CENTS_PER_DOLLAR).toString() : '',
      percentage:
        initialExpenseContext?.driver === 'headcountPercentCompensation' && initialExpenseContext.amount !== undefined
          ? (initialExpenseContext.amount / 100).toString()
          : '',
      employmentTypes: initialExpenseContext?.employmentTypes.includes('all')
        ? Object.values(IEmploymentType)
        : initialExpenseContext?.employmentTypes ?? [
            IEmploymentType.FullTime,
            IEmploymentType.PartTime,
            IEmploymentType.Contractor,
          ],
      frequency: initialExpenseContext?.frequency ?? undefined,
      startDate: initialFormulaData?.recipe?.startDate ?? undefined,
      endDate: initialFormulaData?.recipe?.endDate ?? undefined,
      min: initialFormulaData?.recipe?.min ?? undefined,
      max: initialFormulaData?.recipe?.max ?? undefined,
    },
    resolver: zodResolver(ZExpenseForm),
    mode: 'all',
  });

  const [formulaInputState, setFormulaInputState] = useState<IFormulaInputState>();

  const [upsertExpenseFormula] = formulasApi.useUpsertExpenseFormulaMutation();

  const frequency = watch('frequency');
  const segmentType = watch('segmentType');
  const expenseDriver = watch('expenseDriver');

  useEffect(() => {
    if (initialFormulaData?.formulaUuid) {
      if (initialExpenseContext?.driver === ExpenseDriverEnum.Custom) {
        setFormulaInputState((prev) => ({
          ...prev,
          formulaState: {
            ...prev?.formulaState,
            formula: initialFormulaData.recipe?.expression ?? '',
            variables: initialFormulaData.recipe?.variables ?? {},
            formulaList: formulaList,
            editable: true,
            topLevelFormulaUuid: initialFormulaData.formulaUuid,
          },
          variables: initialFormulaData.recipe?.variables ?? {},
          formulaUuid: initialFormulaData.formulaUuid,
          formulaTitle: initialFormulaData.recipe?.name,
          isCustom: true,
        }));
      } else {
        setFormulaInputState({
          formulaState: {
            topLevelFormulaUuid: initialFormulaData.formulaUuid,
            formula: '',
            variables: {},
            formulaList: formulaList,
            editable: true,
          },
          variables: {},
          isCustom: false,
          formulaUuid: initialFormulaData.formulaUuid,
        });
      }
    } else {
      const segmentType = watch('segmentType');
      setFormulaInputState((prev) => ({
        ...prev,
        formulaState: {
          ...prev?.formulaState,
          formula: formula.map((f) => f.textValue).join(''),
          variables: variables,
          formulaList: formulaList,
          editable: true,
        },
        variables: variables,
        isCustom: segmentType === 'custom',
        formulaUuid: undefined,
      }));
    }
  }, [initialFormulaData, formula, variables, formulaList]);

  useEffect(() => {
    if (frequency === 'oneTime') {
      setValue('endDate', null);
    }
  }, [frequency, setValue]);

  const onSubmitForm = handleSubmit(async (data) => {
    try {
      const transformedData = transformExpenseFormForSubmission({
        formData: data,
        formulaUuid: isDuplicating ? undefined : initialFormulaData?.formulaUuid,
        scenarioUuid: scenarioUuid ?? undefined,
        customFormula: segmentType === 'custom' ? (updatedFormula.length > 0 ? updatedFormula : formula) : undefined,
        customVariables: segmentType === 'custom' ? variables : undefined,
        allDepartments: departments.map((dept) => dept.departmentUuid),
      });

      await upsertExpenseFormula(transformedData).unwrap();

      onClose({ successfulSave: true });
    } catch (error) {
      setError('root', {
        message: 'Expense failed to save. Please try again.',
      });
    }
  });

  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <Input {...register('name')} id="expenseName" label="Expense Name" type="text" error={errors.name?.message} />
          <Controller
            control={control}
            name="category"
            render={({ field, fieldState }) => (
              <Select
                id="expenseCategory"
                label="Category"
                options={CATEGORY_OPTIONS.filter((option) => option.value !== 'Total Compensation')}
                error={fieldState.error?.message}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  if (value === 'Cost of Goods Sold') {
                    setValue('departments', []);
                    // Retrigger department validation which will clear any errors
                    trigger('departments');
                  } else {
                    trigger('departments');
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="departments"
            render={({ field, fieldState }) => (
              <SelectMultiple
                id="expenseDepartments"
                label="Department Allocation"
                placeholder={
                  watch('category') === 'Cost of Goods Sold' ? 'COGS does not apply to departments' : 'Select options'
                }
                disabled={watch('category') === 'Cost of Goods Sold'}
                onChange={(value) => field.onChange(value)}
                options={departments.map((department) => ({
                  value: department.departmentUuid,
                  label: department.name,
                }))}
                error={fieldState.error?.message}
                value={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name="dataSourceUuids"
            render={({ field, fieldState }) => (
              <SelectMultiple
                label="Data Sources"
                id="expense-sync-with-actuals"
                disabled={expenseIntegrationsWithMappings.length === 0}
                placeholder={
                  expenseIntegrationsWithMappings.length === 0
                    ? 'Setup connection in settings to sync'
                    : 'Select options'
                }
                onChange={(value) => field.onChange(value)}
                options={expenseIntegrationsWithMappings.flatMap((integration) =>
                  integration.mappings.map((mapping) => ({
                    value: mapping.uuid,
                    label: mapping.name,
                    disabled: usedIntegrations.has(mapping.uuid),
                  })),
                )}
                value={field.value}
                error={fieldState.error?.message}
                includeSearch
              />
            )}
          />
          <Divider />
          <Controller
            control={control}
            name="segmentType"
            render={({ field }) => (
              <SegmentedControl
                id="expense-type-control"
                name="segmentType"
                segments={EXPENSE_TYPE_OPTIONS}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value as 'setCost' | 'headcountDriven' | 'custom');
                  if (value === 'setCost') {
                    setValue('min', '');
                    setValue('max', '');
                    setValue('expenseDriver', ExpenseDriverEnum.SetCost);
                  } else if (value === 'headcountDriven') {
                    setValue('min', '');
                    setValue('max', '');
                    setValue('expenseDriver', ExpenseDriverEnum.HeadcountFixed);
                  } else if (value === 'custom') {
                    setValue('expenseDriver', ExpenseDriverEnum.Custom);
                    if (initialHasMinMax) {
                      setAddMinMax(true);
                      setValue('min', initialFormulaData?.recipe?.min ?? '');
                      setValue('max', initialFormulaData?.recipe?.max ?? '');
                    }
                  }
                }}
              />
            )}
          />
          {segmentType === 'headcountDriven' && (
            <Controller
              control={control}
              name="expenseDriver"
              render={({ field, fieldState }) => (
                <Select
                  label="Type"
                  id={'expenseHeadcountDriver'}
                  options={HEADCOUNT_DRIVER_OPTIONS}
                  value={field.value}
                  onChange={(value) => field.onChange(value as ExpenseDriverEnum)}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {/* If the expense driver is headcount percent compensation, show the percentage input */}
          {expenseDriver === 'headcountPercentCompensation' && (
            <Controller
              control={control}
              name="percentage"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  id="expenseAmount"
                  type="percentage"
                  label="Percentage"
                  error={fieldState.error?.message}
                  onChange={(value) => field.onChange(value)}
                  value={field.value ?? undefined}
                />
              )}
            />
          )}
          {/* If the expense driver is set cost or headcount fixed, show the currency input */}
          {[ExpenseDriverEnum.SetCost, ExpenseDriverEnum.HeadcountFixed].includes(
            expenseDriver as ExpenseDriverEnum,
          ) && (
            <Controller
              control={control}
              name="amount"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  type="currency"
                  includeDollarSign
                  id="expenseAmount"
                  label={`${getCurrencyLabel({
                    type: segmentType,
                    frequency,
                  })}`}
                  error={fieldState.error?.message}
                  onChange={(value) => field.onChange(value)}
                  value={field.value ?? undefined}
                />
              )}
            />
          )}
          {segmentType === 'headcountDriven' && (
            <Controller
              control={control}
              name="employmentTypes"
              render={({ field, fieldState }) => (
                <SelectMultiple
                  id="employmentType"
                  label="Employment Type"
                  options={EMPLOYMENT_TYPE_OPTIONS}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {segmentType !== 'custom' && (
            <Controller
              control={control}
              name="frequency"
              render={({ field, fieldState }) => (
                <RadioInputTile
                  {...field}
                  id="expenseFrequency"
                  label="Frequency"
                  options={getFrequencyOptionsFromSegmentType(segmentType)}
                  error={fieldState.error?.message}
                  disabled={false}
                  value={field.value}
                />
              )}
            />
          )}
          {segmentType === 'custom' && (
            <>
              <FormulaBuilderInput
                formulaState={formulaInputState?.formulaState}
                variablesState={formulaInputState?.variables}
                formulaUuid={formulaInputState?.formulaUuid}
                formulaTitle={formulaInputState?.formulaTitle}
                variables={variables}
                setVariables={setVariables}
                formula={formula}
                setFormula={setFormula}
                updatedFormula={updatedFormula}
                setUpdatedFormula={setUpdatedFormula}
                isOpen={formulaInputState?.isCustom ?? false}
                value={formulaValue}
                setValue={setFormulaValue}
                formulaList={formulaList}
                inputAttributeTitle={watch('name')}
                displayFormulaError={displayFormulaError}
                setDisplayFormulaError={setDisplayFormulaError}
                attributeType={IFormulaTypeEnum.Expense}
              />
              <div className="flex flex-col">
                <label className="flex flex-row gap-2 items-center mb-2">
                  <Checkbox
                    id="add-min-max"
                    checked={addMinMax}
                    className=" !ml-0 "
                    toggleValue={() => {
                      setAddMinMax((prev) => {
                        const newValue = !prev;
                        if (!newValue) {
                          setValue('min', '');
                          setValue('max', '');
                        }
                        return newValue;
                      });
                    }}
                  />
                  <Typography className="text-sm">Add min/max values</Typography>
                </label>
                <div
                  className={`transition-all duration-300 ${addMinMax ? 'max-h-[62px] min-h-[38px] h-auto opacity-100 overflow-visible flex flex-col' : 'max-h-0 min-h-0 h-0 opacity-0 overflow-hidden'}`}
                >
                  <div className="flex flex-row gap-2">
                    <Controller
                      control={control}
                      name="min"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          value={field.value ?? undefined}
                          type="currency"
                          includeDollarSign
                          placeholder="Min (optional)"
                          id="expenseMin"
                          error={fieldState.error?.message ? 'Min must be less than max' : undefined}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="max"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          value={field.value ?? undefined}
                          type="currency"
                          includeDollarSign
                          placeholder="Max (optional)"
                          id="expenseMax"
                          error={fieldState.error?.message ? 'Max must be greater than min' : undefined}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row gap-5">
            <div className="relative w-44" data-testid="create-expense-period-picker">
              <Controller
                control={control}
                name="startDate"
                render={({ field, fieldState }) => (
                  <PeriodPicker
                    id="expense-start-date-picker"
                    label="Start Date"
                    state={{
                      startDate: field.value,
                      endDate: field.value,
                      mode: 'month',
                      valid: !fieldState.error,
                      errorMessage: fieldState.error?.message,
                    }}
                    setState={(newState) => {
                      const value =
                        typeof newState === 'function'
                          ? newState({
                              startDate: field.value,
                              endDate: field.value,
                              mode: 'month',
                              valid: !fieldState.error,
                              errorMessage: fieldState.error?.message,
                            })
                          : newState;
                      field.onChange(value.startDate);
                    }}
                    beBefore={watch('endDate')}
                  />
                )}
              />
            </div>
            <div className={`relative w-44 ${frequency === 'oneTime' ? 'hidden' : ''}`}>
              <Controller
                control={control}
                name="endDate"
                render={({ field, fieldState }) => (
                  <PeriodPicker
                    id="expense-end-date-picker"
                    label="End Date"
                    clearable
                    optional
                    state={{
                      startDate: field.value ?? null,
                      endDate: field.value ?? null,
                      mode: 'month',
                      valid: !fieldState.error,
                      errorMessage: fieldState.error?.message,
                    }}
                    setState={(newState) => {
                      const value =
                        typeof newState === 'function'
                          ? newState({
                              startDate: field.value ?? null,
                              endDate: field.value ?? null,
                              mode: 'month',
                              valid: !fieldState.error,
                              errorMessage: fieldState.error?.message,
                            })
                          : newState;
                      field.onChange(value.endDate);
                    }}
                    beAfter={watch('startDate')}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex justify-between gap-5">
          <Button
            className="!w-auto !px-0"
            id="cancel-create-expense"
            fill="clear"
            onClick={() => onClose({ successfulSave: false })}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-4">
            {initialFormulaData?.formulaUuid && (
              <Button
                id="delete-expense"
                onClick={onDeleteExpense}
                fill="destructiveOutline"
                className="!w-auto"
                disabled={isSubmitting}
              >
                Delete
              </Button>
            )}
            <Button
              id="save-expense"
              onClick={() => {
                onSubmitForm();
              }}
              className="!w-auto"
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
