import React, { ReactElement, useMemo } from 'react';
import FormulasTable from '~/components/Formulas/FormulasTable';
import useFormulaContext from '../../../components/Formulas/context/useFormulaContext';
import GroupRow from '../../../components/Formulas/CollapsableGroup/CollapsableGroup';
import { IColumn } from '~/components/Formulas/FormulasTable/types';
import ModelBuilderExpression from '../../../components/Formulas/ExpressionBuilder';
import MonthCell from '../../../components/Formulas/MonthValueEditor/MonthCell';
import Typography from '~/components/Typography';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import SearchInput from '~/components/Formulas/SearchInput/SearchInput';
import DisplayAttributes from '../../../components/DisplayAttributes';
import HandleCollapseButton from './HandleCollapseButton';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import DraggableLabel from '~/components/Formulas/DraggableLabel';
import ManageGroupsFooter from './ManageGroupsFooter';
import { IBvaReviewStatus } from '~/services/parallel/bva.types';
import BvaDropdown from '~/components/BvaDropdown';
import { useFeatureFlagHarness } from '~/utils/hooks/useFeatureFlag';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';
import { bvaApi } from '~/services/parallel/api/bvaApi';

const FinancialModelTable = (): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const {
    filteredFormulasData,
    onDragEnd,
    selectedMonths,
    searchFilter,
    setSearchFilter,
    formulaDictionary,
    scrollEnabled,
  } = useFormulaContext();
  const bvaResult = bvaApi.useGetBvaQuery();
  const runBva = useFeatureFlagHarness('runBva');

  const revenueFormulaUuid = Object.values(formulaDictionary).find(
    (formula) => formula.recipe.name === 'Revenue',
  )?.formulaUuid;

  const revenueReview = revenueFormulaUuid ? bvaResult.data?.bvaResult?.modelReview[revenueFormulaUuid] : null;
  const revenueNeedsReview = revenueReview ? revenueReview.status === IBvaReviewStatus.Pending : false;

  const columns = useMemo<IColumn[]>(() => {
    return [
      {
        key: 'label',
        Header: ({ columnWidth }: { columnWidth: number }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-white border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
              boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
            }}
          >
            <div className="flex w-fit gap-2 py-1">
              <SearchInput />
              <HandleCollapseButton />
            </div>
            <ColumnResize columnIndex={1} />
          </div>
        ),
        Cell: DraggableLabel,
      },
      {
        key: 'formula',
        Cell: ModelBuilderExpression,
      },
      ...selectedMonths.map((month) => ({
        key: `month-${month}`,
        Header: ({ columnWidth, columnIndex }: { columnWidth: number; columnIndex: number }): ReactElement => {
          const LEADING_COLUMN_COUNT = 2;
          const month = selectedMonths[columnIndex - LEADING_COLUMN_COUNT];
          const showYearDivider = month.includes('Dec');
          return (
            <div
              className={`flex justify-end items-end py-2 bg-white relative border-b border-neutral-50 px-4${showYearDivider ? ' border-r' : ''}`}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              <Typography color="lightGray" id={`month-${month}`}>
                {month.toUpperCase()}
              </Typography>
            </div>
          );
        },
        Cell: MonthCell,
      })),
    ];
  }, [selectedMonths]);

  const potentialFormulasData = useMemo(() => {
    return filteredFormulasData
      .flatMap((item) => {
        if (item.type === IRecordTypeEnum.Group) {
          return item.formulas.map((formula) => ({
            formulaUuid: formula.formulaUuid,
            formulaName: formula.label.name,
          }));
        } else {
          return [
            {
              formulaUuid: item.formulaUuid,
              formulaName: item.label.name,
            },
          ];
        }
      })
      .filter((formula) => formula.formulaName.toLowerCase().includes(searchFilter.value.toLowerCase()));
  }, [filteredFormulasData, searchFilter]);

  return (
    <div
      className={`relative max-w-full flex flex-col ${activeScenarioUuid ? 'h-[calc(100vh-125px)]' : 'h-[calc(100vh-64px)]'} ${scrollEnabled.x ? 'overflow-x-auto' : 'overflow-x-hidden overscroll-x-none'} ${scrollEnabled.y ? 'overflow-y-auto' : 'overflow-y-hidden overscroll-y-none'}`}
      data-testid="financial-model-table"
    >
      {revenueReview && revenueNeedsReview && runBva && !activeScenarioUuid && (
        <div className="sticky left-0 px-10">
          <BvaDropdown mode="revenue" forecast={revenueReview.forecastedValues} actuals={revenueReview.actualValues} />
        </div>
      )}
      <FormulasTable
        columns={columns}
        data={filteredFormulasData}
        groupComponent={GroupRow}
        onDragEnd={onDragEnd}
        footerComponent={ManageGroupsFooter}
      />
      {searchFilter.value && (
        <div
          className={`sticky left-0 z-10 w-full h-[500px] min-h-[500px] bg-white p-5 ${activeScenarioUuid ? 'bottom-10' : 'bottom-0'}`}
        >
          <DisplayAttributes
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            formulaDictionary={formulaDictionary}
            potentialFormulasData={potentialFormulasData}
          />
        </div>
      )}
    </div>
  );
};

export default FinancialModelTable;
