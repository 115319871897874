import {
  NewspaperIcon,
  UserGroupIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowPathIcon,
  Bars3Icon,
  XMarkIcon,
  TableCellsIcon,
  ChartPieIcon,
  ChartBarIcon,
  IdentificationIcon,
  ListBulletIcon,
  BanknotesIcon,
  SparklesIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { State } from '~/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import logo from '~/assets/parallelLogoIcon.svg';
import request from '~/utils/request';
import Typography from '../Typography';
import parallelLogo from '~/assets/parallelWordmarkLogo.svg';
import { userSlice, IUserPreferences } from '~/store/userSlice';
import Button from '../Button';
import SwitchOrgs from './SwitchOrgs';
import useIsMobile from '~/utils/hooks/useIsMobile';
import { IAPIResponse } from '~/utils/types';
import { IUserNotification } from '~/pages/Ratios/entity/types';
import { Channel } from 'pusher-js';
import useQueryParams from '~/utils/hooks/useQueryParams';
import UserNavigationPopover from './UserNavigationPopover';
import { StatusCodes } from 'http-status-codes';
import { useFeatureFlagPosthog } from '~/utils/hooks/useFeatureFlag';
import { IBvaReviewStatus } from '~/services/parallel/bva.types';
import PingNotification from '../PingNotification';
import { bvaApi } from '~/services/parallel/api/bvaApi';

interface Props {
  showRefresh: boolean;
  setShowChangeLogModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUserPreferencesResponse {
  data: {
    data: IUserPreferences;
  };
  status: number;
}

const SideMenu = ({ showRefresh, setShowChangeLogModal }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid: userUuid, preferences } = useSelector((state: State) => state.user);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { sideMenuExpanded } = preferences;
  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [showRatioNotification, setShowRatioNotification] = useState<boolean>(false);
  const { queryParams } = useQueryParams();
  const { contractsEnabled, ratiosEnabled } = useSelector((state: State) => state.organization.configuration);
  const copilotBetaEnabled = useFeatureFlagPosthog('copilot_beta');
  const bvaResult = bvaApi.useGetBvaQuery(undefined, { skip: !organizationUuid });

  useEffect(() => {
    let userNotificationChannel: Channel | null = null;
    if (organizationUuid) {
      if (pusher) {
        userNotificationChannel = pusher.subscribe(organizationUuid);

        userNotificationChannel.bind('notification-created', () => {
          if (location.pathname !== '/ratios') {
            setShowRatioNotification(true);
          }
        });
      }
    }

    const fetchUserNotifications = async (): Promise<void> => {
      const userNotificationResponse = (await request({
        url: `/users/${userUuid}/notifications`,
        method: 'GET',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      })) as IAPIResponse<IUserNotification[]>;

      if (userNotificationResponse.status === StatusCodes.OK) {
        const hasUnreadNotifications = userNotificationResponse.data.data.some((notification) => !notification.isRead);
        setShowRatioNotification(hasUnreadNotifications);
      }
    };

    if (!showRatioNotification && location.pathname !== '/ratios' && organizationUuid) {
      fetchUserNotifications();
    } else if (location.pathname === '/ratios') {
      setShowRatioNotification(false);
    }

    return () => {
      if (userNotificationChannel) {
        userNotificationChannel.unbind('notification-created');
      }
    };
  }, [userUuid, pusher, organizationUuid, location.pathname]);

  const handleClickLink = (to?: string): void => {
    if (isMobile) {
      updateSideMenuPreference(false);
    }

    if (showRefresh && !activeScenarioUuid && to) {
      window.location.href = to;
    }
  };

  const updateSideMenuPreference = async (updatedValue: boolean): Promise<void> => {
    dispatch(
      userSlice.actions.setPreferences({
        ...preferences,
        sideMenuExpanded: updatedValue,
      }),
    );

    const userPreferenceResponse = (await request({
      url: `/users/${userUuid}/preferences`,
      method: 'PATCH',
      body: {
        sideMenuExpanded: updatedValue,
      },
    })) as IUserPreferencesResponse;

    if (userPreferenceResponse.status >= 400) {
      throw Error('Failed to update user preferences');
    }
  };

  const menuWidth =
    sideMenuExpanded || isUserPopoverOpen
      ? `w-[240px] max-sm:w-screen max-sm:fixed max-sm:top-0 max-sm:h-dvh max-sm:flex-col max-sm:z-50 
      max-sm:transition-all max-sm:duration-150 max-sm:overflow-y-scroll max-sm:overflow-x-hidden 
      max-sm:shadow-xl max-sm:border-r max-sm:border-green-25`
      : `w-[72px] group-hover/sidemenu:w-[240px] max-sm:hidden`;
  const menuHeight = activeScenarioUuid ? 'max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)]' : 'max-h-dvh min-h-dvh';
  const navItemStyle = 'flex items-center justify-start p-2 py-2 space-x-2 rounded mb-2 ml-2 mr-2';
  const iconStyle = 'size-6';

  const isActive = (path: string): string =>
    location.pathname === path ? 'bg-white text-green-500' : 'text-green-500 hover:bg-white';

  const isActiveContracts = (view: string): string => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (location.pathname.includes('contracts')) {
      if (view === 'details' && currentParams.view === 'details') {
        return 'bg-white text-green-500';
      }
      if (view === 'collections' && currentParams.view === 'collections') {
        return 'bg-white text-green-500';
      }
      if (view === 'revenue' && currentParams.view === 'revenue') {
        return 'bg-white text-green-500';
      }
    }
    return 'text-green-500 hover:bg-white';
  };

  const headcountNeedsReview = Object.values(bvaResult.data?.bvaResult?.headcountReview ?? {}).some(
    (review) => review.status === IBvaReviewStatus.Pending,
  );

  const expensesNeedsReview = Object.values(bvaResult.data?.bvaResult?.expenseReview ?? {}).some(
    (review) => review.status === IBvaReviewStatus.Pending,
  );

  const modelNeedsReview = Object.values(bvaResult.data?.bvaResult?.modelReview ?? {}).some(
    (review) => review.status === IBvaReviewStatus.Pending,
  );

  const showExpandedMenuComponents = sideMenuExpanded || isUserPopoverOpen;

  return (
    <div className={`flex max-h-screen max-sm:fixed max-sm:top-0 max-sm:w-screen max-sm:flex-col`}>
      {/* Mobile header to access sidebar */}
      <div className="sm:hidden max-sm:w-screen max-sm:h-[100px] max-sm:flex max-sm:items-center max-sm:justify-between max-sm:px-3">
        <Link to="/dashboard">
          <img src={logo} alt="alt-logo" className="w-[27px] ml-2" />
        </Link>
        <Bars3Icon className="w-8 h-8 text-green-400" onClick={() => updateSideMenuPreference(true)} />
      </div>

      <div className="group/sidemenu z-40 transition-all duration-[150ms] sticky top-0">
        <div
          className={`${menuWidth} ${menuHeight} flex flex-col h-full sidebar-bg border-r border-green-25 transition-all overflow-hidden absolute`}
          style={{
            transitionProperty: 'width',
            transitionDuration: '150ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          <div
            className="absolute inset-0 transition-all duration-150 pointer-events-none"
            style={{
              width: '260px',
              opacity: sideMenuExpanded ? '1' : '0',
            }}
          />
          <div className="relative w-full group-hover/sidemenu:w-[240px] transition-all duration-150 flex flex-col h-full">
            <div className="w-full">
              <div className="relative flex flex-row w-full start-end justify-end text-neutral-800 font-semibold text-xl px-[22px] py-4 mt-1 overflow-hidden">
                <div className="absolute top-6 left-[22px] transition-opacity duration-150">
                  <img
                    src={logo}
                    alt="alt-logo"
                    className={`min-w-[27px] transition-opacity duration-150 ${
                      showExpandedMenuComponents ? 'opacity-0' : 'opacity-100 group-hover/sidemenu:opacity-0'
                    }`}
                  />
                  <img
                    src={parallelLogo}
                    alt="Parallel logo"
                    className={`min-w-[107px] absolute top-0 left-0 transition-opacity duration-150 ${
                      sideMenuExpanded || isUserPopoverOpen
                        ? 'opacity-100'
                        : 'opacity-0 group-hover/sidemenu:opacity-100'
                    }`}
                  />
                </div>
                <div className="flex items-center min-h-[34.875px]">
                  <div
                    className={`w-fit h-fit p-[2px] group-hover/sidemenu:bg-white max-sm:bg-transparent 
                    text-green-500 rounded-full cursor-pointer transition-all duration-150
                    ${showExpandedMenuComponents ? 'opacity-100' : 'opacity-0 group-hover/sidemenu:opacity-100'}`}
                    onClick={() => updateSideMenuPreference(!sideMenuExpanded)}
                  >
                    {sideMenuExpanded ? (
                      <>
                        <ChevronLeftIcon className="size-5 transition-opacity duration-150 max-sm:hidden opacity-0 group-hover/sidemenu:opacity-100" />
                        <XMarkIcon className="size-6 sm:hidden text-green-400" />
                      </>
                    ) : (
                      <ChevronRightIcon className="size-5 max-sm:hidden" />
                    )}
                  </div>
                </div>
              </div>
              <div className={showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'}>
                <SwitchOrgs />
              </div>
            </div>
            <nav
              className="px-2 py-4 mt-3 text-green-500 flex flex-col flex-1 justify-between"
              data-testid="sidemenu-container"
            >
              <ul className="space-y-1">
                <Link to="/dashboard" data-testid="sidemenu-dashboard" onClick={() => handleClickLink('/dashboard')}>
                  <li className={`${navItemStyle} ${isActive('/dashboard')} transition-colors duration-150`}>
                    <div className="flex items-center w-full">
                      <NewspaperIcon className="flex-shrink-0 size-6" aria-hidden="true" />
                      <Typography
                        color="green"
                        className={`transition-all duration-150 ml-2 ${
                          sideMenuExpanded || isUserPopoverOpen
                            ? 'opacity-100 w-auto'
                            : 'w-0 opacity-0 group-hover/sidemenu:opacity-100 group-hover/sidemenu:w-auto'
                        } whitespace-nowrap overflow-hidden`}
                      >
                        Dashboard
                      </Typography>
                    </div>
                  </li>
                </Link>
                <Link
                  to="/headcount"
                  data-testid="sidemenu-headcount"
                  onClick={() => handleClickLink('/headcount')}
                  className="hidden md:block"
                >
                  <li className={`${navItemStyle} ${isActive('/headcount')} ${isActive('/ratios')}`}>
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row items-center gap-2">
                        <UserGroupIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                        >
                          Headcount
                        </Typography>
                      </div>
                      {!activeScenarioUuid && headcountNeedsReview && <PingNotification shouldAnimate />}
                    </div>
                  </li>
                </Link>
                {ratiosEnabled && (
                  <div
                    className={`flex-col -mt-2 ${
                      ['/headcount', '/ratios'].includes(location.pathname) && sideMenuExpanded
                        ? 'flex'
                        : ['/headcount', '/ratios'].includes(location.pathname)
                          ? 'hidden group-hover/sidemenu:flex'
                          : 'hidden'
                    }`}
                  >
                    <Link
                      to="/ratios"
                      data-testid="sidemenu-ratios"
                      onClick={() => handleClickLink('/ratios')}
                      className="hidden md:block"
                    >
                      <li
                        className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActive('/ratios')}`}
                      >
                        <ChartPieIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                        >
                          Ratios
                        </Typography>
                      </li>
                    </Link>
                  </div>
                )}
                <Link
                  to="/expenses"
                  data-testid="sidemenu-expenses"
                  onClick={() => handleClickLink('/expenses')}
                  className="hidden md:block"
                >
                  <li className={`${navItemStyle} ${isActive('/expenses')}`}>
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row items-center gap-2">
                        <CurrencyDollarIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                        >
                          Expenses
                        </Typography>
                      </div>
                      {!activeScenarioUuid && expensesNeedsReview && <PingNotification shouldAnimate />}
                    </div>
                  </li>
                </Link>
                {contractsEnabled && (
                  <div>
                    <Link
                      to="/contracts?view=details"
                      data-testid="sidemenu-contracts"
                      onClick={() => handleClickLink('/contracts')}
                      className="hidden md:block"
                    >
                      <li className={`${navItemStyle} ${isActive('/contracts')}`}>
                        <IdentificationIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                        >
                          Contracts
                        </Typography>
                      </li>
                    </Link>
                    <div
                      className={`flex-col -mt-2 ${
                        ['/contracts'].includes(location.pathname) && sideMenuExpanded
                          ? 'flex'
                          : ['/contracts'].includes(location.pathname)
                            ? 'hidden group-hover/sidemenu:flex'
                            : 'hidden'
                      }`}
                    >
                      <Link
                        to="/contracts?view=details"
                        data-testid="sidemenu-contracts"
                        onClick={() => handleClickLink('/contracts')}
                        className="hidden md:block"
                      >
                        <li
                          className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('details')}`}
                        >
                          <ListBulletIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                          <Typography
                            color="green"
                            className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                          >
                            Details
                          </Typography>
                        </li>
                      </Link>
                      <Link
                        to="/contracts?view=collections"
                        data-testid="sidemenu-collections"
                        onClick={() => handleClickLink('/contracts')}
                        className="hidden md:block"
                      >
                        <li
                          className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('collections')}`}
                        >
                          <BanknotesIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                          <Typography
                            color="green"
                            className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                          >
                            Collections
                          </Typography>
                        </li>
                      </Link>
                      <Link
                        to="/contracts?view=revenue"
                        data-testid="sidemenu-revenue"
                        onClick={() => handleClickLink('/contracts')}
                        className="hidden md:block"
                      >
                        <li
                          className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('revenue')}`}
                        >
                          <ChartBarIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                          <Typography
                            color="green"
                            className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                          >
                            Revenue
                          </Typography>
                        </li>
                      </Link>
                    </div>
                  </div>
                )}
                <Link
                  to="/financial-model"
                  data-testid="sidemenu-financial-model"
                  onClick={() => handleClickLink('/financial-model')}
                  className="hidden md:block"
                >
                  <li className={`${navItemStyle} ${isActive('/financial-model')}`}>
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row items-center gap-2">
                        <TableCellsIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                        >
                          Financial Model
                        </Typography>
                      </div>
                      {!activeScenarioUuid && modelNeedsReview && <PingNotification shouldAnimate />}
                    </div>
                  </li>
                </Link>
              </ul>
              <ul className="mt-auto">
                {copilotBetaEnabled && (
                  <Link to="/chat" data-testid="sidemenu-chat" onClick={() => handleClickLink('/chat')}>
                    <li className={`${navItemStyle} ${isActive('/chat')}`}>
                      <SparklesIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                      <Typography
                        color="green"
                        className={` ${showExpandedMenuComponents ? 'block' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap overflow-hidden `}
                      >
                        Co-Pilot
                      </Typography>
                    </li>
                  </Link>
                )}
                {showRefresh && (
                  <li className={`${navItemStyle}`}>
                    <ArrowPathIcon
                      className={`flex-shrink-0 ${iconStyle} ${showExpandedMenuComponents ? 'hidden' : 'group-hover/sidemenu:hidden'}`}
                      aria-hidden="true"
                    />
                    <Button
                      fill="outline"
                      className={`${showExpandedMenuComponents ? '' : 'hidden group-hover/sidemenu:hidden'} whitespace-nowrap overflow-hidden`}
                      onClick={() => window.location.reload()}
                    >
                      Update Version
                    </Button>
                  </li>
                )}
                <UserNavigationPopover
                  setShowChangeLogModal={setShowChangeLogModal}
                  sideMenuExpanded={sideMenuExpanded}
                  handleClickLink={handleClickLink}
                  onOpenChange={setIsUserPopoverOpen}
                />
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
