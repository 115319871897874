import { z } from 'zod';
import { ZChangeDescription, ZChangeLogElement, ZChangeLogElementWithIndex, ZChangeLogResponse } from './schema';

export type IChangeLogElement = z.infer<typeof ZChangeLogElement>;
export type IChangeLogElementWithIndex = z.infer<typeof ZChangeLogElementWithIndex>;
export type IChangeLogDescription = z.infer<typeof ZChangeDescription>;

export enum IChangeLogActionEnum {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
}

export enum IChangeLogEntityEnum {
  Position = 'positions',
  Contract = 'contracts',
  Department = 'departments',
  Expense = 'expenses',
  Ratio = 'ratios',
  Formula = 'formulas',
}

export enum IChangeLogElementTypeEnum {
  Position = 'position',
  Formula = 'formula',
  Contract = 'contract',
  Ratio = 'ratio',
  Expense = 'expense',
  Department = 'department',
  ChangeLogGroup = 'changeLogGroup',
}

export type IChangeLogResponse = z.infer<typeof ZChangeLogResponse>;
