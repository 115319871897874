import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import Select, { useSelect } from '~/components/Select';
import { useInput } from '~/components/Input';
import InputWrapper from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { useRadioInput } from '~/components/RadioInput';
import RadioInputCard from '~/components/RadioInputCard';
import TargetValueIcon from './TargetValueIcon';
import TargetDateIcon from './TargetDateIcon';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { StatusCodes } from 'http-status-codes';
import { updateGoal } from '../../utils/goals/updateGoal';
import { createGoal } from '../../utils/goals/createGoal';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { ICompanyGoal } from '../../entity/types';
import { ZCompanyGoal } from '../../entity/schemas';
import { DashboardPageContext } from '../../context/DashboardContext';

enum GoalTypeEnum {
  TargetValue = 'targetValue',
  TargetDate = 'targetDate',
}

const GoalModal = ({
  createGoalModal,
  setCreateGoalModal,
  setTopLevelGoals,
}: {
  createGoalModal: {
    open: boolean;
    goalUuid: string | null;
    formulaUuid: string | null;
    targetValue: string | null;
    targetDate: string | null;
  };
  setCreateGoalModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      goalUuid: string | null;
      formulaUuid: string | null;
      targetValue: string | null;
      targetDate: string | null;
    }>
  >;
  setTopLevelGoals?: React.Dispatch<React.SetStateAction<ICompanyGoal[]>>;
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const dashboardConfiguration = useSelector((state: State) => state.organization.configuration.dashboardConfiguration);
  const { reports, reload } = useContext(DashboardPageContext);

  const [selectedGoalType, setSelectedGoalType, resetSelectedGoalType] = useRadioInput({
    options: [
      {
        label: 'Target Value',
        value: GoalTypeEnum.TargetValue,
        icon: <TargetValueIcon />,
      },
      {
        label: 'Target Date',
        value: GoalTypeEnum.TargetDate,
        icon: <TargetDateIcon />,
      },
    ],
    selected: undefined,
  });
  const filteredReports = useMemo(() => {
    if (reports.consolidated) {
      return Object.keys(reports.consolidated).filter(
        (key) => dashboardConfiguration.graphs.includes(key) && reports.consolidated?.[key]?.title !== 'Revenue',
      );
    }
    return [];
  }, [reports.consolidated, dashboardConfiguration.graphs]);

  const [graphSelect, setGraphSelect, resetGraphSelect] = useSelect({
    options: filteredReports.map((key) => ({
      label: reports.consolidated?.[key]?.title ?? key,
      value: key,
    })),
    selected: undefined,
  });
  const [targetValue, setTargetValue, resetTargetValue] = useInput({
    value: '',
  });
  const [targetDate, setTargetDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [targetValueFormatting, setTargetValueFormatting] = useState<IFormattingEnum>(IFormattingEnum.Number);

  useEffect(() => {
    if (graphSelect.selected?.value) {
      const report = reports.consolidated?.[graphSelect.selected.value];
      if (report) {
        setTargetValueFormatting(report.formatting ?? IFormattingEnum.Number);
      }
    }
  }, [graphSelect.selected?.value, reports.consolidated]);

  useEffect(() => {
    if (createGoalModal.goalUuid) {
      setSelectedGoalType({
        ...selectedGoalType,
        selected: createGoalModal.targetDate
          ? {
              label: 'Target Date',
              value: GoalTypeEnum.TargetDate,
            }
          : {
              label: 'Target Value',
              value: GoalTypeEnum.TargetValue,
            },
      });
      setGraphSelect({
        ...graphSelect,
        selected: {
          label:
            (createGoalModal.formulaUuid && reports.consolidated?.[createGoalModal.formulaUuid]?.title) ??
            createGoalModal.formulaUuid,
          value: createGoalModal.formulaUuid,
        },
      });
      setTargetValue({
        ...targetValue,
        value: createGoalModal.targetValue ?? '',
      });
      setTargetDate({
        ...targetDate,
        startDate: createGoalModal.targetDate ?? null,
        endDate: createGoalModal.targetDate ?? null,
      });
    }
  }, [createGoalModal.goalUuid]);
  const closeModal = (): void => {
    setCreateGoalModal({
      open: false,
      goalUuid: null,
      formulaUuid: null,
      targetValue: null,
      targetDate: null,
    });
    resetSelectedGoalType();
    resetGraphSelect();
    resetTargetValue();
    setTargetDate({
      ...targetDate,
      startDate: null,
      endDate: null,
    });
  };

  useEffect(() => {
    setGraphSelect({
      ...graphSelect,
      options: filteredReports.map((key) => ({
        label: reports.consolidated?.[key]?.title ?? key,
        value: key,
      })),
    });
  }, [filteredReports]);

  const validateForm = (): boolean => {
    const typeSelected = Boolean(selectedGoalType.selected?.value);
    const graphSelected = Boolean(graphSelect.selected?.value);
    const validTargetValue = Boolean(targetValue.value);
    const targetDateSelected =
      Boolean(targetDate.startDate) || selectedGoalType.selected?.value === GoalTypeEnum.TargetValue;

    if (!typeSelected) {
      setSelectedGoalType((prev) => ({
        ...prev,
        valid: false,
        pristine: false,
        touched: true,
        errorMessage: 'Please select a goal type',
      }));
    }
    if (!validTargetValue) {
      setTargetValue((prev) => ({
        ...prev,
        valid: false,
        pristine: false,
        touched: true,
      }));
    }
    if (!graphSelected) {
      setGraphSelect((prev) => ({
        ...prev,
        valid: false,
        pristine: false,
        touched: true,
      }));
    }
    if (!targetDateSelected) {
      setTargetDate((prev) => ({
        ...prev,
        valid: false,
        pristine: false,
        touched: true,
        errorMessage: 'Please select a date or choose type target value',
      }));
    }
    return typeSelected && graphSelected && validTargetValue && targetDateSelected;
  };

  const handleSave = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const isValid = validateForm();
      if (isValid) {
        const finalTargetValue =
          targetValueFormatting === IFormattingEnum.Percent
            ? parseFloat(targetValue.value)
            : parseFloat(targetValue.value) * CENTS_PER_DOLLAR;
        const bodyData = {
          targetValue: finalTargetValue,
          targetDate:
            targetDate.startDate && selectedGoalType.selected?.value === GoalTypeEnum.TargetDate
              ? targetDate.startDate
              : null,
          formulaUuid: graphSelect.selected?.value ?? null,
          organizationUuid,
        };
        const response = createGoalModal.goalUuid
          ? await updateGoal({
              ...bodyData,
              goalUuid: createGoalModal.goalUuid,
            })
          : await createGoal(bodyData);
        if (createGoalModal.goalUuid ? response.status === StatusCodes.OK : response.status === StatusCodes.CREATED) {
          await reload();
          if (setTopLevelGoals) {
            const parsedGoal = ZCompanyGoal.parse(response.data.data);
            if (createGoalModal.goalUuid) {
              setTopLevelGoals((prev) =>
                prev.map((goal) => (goal.goalUuid === createGoalModal.goalUuid ? parsedGoal : goal)),
              );
            } else {
              setTopLevelGoals((prev) => [...prev, parsedGoal]);
            }
          }
          toast.success('Goal created successfully');
          closeModal();
        } else {
          toast.error('Failed to create goal');
        }
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to create goal');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={createGoalModal.goalUuid ? 'Edit Goal' : 'New Goal'}
      isOpen={createGoalModal.open}
      showClose
      onClose={closeModal}
      size="xs"
    >
      <div className="flex flex-col w-full items-center justify-center gap-4 mt-3">
        <div className="flex flex-col gap-2 w-full items-start">
          <RadioInputCard id="goal-type" state={selectedGoalType} setState={setSelectedGoalType} />
          <Select
            id="graph-select"
            label="Graph"
            state={graphSelect}
            setState={setGraphSelect}
            errorMessage="Please select a graph"
            className="z-50"
          />
          {targetValueFormatting === IFormattingEnum.Currency ? (
            <CurrencyInput
              id="target-value"
              label="Target Value"
              state={targetValue}
              setState={setTargetValue}
              allowNegative
            />
          ) : (
            <InputWrapper
              id="target-value"
              label="Target Value"
              state={targetValue}
              setState={setTargetValue}
              type={targetValueFormatting === IFormattingEnum.Percent ? 'percentage' : 'currency'}
              includeDollarSign={false}
              allowNegative
            />
          )}
          {selectedGoalType.selected?.value === 'targetDate' && (
            <div className="w-full">
              <PeriodPicker
                id="target-date"
                label="Date"
                state={targetDate}
                setState={setTargetDate}
                defaultInputDisplay="Select Date"
              />
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-between">
          <Button fill="clear" className="!w-fit !px-0" onClick={closeModal}>
            Cancel
          </Button>
          <div className="flex gap-1 items-center justify-end">
            <Button className="!w-fit" loading={isLoading} onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GoalModal;
