import { elementAlphanumericSortFn, sortActiveFn, sortDateFn } from './utils/customSortingFn';

export const headcountTableColumns = [
  {
    id: 'isActive',
    label: '',
    enableSorting: true,
    sortingFn: sortActiveFn,
    className: 'w-full text-left text-nowrap',
  },
  {
    id: 'employeeName',
    label: 'EMPLOYEE',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'w-full text-left ml-2.5 text-nowrap',
  },
  {
    id: 'title',
    label: 'TITLE',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'w-full text-left ml-2.5 text-nowrap',
  },
  {
    id: 'employmentType',
    label: 'EMPLOYMENT TYPE',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'w-full text-left text-nowrap',
  },
  {
    id: 'departmentUuid',
    label: 'DEPARTMENT',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'w-full text-left text-nowrap',
  },
  {
    id: 'payRate',
    label: 'TOTAL COMP.',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'w-full text-right text-nowrap flex flex-row items-center justify-end gap-1',
  },
  {
    id: 'fullyBurdenedCost',
    label: 'TOTAL COST',
    enableSorting: true,
    sortingFn: elementAlphanumericSortFn,
    className: 'flex flex-row w-full justify-end items-end text-nowrap items-center gap-1',
  },
  {
    id: 'hireDate',
    label: 'HIRE DATE',
    enableSorting: true,
    sortingFn: sortDateFn,
    className: 'w-full text-left text-nowrap',
  },
  {
    id: 'termDate',
    label: 'TERM DATE',
    enableSorting: true,
    sortingFn: sortDateFn,
    className: 'w-full text-left text-nowrap',
  },
  { id: 'ellipsisDropdown', label: '', className: 'w-full text-left text-nowrap' },
];
