const operators = ['+', '-', '*', '/', '(', ')', ','];

export const findLastBoundaryOperator = (text: string): number => {
  const chars = [...text.trimEnd()];
  for (let i = chars.length - 1; i >= 0; i--) {
    const char = chars[i];
    // Skip whitespace when looking for previous character
    if (char === ' ') {
      continue;
    }
    if (operators.includes(char)) {
      if (i === 0) {
        return 0;
      }
      // Look for previous non-whitespace character
      for (let j = i - 1; j >= 0; j--) {
        if (chars[j] === ' ') continue;
        // If previous char is not a letter, this operator is our boundary
        if (!/[a-zA-Z]/.test(chars[j])) {
          return i;
        }
        // Previous char was a letter, keep scanning
        break;
      }
    }
  }
  return -1;
};
