import React, { createContext, useState, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DragEndEvent } from '@dnd-kit/core';
import { v4 as uuid } from 'uuid';
import * as api from '~/services/parallel';
import { State } from '~/store';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';
import logger from '~/utils/logger';
import { useInput } from '~/components/Input/InputWrapper';
import { convertModelToCsvData } from '~/pages/FinancialModel/utils/convertModelToCsvData';
import { IOrganizationState } from '~/store/organizationSlice';
import { ScenarioState } from '~/store/scenarioSlice';
import { UserState } from '~/store/userSlice';
import * as stringDate from '~/utils/stringDate';
import { IFormattingEnum, IFormula, IFormulaTypeEnum, IRoundDirectionEnum } from '~/services/parallel/formulas.types';
import { IIntegrationMapping, IIntegrationSources } from '~/services/parallel/integrations.types';
import { IFormulaData, IFormulaType, IGroupType, ILoadingFormulas, IMonthlyValues } from './types';
import { IStringDate } from '~/utils/stringDate/types';
import { cloneDeep } from 'lodash';
import { IUpdateFormulaParams } from '~/services/parallel/formulas';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { IOnboardingStepNameEnum, IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';
import { createSelector } from '@reduxjs/toolkit';

export interface IFormulasContext {
  toggleGroupCollapse: (value: string | boolean) => void;
  onDragEnd: (value: DragEndEvent) => void;
  formulaDictionary: Record<string, IFormula>;
  selectedMonths: string[];
  updateFormulaMonthsValue: (params: {
    formulaUuid: string;
    months: stringDate.types.IStringDate[];
    values: (number | null)[];
    lastNeededDate: stringDate.types.IStringDate;
  }) => void;
  availableIntegration: IIntegrationSources | null;
  loadingFormulas: ILoadingFormulas[];
  updateFormulaName: (params: { formulaUuid: string; name: string }) => void;
  updateFormulaFormatting: (params: { formulaUuid: string; formatting: IFormattingEnum }) => void;
  updateFormulaRounding: (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }) => void;
  updateFormulaDataSource: (params: { formulaUuid: string; dataSourceUuids: string[] }) => void;
  updateFormulaMinMax: (params: { formula: IFormula; min: string | null; max: string | null }) => Promise<void>;
  dataSources: IIntegrationMapping[];
  allFormulasData: IFormulaData[];
  filteredFormulasData: IFormulaData[];
  searchFilter: Types.InputState;
  setSearchFilter: React.Dispatch<React.SetStateAction<Types.InputState>>;
  refreshData: () => Promise<void>;
  createNewAttribute: (params: { uuid: string }) => void;
  pendingAttributeData: {
    groupUuid: string;
  } | null;
  setPendingAttributeData: React.Dispatch<
    React.SetStateAction<{
      groupUuid: string;
    } | null>
  >;
  saveNewAttribute: (params: {
    formulaGroupUuid: string;
    name: string;
    tabIntoFormula: boolean;
    nextSortOrder: number;
  }) => Promise<void>;
  deleteFormula: (formulaUuid: string) => Promise<void>;
  formulaUuidToFocus: string | null;
  setFormulaUuidToFocus: React.Dispatch<React.SetStateAction<string | null>>;
  pendingTabTarget: {
    columnIndex: number;
    formulaUuid: string;
  } | null;
  setPendingTabTarget: React.Dispatch<React.SetStateAction<{ columnIndex: number; formulaUuid: string } | null>>;
  csvExportData: string;
  viewOnly: boolean;
  manageGroupsModalOpen: boolean;
  setManageGroupsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollEnabled: { x: boolean; y: boolean };
  setScrollEnabled: React.Dispatch<React.SetStateAction<{ x: boolean; y: boolean }>>;
  mode: IFormulaTypeEnum;
  allFormulasDictionary: Record<string, IFormula>;
  updateFormulaGroups: (params: { create: IGroupType[]; update: IGroupType[]; delete: string[] }) => Promise<void>;
}

export const FormulasContext = createContext<IFormulasContext | undefined>(undefined);

interface FormulasProviderProps {
  children: ReactNode;
  defaultData?: {
    organization: IOrganizationState;
    scenario: ScenarioState;
    user: UserState;
  };
  viewOnly?: boolean;
  startDate?: IStringDate;
  endDate?: IStringDate;
  scenarioUuid?: string;
  mode: IFormulaTypeEnum;
  accessTokenOverride?: string | null;
}

const selectFormulasData = createSelector(
  (state: State) => state.organization.uuid,
  (state: State) => state.scenario.activeScenarioData,
  (state: State) => state.user.preferences.defaultGraphEndDate,
  (state: State) => state.user.preferences.defaultGraphStartDate,
  (state: State) => state.integrations.isPulling,
  (state: State) => state.integrations.lastPulled,
  (state: State) => state.scenario.activeScenarioUuid,
  (
    orgUuid,
    activeScenarioData,
    defaultGraphEndDate,
    defaultGraphStartDate,
    isPulling,
    lastPulled,
    activeScenarioUuid,
  ) => ({
    organization: {
      uuid: orgUuid,
    },
    integrations: {
      isPulling,
      lastPulled,
    },
    scenario: {
      activeScenarioData,
      activeScenarioUuid,
    },
    user: {
      preferences: {
        defaultGraphEndDate,
        defaultGraphStartDate,
      },
    },
  }),
);

export const FormulasProvider: React.FC<FormulasProviderProps> = ({
  children,
  defaultData,
  viewOnly = false,
  startDate,
  endDate,
  scenarioUuid,
  mode,
  accessTokenOverride,
}: FormulasProviderProps) => {
  const stateData = useSelector(selectFormulasData);
  const isInitialRender = useRef<boolean>(true);
  const { organization, scenario, user } = defaultData ?? stateData;
  const { defaultGraphEndDate, defaultGraphStartDate } = user.preferences;
  const [allFormulasData, setAllFormulasData] = useState<IFormulaData[]>([]);
  const [formulaDictionary, setFormulaDictionary] = useState<Record<string, IFormula>>({});
  const [loadingFormulas, setLoadingFormulas] = useState<ILoadingFormulas[]>([]);
  const [searchFilter, setSearchFilter] = useInput({ validation: /.*/ });
  const [dataSources, setDataSources] = useState<IIntegrationMapping[]>([]);
  const [availableIntegration, setAvailableIntegration] = useState<IIntegrationSources | null>(null);
  const [pendingAttributeData, setPendingAttributeData] = useState<{
    groupUuid: string;
  } | null>(null);
  const [formulaUuidToFocus, setFormulaUuidToFocus] = useState<string | null>(null);
  const [pendingTabTarget, setPendingTabTarget] = useState<{
    columnIndex: number;
    formulaUuid: string;
  } | null>(null);
  const [manageGroupsModalOpen, setManageGroupsModalOpen] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState<{ x: boolean; y: boolean }>({ x: true, y: true });
  const [allFormulasDictionary, setAllFormulasDictionary] = useState<Record<string, IFormula>>({});

  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery();
  const [updateOnboardingStep] = onboardingStepsApi.useUpdateOnboardingStepMutation();

  const csvExportData = useMemo(
    () =>
      convertModelToCsvData({
        modelData: allFormulasData,
        startDate: startDate ?? defaultGraphStartDate,
        endDate: endDate ?? defaultGraphEndDate,
      }),
    [allFormulasData, defaultGraphEndDate, defaultGraphStartDate, startDate, endDate],
  );

  const filteredFormulasData = useMemo(() => {
    if (mode === IFormulaTypeEnum.ModelBuilder) {
      return allFormulasData
        .map((group) => {
          if (group.type === IRecordTypeEnum.Group) {
            return {
              ...group,
              isCollapsed: searchFilter.value ? false : group.isCollapsed,
              formulas: group.formulas
                .filter(
                  (formula) =>
                    formula.formula.recipe.name.toLowerCase().includes(searchFilter.value.toLowerCase()) ||
                    Object.values(formula.formula.recipe.variables).some(
                      (variable) =>
                        variable.formulaUuid &&
                        variable.formulaUuid in formulaDictionary &&
                        formulaDictionary[variable.formulaUuid].recipe.name
                          .toLowerCase()
                          .includes(searchFilter.value.toLowerCase()),
                    ),
                )
                .sort((a, b) => {
                  if (a.sortOrder === null || b.sortOrder === null) {
                    return 0;
                  }
                  return a.sortOrder - b.sortOrder;
                }),
            };
          }
          return group;
        })
        .filter((group) => (searchFilter.value && group.type === IRecordTypeEnum.Group ? group.formulas.length : true));
    } else {
      return allFormulasData
        .filter(
          (item) =>
            item.type === IRecordTypeEnum.Formula &&
            item.formula.recipe.name.toLowerCase().includes(searchFilter.value.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.sortOrder === null) return 1;
          if (b.sortOrder === null) return -1;
          return a.sortOrder - b.sortOrder;
        });
    }
  }, [allFormulasData, searchFilter.value]);

  const selectedMonths = useMemo(() => {
    const startDateTime = startDate ?? user.preferences.defaultGraphStartDate;
    const endDateTime = endDate ?? user.preferences.defaultGraphEndDate;

    return stringDate
      .createMonthArrayBetweenDates({
        startDate: startDateTime,
        endDate: endDateTime,
      })
      .map((month) => stringDate.format(month, 'MMM yyyy'));
  }, [user.preferences.defaultGraphEndDate, user.preferences.defaultGraphStartDate]);

  const fetchData = async (): Promise<void> => {
    const allFormulas = await api.formulas.list({
      organizationUuid: organization.uuid,
      startDate: startDate ?? defaultGraphStartDate,
      endDate: endDate ?? defaultGraphEndDate,
      scenarioUuid: scenarioUuid ?? scenario.activeScenarioUuid ?? undefined,
      accessTokenOverride,
    });

    const formulasList = allFormulas.filter((formula) => {
      if (mode === IFormulaTypeEnum.Expense) {
        // Only show expense formulas when mode is Expense
        return (
          (!formula.creationStatus || formula.creationStatus === 'created') &&
          (formula.type === mode || formula.type === IFormulaTypeEnum.Headcount)
        );
      }
      // Show all formulas when mode is not Expense
      return !formula.creationStatus || formula.creationStatus === 'created';
    });

    const formulasDictionary = formulasList.reduce((output: Record<string, IFormula>, formula) => {
      output[formula.formulaUuid] = formula;
      return output;
    }, {});

    setFormulaDictionary(formulasDictionary);

    setAllFormulasDictionary(
      allFormulas.reduce((output: Record<string, IFormula>, formula) => {
        output[formula.formulaUuid] = formula;
        return output;
      }, {}),
    );

    const integrationsList = !defaultData
      ? await api.integrations.list({
          organizationUuid: organization.uuid,
          accessTokenOverride,
        })
      : [];

    if (integrationsList.length) {
      setAvailableIntegration(integrationsList[0].source?.slug as IIntegrationSources);
    }

    const allIntegrationMappings: IIntegrationMapping[] = [];
    if (integrationsList.length) {
      for (const integration of integrationsList) {
        const integrationMappings = await api.integrations.listMappings({
          organizationUuid: organization.uuid,
          integrationUuid: integration.uuid,
          accessTokenOverride,
        });
        allIntegrationMappings.push(...integrationMappings);
      }
    }

    setDataSources(allIntegrationMappings);

    const integrationMappingsDictionary = allIntegrationMappings.reduce(
      (output: Record<string, string>, integrationMapping) => {
        if (integrationMapping.uuid in output) return output;
        const integrationSlug = integrationsList.find(
          (integration) => integration.uuid === integrationMapping.integrationUuid,
        );
        if (integrationSlug) {
          output[integrationMapping.uuid] = integrationSlug.source?.slug ?? '';
        }
        return output;
      },
      {},
    );

    if (mode === IFormulaTypeEnum.ModelBuilder) {
      /** MANAGE SORT ORDER - START */
      const formulaGroups = (
        await api.formulas.getFormulaGroups({
          organizationUuid: organization.uuid,
          scenarioUuid: scenarioUuid ?? scenario.activeScenarioUuid ?? undefined,
          accessTokenOverride,
        })
      ).sort((a, b) => a.sortOrder - b.sortOrder);

      setAllFormulasData((prevState) => {
        return formulaGroups.map((group): IGroupType => {
          const formulas = formulasList
            .filter(
              (formula) =>
                formula.formulaGroupUuid === group.formulaGroupUuid &&
                (formula.type === IFormulaTypeEnum.ModelBuilder || formula.type === IFormulaTypeEnum.Headcount),
            )
            .sort((a, b) => {
              if (a.sortOrder === null || b.sortOrder === null) {
                return 0;
              }
              return a.sortOrder - b.sortOrder;
            })
            .map((formula): IFormulaType => {
              const isReferencedElsewhere = formulasList.some((f) =>
                Object.values(f.recipe.variables).some((variable) => variable.formulaUuid === formula.formulaUuid),
              );

              const actualsDict = formula.actuals.reduce<Record<string, number>>((output, actual) => {
                output[stringDate.format(actual.date, 'MMM yyyy')] = actual.value;
                return output;
              }, {});

              const overridesDict = formula.overrides.reduce<Record<string, number>>((output, override) => {
                output[stringDate.format(override.date, 'MMM yyyy')] = override.value;
                return output;
              }, {});

              const calculationsDict = formula.calculations.reduce<Record<string, number>>((output, calculation) => {
                output[stringDate.format(calculation.date, 'MMM yyyy')] = calculation.value ?? 0;
                return output;
              }, {});

              const monthlyValues = selectedMonths.reduce<IMonthlyValues>((output, month) => {
                output[month] = {
                  calculatedValue: calculationsDict[month] ?? 0,
                  overrideValue: overridesDict[month] ?? null,
                  actualValue: actualsDict[month] ?? null,
                  formatting: formula.formatting ?? null,
                  roundingInstructions: formula.recipe.roundingInstructions ?? null,
                };
                return output;
              }, {});

              return {
                type: IRecordTypeEnum.Formula,
                formulaUuid: formula.formulaUuid,
                relatedResourceUuid: null,
                label: {
                  name: formula.recipe.name,
                  integration:
                    (formula.dataSourceUuids.length && integrationMappingsDictionary[formula.dataSourceUuids[0]]) ||
                    null,
                  isIsolated: !isReferencedElsewhere,
                  isProtected: formula.isProtected,
                },
                formula: {
                  recipe: formula.recipe,
                  isProtected: formula.isProtected,
                  scenarioUuid: formula.scenarioUuid,
                },
                monthlyValues,
                sortOrder: formula.sortOrder ?? null,
                formulaGroupUuid: formula.formulaGroupUuid ?? null,
              };
            });

          let isCollapsed = false;
          if (
            prevState.find((g): g is IGroupType => g.type === IRecordTypeEnum.Group && g.name === group.name)
              ?.isCollapsed !== undefined
          ) {
            isCollapsed =
              prevState.find((g): g is IGroupType => g.type === IRecordTypeEnum.Group && g.name === group.name)
                ?.isCollapsed ?? false;
          }

          return {
            type: IRecordTypeEnum.Group,
            uuid: group.uuid,
            name: group.name,
            isCollapsed,
            formulas,
            sortOrder: group.sortOrder,
            formulaGroupUuid: group.formulaGroupUuid,
          };
        });
      });
    } else {
      setAllFormulasData(
        Object.entries(formulasDictionary).map(([formulaUuid, formula]): IFormulaType => {
          const isReferencedElsewhere = formulasList.some((f) =>
            Object.values(f.recipe.variables).some((variable) => variable.formulaUuid === formulaUuid),
          );

          const actualsDict = formula.actuals.reduce<Record<string, number>>((output, actual) => {
            output[stringDate.format(actual.date, 'MMM yyyy')] = actual.value;
            return output;
          }, {});

          const overridesDict = formula.overrides.reduce<Record<string, number>>((output, override) => {
            output[stringDate.format(override.date, 'MMM yyyy')] = override.value;
            return output;
          }, {});

          const calculationsDict = formula.calculations.reduce<Record<string, number>>((output, calculation) => {
            output[stringDate.format(calculation.date, 'MMM yyyy')] = calculation.value ?? 0;
            return output;
          }, {});

          const monthlyValues = selectedMonths.reduce<IMonthlyValues>((output, month) => {
            output[month] = {
              calculatedValue: calculationsDict[month] ?? 0,
              overrideValue: overridesDict[month] ?? null,
              actualValue: actualsDict[month] ?? null,
              formatting: formula.formatting ?? null,
              roundingInstructions: formula.recipe.roundingInstructions ?? null,
            };
            return output;
          }, {});

          return {
            type: IRecordTypeEnum.Formula,
            formulaUuid: formula.formulaUuid,
            relatedResourceUuid: formula.recipe.relatedResourceUuid ?? null,
            label: {
              name: formula.recipe.name,
              integration:
                (formula.dataSourceUuids.length && integrationMappingsDictionary[formula.dataSourceUuids[0]]) || null,
              isIsolated: !isReferencedElsewhere,
              isProtected: formula.isProtected,
            },
            formula: {
              recipe: formula.recipe,
              context: formula.context,
              isProtected: formula.isProtected,
              scenarioUuid: formula.scenarioUuid,
            },
            monthlyValues,
            sortOrder: formula.sortOrder ?? null,
            formulaGroupUuid: formula.formulaGroupUuid ?? null,
          };
        }),
      );
    }
  };

  useEffect(() => {
    if (organization.uuid) {
      fetchData();
    }
  }, [defaultGraphEndDate, defaultGraphStartDate, organization.uuid, startDate, endDate, scenario.activeScenarioUuid]);

  const toggleGroupCollapse = (value: string | boolean): void => {
    if (mode !== IFormulaTypeEnum.ModelBuilder) throw new Error('Unable to access group. Type not ModelBuilder');
    if (typeof value === 'string') {
      // Toggle individual group
      setAllFormulasData((prev) =>
        prev.map((group) => {
          if (group.type !== IRecordTypeEnum.Group) return group;
          return group.uuid === value ? { ...group, isCollapsed: !group.isCollapsed } : group;
        }),
      );
    } else {
      // Toggle all groups at once
      setAllFormulasData((prev) =>
        prev.map((group) => {
          if (group.type !== IRecordTypeEnum.Group) return group;
          return { ...group, isCollapsed: value };
        }),
      );
    }
  };

  const onDragEnd = async (value: DragEndEvent): Promise<void> => {
    if (mode !== IFormulaTypeEnum.ModelBuilder) {
      throw new Error('Sorting is not supported outside of ModelBuilder type');
    }

    if (viewOnly) return;

    const { active, over } = value;
    if (!over) return;

    // Verify all items in allFormulasData are of type IGroup
    if (!allFormulasData.every((group): group is IGroupType => group.type === IRecordTypeEnum.Group)) {
      throw new Error('Invalid data structure: allFormulasData must contain only Group type records');
    }

    const clonedGroups = cloneDeep(allFormulasData);

    // Find the source and target groups
    const sourceGroup = clonedGroups.find((group) =>
      group.formulas.some((formula) => formula.formulaUuid === active.id.toString()),
    );
    const targetGroup = clonedGroups.find(
      (group) =>
        group.formulas.some((formula) => formula.formulaUuid === over.id.toString()) ||
        group.uuid === over.id.toString(),
    );

    if (!sourceGroup || !targetGroup) return;

    const sourceIndex = sourceGroup.formulas.findIndex((f) => f.formulaUuid === active.id.toString());
    const targetIndex = targetGroup.formulas.findIndex((f) => f.formulaUuid === over.id.toString());

    // Get the formula being moved
    const movedFormula = sourceGroup.formulas[sourceIndex];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!movedFormula) return;

    try {
      // Calculate new sort order
      let newSortOrder: number;

      if (sourceGroup.uuid === targetGroup.uuid) {
        // Moving within same group
        if (targetIndex === -1) {
          // Dropped on group header - move to start
          newSortOrder = (targetGroup.formulas[0]?.sortOrder ?? 10) - 10;
        } else if (sourceIndex > targetIndex) {
          // Moving up - get midpoint between target and item before it
          const itemBefore = targetGroup.formulas[targetIndex];
          const itemAt = targetGroup.formulas[targetIndex + 1];
          if (itemAt.sortOrder === null || itemBefore.sortOrder === null) {
            throw new Error('Sort order is null');
          }
          newSortOrder = targetIndex === 0 ? itemAt.sortOrder - 10 : (itemBefore.sortOrder + itemAt.sortOrder) / 2;
        } else {
          // Moving down - get midpoint between target and item after it
          const itemAt = targetGroup.formulas[targetIndex];
          if (targetGroup.formulas.length === targetIndex + 1) {
            newSortOrder = (itemAt.sortOrder ?? 0) + 10;
          } else {
            const itemAfter = targetGroup.formulas[targetIndex + 1];
            if (itemAt.sortOrder === null || itemAfter.sortOrder === null) {
              throw new Error('Sort order is null');
            }
            newSortOrder = (itemAt.sortOrder + itemAfter.sortOrder) / 2;
          }
        }
      } else {
        // Moving to different group
        if (targetIndex === -1) {
          // TODO - test this

          // Dropped on group header - move to start
          newSortOrder = (targetGroup.formulas[0]?.sortOrder ?? 10) - 10;
        } else {
          // Dropped on formula - get midpoint between target and next item
          const itemAt = targetGroup.formulas[targetIndex];
          if (targetGroup.formulas.length === targetIndex + 1) {
            newSortOrder = (itemAt.sortOrder ?? 0) + 10;
          } else {
            const itemAfter = targetGroup.formulas[targetIndex + 1];
            if (itemAt.sortOrder === null || itemAfter.sortOrder === null) {
              throw new Error('Sort order is null');
            }
            newSortOrder = (itemAt.sortOrder + itemAfter.sortOrder) / 2;
          }
        }
      }

      // Update the formula's group and sort order in the backend
      if (targetGroup.formulaGroupUuid !== sourceGroup.formulaGroupUuid || newSortOrder !== movedFormula.sortOrder) {
        await api.formulas.update({
          organizationUuid: organization.uuid,
          formulaUuid: movedFormula.formulaUuid,
          formulaGroupUuid: targetGroup.formulaGroupUuid,
          sortOrder: newSortOrder,
          scenarioUuid: scenario.activeScenarioUuid ?? undefined,
        });
      }

      // Update local state to reflect the change
      setAllFormulasData((prevGroups) => {
        const updatedGroups = prevGroups.map((group) => {
          if (group.type !== IRecordTypeEnum.Group) return group;
          if (
            targetGroup.formulaGroupUuid !== group.formulaGroupUuid &&
            sourceGroup.formulaGroupUuid !== group.formulaGroupUuid
          ) {
            return group;
          }

          if (targetGroup.formulaGroupUuid === sourceGroup.formulaGroupUuid) {
            const formulas = group.formulas
              .map((formula) => {
                if (formula.formulaUuid === movedFormula.formulaUuid) {
                  return { ...formula, sortOrder: newSortOrder };
                }
                return formula;
              })
              .sort((a, b) => {
                if (a.sortOrder === null || b.sortOrder === null) return 0;
                return a.sortOrder - b.sortOrder;
              });

            return {
              ...group,
              formulas,
            };
          } else {
            const formulasToUpdate = [...group.formulas];

            if (sourceGroup.formulaGroupUuid === group.formulaGroupUuid) {
              formulasToUpdate.splice(sourceIndex, 1);
              return {
                ...group,
                formulas: formulasToUpdate,
              };
            } else if (targetGroup.formulaGroupUuid === group.formulaGroupUuid) {
              formulasToUpdate.push({
                ...movedFormula,
                sortOrder: newSortOrder,
                formulaGroupUuid: targetGroup.formulaGroupUuid,
              });

              return {
                ...group,
                formulas: formulasToUpdate.sort((a, b) => {
                  if (a.sortOrder === null || b.sortOrder === null) return 0;
                  return a.sortOrder - b.sortOrder;
                }),
              };
            } else {
              return group;
            }
          }
        });

        return updatedGroups;
      });
    } catch (error) {
      logger.error(new Error('Error updating formula position:'));
      // Optionally refresh data to ensure UI is in sync with backend
      await fetchData();
    }
  };

  const updateFormulaGroups = async (updatedFormulasData: {
    create: IGroupType[];
    update: IGroupType[];
    delete: string[];
  }): Promise<void> => {
    if (viewOnly) return;

    const promises = [];

    if (updatedFormulasData.create.length) {
      promises.push(
        ...updatedFormulasData.create.map((groupToCreate) =>
          api.formulas.createFormulaGroup({
            organizationUuid: organization.uuid,
            scenarioUuid: scenario.activeScenarioUuid ?? undefined,
            data: groupToCreate,
          }),
        ),
      );

      setAllFormulasData((prevData) => {
        return [...prevData, ...updatedFormulasData.create];
      });
    }

    if (updatedFormulasData.update.length) {
      promises.push(
        ...updatedFormulasData.update.map((groupToUpdate) =>
          api.formulas.updateFormulaGroup({
            organizationUuid: organization.uuid,
            scenarioUuid: scenario.activeScenarioUuid ?? undefined,
            formulaGroupUuid: groupToUpdate.formulaGroupUuid,
            data: {
              name: groupToUpdate.name,
              sortOrder: groupToUpdate.sortOrder,
            },
          }),
        ),
      );

      setAllFormulasData((prevData) => {
        return prevData
          .map((item) => {
            // Find if this item needs to be updated
            const updateItem = updatedFormulasData.update.find(
              (update) => item.type === IRecordTypeEnum.Group && item.formulaGroupUuid === update.formulaGroupUuid,
            );

            if (updateItem) {
              return {
                ...item,
                name: updateItem.name,
                sortOrder: updateItem.sortOrder,
              };
            }

            return item;
          })
          .sort((a, b) => {
            if (a.type !== IRecordTypeEnum.Group || b.type !== IRecordTypeEnum.Group)
              throw new Error('Group must be top level data type');
            return a.sortOrder - b.sortOrder;
          });
      });
    }

    if (updatedFormulasData.delete.length) {
      promises.push(
        ...updatedFormulasData.delete.map((formulaGroupUuidToDelete) =>
          api.formulas.deleteFormulaGroup({
            organizationUuid: organization.uuid,
            scenarioUuid: scenario.activeScenarioUuid ?? undefined,
            formulaGroupUuid: formulaGroupUuidToDelete,
          }),
        ),
      );

      setAllFormulasData((prevData) => {
        return prevData.filter(
          (item) =>
            !updatedFormulasData.delete.some(
              (formulaGroupUuidToDelete) =>
                item.type === IRecordTypeEnum.Group && formulaGroupUuidToDelete === item.formulaGroupUuid,
            ),
        );
      });
    }

    await Promise.all(promises);
  };

  const getEffectedMonths = (month: stringDate.types.IStringDate): stringDate.types.IStringDate[] => {
    const months = selectedMonths.filter((m) =>
      stringDate.isSameMonthOrAfter({ comparison: month, dateToCheck: stringDate.MMMyyyyToStringDate(m) }),
    );
    return months;
  };

  const getEffectedFormulas = (formulaUuid: string, processedUuids: Set<string> = new Set()): string[] => {
    // If we've already processed this UUID, return empty array to prevent circular recursion
    if (processedUuids.has(formulaUuid)) {
      return [];
    }

    // Add current UUID to processed set
    processedUuids.add(formulaUuid);

    const uuids = Object.values(formulaDictionary)
      .filter((formula) => {
        const recipeVariables = formula.recipe.variables;
        const variables = Object.values(recipeVariables);
        return variables.some((variable) => variable.formulaUuid === formulaUuid);
      })
      .map((formula) => formula.formulaUuid);

    if (uuids.length) {
      const chainedFormulaUuids = uuids.flatMap((uuid) => getEffectedFormulas(uuid, processedUuids));
      return Array.from(new Set([formulaUuid, ...uuids, ...chainedFormulaUuids]));
    }
    return [formulaUuid];
  };

  const handleLoadingMonths = (params: {
    requestUuid: string;
    formulaUuid: string;
    month: stringDate.types.IStringDate;
  }): void => {
    const effectedMonths = getEffectedMonths(params.month);
    const effectedFormulas = getEffectedFormulas(params.formulaUuid);
    setLoadingFormulas((prev) => [
      ...prev,
      {
        requestUuid: params.requestUuid,
        formulaUuids: [params.formulaUuid, ...effectedFormulas],
        months: effectedMonths,
      },
    ]);
  };

  const resolveLoadingFormulas = (params: { requestUuid: string }): void => {
    setLoadingFormulas((prev) => prev.filter((request) => request.requestUuid !== params.requestUuid));
  };

  const updateFormulaMonthsValue = async (params: {
    formulaUuid: string;
    months: stringDate.types.IStringDate[];
    values: (null | number)[];
    lastNeededDate: stringDate.types.IStringDate;
  }): Promise<void> => {
    if (viewOnly) return;
    if (!params.values.length || !params.months.length) return;

    const requestUuid = uuid();
    handleLoadingMonths({ requestUuid, formulaUuid: params.formulaUuid, month: params.months[0] });

    try {
      const formula = formulaDictionary[params.formulaUuid];
      const startOfMonthDate = stringDate.startOfMonth(stringDate.getStringDate());

      const request: IUpdateFormulaParams = {
        organizationUuid: organization.uuid,
        formulaUuid: params.formulaUuid,
        latestNeededDate: params.lastNeededDate,
        idempotencyKey: uuid(),
        scenarioUuid: scenario.activeScenarioUuid ?? undefined,
      };

      // Create maps to track which months to remove/add for both overrides and actuals
      const monthsToUpdate = new Map<string, { value: number | null; isOverride: boolean }>();

      // Process all months at once and determine if they are overrides or actuals
      params.months.forEach((month, index) => {
        const value = params.values[index];
        const isOverride = stringDate.isSameMonthOrAfter({ comparison: startOfMonthDate, dateToCheck: month });
        monthsToUpdate.set(stringDate.endOfMonth(month), { value, isOverride });
      });

      const newOverrides = formula.overrides.filter((override) => {
        return !monthsToUpdate.has(override.date);
      });

      const newActuals = formula.actuals.filter((actual) => {
        return !monthsToUpdate.has(actual.date);
      });

      // Add new values
      monthsToUpdate.forEach((update, date) => {
        if (update.value === null) return;

        if (update.isOverride) {
          newOverrides.push({ date, value: update.value });
        } else {
          newActuals.push({ date, value: update.value });
        }
      });

      request.overrides = newOverrides.map((override) => ({
        ...override,
        date: stringDate.getISO8601EndOfMonth(override.date),
      }));
      request.actuals = newActuals.map((actual) => ({
        ...actual,
        date: stringDate.getISO8601EndOfMonth(actual.date),
      }));

      await api.formulas.update(request);

      await fetchData();
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
    } finally {
      resolveLoadingFormulas({ requestUuid });
    }
  };

  const updateFormulaName = async (params: { formulaUuid: string; name: string }): Promise<void> => {
    if (viewOnly) return;
    const formula = formulaDictionary[params.formulaUuid];

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
      recipe: {
        ...formula.recipe,
        name: params.name,
      },
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaFormatting = async (params: {
    formulaUuid: string;
    formatting: IFormattingEnum;
  }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      formatting: params.formatting,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaMinMax = async (params: {
    formula: IFormula;
    min: string | null;
    max: string | null;
  }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formula.formulaUuid,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
      recipe: {
        ...params.formula.recipe,
        min: params.min?.length ? params.min : null,
        max: params.max?.length ? params.max : null,
      },
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaRounding = async (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }): Promise<void> => {
    if (viewOnly) return;
    const recipe = formulaDictionary[params.formulaUuid].recipe;

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
      recipe: {
        ...recipe,
        roundingInstructions:
          params.direction && params.roundingPrecision
            ? {
                direction: params.direction,
                precision: params.roundingPrecision,
              }
            : null,
      },
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaDataSource = async (params: { formulaUuid: string; dataSourceUuids: string[] }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      dataSourceUuids: params.dataSourceUuids,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const createNewAttribute = ({ uuid }: { uuid: string }): void => {
    if (viewOnly) return;
    setPendingAttributeData({
      groupUuid: uuid,
    });
  };

  const saveNewAttribute = async (params: {
    formulaGroupUuid: string;
    name: string;
    tabIntoFormula: boolean;
    nextSortOrder: number;
  }): Promise<void> => {
    if (viewOnly) return;

    const createdAttribute = await api.formulas.createAttribute({
      organizationUuid: organization.uuid,
      name: params.name,
      scenarioUuid: scenarioUuid ?? scenario.activeScenarioUuid ?? undefined,
      formulaGroupUuid: params.formulaGroupUuid,
      sortOrder: params.nextSortOrder,
    });

    const onboardingStep = onboardingSteps.data?.dictionary[IOnboardingStepNameEnum.RevenueModel];
    if (onboardingStep && onboardingStep.status === IOnboardingStepStatusEnum.Ready) {
      await updateOnboardingStep({
        onboardingStepUuid: onboardingStep.uuid,
        body: {
          status: IOnboardingStepStatusEnum.Active,
        },
      });
    }

    await fetchData();

    setPendingAttributeData(null);

    if (params.tabIntoFormula && createdAttribute.formulaUuid) {
      setFormulaUuidToFocus(createdAttribute.formulaUuid);
    }
  };

  const deleteFormula = async (formulaUuid: string): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid,
      scenarioUuid: scenario.activeScenarioUuid ?? undefined,
    };
    await api.formulas.remove(request);
    await fetchData();
  };

  useEffect(() => {
    const handleClick = (): void => {
      if (pendingTabTarget) setPendingTabTarget(null);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (!stateData.integrations.isPulling) {
      fetchData();
    }
  }, [stateData.integrations.isPulling]);

  const value = {
    allFormulasData,
    formulaDictionary,
    dataSources,
    availableIntegration,
    toggleGroupCollapse,
    onDragEnd,
    selectedMonths,
    updateFormulaMonthsValue,
    loadingFormulas,
    updateFormulaName,
    updateFormulaFormatting,
    updateFormulaRounding,
    updateFormulaDataSource,
    updateFormulaMinMax,
    filteredFormulasData,
    searchFilter,
    setSearchFilter,
    createNewAttribute,
    pendingAttributeData,
    setPendingAttributeData,
    saveNewAttribute,
    deleteFormula,
    formulaUuidToFocus,
    setFormulaUuidToFocus,
    refreshData: fetchData,
    pendingTabTarget,
    setPendingTabTarget,
    csvExportData,
    viewOnly,
    manageGroupsModalOpen,
    setManageGroupsModalOpen,
    scrollEnabled,
    setScrollEnabled,
    mode,
    allFormulasDictionary,
    updateFormulaGroups,
  };

  return <FormulasContext.Provider value={value}>{children}</FormulasContext.Provider>;
};
