import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { ILineProps } from '~/components/LineGraph/entity/types';
import LineGraph from '~/components/LineGraph';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import { getDecimalBasedOnValue } from '~/pages/Dashboard/utils/getDecimalBasedOnValue';
import { SegmentedControl } from '~/components/UncontrolledComponents/SegmentedControl';
import { useDebounce } from '~/utils/hooks/useDebounce';
import HeadcountGraphTooltip from './HeadcountGraphTooltip';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import { SelectState } from '~/components/Select/Select.types';
import DepartmentTooltip from './DepartmentTooltip';

const HeadcountGraphs = ({
  organizationUuid,
  scenarioUuid,
  search,
  departments,
}: {
  organizationUuid: string;
  scenarioUuid: string | null;
  search?: Types.InputState;
  departments: SelectState;
}): React.ReactNode => {
  const { data: departmentData } = departmentsApi.useGetDepartmentsQuery(
    {
      orgUuid: organizationUuid,
      scenarioUuid: scenarioUuid ?? undefined,
    },
    {
      skip: !organizationUuid,
      refetchOnMountOrArgChange: true,
    },
  );

  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);

  const debouncedSearch = useDebounce(search?.value, 200);

  const [selectedSegment, setSelectedSegment] = useState<string>('comp');
  const { data, isLoading } = positionsApi.useGetBreakdownQuery(
    {
      orgUuid: organizationUuid,
      scenarioUuid: scenarioUuid ?? undefined,
      startDate: defaultGraphStartDate,
      endDate: defaultGraphEndDate,
      search: debouncedSearch,
    },
    { skip: !defaultGraphStartDate || !defaultGraphEndDate || !organizationUuid, refetchOnMountOrArgChange: true },
  );

  const dataKeys = useMemo(() => {
    if (data) {
      const applicableKeys = ['workingModel', 'activeScenario'];
      return Object.keys(data.compensationGraphData['total'].data[0]).filter((key) => applicableKeys.includes(key));
    }
    return [];
  }, [data]);

  const lines: ILineProps[] = [
    { dataKey: 'workingModel', stroke: '#64755C' },
    { dataKey: 'activeScenario', stroke: '#406F83', isDashed: true },
  ];

  const filteredLines = useMemo(() => {
    return lines.filter((line) => dataKeys.includes(line.dataKey));
  }, [dataKeys, lines]);

  const selectedLineGraphData = useMemo(() => {
    if (data) {
      const keyValue = departments.selected?.value ?? 'total';
      if (selectedSegment === 'comp') {
        return data.compensationGraphData[keyValue].data;
      }
      return data.headcountGraphData[keyValue].data;
    }
    return [];
  }, [data, selectedSegment, departments.selected?.value]);

  const selectedFullGraphData = useMemo(() => {
    if (data) {
      if (selectedSegment === 'comp') {
        return data.compensationGraphData;
      }
      return data.headcountGraphData;
    }
    return {};
  }, [data, selectedSegment]);

  const segments = [
    {
      value: 'comp',
      label: 'Total Comp.',
      segmentClassName: '!w-3/4 px-2',
    },
    {
      value: 'count',
      label: 'Count',
      segmentClassName: 'px-2',
    },
  ];

  const yFormatter = useCallback(
    (value: number | null): string => {
      if (selectedSegment === 'comp') {
        return formatToLetterAbbreviatedNumber({
          value: value ?? 0,
          decimal: getDecimalBasedOnValue(value),
        });
      }
      return value ? value.toString() : '0';
    },
    [selectedSegment],
  );

  const customTooltip = useMemo(() => {
    if (departments.selected?.value && departmentData?.dictionary[departments.selected.value]) {
      return (
        <DepartmentTooltip
          selectedSegment={selectedSegment}
          departmentData={departmentData.dictionary[departments.selected.value]}
        />
      );
    }
    return (
      <HeadcountGraphTooltip
        lines={filteredLines}
        graphData={selectedFullGraphData}
        departmentData={departmentData}
        selectedSegment={selectedSegment}
      />
    );
  }, [selectedSegment, filteredLines, departments.selected?.value, selectedFullGraphData, departmentData]);

  return (
    <div className="flex max-md:flex-col items-center justify-between gap-4 h-[250px] min-w-[500px] px-10">
      <div className="w-full h-full relative">
        {isLoading || !data ? (
          <div>Loading</div>
        ) : (
          <>
            <div className="absolute top-3 right-6 whitespace-nowrap">
              <SegmentedControl
                id="breakdown-segmented-control"
                name="breakdown-segmented-control"
                segments={segments}
                value={selectedSegment}
                onChange={setSelectedSegment}
                thin
              />
            </div>
            <LineGraph
              id="breakdown-graph"
              data={selectedLineGraphData}
              lines={filteredLines}
              customTooltip={customTooltip}
              dataKeys={['date', ...dataKeys]}
              card={{ title: selectedSegment === 'comp' ? 'Total Compensation' : 'Headcount' }}
              roundTicksToMoney={selectedSegment === 'comp'}
              yFormatter={yFormatter}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeadcountGraphs;
