import React, { useState, useEffect, useMemo } from 'react';
import LineGraph from '~/components/LineGraph';
import { IDataArrayDictionary } from '~/components/LineGraph/entity/types';
import Typography from '~/components/Typography';
import { graphDataByType } from './graphDataByType';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { ExpenseFrequencyEnum, type IFormula } from '~/services/parallel/formulas.types';
import * as stringDate from '~/utils/stringDate';
import useQueryParams from '~/utils/hooks/useQueryParams';

const ExpensesGraph = ({
  expenseFormulas,
  filter,
}: {
  expenseFormulas: IFormula[];
  filter: {
    category: string | null;
    hidePastExpenses: boolean;
  };
}): React.ReactElement | null => {
  const { queryParams } = useQueryParams();
  const search = queryParams.get('search') ?? null;

  const filteredExpenseFormulas = useMemo(() => {
    let formulas = expenseFormulas;
    if (search) {
      formulas = formulas.filter((formula) => formula.recipe.name.toLowerCase().includes(search.toLowerCase()));
    }
    if (filter.category && filter.category !== 'all' && filter.category !== 'Total Compensation') {
      formulas = formulas.filter((formula) => formula.context?.expenseContext?.tag === filter.category);
    }
    if (filter.category === 'Total Compensation') {
      formulas = formulas.filter((formula) => formula.recipe.name === 'Total Compensation');
    }
    if (filter.hidePastExpenses) {
      formulas = formulas.filter(
        (formula) =>
          formula.context?.expenseContext?.frequency !== ExpenseFrequencyEnum.OneTime ||
          (formula.recipe.endDate &&
            stringDate.isAfter({
              dateToCheck: formula.recipe.endDate,
              comparison: stringDate.getStringDate(),
            })),
      );
    }
    return formulas;
  }, [expenseFormulas, filter.category, search]);

  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);

  const baseLines = [
    {
      dataKey: 'total',
      stroke: '#64755C',
      isDashed: false,
    },
  ];

  const dataKeys = ['date', 'total'];
  const [totalGraphData, setTotalGraphData] = useState<IDataArrayDictionary[]>([]);

  useEffect(() => {
    const graphData = graphDataByType({
      expenseFormulas: filteredExpenseFormulas,
      startDate: defaultGraphStartDate,
      endDate: defaultGraphEndDate,
    });
    setTotalGraphData(graphData.totalData);
  }, [filteredExpenseFormulas, defaultGraphStartDate, defaultGraphEndDate]);

  const getGraphData = (): IDataArrayDictionary[] => {
    return totalGraphData;
  };

  const graphTitle = (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center justify-start gap-3">
        <Typography size="sm">💰 Expenses</Typography>
      </div>
    </div>
  );

  return (
    <div className="px-10">
      <div className="h-[250px]">
        <LineGraph
          data={getGraphData()}
          card={{
            title: graphTitle,
          }}
          lines={baseLines}
          dataKeys={dataKeys}
          id="expenses-page"
        />
      </div>
    </div>
  );
};

export default ExpensesGraph;
