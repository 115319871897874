import { LinkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { toast } from 'react-hot-toast';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';

const LinkCell = ({
  shareLinkUuid,
  isValid,
}: {
  shareLinkUuid: string;
  isValid: boolean;
}): React.ReactElement | null => {
  if (!isValid) return null;
  return (
    <HoverPopover
      buttonContent={
        <div
          className="hover:bg-green-15 p-4 rounded-full group"
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}/share/${shareLinkUuid}`);
            toast.success('Link Copied');
          }}
        >
          <LinkIcon className="size-4 text-green-400 cursor-pointer group-hover:text-green-500" />
        </div>
      }
      panelContent={
        <div className="flex flex-col gap-2 rounded-lg bg-black px-3 py-2">
          <Typography color="white">Copy Link</Typography>
        </div>
      }
      anchor="top"
    />
  );
};

export default LinkCell;
