import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { ZRoundingInstructions } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { ZCompanyGoal } from '~/pages/Dashboard/entity/schemas';

export enum ReportDataTypeEnum {
  Static = 'static',
  Timeseries = 'timeseries',
  Consolidated = 'consolidated',
  ItemizedExpense = 'itemizedExpense',
}

export const ZTimeseriesReportData = z.object({
  date: z.union([z.string().transform((val) => new Date(val).getTime()), z.number()]),
  value: z.number().nullable(),
});

export const ZStaticReportData = z.object({
  value: z.number().nullable(),
  isTrendingPositive: z.boolean().optional(),
});

const ZDateForConsolidatedReportData = z.object({
  date: z.number(),
});
const ZRecordForConsolidatedReportData = z.record(z.number());
export const ZConsolidatedReportData = ZDateForConsolidatedReportData.and(ZRecordForConsolidatedReportData);

export const ZConsolidatedReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Consolidated),
  data: z.array(ZConsolidatedReportData),
});

export const ZStaticReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Static),
  data: ZStaticReportData,
});

export const ZTimeseriesReport = z.object({
  title: z.string(),
  type: z.literal(ReportDataTypeEnum.Timeseries),
  data: z.array(ZTimeseriesReportData),
});

export const ZReportCollection = z.object({
  cashBalance: ZTimeseriesReport.optional(),
  cashflow: ZTimeseriesReport.optional(),
  expenses: ZTimeseriesReport.optional(),
  revenue: ZTimeseriesReport.optional(),
  runway: ZStaticReport.optional(),
  filteredExpenses: ZTimeseriesReport.optional(),
});

export const ZConsolidatedReportCollection = z.object({
  cashBalance: ZConsolidatedReport.optional(),
  cashflow: ZConsolidatedReport.optional(),
  expenses: ZConsolidatedReport.optional(),
  revenue: ZConsolidatedReport.optional(),
  runway: z.array(ZStaticReport).optional(),
  filteredExpenses: ZConsolidatedReport.optional(),
});

export const ZConsolidatedData = ZDateForConsolidatedReportData.and(z.record(z.string(), z.number()));

export const ZDataOverride = z.record(
  z.string(),
  z.array(
    z.object({
      date: ZStringDate,
      value: z.number(),
    }),
  ),
);

export const ZDataActual = z.record(
  z.string(),
  z.array(
    z.object({
      date: ZStringDate,
      value: z.number(),
    }),
  ),
);

export const ZConsolidatedGraphBody = z.object({
  title: z.string(),
  type: z.string(),
  data: z.array(ZConsolidatedData),
  formatting: z.nativeEnum(IFormattingEnum).nullable().optional(),
  dataOverrides: ZDataOverride.optional(),
  dataActuals: ZDataActual.optional(),
  versionUuids: z.record(z.string(), z.string()),
  companyGoals: z.lazy(() => z.array(ZCompanyGoal)),
  roundingInstructions: ZRoundingInstructions.optional(),
});

export const ZStaticGraphBody = z.object({
  title: z.string(),
  type: z.string(),
  data: z.record(z.string(), ZStaticReportData),
  formatting: z.nativeEnum(IFormattingEnum).nullable().optional(),
  dataOverrides: ZDataOverride.optional(),
  dataActuals: ZDataActual.optional(),
});

export const ZStaticGraphData = z.record(z.string(), ZStaticGraphBody);

export const ZConsolidatedGraphData = z.record(z.string(), ZConsolidatedGraphBody);

export const ZGraphData = z.object({
  consolidated: ZConsolidatedGraphData.optional(),
  static: ZStaticGraphData.optional(),
});

export type IStaticReport = z.infer<typeof ZStaticReport>;
export type IStaticReportData = z.infer<typeof ZStaticReportData>;
export type ITimeseriesReport = z.infer<typeof ZTimeseriesReport>;
export type IReportCollection = z.infer<typeof ZReportCollection>;
export type ITimeseriesReportData = z.infer<typeof ZTimeseriesReportData>;
export type IConsolidatedReportsData = z.infer<typeof ZConsolidatedReportData>;
export type IConsolidatedReport = z.infer<typeof ZConsolidatedReport>;
export type IConsolidatedReportCollection = z.infer<typeof ZConsolidatedReportCollection>;
export type IConsolidatedData = z.infer<typeof ZConsolidatedData>;
export type IConsolidatedGraphBody = z.infer<typeof ZConsolidatedGraphBody>;
export type IStaticGraphBody = z.infer<typeof ZStaticGraphBody>;
export type IStaticGraphData = z.infer<typeof ZStaticGraphData>;
export type IConsolidatedGraphData = z.infer<typeof ZConsolidatedGraphData>;
export type IGraphData = z.infer<typeof ZGraphData>;
export type IDataOverride = z.infer<typeof ZDataOverride>;
export type IDataActual = z.infer<typeof ZDataActual>;
