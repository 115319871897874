import React, { useEffect, useRef, useState, ReactNode, forwardRef } from 'react';

interface SegmentOption {
  value: string;
  label: ReactNode | string;
  segmentClassName?: string;
  labelClassName?: string;
}

interface Props {
  id: string;
  name: string;
  segments: SegmentOption[];
  value?: string;
  onChange?: (value: string) => void;
  thin?: boolean;
  disabled?: boolean;
  backgroundColor?: 'green' | 'gray';
}

export const SegmentedControl = forwardRef<HTMLInputElement, Props>(
  ({ id, name, segments, value, onChange, thin = false, disabled = false, backgroundColor = 'green' }, ref) => {
    const foundIndex = segments.findIndex((segment) => segment.value === value) || 0;
    const activeIndex = foundIndex > -1 ? foundIndex : 0;
    const [enableTransitioning, setEnableTransitioning] = useState(false);
    const transitionClasses =
      'before:transition-transform before:duration-300 before:ease-in-out before:transition-width before:duration-300 before:ease-in-out';
    const controlRef = useRef<HTMLDivElement | null>(null);

    const setHighlighterPosition = (segment: HTMLElement): void => {
      if (!controlRef.current?.firstChild) return;
      const { style } = controlRef.current;
      style.setProperty('--highlight-width', `${segment.offsetWidth}px`);
      style.setProperty('--highlight-x-pos', `${segment.offsetLeft}px`);
    };

    const handleChange = (newValue: string, index: number, segment: HTMLDivElement): void => {
      setHighlighterPosition(segment);
      if (onChange) {
        onChange(newValue);
      }
    };

    // Initial setup
    useEffect(() => {
      if (controlRef.current) {
        const initialSegment = controlRef.current.children[activeIndex] as HTMLDivElement;
        setHighlighterPosition(initialSegment);
        setEnableTransitioning(true);
      }
    }, []); // Run once on mount

    useEffect(() => {
      setHighlighterPosition(controlRef.current?.children[activeIndex] as HTMLDivElement);
      setEnableTransitioning(true);
    }, [value, activeIndex]);

    const getTextColorClass = (active: boolean): string => {
      if (active) return 'text-neutral-900';
      return backgroundColor === 'green' ? 'text-green-500' : 'text-neutral-300';
    };

    return (
      <div
        className={`controls-container w-full flex my-100 mt-0 mb-25${disabled ? ' opacity-50 cursor-default' : ''}`}
        data-testid={id}
      >
        <div
          className={`controls w-full before:absolute before:top-1 before:bottom-1 before:left-0 before:z-0 before inline-flex justify-between relative border 
            rounded-lg overflow-hidden ${backgroundColor === 'green' ? 'bg-green-50' : 'bg-neutral-25'} m-auto p-1 before:bg-white before:rounded idle ${
              enableTransitioning ? transitionClasses : 'idle'
            }`}
          ref={controlRef}
        >
          {segments.map((item, i) => {
            const active = i === activeIndex;
            return (
              <div
                key={item.value}
                className={`segment z-10 w-1/2 text-center relative px-1 ${active ? 'active' : 'inactive'} ${item.segmentClassName}`}
              >
                <input
                  ref={i === 0 ? ref : undefined}
                  data-testid={`${id}-segment-${item.value.toLowerCase()}`}
                  className={`absolute opacity-0 w-full h-full${
                    disabled ? ' cursor-default' : ' cursor-pointer'
                  } m-0 inset-0`}
                  type="radio"
                  value={item.value}
                  id={`${id}-${item.value}`}
                  name={name}
                  checked={i === activeIndex}
                  onChange={(e) => {
                    if (!disabled) {
                      handleChange(item.value, i, e.currentTarget.parentElement as HTMLDivElement);
                    }
                  }}
                />
                <label
                  className={`${getTextColorClass(active)} text-sm cursor-pointer items-center block text-bold transition-color duration-500 ease-in-out ${item.labelClassName} ${
                    thin ? 'px-1' : 'p-1'
                  }`}
                  htmlFor={`${id}-${item.value}`}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

SegmentedControl.displayName = 'SegmentedControl';
