import React, { forwardRef } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Typography from '~/components/Typography';

export interface SelectOption {
  value: string;
  label: string;
  node?: React.ReactNode;
  disabled?: boolean;
}

interface Props {
  id: string;
  options: SelectOption[];
  label?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: 'below' | 'above';
  required?: boolean;
  listBoxClassName?: string;
  value?: string | null;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  name?: string;
  activeBackgroundColor?: string;
  thin?: boolean;
}

export const Select = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      label,
      options,
      className = '',
      error,
      placeholder = 'Select an option',
      disabled = false,
      checkmark = true,
      direction = 'below',
      required = false,
      listBoxClassName = '',
      value,
      onChange,
      onBlur,
      onFocus,
      name,
      activeBackgroundColor = 'bg-green-100',
      thin = false,
    },
    ref,
  ) => {
    const selected = options.find((option) => option.value === value) || null;

    const handleChange = (option: SelectOption): void => {
      if (onChange) {
        onChange(option.value);
      }
    };

    let spanClass = 'truncate block truncate text-sm leading-5 ';
    if (disabled) {
      spanClass += ' text-neutral-100';
    } else if (selected?.value) {
      spanClass += ' text-neutral-900';
    } else {
      spanClass += ' text-neutral-400';
    }

    return (
      <div className={`w-full ${className}`} data-testid={id}>
        {label && (
          <div className="flex flex-row mb-1">
            <Typography tag="span" className={disabled ? '!text-neutral-200' : ''}>
              {label}
            </Typography>
            {required && (
              <Typography tag="span" size="xs" className={disabled ? '!text-neutral-200' : ''}>
                *
              </Typography>
            )}
          </div>
        )}
        <Listbox disabled={disabled} value={selected} onChange={handleChange} name={name}>
          <div className="relative">
            <Listbox.Button
              ref={ref}
              data-testid={`${id}-button`}
              onFocus={onFocus}
              onBlur={onBlur}
              className={`relative cursor-pointer disabled:cursor-not-allowed w-full max-w-full truncate border border-solid ${
                error ? 'border-red-300' : 'border-gray-300'
              } ${thin ? 'py-1' : 'py-2'} rounded shadow-sm bg-white disabled:bg-neutral-25 disabled:text-neutral-100 pl-3 pr-10 text-left 
              focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white 
              focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
            >
              <span data-testid={`${id}-selection`} className={spanClass}>
                {selected?.node ?? selected?.label ?? placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={`absolute ${direction === 'below' ? 'top-full' : 'bottom-full'} w-full z-20`}>
                {direction === 'below' && <div className="w-full h-1 bg-transparent" />}
                <Listbox.Options
                  className={`max-h-60 rounded w-full overflow-auto bg-white shadow-sm ring-1 
                    ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listBoxClassName}`}
                  data-testid={`${id}-options`}
                >
                  {options.map((option) => {
                    const isSelected = option.value === selected?.value;

                    if (option.value === 'divider') {
                      return (
                        <Listbox.Option
                          key={option.value}
                          disabled
                          className="relative cursor-pointer select-none py-2 pl-3 pr-4 text-xs"
                          value={option}
                        >
                          <hr className="border-gray-300" />
                        </Listbox.Option>
                      );
                    }

                    return (
                      <Listbox.Option
                        key={option.value}
                        data-testid={`${id}-option-${option.value}`}
                        disabled={option.disabled}
                        className={({ active }) =>
                          `relative text-sm leading-5 cursor-pointer select-none py-2${
                            checkmark ? ' pl-10' : ' pl-3'
                          } pr-4${active ? ` ${activeBackgroundColor} text-green-900` : ''}${
                            isSelected ? ` ${!checkmark && activeBackgroundColor} text-green-900` : ' text-gray-900'
                          }${option.disabled ? ' !text-neutral-75' : ''}`
                        }
                        value={option}
                      >
                        <span className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'}`}>
                          {option.node ?? option.label}
                        </span>
                        {isSelected && checkmark && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        )}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
                {direction === 'above' && <div className="w-full h-1 bg-transparent" />}
              </div>
            </Transition>
          </div>
          {error && (
            <Typography id={`${id}-select-error`} tag="span" color="warning" className="italic p-1">
              {error}
            </Typography>
          )}
        </Listbox>
      </div>
    );
  },
);

Select.displayName = 'Select';
