import React, { useEffect } from 'react';
import useFormulaContext from '../context/useFormulaContext';
import Input from '~/components/Input/InputWrapper';
import useQueryParams from '~/utils/hooks/useQueryParams';

const SearchInput = ({ thin = false }: { thin?: boolean }): React.ReactElement => {
  const { searchFilter, setSearchFilter } = useFormulaContext();
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    const currentParams = Object.fromEntries(queryParams.entries());
    currentParams.search = searchFilter.value;
    setQueryParams(currentParams);
  }, [searchFilter]);

  return (
    <div className={`w-[230px] ${thin ? '' : 'py-2'} ml-2`}>
      <Input
        id="search-formulas"
        state={searchFilter}
        thin={thin}
        setState={setSearchFilter}
        type="search"
        placeholder="Search"
      />
    </div>
  );
};

export default SearchInput;
