import React from 'react';
import DashboardGraphs from '~/pages/Dashboard/components/DashboardGraphs/DashboardGraphs';
import DashboardMetrics from '~/pages/Dashboard/components/DashboardMetrics/DashboardMetrics';
import { IGraphData } from '~/services/parallel/reports.types';
import { IDashboardConfiguration } from '~/store/organizationSlice';

const ShareLinkDashboard = ({
  reportData,
  pageLoading,
  dashboardConfiguration,
  overrideUserActiveIndex,
  setOverrideUserActiveIndex,
}: {
  reportData?: IGraphData;
  pageLoading: boolean;
  dashboardConfiguration?: IDashboardConfiguration;
  overrideUserActiveIndex?: number;
  setOverrideUserActiveIndex?: (value: number) => void;
}): React.ReactElement => {
  return (
    <div className="w-full max-w-[1016px] mt-4">
      <div className="w-full flex flex-col gap-3">
        <DashboardMetrics
          staticMetrics={reportData?.static}
          consolidatedReports={reportData?.consolidated}
          pageLoading={pageLoading}
          dashboardConfiguration={dashboardConfiguration}
          overrideUserActiveIndex={overrideUserActiveIndex}
        />
        <DashboardGraphs
          consolidatedReports={reportData?.consolidated}
          dashboardConfiguration={dashboardConfiguration}
          pageLoading={pageLoading}
          viewingShareLink
          overrideUserActiveIndex={overrideUserActiveIndex}
          setOverrideUserActiveIndex={setOverrideUserActiveIndex}
        />
      </div>
    </div>
  );
};

export default ShareLinkDashboard;
