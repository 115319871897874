import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../baseQuery';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import {
  ICreatePositionRequest,
  IDeletePositionRequest,
  IGetCompensationBreakdownParams,
  IListPositionsParams,
  IUpdatePositionRequest,
  IUpdatePositionsDatesRequest,
} from './positionsRequestSchemas';
import { IBreakdownGraphData } from '../../headcount.types';

export const positionsApi = createApi({
  reducerPath: 'positionsApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Position'],
  endpoints: (builder) => ({
    listPositions: builder.query<IPositionDetails[], IListPositionsParams>({
      query: ({ orgUuid, scenarioUuid, includeFullyBurdened, search, departmentUuids, excludeTerminated }) => ({
        url: `/organizations/${orgUuid}/positions`,
        params: { scenarioUuid, includeFullyBurdened, search, departmentUuids, excludeTerminated },
      }),
      transformResponse: (response: { data: IPositionDetails[] }) => {
        return response.data.sort((a, b) => new Date(a.hireDate).getTime() - new Date(b.hireDate).getTime());
      },
      providesTags: ['Position'],
    }),
    createPositions: builder.mutation<
      { data: IPositionDetails[]; headers: Record<string, string> },
      ICreatePositionRequest
    >({
      query: ({ params, query: { scenarioUuid, createScenario, numberToCreate, awaitCalculations }, body }) => ({
        url: `/organizations/${params.orgUuid}/positions`,
        method: 'POST',
        params: {
          scenarioUuid,
          createScenario,
          numberToCreate,
          awaitCalculations,
        },
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { query, body, listParams, updateIndexes } = args;
        // Create an array of optimistic positions
        const optimisticPositions: IPositionDetails[] = [];
        for (let i = 0; i < query.numberToCreate; i++) {
          const tempPosition: IPositionDetails = {
            positionUuid: `temp-${Date.now()}-${i}`, // temporary unique id
            hireDate: body.hireDate,
            termDate: null,
            departmentUuid: body.departmentUuid,
            currentDepartment: {
              uuid: 'depUuid', // placeholder (adjust if you have a proper value)
              departmentUuid: body.departmentUuid,
              name: 'depName', // placeholder name
            },
            title: body.title,
            employeeName: body.employeeName ?? null,
            currentPayRate: body.payRate.map((payRate) => {
              return {
                value: Number(payRate.value) || 0,
                effectiveAt: payRate.effectiveAt,
              };
            })[0],
            payRates: body.payRate.map((payRate) => ({
              value: Number(payRate.value) || 0,
              effectiveAt: payRate.effectiveAt,
            })),
            isActive: true,
            fullyBurdenedCost: Number(body.payRate[0].value) || 0,
            employmentType: body.employmentType,
            currentBonus: null,
            currentCommission: null,
            commissions: null,
            bonuses: null,
            scenarioUuid: query.scenarioUuid ?? null,
          };
          optimisticPositions.push(tempPosition);
        }

        updateIndexes((prev) => {
          const maxIndex = Object.values(prev).reduce((max, val) => Math.max(max, val), -1);
          const newIndexes = { ...prev };
          optimisticPositions.forEach((pos, i) => {
            newIndexes[pos.positionUuid] = maxIndex + 1 + i;
          });
          return newIndexes;
        });

        // Optimistically update the positions cache by appending the new positions
        const patchResult = dispatch(
          positionsApi.util.updateQueryData('listPositions', listParams, (draft) => {
            optimisticPositions.forEach((pos) => {
              draft.push(pos);
            });
          }),
        );

        try {
          // Wait for the mutation to complete.
          const { data } = await queryFulfilled;
          // Update your positionIndexes: replace temporary keys with the real ones.
          updateIndexes((prev) => {
            const newIndexes = { ...prev };
            // We assume the optimisticPositions and the returned positions are in the same order.
            data.data.forEach((realPos, i) => {
              const tempId = optimisticPositions[i].positionUuid;
              const indexValue = newIndexes[tempId];
              if (indexValue) {
                delete newIndexes[tempId];
                newIndexes[realPos.positionUuid] = indexValue;
              }
            });
            return newIndexes;
          });

          // Update the cache: remove all optimistic entries and add the real positions.
          dispatch(
            positionsApi.util.updateQueryData('listPositions', listParams, (draft) => {
              // Remove optimistic positions (those with ids starting with "temp-")
              for (let i = draft.length - 1; i >= 0; i--) {
                if (draft[i].positionUuid.startsWith('temp-')) {
                  draft.splice(i, 1);
                }
              }
              // Append real positions from the response.
              data.data.forEach((newPos) => {
                draft.push(newPos);
              });
            }),
          );
        } catch (error) {
          patchResult.undo();
        }
      },
      transformResponse: (response: { data: IPositionDetails[] }, meta: { response: { headers: Headers } }) => {
        const headersObj: Record<string, string> = {};
        meta.response.headers.forEach((value, key) => {
          headersObj[key] = value;
        });
        return {
          data: response.data,
          headers: headersObj,
        };
      },
      invalidatesTags: ['Position'],
    }),
    updatePosition: builder.mutation<
      { data: IPositionDetails; headers: Record<string, string> },
      IUpdatePositionRequest
    >({
      query: ({ params, query: { scenarioUuid, awaitCalculations, createScenario }, body }) => ({
        url: `/organizations/${params.orgUuid}/positions/${params.positionUuid}`,
        method: 'PATCH',
        params: { scenarioUuid, awaitCalculations, createScenario },
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { positionUuid, orgUuid } = args.params;
        const { scenarioUuid } = args.query;
        const patchResult = dispatch(
          positionsApi.util.updateQueryData('listPositions', args.listParams, (draft) => {
            const index = draft.findIndex((position) => position.positionUuid === positionUuid);
            if (index !== -1) {
              // Merge the update fields optimistically.
              draft[index] = { ...draft[index], ...args.body };
            }
          }),
        );

        try {
          // Wait for the mutation to complete.
          const { data } = await queryFulfilled;
          // Update the cache with the server response, which may contain additional changes.
          dispatch(
            positionsApi.util.updateQueryData(
              'listPositions',
              {
                orgUuid,
                scenarioUuid,
                includeFullyBurdened: true,
              },
              (draft) => {
                const index = draft.findIndex((position) => position.positionUuid === positionUuid);
                if (index !== -1) {
                  draft[index] = { ...draft[index], ...data.data };
                }
              },
            ),
          );
        } catch (error) {
          patchResult.undo();
        }
      },
      transformResponse: (response: { data: IPositionDetails }, meta: { response: { headers: Headers } }) => {
        const headersObj: Record<string, string> = {};
        meta.response.headers.forEach((value, key) => {
          headersObj[key] = value;
        });
        return {
          data: response.data,
          headers: headersObj,
        };
      },
      invalidatesTags: ['Position'],
    }),
    updatePositionsDates: builder.mutation<
      { data: IPositionDetails[]; headers: Record<string, string> },
      IUpdatePositionsDatesRequest
    >({
      query: ({ query: { scenarioUuid, createScenario }, body }) => ({
        url: `/positions`,
        method: 'PATCH',
        params: { scenarioUuid, createScenario },
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          positionsApi.util.updateQueryData('listPositions', args.listParams, (draft) => {
            // Assume args.body is an array of updates
            args.body.forEach((update: { positionUuid: string; hireDate?: string; termDate?: string | null }) => {
              const index = draft.findIndex((position) => position.positionUuid === update.positionUuid);
              if (index !== -1) {
                // Optimistically merge the update fields
                draft[index] = { ...draft[index], ...update };
              }
            });
          }),
        );

        try {
          const { data } = await queryFulfilled;
          // When the response returns, update each matching position with the returned data
          dispatch(
            positionsApi.util.updateQueryData('listPositions', args.listParams, (draft) => {
              data.data.forEach((updatedPosition) => {
                const index = draft.findIndex((position) => position.positionUuid === updatedPosition.positionUuid);
                if (index !== -1) {
                  draft[index] = { ...draft[index], ...updatedPosition };
                }
              });
            }),
          );
        } catch (error) {
          patchResult.undo();
        }
      },
      transformResponse: (response: { data: IPositionDetails[] }, meta: { response: { headers: Headers } }) => {
        const headersObj: Record<string, string> = {};
        meta.response.headers.forEach((value, key) => {
          headersObj[key] = value;
        });
        return { data: response.data, headers: headersObj };
      },
      invalidatesTags: ['Position'],
    }),
    deletePosition: builder.mutation<{ headers: Record<string, string> }, IDeletePositionRequest>({
      query: ({ params, query: { scenarioUuid, createScenario } }) => ({
        url: `/organizations/${params.orgUuid}/positions/${params.positionUuid}`,
        method: 'DELETE',
        params: {
          scenarioUuid,
          createScenario,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // args.listParams again must contain the same parameters as used in listPositions.
        const patchResult = dispatch(
          positionsApi.util.updateQueryData('listPositions', args.listParams, (draft) => {
            const index = draft.findIndex((position) => position.positionUuid === args.params.positionUuid);
            if (index !== -1) {
              // Remove the deleted position from the list
              draft.splice(index, 1);
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
      transformResponse: (response, meta: { response: { headers: Headers } }) => {
        const headersObj: Record<string, string> = {};
        meta.response.headers.forEach((value, key) => {
          headersObj[key] = value;
        });
        return {
          headers: headersObj,
        };
      },
      invalidatesTags: ['Position'],
    }),
    getBreakdown: builder.query<IBreakdownGraphData, IGetCompensationBreakdownParams>({
      query: ({ orgUuid, scenarioUuid, startDate, endDate, search }) => ({
        url: `/organizations/${orgUuid}/positions/breakdown`,
        params: { scenarioUuid, startDate, endDate, search },
      }),
      transformResponse: (response: { data: IBreakdownGraphData }) => {
        return response.data;
      },
      providesTags: ['Position'],
    }),
  }),
});

export const {
  useListPositionsQuery,
  useCreatePositionsMutation,
  useUpdatePositionMutation,
  useUpdatePositionsDatesMutation,
  useGetBreakdownQuery,
  useDeletePositionMutation,
} = positionsApi;
