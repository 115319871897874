import React from 'react';
import Divider from '~/components/Divider';
import { IDataArrayDictionary } from '~/components/LineGraph/entity/types';
import Typography from '~/components/Typography';
import { IDepartment } from '~/services/parallel/departments.types';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import { formatDate } from 'date-fns';

interface IProps {
  active?: boolean;
  payload?: { payload: IDataArrayDictionary }[];
  label?: number;
  selectedSegment: string;
  departmentData: IDepartment;
}

const DepartmentTooltip = ({
  selectedSegment,
  active,
  payload,
  label,
  departmentData,
}: IProps): React.ReactElement | null => {
  const monthFormatter = (value: number | null): string => {
    if (!value) return '';
    return formatDate(value, 'MMM yyyy');
  };

  const getFormattedNumber = ({ value, selectedSegment }: { value: number; selectedSegment: string }): string => {
    if (selectedSegment === 'comp') {
      let decimalPlaces = 1;
      if (value >= 10000000 && value < 100000000) {
        decimalPlaces = 0;
      }
      return formatToLetterAbbreviatedNumber({
        value,
        decimal: decimalPlaces,
      });
    }
    return value.toString();
  };

  const valueWidth = selectedSegment === 'comp' ? 'w-[45px]' : 'w-[20px]';

  if (active && payload?.length) {
    return (
      <div className="bg-white ml-2 rounded-xl shadow-md py-1 px-3.5">
        <Typography color="empty" size="xs">
          {monthFormatter(label ?? null)}
        </Typography>
        {payload[0] && (
          <div className="flex flex-row ">
            <Typography className="pr-3">{departmentData.name}</Typography>
            {'workingModel' in payload[0].payload && (
              <Typography color="primaryGreen" className={`${valueWidth} text-right`}>
                {getFormattedNumber({ value: payload[0].payload.workingModel ?? 0, selectedSegment })}
              </Typography>
            )}
            {'activeScenario' in payload[0].payload && (
              <>
                <Divider orientation="vertical" className="text-neutral-50 px-1" />
                <Typography color="mediumBlue" className={`${valueWidth} text-right`}>
                  {getFormattedNumber({ value: payload[0].payload.activeScenario ?? 0, selectedSegment })}
                </Typography>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default DepartmentTooltip;
