import React from 'react';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import { State } from '~/store';
import { IBvaReviewStatus } from '~/services/parallel/bva.types';
import toast from 'react-hot-toast';
import { bvaApi } from '~/services/parallel/api/bvaApi';

const BvaInfo = ({ mode }: { mode: 'expense' | 'headcount' | 'revenue' }): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const bvaResult = bvaApi.useGetBvaQuery(undefined, { skip: !organizationUuid });
  const [completeReview] = bvaApi.useCompleteReviewMutation();

  let description;
  switch (mode) {
    case 'expense':
      description = 'Ensure individual expenses are properly forecasted';
      break;
    case 'headcount':
      description = 'Ensure all employees are accounted for and their data is accurate';
      break;
    case 'revenue':
      description = 'Ensure the attributes that drive revenue are properly forecasted';
      break;
  }

  const handleCompleteReview = async (mode: 'expense' | 'headcount' | 'revenue'): Promise<void> => {
    try {
      if (mode === 'expense') {
        const expenseReviewsToComplete = Object.entries(bvaResult.data?.bvaResult?.expenseReview ?? {}).reduce(
          (acc, [key, value]) => {
            if (value.status === IBvaReviewStatus.Pending) {
              acc.push(key);
            }
            return acc;
          },
          [] as string[],
        );

        await completeReview({
          body: {
            expenseReviewsToComplete,
          },
        }).unwrap();
      } else if (mode === 'headcount') {
        const headcountReviewsToComplete = Object.entries(bvaResult.data?.bvaResult?.headcountReview ?? {}).reduce(
          (acc, [key, value]) => {
            if (value.status === IBvaReviewStatus.Pending) {
              acc.push(key);
            }
            return acc;
          },
          [] as string[],
        );

        await completeReview({
          body: {
            headcountReviewsToComplete,
          },
        }).unwrap();
      } else {
        const modelReviewsToComplete = Object.entries(bvaResult.data?.bvaResult?.modelReview ?? {}).reduce(
          (acc, [key, value]) => {
            if (value.status === IBvaReviewStatus.Pending) {
              acc.push(key);
            }
            return acc;
          },
          [] as string[],
        );

        await completeReview({
          body: {
            modelReviewsToComplete,
          },
        }).unwrap();
      }

      toast.success(`Completed review for ${mode} data`);
    } catch (error) {
      toast.error('Failed to complete review');
    }
  };

  return (
    <div className="flex flex-col w-[30%] pt-5 px-10">
      <Typography weight="medium">Update {camelCaseToNormal(mode)} Data</Typography>
      <Typography color="secondary">{description}</Typography>
      <Button className="!w-fit !px-5 !py-2 mt-2" onClick={() => handleCompleteReview(mode)}>
        Review Completed
      </Button>
    </div>
  );
};

export default BvaInfo;
