import React from 'react';
import { IUpdatePositionRequestBody } from '~/services/parallel/api/positions/positionsRequestSchemas';
import TogglePosition from '../../TogglePosition';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import toast from 'react-hot-toast';

const CellToggle = React.memo(
  ({
    displayToggle,
    positionUuid,
    isActive,
    onUpdate,
  }: {
    displayToggle: boolean;
    positionUuid: string;
    isActive: boolean;
    onUpdate: ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
    }: {
      positionUuid: string;
      positionUpdates: IUpdatePositionRequestBody;
      awaitCalculations?: boolean;
    }) => Promise<{ data: IPositionDetails; headers: Record<string, string> }>;
  }): React.ReactNode => {
    const onToggle = async (isActive: boolean): Promise<void> => {
      try {
        await onUpdate({
          positionUuid,
          positionUpdates: { isActive },
        });
      } catch (error) {
        toast.error('Failed to update position');
      }
    };
    return (
      <>
        {displayToggle ? (
          <TogglePosition positionUuid={positionUuid} isActive={isActive} onToggle={onToggle} />
        ) : (
          <div className="w-11 h-4 " />
        )}
      </>
    );
  },
);

CellToggle.displayName = 'CellToggle';

export default CellToggle;
