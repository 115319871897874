import React from 'react';

const EmptyExpenseIcon = (): React.ReactElement => {
  return (
    <svg width="228" height="170" viewBox="0 0 228 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="117.353" cy="78.87" r="68.87" fill="#EAEFE8" />
      <path
        d="M75.9985 47.0566C75.9985 42.6384 79.5803 39.0566 83.9985 39.0566H143.999C148.417 39.0566 151.999 42.6384 151.999 47.0566V119.057C151.999 127.893 144.835 135.057 135.999 135.057H77.9985C76.894 135.057 75.9985 134.161 75.9985 133.057V47.0566Z"
        fill="white"
        stroke="#BFC9BA"
      />
      <circle cx="92.9985" cy="58" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="57" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="68" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="67" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="78" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="77" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="88" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="87" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="98" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="97" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="108" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="107" width="37" height="2" rx="1" fill="#BFC9BA" />
      <circle cx="92.9985" cy="118" r="1" fill="#BFC9BA" />
      <rect x="97.9985" y="117" width="37" height="2" rx="1" fill="#BFC9BA" />
      <path
        d="M120.999 118.057C120.999 114.191 117.865 111.057 113.999 111.057H63.9985C60.1325 111.057 56.9985 114.191 56.9985 118.057V121.057C56.9985 128.789 63.2665 135.057 70.9985 135.057H135.999C136.16 135.057 136.321 135.054 136.482 135.048C127.884 134.776 120.999 127.72 120.999 119.057V118.057Z"
        fill="#F8F9F6"
        stroke="#BFC9BA"
      />
      <path
        d="M161.999 45C161.999 53.8366 154.835 61 145.999 61C137.162 61 129.999 53.8366 129.999 45C129.999 36.1634 137.162 29 145.999 29C154.835 29 161.999 36.1634 161.999 45Z"
        fill="#7F9376"
        stroke="#7F9376"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.999 34V56M140.999 50.8333L142.463 52.0418C144.416 53.6527 147.582 53.6527 149.534 52.0418C151.487 50.4309 151.487 47.8191 149.534 46.2082C148.558 45.4026 147.278 44.9999 145.998 45C144.79 45.0001 143.582 44.5974 142.66 43.7919C140.817 42.1809 140.817 39.5691 142.66 37.9582C144.504 36.3473 147.493 36.3473 149.336 37.9582L150.028 38.5625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="176.417"
        y="70.9277"
        width="34.377"
        height="31.1789"
        rx="2.37634"
        transform="rotate(36.2171 176.417 70.9277)"
        fill="#BFC9BA"
      />
      <rect
        x="170.62"
        y="83.0078"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 170.62 83.0078)"
        fill="white"
      />
      <rect
        x="176.342"
        y="74.4434"
        width="29.466"
        height="7.77574"
        rx="0.818499"
        transform="rotate(36.2171 176.342 74.4434)"
        fill="white"
      />
      <rect
        x="167.237"
        y="87.6309"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 167.237 87.6309)"
        fill="white"
      />
      <rect
        x="163.85"
        y="92.25"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 163.85 92.25)"
        fill="white"
      />
      <rect
        x="177.225"
        y="87.8438"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 177.225 87.8438)"
        fill="white"
      />
      <rect
        x="173.838"
        y="92.4629"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 173.838 92.4629)"
        fill="white"
      />
      <rect
        x="170.456"
        y="97.0898"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 170.456 97.0898)"
        fill="white"
      />
      <rect
        x="183.827"
        y="92.6797"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 183.827 92.6797)"
        fill="white"
      />
      <rect
        x="180.44"
        y="97.3008"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 180.44 97.3008)"
        fill="white"
      />
      <rect
        x="177.057"
        y="101.926"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 177.057 101.926)"
        fill="white"
      />
      <rect
        x="190.424"
        y="97.5137"
        width="4.91099"
        height="3.274"
        rx="0.818499"
        transform="rotate(36.2171 190.424 97.5137)"
        fill="white"
      />
      <rect
        x="187.042"
        y="102.137"
        width="4.91099"
        height="9.00349"
        rx="0.818499"
        transform="rotate(36.2171 187.042 102.137)"
        fill="white"
      />
      <path
        d="M55.9014 75.5199C57.6849 77.6758 58.7958 80.3082 59.096 83.0901C59.3963 85.8719 58.8727 88.6807 57.5903 91.1675C56.3079 93.6544 54.3231 95.7097 51.8826 97.0782C49.4421 98.4467 46.6534 99.0681 43.8627 98.8653C41.072 98.6624 38.4025 97.6442 36.1855 95.9371C33.9686 94.23 32.302 91.9093 31.3927 89.2631C30.4835 86.617 30.3716 83.762 31.071 81.0528C31.7704 78.3436 33.2503 75.8996 35.3269 74.0242L44.8981 84.6224L55.9014 75.5199Z"
        fill="#BFC9BA"
      />
      <path
        d="M33.7749 69.7444C35.8365 67.8835 38.3961 66.6642 41.1402 66.2357C43.8842 65.8072 46.6937 66.1882 49.2245 67.3319L43.3434 80.3451L33.7749 69.7444Z"
        fill="#AAB7A4"
      />
      <path
        d="M50.2912 68.2008C51.5373 68.7604 52.6967 69.4959 53.7339 70.3848L44.441 81.2279L50.2912 68.2008Z"
        fill="#AAB7A4"
      />
      <path
        d="M54.9279 71.0927C55.5482 71.6242 56.1218 72.2079 56.6423 72.8375L45.6364 81.9369L54.9279 71.0927Z"
        fill="#AAB7A4"
      />
    </svg>
  );
};

export default EmptyExpenseIcon;
