import React, { useRef, useEffect, useState } from 'react';
import useInput from '~/components/Input/useInput';
import useFormulaContext from '../context/useFormulaContext';
import InputWrapper from '~/components/Input/InputWrapper';
import useTableContext from '~/components/Formulas/FormulasTable/hooks/useTableContext';
import ErrorPopover from './ErrorPopover';
import Typography from '~/components/Typography';
import spinner from '~/assets/spinner-neutral-200.svg';

const PendingAttributeInput = ({
  formulaGroupUuid,
  active,
  nextSortOrder,
}: {
  formulaGroupUuid: string;
  active: boolean;
  nextSortOrder: number;
}): React.ReactElement => {
  const { setPendingAttributeData, saveNewAttribute, formulaDictionary } = useFormulaContext();
  const { columnWidths } = useTableContext();
  const [pendingLabel, setPendingLabel] = useInput({
    validation: /.*/,
  });
  const pendingLabelRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const onBlurPendingLabel = (): void => {
    // When loading, we don't want to save the attribute additional times
    if (loading) return;

    if (!pendingLabel.value) {
      setPendingAttributeData(null);
    } else if (isNameUnique(pendingLabel.value)) {
      setLoading(true);
      saveNewAttribute({
        name: pendingLabel.value.trim(),
        formulaGroupUuid,
        tabIntoFormula: false,
        nextSortOrder,
      });
    }
  };

  const onKeyDownPendingLabel = (event: React.KeyboardEvent): void => {
    if ((event.key === 'Enter' || event.key === 'Tab') && pendingLabel.value && isNameUnique(pendingLabel.value)) {
      event.preventDefault();
      (event.currentTarget as HTMLInputElement).blur();
    }

    if (event.key === 'Escape') {
      setPendingLabel((prev) => ({ ...prev, value: '' }));
      setPendingAttributeData(null);
    }
  };

  useEffect(() => {
    if (active) {
      setPendingLabel((prev) => ({ ...prev, value: '' }));
      const element = pendingLabelRef.current;
      element?.focus();
    } else {
      setLoading(false);
      setPendingLabel((prev) => ({ ...prev, value: '' }));
    }
  }, [active]);

  const isNameUnique = (name: string): boolean => {
    if (loading) return true; // Bypass validation while saving

    const formulaWithSameName = Object.values(formulaDictionary).find((formula) => formula.recipe.name === name.trim());
    return !formulaWithSameName;
  };

  if (!active) return <></>;

  return (
    <div className="flex border-b bg-white h-[48px] relative">
      <div
        className="flex items-center justify-start border-r pl-[25px] pr-2 sticky left-0 z-20 bg-white"
        style={{
          width: `${columnWidths[0] + columnWidths[1]}px`,
          minWidth: `${columnWidths[0] + columnWidths[1]}px`,
          boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
        }}
      >
        <InputWrapper
          disabled={loading}
          placeholder="Attribute Name"
          ref={pendingLabelRef}
          id="pending-label"
          state={pendingLabel}
          setState={setPendingLabel}
          className={`h-[48px] focus-visible:!ring-0 focus-visible:!ring-offset-0 ${
            !isNameUnique(pendingLabel.value) ? '!border-red-500' : ''
          }`}
          onBlur={onBlurPendingLabel}
          onKeyDown={onKeyDownPendingLabel}
          append={
            loading ? (
              <img src={spinner} alt="loading spinner" className="size-5" />
            ) : (
              <div onClick={() => pendingLabelRef.current?.blur()} className="cursor-pointer">
                <Typography color="empty" className="hover:text-neutral-500">
                  &crarr;
                </Typography>
              </div>
            )
          }
        />
        <ErrorPopover
          visible={!isNameUnique(pendingLabel.value)}
          formulaName={pendingLabel.value.trim()}
          right={pendingLabelRef.current?.getBoundingClientRect().right ?? 0}
          top={pendingLabelRef.current?.getBoundingClientRect().top ?? 0}
        />
      </div>
    </div>
  );
};

export default PendingAttributeInput;
