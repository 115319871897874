import React from 'react';
import History from './components/History';
import Conversation from './components/Conversation';
import { ChatContextProvider } from './context/ChatContext';
import Settings from './components/Settings';
import UpdateBusinessContextModal from './components/Settings/UpdateBusinessContextModal';

const Chat = (): React.ReactElement => {
  return (
    <ChatContextProvider>
      <div className="flex w-full">
        <History />
        <Conversation />
        <Settings />
        <UpdateBusinessContextModal />
      </div>
    </ChatContextProvider>
  );
};

export default Chat;
