import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../baseQuery';
import { IHelpCenterPost, IPostWithReactions, IReactionType } from './requestSchemas';

interface SearchParams {
  search?: string;
}

interface ApiResponse<T> {
  data: T;
}

export const helpCenterApi = createApi({
  reducerPath: 'helpCenterApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['HelpCenterPost', 'Reaction'] as const,
  endpoints: (builder) => ({
    getPosts: builder.query<IHelpCenterPost[], SearchParams>({
      query: ({ search }) => ({
        url: '/help-center/posts',
        params: { search },
      }),
      transformResponse: (response: ApiResponse<IHelpCenterPost[]>) => {
        return response.data;
      },
      providesTags: (result) =>
        result ? [...result.map(({ uuid }) => ({ type: 'HelpCenterPost' as const, id: uuid }))] : [],
    }),

    getPost: builder.query<IPostWithReactions, { uuid: string }>({
      query: ({ uuid }) => `/help-center/posts/${uuid}`,
      transformResponse: (response: ApiResponse<IPostWithReactions>) => {
        return response.data;
      },
      providesTags: (_result, _error, { uuid }) => [{ type: 'HelpCenterPost' as const, id: uuid }],
    }),

    updatePost: builder.mutation<IHelpCenterPost, { uuid: string; data: Partial<IHelpCenterPost> }>({
      query: ({ uuid, data }) => ({
        url: `/help-center/posts/${uuid}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_result, _error, { uuid }) => [{ type: 'HelpCenterPost' as const, id: uuid }],
    }),

    createPost: builder.mutation<
      IHelpCenterPost,
      Omit<
        IHelpCenterPost,
        'uuid' | 'createdBy' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'publishedAt' | 'numberOfPageVisits'
      >
    >({
      query: (data) => ({
        url: '/help-center/posts',
        method: 'POST',
        body: data,
      }),
    }),

    addReaction: builder.mutation<void, { postUuid: string; reaction: IReactionType }>({
      query: ({ postUuid, reaction }) => ({
        url: `/help-center/posts/${postUuid}/reactions`,
        method: 'POST',
        body: { reaction },
      }),
    }),

    removeReaction: builder.mutation<void, { postUuid: string }>({
      query: ({ postUuid }) => ({
        url: `/help-center/posts/${postUuid}/reactions`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPostQuery,
  useAddReactionMutation,
  useRemoveReactionMutation,
  useUpdatePostMutation,
  useCreatePostMutation,
} = helpCenterApi;
