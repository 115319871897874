import React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import { State } from '~/store';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { Select } from '~/components/UncontrolledComponents/Select';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
  formulaUuid: z
    .string({
      required_error: 'Please select an expense to link to',
    })
    .min(1, 'Please select an expense to link to'),
});
type FormValues = z.infer<typeof formSchema>;

const LinkDatasourceToExistingExpenseModal = ({
  isOpen,
  onClose,
  datasourceUuid,
  integrationsWithMappings,
}: {
  isOpen: boolean;
  onClose: () => void;
  datasourceUuid: string;
  integrationsWithMappings: IIntegrationWithMappings[];
}): React.ReactNode => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { refreshData } = useFormulaContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      formulaUuid: '',
    },
    resolver: zodResolver(formSchema),
  });

  const datasourceName = integrationsWithMappings
    .flatMap((integration) => integration.mappings)
    .find((mapping) => mapping.uuid === datasourceUuid)?.name;

  const { data: formulas } = formulasApi.useListFormulasQuery({
    scenarioUuid: activeScenarioUuid ?? undefined,
    types: [IFormulaTypeEnum.Expense, IFormulaTypeEnum.Headcount],
  });

  const [upsertExpenseFormula, { isLoading: isUpdating }] = formulasApi.useUpsertExpenseFormulaMutation();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await upsertExpenseFormula({
        query: {
          scenarioUuid: activeScenarioUuid ?? undefined,
          awaitCalculations: false,
        },
        body: {
          formulaUuid: data.formulaUuid,
          dataSourceUuids: [datasourceUuid],
        },
      }).unwrap();

      refreshData();
      toast.success('Expense linked');
      handleClose();
    } catch (error) {
      setError('root', {
        message: 'Expense failed to save. Please try again.',
      });
    }
  });

  const handleClose = (): void => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Link to Expense">
      <div className="flex flex-col gap-3 w-full">
        <Typography color="secondary">{datasourceName}</Typography>
        <div className="flex flex-col gap-4 w-full items-center">
          <Controller
            control={control}
            name="formulaUuid"
            render={({ field, fieldState }) => (
              <Select
                id="select-expense"
                options={formulas?.map((formula) => ({ label: formula.recipe.name, value: formula.formulaUuid })) ?? []}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                error={fieldState.error?.message}
              />
            )}
          />
          <div className="flex items-center justify-between w-full">
            <Button onClick={handleClose} disabled={isUpdating} className="!w-fit !px-0" fill="clear">
              Cancel
            </Button>
            <Button onClick={onSubmit} className="!w-fit" loading={isUpdating}>
              Link
            </Button>
          </div>
          {errors.root && <Typography color="warning">{errors.root.message}</Typography>}
        </div>
      </div>
    </Modal>
  );
};

export default LinkDatasourceToExistingExpenseModal;
