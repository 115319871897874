import React, { LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { useDatePicker } from '~/components/DatePicker';
import { IUpdatePositionRequestBody } from '~/services/parallel/api/positions/positionsRequestSchemas';
import useHover from '~/utils/hooks/useHover';
import * as stringDate from '~/utils/stringDate';
import useClickOutside from '~/utils/hooks/useClickOutside';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import './styles.css';

const CellDate = React.memo(
  ({
    id,
    isActive,
    isScenario,
    onUpdate,
    value,
    positionUuid,
    minDate,
    maxDate,
  }: {
    id: string;
    isActive: boolean;
    isScenario: boolean;
    onUpdate: ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
    }: {
      positionUuid: string;
      positionUpdates: IUpdatePositionRequestBody;
      awaitCalculations?: boolean;
    }) => Promise<{ data: IPositionDetails; headers: Record<string, string> }>;
    value?: string | null;
    positionUuid: string;
    minDate?: string;
    maxDate?: string;
  }): React.ReactNode => {
    const [date, setDate] = useDatePicker({
      value: { startDate: value ?? null, endDate: value ?? null },
      minDate: minDate,
      maxDate: maxDate,
    });
    const wrapperRef = useRef<HTMLElement>(null);
    const [ref, hovering] = useHover();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);

    const resetDate = (): void => {
      setDate((prev) => ({
        ...prev,
        value: {
          startDate: value ?? null,
          endDate: value ?? null,
        },
        minDate: minDate,
        maxDate: maxDate,
        valid: true,
        pristine: true,
        touched: false,
        disabled: false,
      }));
      setInEditMode(false);
      setShowDatePicker(false);
    };

    const onBlur = async (): Promise<void> => {
      await onUpdate({ positionUuid, positionUpdates: { [id]: date.value.startDate } });
    };

    useEffect(() => {
      if (hovering && isActive) {
        if (!showDatePicker) {
          setShowDatePicker(true);
        }
      } else if (!hovering && isActive && !inEditMode) {
        setShowDatePicker(false);
      }
    }, [hovering, isActive]);

    useEffect(() => {
      if (
        (date.value.startDate && value && !stringDate.isSameDay({ date1: date.value.startDate, date2: value })) ||
        (date.value.startDate && !value) ||
        (!date.value.startDate && value)
      ) {
        onBlur();
      }
    }, [date.value.startDate]);

    useClickOutside(wrapperRef, resetDate);

    const dateToRender = useMemo(() => {
      if (date.value.startDate) {
        return stringDate.format(date.value.startDate, 'mm/dd/yyyy');
      } else if (id === 'termDate' && value && !date.value.startDate) {
        return '-';
      } else if (value) {
        return stringDate.format(value, 'mm/dd/yyyy');
      } else {
        return '-';
      }
    }, [date.value.startDate, value]);

    const datePicker = useMemo(() => {
      return (
        <DatePicker
          id={`${id}-${positionUuid}-date-picker`}
          className={id === 'hireDate' ? 'clearDateHidden' : ''}
          state={date}
          setState={setDate}
          required={id === 'hireDate'}
        />
      );
    }, [showDatePicker, date]);

    return (
      <div className={id === 'hireDate' ? 'w-[135px]' : 'w-[150px]'} ref={ref as LegacyRef<HTMLDivElement>}>
        {inEditMode || showDatePicker ? (
          <div
            ref={wrapperRef as LegacyRef<HTMLDivElement>}
            data-testid={`${id}-${positionUuid}-container`}
            onClick={(e) => {
              e.stopPropagation();
              isActive && setInEditMode(true);
            }}
          >
            {datePicker}
          </div>
        ) : (
          <div
            className={`flex text-nowrap overflow-hidden${
              isActive ? '' : ' text-neutral-100 cursor-default'
            } ${isScenario && 'text-blue-400'}`}
            data-testid={`${id}-${positionUuid}-container`}
            onClick={(e) => {
              e.stopPropagation();
              isActive && setInEditMode(true);
            }}
          >
            {dateToRender}
          </div>
        )}
      </div>
    );
  },
);

CellDate.displayName = 'CellDate';

export default CellDate;
