import React, { useEffect, useState } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';

const ResponseLoading = (): React.ReactElement => {
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);

  const loadingTextOptions = [
    'Processing Request',
    'Retrieving Required Data',
    'Formatting Data',
    'Aggregating Relevant Information',
    'Analyzing Key Insights',
    'Generating Response',
    'Assembling Final Answer',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTextOptions.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full flex justify-start items-center gap-4 pb-8">
      <div className="size-8 rounded-full bg-green-25 flex items-center justify-center">
        <SparklesIcon className="size-5 text-green-500" />
      </div>
      <Typography className="animate-shimmerLoadingText bg-[linear-gradient(110deg,#AAB7A4,45%,#64755C,55%,#AAB7A4)] bg-[length:200%_100%] inline-block bg-clip-text text-transparent">
        {loadingTextOptions[loadingTextIndex]}
      </Typography>
    </div>
  );
};

export default ResponseLoading;
