import { Extension } from '@tiptap/core';

export const DollarSignExtension = Extension.create({
  name: 'dollarSign',
  onCreate() {
    const content = this.editor.getHTML();
    // Using Unicode character U+FE69 (SMALL DOLLAR SIGN) which looks like $ but won't trigger LaTeX
    const transformedContent = content.replace(/\^%/g, '﹩');
    if (content !== transformedContent) {
      this.editor.commands.setContent(transformedContent);
    }
  },
});
