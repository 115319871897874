import { datadogRum } from '@datadog/browser-rum';
import posthog from 'posthog-js';

interface IParams {
  user: {
    uuid: string;
    name: string;
    email: string;
    role: string;
    preferences: {
      primaryOrganizationUuid: string;
    };
  };
  isLoggedIn: boolean;
}

export default ({ user, isLoggedIn }: IParams): void => {
  // Test posthog to ensure it works by going side by side with datadog
  if (isLoggedIn && user.uuid) {
    posthog.identify(user.uuid, {
      email: user.email,
      name: user.name,
      role: user.role,
      organizationUuid: user.preferences.primaryOrganizationUuid,
    });
  }

  const { VITE_DATADOG_APPLICATION_ID, VITE_DATADOG_CLIENT_TOKEN, VITE_DATADOG_SITE } = import.meta.env;
  if (
    VITE_DATADOG_APPLICATION_ID &&
    VITE_DATADOG_CLIENT_TOKEN &&
    VITE_DATADOG_SITE &&
    isLoggedIn &&
    user.uuid &&
    user.role === 'user' &&
    import.meta.env.PROD &&
    !user.email.endsWith('@getparallel.com') &&
    !datadogRum.getInternalContext()
  ) {
    datadogRum.init({
      applicationId: VITE_DATADOG_APPLICATION_ID,
      clientToken: VITE_DATADOG_CLIENT_TOKEN,
      site: VITE_DATADOG_SITE,
      version: import.meta.env.VITE_RELEASE_VERSION as string,
      service: 'parallel',
      env: 'production',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: false,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.setUser({
      id: user.uuid,
      name: user.name,
      email: user.email,
    });

    /**
     * HubSpot tracking
     */
    // @ts-expect-error - window._hsq is not typed
    if (window._hsq) {
      // @ts-expect-error - window._hsq.push is not typed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      window._hsq.push([
        'identify',
        {
          contact_uuid: user.uuid,
          email: user.email,
          firstname: user.name,
          lastname: user.name,
        },
      ]);
    }
  }
};
