import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { ZScenario } from '~/services/parallel/scenarios.types';
import { IScenario } from '~/services/parallel/scenarios.types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const getScenarios = async ({ organizationUuid }: { organizationUuid: string }): Promise<IScenario[]> => {
  const response = (await request({
    url: `/organizations/${organizationUuid}/scenarios`,
    method: 'GET',
  })) as IAPIResponse<IScenario[]>;

  const parsedData = z.array(ZScenario).parse(response.data.data);
  return parsedData;
};

export const fetchScenario = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<IScenario> => {
  const response = (await request({
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
    method: 'GET',
  })) as IAPIResponse<IScenario>;

  if (response.status === StatusCodes.OK) {
    const parsedData = ZScenario.parse(response.data.data);
    return parsedData;
  }
  throw new Error('Failed to fetch scenario');
};

export const deleteScenario = async ({
  organizationUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  scenarioUuid: string;
}): Promise<void> => {
  const response = await request({
    url: `/organizations/${organizationUuid}/scenarios/${scenarioUuid}`,
    method: 'DELETE',
  });

  if (response.status === StatusCodes.NO_CONTENT) {
    return;
  }
  throw new Error('Failed to delete scenario');
};
