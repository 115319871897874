import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { z } from 'zod';
import CreatePositionModal from '~/pages/Headcount/components/CreatePositionModal/CreatePositionModal';
import DeletePositionModal from '~/pages/Headcount/components/DeletePositionModal';
import HeadcountTimeline from '~/pages/Headcount/components/HeadcountTimeline/HeadcountTimeline';
import UpdateCompensationModal from '~/pages/Headcount/components/UpdateCompensationModal/UpdateCompensationModal';
import UpdatePositionModal from '~/pages/Headcount/components/UpdatePositionModal/UpdatePositionModal';
import { ZPositionDetails } from '~/pages/Headcount/entity/schemas';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { State } from '~/store';
import Skeleton from 'react-loading-skeleton';
import EditTermDateModal from '~/pages/Headcount/components/EditTermDate/EditTermDateModal';
import * as stringDate from '~/utils/stringDate';
interface IExistingPositionModalState {
  isOpen: boolean;
  position: IPositionDetails | null;
}

const DashboardTimelineContainer = ({
  scenarioUuid,
  reloadDashboard,
}: {
  scenarioUuid: string | null;
  reloadDashboard: () => Promise<void>;
}): React.ReactNode => {
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  const listParams = useMemo(
    () => ({
      orgUuid: organizationUuid,
      scenarioUuid: scenarioUuid ?? undefined,
      includeFullyBurdened: true,
    }),
    [organizationUuid, scenarioUuid],
  );

  const { data: positions, isLoading: isLoadingPositions } = positionsApi.useListPositionsQuery(listParams, {
    skip: !defaultGraphStartDate || !defaultGraphEndDate || !organizationUuid,
  });

  const [createPositionModalIsOpen, setCreatePositionModalIsOpen] = useState(false);
  const [updatePositionModalState, setUpdatePositionModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [deletePositionModalState, setDeletePositionModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [updateCompensationModalState, setUpdateCompensationModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });
  const [editTermDateModalState, setEditTermDateModalState] = useState<IExistingPositionModalState>({
    isOpen: false,
    position: null,
  });

  const [positionIndexes, setPositionIndexes] = useState<Record<string, number>>({});

  const previousParsedPositionsRef = useRef<IPositionDetails[]>([]);

  const memoizedParsedPositions = useMemo(() => {
    // If data is present, parse it; otherwise, return an empty array.
    const parsed = positions?.length ? z.array(ZPositionDetails).parse(positions) : [];
    // Optionally, compare with previous parsed data to decide if a new array is needed.
    if (isEqual(previousParsedPositionsRef.current, parsed)) {
      return previousParsedPositionsRef.current;
    } else {
      previousParsedPositionsRef.current = parsed;
      return parsed;
    }
  }, [positions]);

  useEffect(() => {
    if (
      memoizedParsedPositions.length &&
      memoizedParsedPositions
        .map((position) => position.positionUuid)
        .some((uuid) => typeof positionIndexes[uuid] !== 'number')
    ) {
      setPositionIndexes(
        memoizedParsedPositions
          .sort((a, b) => a.hireDate.localeCompare(b.hireDate))
          .reduce(
            (acc, position, index) => {
              acc[position.positionUuid] = index;
              return acc;
            },
            {} as Record<string, number>,
          ),
      );
    }
  }, [memoizedParsedPositions]);

  return (
    <>
      {isLoadingPositions && <Skeleton className="h-[500px]" />}
      {!isLoadingPositions &&
        !memoizedParsedPositions
          .map((position) => position.positionUuid)
          .some((uuid) => typeof positionIndexes[uuid] !== 'number') && (
          <HeadcountTimeline
            organizationUuid={organizationUuid}
            scenarioUuid={scenarioUuid}
            positions={memoizedParsedPositions.filter((position) =>
              stringDate.isAfter({ comparison: stringDate.getStringDate(), dateToCheck: position.hireDate }),
            )}
            setCreatePositionModalIsOpen={setCreatePositionModalIsOpen}
            setUpdatePositionModalState={setUpdatePositionModalState}
            setDeletePositionModalState={setDeletePositionModalState}
            setUpdateCompensationModalState={setUpdateCompensationModalState}
            setEditTermDateModalState={setEditTermDateModalState}
            positionIndexes={positionIndexes}
            reloadDashboard={reloadDashboard}
            createScenario={true}
            addNewPosition={true}
            onlyNewHires={true}
            listParams={listParams}
          />
        )}
      <CreatePositionModal
        isOpen={createPositionModalIsOpen}
        onClose={() => setCreatePositionModalIsOpen(false)}
        orgUuid={organizationUuid}
        scenarioUuid={scenarioUuid}
        createScenario={true}
        reloadDashboard={reloadDashboard}
        awaitCalculations={true}
        listParams={listParams}
        setPositionIndexes={setPositionIndexes}
      />
      <UpdatePositionModal
        isOpen={updatePositionModalState.isOpen}
        onClose={() => setUpdatePositionModalState({ isOpen: false, position: null })}
        orgUuid={organizationUuid}
        scenarioUuid={scenarioUuid}
        position={updatePositionModalState.position}
        createScenario={true}
        reloadDashboard={reloadDashboard}
        awaitCalculations={true}
        listParams={listParams}
      />
      <DeletePositionModal
        isOpen={deletePositionModalState.isOpen}
        onClose={() => setDeletePositionModalState({ isOpen: false, position: null })}
        position={deletePositionModalState.position}
        orgUuid={organizationUuid}
        scenarioUuid={scenarioUuid}
        setPositionIndexes={setPositionIndexes}
        createScenario={true}
        reloadDashboard={reloadDashboard}
        awaitCalculations={true}
        listParams={listParams}
      />
      <UpdateCompensationModal
        isOpen={updateCompensationModalState.isOpen}
        onClose={() => setUpdateCompensationModalState({ isOpen: false, position: null })}
        position={updateCompensationModalState.position}
        organizationUuid={organizationUuid}
        scenarioUuid={scenarioUuid}
        createScenario={true}
        reloadDashboard={reloadDashboard}
        awaitCalculations={true}
        listParams={listParams}
      />
      <EditTermDateModal
        isOpen={editTermDateModalState.isOpen}
        onClose={() => setEditTermDateModalState({ isOpen: false, position: null })}
        position={editTermDateModalState.position}
        orgUuid={organizationUuid}
        scenarioUuid={scenarioUuid}
        createScenario={true}
        reloadDashboard={reloadDashboard}
        awaitCalculations={true}
        listParams={listParams}
      />
    </>
  );
};

export default DashboardTimelineContainer;
