import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../baseQuery';
import { IRollbackRequest } from './requestSchemas';
import { formulasApi } from '../formulas/formulasApi';
import { departmentsApi } from '../departmentsApi';
import { scenariosApi } from '../scenarios/scenariosApi';
import { changeLogApi } from '../changeLogApi';
import { bvaApi } from '../bvaApi';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Organization'],
  endpoints: (builder) => ({
    rollback: builder.mutation<void, IRollbackRequest>({
      query: (data) => {
        return {
          url: `organizations/${data.params.organizationUuid}/rollback`,
          method: 'PATCH',
          body: data.body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(formulasApi.util.invalidateTags(['Formula']));
        dispatch(departmentsApi.util.invalidateTags(['Department']));
        dispatch(scenariosApi.util.invalidateTags(['Scenario']));
        dispatch(changeLogApi.util.invalidateTags(['ChangeLog']));
        dispatch(bvaApi.util.invalidateTags(['Bva']));
      },
    }),
  }),
});

export const { useRollbackMutation } = organizationApi;
