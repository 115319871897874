import React from 'react';
import { RadioInputType } from '~/components/RadioInput/RadioInput.types';
import Typography from '~/components/Typography';

interface Props {
  id: string;
  options: RadioInputType[];
  label: string;
  description?: string;
  disabled?: boolean;
  error?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const RadioInputTile = ({
  id,
  options,
  label,
  disabled,
  error,
  name,
  onChange,
  value,
}: Props): React.ReactNode => {
  return (
    <div className="flex flex-col flex-wrap w-full">
      <div className="flex flex-row flex-wrap w-full">
        <Typography tag="span" color={disabled ? 'disabled' : 'primary'} className={`${label ? 'mb-1' : ''}`}>
          {label}
        </Typography>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {options.map((option) => (
          <label key={option.value} className="flex items-center cursor-pointer">
            <input
              type="radio"
              value={option.value}
              name={name || id}
              id={`${id}-${option.value}`}
              disabled={disabled}
              onChange={onChange}
              className="sr-only peer"
              checked={value === option.value}
            />
            <span
              className={`flex items-center bg-transparent py-2 px-4 text-sm text-gray-800 border border-gray-300 rounded transition duration-300 ease-in-out peer-checked:bg-green-500 peer-checked:text-white peer-checked:border-green-400 peer-hover:bg-transparent peer-hover:text-green-400 peer-hover:border-gray-400 peer-checked:peer-hover:bg-green-500 peer-checked:peer-hover:text-white peer-checked:peer-hover:border-green-400`}
              data-testid={`${id}-option-${option.value}`}
            >
              {option.icon}
              <span className={`whitespace-nowrap ${option.icon ? 'ml-2' : ''}`}>{option.label}</span>
            </span>
          </label>
        ))}
      </div>
      {error && (
        <Typography id={`${id}-input-error`} tag="span" color="warning" className="italic p-1">
          {error}
        </Typography>
      )}
    </div>
  );
};
