import React, { useContext, useEffect } from 'react';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import Typography from '~/components/Typography';
import { ContractsContext } from '../context/ContractsContext';
import request from '~/utils/request';
import { HttpStatusCode } from 'axios';
import toast from 'react-hot-toast';
import logger from '~/utils/logger';
import { IAPIResponse } from '~/utils/types';
import { IContract } from '../entity/types';
import { ZContract } from '../entity/schema';
import { State } from '~/store';
import { useSelector } from 'react-redux';

const DiscontinueContractModal = (): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { showDiscontinueContractModal, setShowDiscontinueContractModal, setContracts } = useContext(ContractsContext);
  const [finalMonth, setFinalMonth] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
  });

  useEffect(() => {
    if (showDiscontinueContractModal) {
      setFinalMonth((prevState) => ({
        ...prevState,
        startDate: showDiscontinueContractModal.endDate,
        endDate: showDiscontinueContractModal.endDate,
      }));
    } else {
      setFinalMonth((prevState) => ({
        ...prevState,
        startDate: null,
        endDate: null,
      }));
    }
  }, [showDiscontinueContractModal]);

  const handleDiscontinueContract = async (): Promise<void> => {
    try {
      if (showDiscontinueContractModal && finalMonth.startDate) {
        const response = (await request({
          url: `/contracts/${showDiscontinueContractModal.contractUuid}`,
          method: 'PATCH',
          headers: {
            'Organization-Uuid': organizationUuid,
          },
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          body: {
            endDate: finalMonth.startDate,
          },
        })) as IAPIResponse<IContract>;
        if (response.status === HttpStatusCode.Ok) {
          const parsedData = ZContract.parse(response.data.data);
          setContracts((prevState) =>
            prevState.map((contract) => (contract.contractUuid === parsedData.contractUuid ? parsedData : contract)),
          );
          setShowDiscontinueContractModal(null);
        }
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Error discontinuing contract');
    }
  };

  return (
    <Modal
      isOpen={!!showDiscontinueContractModal}
      title={`Discontinue ${showDiscontinueContractModal?.contractTitle ?? ''} Contract`}
    >
      <div className="flex flex-col gap-4">
        <Typography size="sm" color="secondary">
          Discontinuing will remove the contracts impact on the forecast. Specify the final month to include this
          contract.
        </Typography>
        <PeriodPicker id="final-month" state={finalMonth} setState={setFinalMonth} label="End Date" />
        <div className="flex w-full items-center justify-between">
          <Button fill="clear" className="!w-fit !px-0" onClick={() => setShowDiscontinueContractModal(null)}>
            Cancel
          </Button>
          <Button fill="destructive" className="!w-fit" onClick={handleDiscontinueContract}>
            Discontinue Contract
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DiscontinueContractModal;
