import React from 'react';
import Typography from '../Typography';

const CompletionBar = ({
  completedStepCount,
  totalStepCount,
}: {
  completedStepCount: number;
  totalStepCount: number;
}): React.ReactNode => {
  const completionPercentage = (completedStepCount / totalStepCount) * 100;
  return (
    <div className="flex flex-col gap-3 mb-4">
      <div className="flex flex-row gap-1">
        <Typography weight="semibold">Onboarding</Typography>
        <Typography color="primaryGreen">{`${completedStepCount} of ${totalStepCount} completed!`}</Typography>
      </div>
      <div className="w-full h-2 bg-green-25 rounded-full h-1">
        <div
          className="h-full bg-green-400 transition-all duration-300 rounded-full"
          style={{ width: `${completionPercentage}%` }}
        />
      </div>
    </div>
  );
};

export default CompletionBar;
