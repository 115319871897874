import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { SelectType } from '~/components/Select/Select.types';
import leven from 'leven';
import { Editor } from '@tiptap/react';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import Typography from '~/components/Typography';

interface IFormulaSelectType extends SelectType {
  type?: IFormulaTypeEnum | null;
}

const Autocomplete = ({
  editor,
  searchValue,
  position,
  selectOptions,
  onSelect,
  onFocus,
  onBlur,
}: {
  editor: Editor | null;
  searchValue: string;
  position: { top: number; left: number };
  selectOptions: IFormulaSelectType[];
  onSelect: (option: IFormulaSelectType) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}): React.ReactElement | null => {
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(0);

  const filteredOptions = selectOptions
    .map((option) => {
      const searchValueLower = searchValue.toLowerCase().trim();

      // Return empty list if search value is just a number
      if (!isNaN(Number(searchValue)) && searchValue !== '') {
        return {
          ...option,
          distance: Infinity,
        };
      }

      const label = option.label?.toLowerCase() || '';

      // Check for exact match first
      if (label === searchValueLower) {
        return {
          ...option,
          distance: 0,
        };
      }

      // Check for substring match second
      if (label.includes(searchValueLower)) {
        return {
          ...option,
          distance: 1.5,
        };
      }

      // Fall back to levenshtein distance
      return {
        ...option,
        distance: leven(searchValueLower, label),
      };
    })
    .sort((a, b) => a.distance - b.distance)
    .filter((option) => option.distance <= 2)
    .slice(0, 5);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (filteredOptions.length === 0) return;
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          if (prevIndex >= filteredOptions.length - 1) return 0;
          return prevIndex + 1;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          if (prevIndex <= 0) return filteredOptions.length - 1;
          return prevIndex - 1;
        });
      } else if (e.key === 'Enter' && highlightedIndex >= 0) {
        e.preventDefault();
        onSelect(filteredOptions[highlightedIndex]);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [filteredOptions, highlightedIndex, onSelect]);

  useEffect(() => {
    if (highlightedIndex >= 0) {
      if (onFocus) {
        onFocus();
      } else if (onBlur) {
        onBlur();
      }
    }

    return () => {
      if (onBlur) {
        onBlur();
      }
    };
  }, [highlightedIndex, searchValue]);

  useEffect(() => {
    if (!filteredOptions.length && onBlur) {
      onBlur();
    }
  }, [filteredOptions]);

  return createPortal(
    <div className="bg-white rounded z-50 shadow" style={{ position: 'fixed', top: position.top, left: position.left }}>
      <div className="flex flex-col" data-testid="autocomplete-options">
        {filteredOptions.map((option, index) => (
          <div
            role="button"
            key={option.value}
            data-testid={`autocomplete-option-${option.label}`}
            className={`border-gray-50 cursor-pointer px-5 ${option.type === IFormulaTypeEnum.Expense ? 'py-1 items-center justify-between' : 'py-2'} flex flex-nowrap ${highlightedIndex === index && 'bg-gray-100'}`}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelect(option);
              setHighlightedIndex(-1);
              if (editor && !editor.isFocused) {
                editor.commands.focus();
              }
            }}
          >
            {option.label}
            {option.type === IFormulaTypeEnum.Expense && (
              <div className="bg-neutral-25 px-3 py-1 rounded-full ml-2">
                <Typography color="empty">Expense</Typography>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>,
    document.body,
  );
};

export default Autocomplete;
