import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import Typography from '../Typography';
import Button from '../Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PeriodPicker from '../PeriodPicker';
import usePeriodPicker from '../PeriodPicker/usePeriodPicker';
import * as stringDate from '~/utils/stringDate';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import Checkbox from '../Checkbox';
import LoadingAnimation from './LoadingAnimation.json';
import QuickbooksLogo from '~/assets/logos/quickbooks.svg';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import Lottie from 'lottie-react';
import XeroLogo from '~/assets/logos/xero.svg';
import { update as updateIntegrations } from '~/store/integrationsSlice';
import logger from '~/utils/logger';
import { format } from 'date-fns';
import { createSelector } from '@reduxjs/toolkit';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import posthog from 'posthog-js';

const integrationIconMapping = {
  'quickbooks-online': QuickbooksLogo,
  xero: XeroLogo,
};

const selectPullActualsData = createSelector(
  (state: State) => state.organization.uuid,
  (state: State) => state.organization.configuration.companyStartDate,
  (state: State) => state.scenario.activeScenarioUuid,
  (state: State) => state.integrations.isPulling,
  (state: State) => state.integrations.lastPulled,
  (state: State) => state.integrations.source,
  (orgUuid, companyStartDate, scenarioUuid, isPulling, lastPulled, source) => ({
    organizationUuid: orgUuid,
    companyStartDate,
    activeScenarioUuid: scenarioUuid,
    integrationSource: source,
    isPulling,
    lastPulled,
  }),
);

const PullActualsModal = ({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const { organizationUuid, companyStartDate, activeScenarioUuid, integrationSource, isPulling, lastPulled } =
    useSelector(selectPullActualsData);
  const [startPeriod, setStartPeriod] = usePeriodPicker({
    startDate: stringDate.subtractMonths(stringDate.getStringDate(), 1),
    endDate: null,
    mode: 'month',
  });
  const [endPeriod, setEndPeriod] = usePeriodPicker({
    startDate: stringDate.subtractMonths(stringDate.getStringDate(), 1),
    endDate: null,
    mode: 'month',
  });
  const [multipleMonths, setMultipleMonths] = useState(false);
  const [pullAccountingActuals] = formulasApi.usePullAccountingActualsMutation();

  useEffect(() => {
    if (!multipleMonths) {
      setEndPeriod({
        startDate: startPeriod.startDate ?? stringDate.getStringDate(),
        endDate: startPeriod.startDate ?? stringDate.getStringDate(),
        mode: 'month',
      });
    }
  }, [multipleMonths, startPeriod.startDate]);

  const handlePullActuals = async (): Promise<void> => {
    try {
      if (!startPeriod.startDate || !endPeriod.startDate) throw new Error('Start or end date is not set');
      if (multipleMonths && stringDate.isAfter({ dateToCheck: startPeriod.startDate, comparison: endPeriod.startDate }))
        throw new Error('End month must be after start month');

      // Track the event before making the API call
      posthog.capture('pull_actuals_clicked', {
        startDate: startPeriod.startDate,
        endDate: endPeriod.startDate,
        multipleMonths,
        integrationSource,
        organizationUuid,
        scenarioUuid: activeScenarioUuid,
      });

      dispatch(
        updateIntegrations({ isPulling: true, lastPulled: stringDate.getStringDate(), source: integrationSource }),
      );

      await pullAccountingActuals({
        organizationUuid,
        startDate: startPeriod.startDate,
        endDate: endPeriod.startDate,
        scenarioUuid: activeScenarioUuid ?? undefined,
      });

      handleClose();
    } catch (err) {
      if (err instanceof Error) logger.error(err);
    }
  };

  const handleClose = (): void => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      size="xxs"
    >
      {isPulling ? (
        <div className="flex flex-col items-center w-full py-3">
          <div className="flex flex-col items-center w-[300px] gap-4">
            <div className="flex items-center justify-center gap-1">
              <img src={integrationIconMapping[integrationSource ?? 'quickbooks-online']} className="size-12" />
              <Lottie animationData={LoadingAnimation} className="size-10" />
              <img src={ParallelLogo} className="size-12" />
            </div>
            <div className="flex flex-col items-center gap-1 w-full">
              <Typography size="sm" color="empty" weight="semibold">
                Syncing Your Data
              </Typography>
              <Typography size="sm" color="empty">
                We&apos;ll notify you when it&apos;s complete
              </Typography>
            </div>
            <Button className="!w-full" fill="clear" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-col">
            <div className="flex justify-between items-start">
              <Typography size="lg" weight="bold" color="primary">
                Pull Actuals
              </Typography>
              <Button className="!w-auto !p-0" fill="clear" onClick={handleClose}>
                <XMarkIcon className="size-6" />
              </Button>
            </div>
            <Typography color="secondary" size="sm">
              Overwrite past months data with accounting actuals
            </Typography>
            {lastPulled && (
              <Typography color="empty" size="sm">
                Last pulled: {format(lastPulled, 'MM/dd/yyyy')}
              </Typography>
            )}
            <div className="flex justify-start gap-2 items-center mt-3">
              <PeriodPicker
                id="pull-quickbooks-actuals-start-date"
                state={startPeriod}
                setState={setStartPeriod}
                beBefore={stringDate.subtractMonths(stringDate.getStringDate(), 1)}
                beAfter={companyStartDate}
              />
              {multipleMonths && (
                <>
                  <Typography color="empty">-</Typography>
                  <PeriodPicker
                    id="pull-quickbooks-actuals-end-date"
                    state={endPeriod}
                    setState={setEndPeriod}
                    beBefore={stringDate.subtractMonths(stringDate.getStringDate(), 1)}
                    beAfter={startPeriod.startDate}
                  />
                </>
              )}
            </div>
            <div className="mt-2">
              <Checkbox
                label="Multiple Months"
                checked={multipleMonths}
                toggleValue={() => setMultipleMonths(!multipleMonths)}
              />
            </div>
            <div className="flex justify-between mt-5">
              <Button loading={isPulling} className="!w-auto !px-4 !pl-0" fill="clear" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="!w-auto !px-4" onClick={handlePullActuals} loading={isPulling}>
                Pull Actuals
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PullActualsModal;
