import React from 'react';
import Typography from '~/components/Typography';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import LegacyTable from '~/components/LegacyTable';
import Checkbox from '~/components/Checkbox';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import * as api from '~/services/parallel/index';

interface IDropdownOption {
  label: string;
  onClick: () => void;
  className?: string;
}

interface Props {
  uuid: string;
  name: string;
  email: string;
  status: string;
  isUser?: boolean;
  options: IDropdownOption[];
  receiveMonthlyEmails: boolean;
  userUuid?: string;
}

const FormatTableData = (rows: Props[], isLoading: boolean, getPageData: () => void): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const currentUserUuid = useSelector((state: State) => state.user.uuid);
  const handleToggleMonthlyEmails = async ({ userUuid }: { userUuid?: string }): Promise<void> => {
    try {
      if (userUuid !== currentUserUuid) {
        toast.error('You do not have permission to change monthly emails for this user');
        return;
      }
      const currentReceiveMonthlyEmails = rows.find((row) => row.userUuid === userUuid)?.receiveMonthlyEmails;
      await api.user.updateUserPermission({
        userUuid,
        organizationUuid,
        updateParams: {
          receiveAutomatedEmails: !currentReceiveMonthlyEmails,
        },
      });
      getPageData();
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to update monthly email status');
    }
  };

  return (
    <LegacyTable
      id="org-users-table"
      className="w-full"
      loadingState={{ isLoading, skeletonRows: 15 }}
      headers={[
        <div key="import-header-file-name" id="importFileName" className="p-4">
          Name
        </div>,
        <div key="import-header-email" id="importEmail" className="p-4">
          Monthly Emails
        </div>,
        <div key="import-header-created-by" id="importStatus" className="p-4">
          Status
        </div>,
        <div key="options" />,
      ]}
      autoSpacing={false}
      data={rows.map(({ uuid, name, email, status, isUser, options, receiveMonthlyEmails, userUuid }) => ({
        id: uuid,
        values: [
          {
            value: (
              <div key={uuid} title={name} className="flex flex-col p-4 truncate">
                <Typography>
                  {name} <span className="!text-green-300">{isUser ? ' (you)' : null}</span>
                </Typography>
                <Typography color="empty">{email}</Typography>
              </div>
            ),
          },
          {
            value: (
              <div key={`${uuid}-email`} className="p-4">
                <Checkbox checked={receiveMonthlyEmails} toggleValue={() => handleToggleMonthlyEmails({ userUuid })} />
              </div>
            ),
          },
          {
            value: (
              <div key={`${uuid}-status`} className="p-4">
                <Typography color={status === 'Pending Approval' ? 'special' : 'primary'}>{status}</Typography>
              </div>
            ),
          },
          {
            value: (
              <div key={`${uuid}-options`}>
                {options.length > 0 && <EllipsisDropdown id={`${email}-options`} options={options} />}
              </div>
            ),
          },
        ],
      }))}
    />
  );
};

export default FormatTableData;
