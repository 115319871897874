import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { IOrganizationState } from '~/store/organizationSlice';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import Modal from '~/components/Modal';
import InputWrapper, { useInput } from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import { setPermissions } from '~/store/userSlice';
import { StatusCodes } from 'http-status-codes';
import { isBefore } from 'date-fns';
import { scenarioSlice } from '~/store/scenarioSlice';
import SelectMultiple from '~/components/SelectMultiple';
import useSelectMultiple from '~/components/SelectMultiple/useSelectMultiple';
import { SelectMultipleState, SelectMultipleType } from '~/components/SelectMultiple/SelectMultiple.types';
import { z } from 'zod';
import logger from '~/utils/logger';

interface IOrganizationResponse {
  data: {
    data?: IOrganizationState[];
  };
  status: number;
}

interface IUserPreferencesResponse {
  status: number;
}

interface IHandleSelectOrgParams {
  orgUuid: string;
  orgName: string;
  orgStartDate: string;
  userUuid: string;
}

const SwitchOrgs = (): React.ReactElement | null => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state: State) => state.organization);
  const { uuid, preferences, permissions, role } = useSelector((state: State) => state.user);
  const [createNewOrgOpen, setCreateNewOrgOpen] = useState<boolean>(false);
  const [orgName, setOrgName] = useInput({});
  const [createOrgLoading, setCreateOrgLoading] = useState<boolean>(false);

  // Initialize SelectMultiple state with empty options and multiple=false
  const [orgSelectState, setOrgSelectState] = useSelectMultiple({
    options: [],
    selected: [],
    multiple: false,
  });

  const handleSelectOrg = async (selectParams: IHandleSelectOrgParams): Promise<void> => {
    let defaultGraphStartDate = selectParams.orgStartDate;
    if (preferences.defaultGraphStartDate) {
      const needToChangeDefaultDate = isBefore(preferences.defaultGraphStartDate, selectParams.orgStartDate);
      defaultGraphStartDate = needToChangeDefaultDate ? selectParams.orgStartDate : preferences.defaultGraphStartDate;
    }

    dispatch(scenarioSlice.actions.reset());

    const response = (await request({
      url: `/users/${selectParams.userUuid}/preferences`,
      method: 'PATCH',
      body: {
        sideMenuExpanded: preferences.sideMenuExpanded,
        primaryOrganizationUuid: selectParams.orgUuid,
        defaultGraphStartDate,
      },
    })) as IUserPreferencesResponse;

    if (response.status === StatusCodes.OK) {
      window.location.href = window.location.pathname;
    } else {
      toast.error('Failed to switch organization');
    }
  };

  const handleOrgSelection = (state: SelectMultipleState): void => {
    if (!state.selected?.length) return;

    const selectedOption = state.selected[0];
    if (selectedOption.value === 'create-new-org') {
      setCreateNewOrgOpen(true);
      return;
    }

    const nodeElement = selectedOption.node as React.ReactElement;

    const OrgNodePropsSchema = z.object({
      type: z.any(),
      key: z.any().optional(),
      ref: z.any().optional(),
      props: z.object({
        'data-org-name': z.string(),
        'data-org-uuid': z.string(),
        'data-org-start-date': z.string(),
      }),
    });

    const result = OrgNodePropsSchema.safeParse(nodeElement);
    if (!result.success) {
      logger.error(new Error('Invalid node props:'), result.error);
      return;
    }

    const { props } = result.data;
    handleSelectOrg({
      orgName: props['data-org-name'],
      orgUuid: props['data-org-uuid'],
      orgStartDate: props['data-org-start-date'],
      userUuid: uuid,
    });
  };

  const handleOrgStateChange = (
    newState: SelectMultipleState | ((prev: SelectMultipleState) => SelectMultipleState),
  ): void => {
    // If it's a function, we need to get the actual state
    const actualState = typeof newState === 'function' ? newState(orgSelectState) : newState;

    setOrgSelectState(actualState);
    handleOrgSelection(actualState);
  };

  const transformOrgsToSelectOptions = (organizations: IOrganizationState[]): SelectMultipleType[] => {
    const options = organizations.map((org) => ({
      value: org.uuid,
      label: org.name,
      disabled: org.uuid === selectedOrg.uuid,
      node: React.createElement(
        'div',
        {
          'data-org-name': org.name,
          'data-org-uuid': org.uuid,
          'data-org-start-date': org.configuration.companyStartDate,
        },
        org.name,
      ),
    }));

    // Sort alphabetically by organization name
    options.sort((a, b) => String(a.label).localeCompare(String(b.label)));

    // Add "Create New Org" option for non-user roles
    if (role !== 'user') {
      options.unshift({
        value: 'create-new-org',
        label: 'Create New Org',
        disabled: false,
        node: (
          <div
            className="text-green-500 hover:text-green-600 w-full flex justify-center items-center"
            onClick={(e) => {
              e.stopPropagation();
              setCreateNewOrgOpen(true);
            }}
          >
            Create New Org
          </div>
        ),
      });
    }

    return options;
  };

  const getUserOrgs = async (): Promise<void> => {
    const response = (await request({
      url: '/organizations',
      method: 'GET',
    })) as IOrganizationResponse;

    if (!response.data.data) return;

    const selectMultipleOptions = transformOrgsToSelectOptions(response.data.data);
    const currentOrgOption = selectMultipleOptions.find((opt) => opt.value === selectedOrg.uuid);
    const selectedOptions = currentOrgOption ? [currentOrgOption] : [];

    setOrgSelectState((prev) => ({
      ...prev,
      options: selectMultipleOptions,
      selected: selectedOptions,
      multiple: false,
    }));
  };

  const handleCreateOrg = async (): Promise<void> => {
    if (orgName.validation.test(orgName.value)) {
      setCreateOrgLoading(true);
      const response = (await request({
        url: '/organizations',
        method: 'POST',
        body: {
          name: orgName.value,
        },
      })) as IOrganizationResponse;

      if (response.status === StatusCodes.CREATED) {
        await getUserOrgs();
        dispatch(
          setPermissions({
            ...permissions,
          }),
        );
        setCreateNewOrgOpen(false);
        toast.success('Organization created successfully');
      } else {
        toast.error('Failed to create organization');
      }
      setCreateOrgLoading(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      getUserOrgs();
    }
  }, [uuid]);

  // Don't render if there's only one or no organizations
  if (orgSelectState.options.length <= 1) return null;

  return (
    <>
      <div className="w-full flex justify-center px-2">
        <SelectMultiple
          id="org-selector"
          state={orgSelectState}
          setState={handleOrgStateChange}
          className="w-[250px]"
          placeholder={selectedOrg.name}
          includeSearch={true}
        />
      </div>
      <Modal
        isOpen={createNewOrgOpen}
        onClose={() => setCreateNewOrgOpen(false)}
        title="Create Organization"
        id="create-org-modal"
      >
        <div className="flex flex-col gap-4 w-full">
          <InputWrapper
            label="Organization Name"
            type="text"
            state={orgName}
            setState={setOrgName}
            id="create-org-name"
          />
          <div className="flex w-full justify-between items-center">
            <Button fill="destructiveClear" className="!w-fit" onClick={() => setCreateNewOrgOpen(false)}>
              Cancel
            </Button>
            <Button
              fill="solid"
              className="!w-fit"
              onClick={handleCreateOrg}
              id="create-org-button"
              loading={createOrgLoading}
            >
              Create Org
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SwitchOrgs;
