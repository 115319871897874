import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IConversation, IMessageRoleEnum } from '~/pages/Chat/entity/types';
import AssistantMessage from './AssistantMessage';
import UserMessage from './UserMessage';
import { useInput } from '~/components/Input';
import Input from '~/components/Input/InputWrapper';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ChatContext } from '../../context/ChatContext';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import ResponseLoading from './ResponseLoading';
import ErrorMessage from './ErrorMessage';
import RecommendedPrompts from '../RecommendedPrompts';
import NextPrompts from './NextPrompts';
import MissingBusinessModelWarning from './MissingBusinessModelWarning';
import DisplayAttributesContainer from '../DisplayAttributesContainer';
import RevenueModelerPrompt from './RevenueModelerPrompt';
import { useFeatureFlagPosthog } from '~/utils/hooks/useFeatureFlag';

const Conversation = (): React.ReactElement | null => {
  const {
    selectedConversationUuid,
    allConversationsDict,
    sendMessage,
    conversationUuidsLoading,
    focusTextInput,
    businessContext,
  } = useContext(ChatContext);
  const [newMessage, setNewMessage] = useInput({ validation: /.*/ });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [selectedConversation, setSelectedConversation] = useState<IConversation | null>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const showMissingBusinessModelWarning = useMemo((): boolean => {
    return (
      !businessContext.context.companyDescription &&
      !businessContext.context.companyUrl &&
      !businessContext.context.keyGoals &&
      !businessContext.context.offeringDetails &&
      !businessContext.context.fundingHistory
    );
  }, [businessContext]);
  const showRevenueModelerPrompt = useFeatureFlagPosthog('revenue_modeler_prompt');

  useEffect(() => {
    if (selectedConversationUuid && selectedConversationUuid in allConversationsDict) {
      setSelectedConversation(allConversationsDict[selectedConversationUuid]);
    } else {
      setSelectedConversation(null);
    }
  }, [selectedConversationUuid, allConversationsDict]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusTextInput]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = '48px';
      const minHeight = 48; // Default height of the input
      const maxHeight = 850; // Arbitrary max height based on when it feels like it's too tall
      const height = Math.min(Math.max(minHeight, inputRef.current.scrollHeight), maxHeight);
      inputRef.current.style.height = `${height}px`;
    }
  }, [newMessage.value]);

  const handleSendMessage = async (): Promise<void> => {
    if (newMessage.value.trim()) {
      const message = newMessage.value;
      setNewMessage((prev) => ({ ...prev, value: '' }));
      await sendMessage({ conversationUuid: selectedConversationUuid, message });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const sendRecommendedPrompt = async (prompt: string): Promise<void> => {
    await sendMessage({ conversationUuid: selectedConversationUuid, message: prompt });
  };

  const relatedFormulasToDisplay = useMemo(() => {
    if (!selectedConversation) {
      return [];
    }

    const latestMessage = selectedConversation.messages[selectedConversation.messages.length - 1];

    if (latestMessage.responseMetadata && latestMessage.responseMetadata.requiredFormulas.length > 0) {
      return latestMessage.responseMetadata.requiredFormulas;
    }

    return [];
  }, [selectedConversation]);

  return (
    <div className="flex flex-col items-center flex-1 pt-10 px-10 pb-5 max-h-screen">
      <div className="w-full flex  flex-col flex-1 overflow-y-auto items-center no-scrollbar pb-2">
        <div className="w-full flex flex-col gap-8 flex-1 pb-10 max-w-[780px]">
          {selectedConversation ? (
            <>
              {selectedConversation.messages.reduce((elements, message, index) => {
                const key = `${index}-${message.role}-${message.content}`;

                if (message.role === IMessageRoleEnum.User) {
                  elements.push(<UserMessage message={message.content} key={key} />);
                } else {
                  elements.push(<AssistantMessage message={message.content} key={key} />);
                }

                if (
                  index === selectedConversation.messages.length - 1 &&
                  selectedConversation.status === 'error' &&
                  !conversationUuidsLoading.includes(selectedConversationUuid ?? '')
                ) {
                  elements.push(
                    <ErrorMessage
                      message={'Hmm...something seems to have gone wrong.'}
                      previousMessage={message.content}
                      conversationUuid={selectedConversationUuid}
                      key={`${key}-error`}
                    />,
                  );
                }

                return elements;
              }, [] as React.ReactElement[])}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-full gap-3">
              <>
                <img src={ParallelLogo} alt="Parallel Logo" className="size-10" />
                <div className="flex flex-col items-center justify-center">
                  <Typography size="lg" weight="bold">
                    Parallel Copilot
                  </Typography>
                  <Typography size="sm" color="secondary">
                    How can we help?
                  </Typography>
                  {showMissingBusinessModelWarning && <MissingBusinessModelWarning />}
                  {showRevenueModelerPrompt && <RevenueModelerPrompt />}
                  {!showRevenueModelerPrompt && !showMissingBusinessModelWarning && (
                    <RecommendedPrompts sendRecommendedPrompt={sendRecommendedPrompt} />
                  )}
                </div>
              </>
            </div>
          )}
          <div ref={lastMessageRef} />
          {conversationUuidsLoading.includes(selectedConversationUuid ?? '') && <ResponseLoading />}
          {relatedFormulasToDisplay.length > 0 &&
            !conversationUuidsLoading.includes(selectedConversationUuid ?? '') && (
              <DisplayAttributesContainer relatedFormulas={relatedFormulasToDisplay} />
            )}
        </div>
        {selectedConversation?.nextPrompts && !conversationUuidsLoading.includes(selectedConversationUuid ?? '') && (
          <div className="max-w-[780px] w-full">
            <NextPrompts nextPrompts={selectedConversation.nextPrompts} conversationUuid={selectedConversationUuid} />
          </div>
        )}
      </div>
      <div className="w-full h-fit shrink-0 max-w-[780px]">
        <div
          className={`flex items-end justify-between w-full h-fit py-1 border rounded-xl px-3 ${
            isFocused
              ? 'border-green-500 ring-2 ring-white ring-opacity-75 ring-offset-2 ring-offset-green-300'
              : 'border-neutral-100'
          }`}
        >
          <Input
            id="new-message"
            type="textarea"
            ref={inputRef}
            state={newMessage}
            setState={setNewMessage}
            className="h-[48px] resize-none !border-none !border-0 !ring-0 !ring-offset-0 !outline-0 !shadow-none !no-scrollbar !rounded-xl leading-[28px]"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={conversationUuidsLoading.includes(selectedConversationUuid ?? '')}
            onKeyDown={handleKeyDown}
            placeholder="Ask a question"
          />
          <Button
            className="mb-1.5 !p-1 !h-fit !w-fit rounded-lg"
            disabled={!newMessage.value || conversationUuidsLoading.includes(selectedConversationUuid ?? '')}
            onClick={handleSendMessage}
          >
            <PaperAirplaneIcon className="size-6" />
          </Button>
        </div>
        <Typography color="disabled">
          Copilot is getting smarter every day — please double-check important details.
        </Typography>
      </div>
    </div>
  );
};

export default Conversation;
