import { IGraphData, ZGraphData } from '~/services/parallel/reports.types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export interface IPageData {
  reportData: IGraphData;
}

export const pageData = async ({
  organizationUuid,
  accessTokenOverride,
}: {
  organizationUuid: string;
  accessTokenOverride?: string | null;
}): Promise<IPageData> => {
  const reportsResponsePromise = request({
    url: `/reports/consolidate`,
    method: 'GET',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
    accessTokenOverride,
  }) as Promise<IAPIResponse<IGraphData>>;

  const [reportsResponse] = await Promise.all([reportsResponsePromise]);

  const parsedReportsData = ZGraphData.parse(reportsResponse.data.data);

  return {
    reportData: parsedReportsData,
  };
};
