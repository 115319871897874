import React, { useEffect } from 'react';
import { IUpdatePositionRequestBody } from '~/services/parallel/api/positions/positionsRequestSchemas';
import { useSelect } from '~/components/Select';
import SelectDepartment from '~/components/SelectDepartment';
import toast from 'react-hot-toast';
import { IPositionDetails } from '~/pages/Headcount/entity/types';

const CellDepartment = React.memo(
  ({
    id,
    isActive,
    isScenario,
    onUpdate,
    value,
    valueUuid,
    positionUuid,
  }: {
    id: string;
    isActive: boolean;
    isScenario: boolean;
    onUpdate: ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
    }: {
      positionUuid: string;
      positionUpdates: IUpdatePositionRequestBody;
      awaitCalculations?: boolean;
    }) => Promise<{ data: IPositionDetails; headers: Record<string, string> }>;
    value: string;
    valueUuid: string;
    positionUuid: string;
  }): React.ReactNode => {
    const [selectDepartment, setSelectDepartment] = useSelect({
      selected: {
        value: valueUuid,
        label: value,
      },
      options: [],
    });

    const onBlur = async (): Promise<void> => {
      try {
        if (selectDepartment.selected?.value && selectDepartment.selected.value !== value) {
          await onUpdate({ positionUuid, positionUpdates: { [id]: selectDepartment.selected.value } });
        }
      } catch (error) {
        toast.error('Failed to update department');
        setSelectDepartment((prev) => ({
          ...prev,
          selected: { value: valueUuid, label: value },
        }));
      }
    };

    useEffect(() => {
      if (selectDepartment.selected?.value !== valueUuid) {
        onBlur();
      }
    }, [selectDepartment.selected]);

    return (
      <div className="w-44 group/department" data-testid={`${id}-${positionUuid}`}>
        <div
          className={`text-nowrap overflow-hidden text-ellipsis ${!isActive ? ' text-neutral-100 cursor-default' : ' group-hover/department:hidden'} 
      ${isScenario && 'text-blue-400'}`}
        >
          {selectDepartment.selected?.label ?? '-'}
        </div>
        <div
          className={`hidden ${isActive && 'group-hover/department:block'}`}
          data-testid={`${id}-${positionUuid}-selector`}
        >
          <SelectDepartment
            departmentOptions={selectDepartment}
            setDepartmentOptions={setSelectDepartment}
            id={`department-${positionUuid}`}
          />
        </div>
      </div>
    );
  },
);

CellDepartment.displayName = 'CellDepartment';

export default CellDepartment;
