import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  IEmploymentType,
  EMPLOYMENT_TYPE_OPTIONS,
  ZCreatePositionForm,
  ICreatePositionForm,
} from '~/services/parallel/headcount.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import Input from '~/components/UncontrolledComponents/Input';
import { Select } from '~/components/UncontrolledComponents/Select';
import { SelectDepartment } from '~/components/UncontrolledComponents/SelectDepartment';
import DatePicker from '~/components/DatePicker';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import toast from 'react-hot-toast';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import Button from '~/components/Button';
import { QuestionMarkCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { IOnboardingStepNameEnum, IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';
import Typography from '~/components/Typography';

const InlineCreate = ({
  columnWidths,
  organizationUuid,
  scenarioUuid,
  setPositionIndexes,
  isCreatingInline,
  setIsCreatingInline,
  listParams,
}: {
  columnWidths: Record<string, number>;
  organizationUuid: string;
  scenarioUuid?: string | null;
  setPositionIndexes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isCreatingInline: boolean;
  setIsCreatingInline: React.Dispatch<React.SetStateAction<boolean>>;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  const [createPositions] = positionsApi.useCreatePositionsMutation();
  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery();
  const [updateOnboardingStep] = onboardingStepsApi.useUpdateOnboardingStepMutation();

  const {
    control,
    handleSubmit,
    watch,
    register,
    trigger,
    formState: { errors },
    reset,
  } = useForm<ICreatePositionForm>({
    defaultValues: {
      employeeName: undefined,
      title: '',
      employmentType: IEmploymentType.FullTime,
      departmentUuid: undefined,
      payRate: undefined,
      hireDate: undefined,
    },
    resolver: zodResolver(ZCreatePositionForm.omit({ numberToCreate: true })),
    mode: 'all',
    shouldFocusError: false,
  });

  const onSubmitForm = handleSubmit(async (data) => {
    if (!isCreatingInline) return;
    try {
      const creationData = {
        employeeName: data.employeeName ?? null,
        title: data.title,
        employmentType: data.employmentType,
        departmentUuid: data.departmentUuid,
        payRate: [{ value: Number(data.payRate) * CENTS_PER_DOLLAR, effectiveAt: 'onHire' }],
        hireDate: data.hireDate,
      };

      const query: {
        scenarioUuid?: string;
        numberToCreate: number;
      } = {
        numberToCreate: 1,
      };

      if (scenarioUuid) query.scenarioUuid = scenarioUuid;

      await createPositions({
        params: {
          orgUuid: organizationUuid,
        },
        query,
        body: creationData,
        listParams,
        updateIndexes: setPositionIndexes,
      }).unwrap();

      setIsCreatingInline(false);
      reset(
        {
          employeeName: undefined,
          title: '',
          employmentType: IEmploymentType.FullTime,
          departmentUuid: undefined,
          payRate: undefined,
          hireDate: undefined,
        },
        {
          keepErrors: false,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        },
      );

      const onboardingStep = onboardingSteps.data?.dictionary[IOnboardingStepNameEnum.AddHeadcount];
      if (onboardingStep && onboardingStep.status === IOnboardingStepStatusEnum.Ready) {
        await updateOnboardingStep({
          onboardingStepUuid: onboardingStep.uuid,
          body: {
            status: IOnboardingStepStatusEnum.Active,
          },
        });
      }

      toast.success('Position created successfully');
    } catch (error) {
      toast.error('Failed to create position. Please try again.');
    }
  });

  const hireDate = watch('hireDate');
  useEffect(() => {
    if (hireDate) {
      onSubmitForm();
    }
  }, [hireDate]);

  useEffect(() => {
    if (!isCreatingInline) {
      reset(
        {
          employeeName: undefined,
          title: '',
          employmentType: IEmploymentType.FullTime,
          departmentUuid: undefined,
          payRate: undefined,
          hireDate: undefined,
        },
        {
          keepErrors: false,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        },
      );
    }
  }, [isCreatingInline]);

  return (
    <div className="flex w-full pb-96">
      <div className="flex flex-row w-full">
        <div style={{ minWidth: `${columnWidths.isActive}px`, width: `${columnWidths.isActive}px` }} />
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.employeeName}px`, width: `${columnWidths.employeeName}px` }}
        >
          {isCreatingInline ? (
            <Input
              {...register('employeeName')}
              id="inline-employeeName"
              type="text"
              error={errors.employeeName?.message}
              onBlur={onSubmitForm}
              placeholder="Name (optional)"
            />
          ) : (
            <Button
              id="create-position-button-inline"
              fill="clear"
              className="!w-fit !px-4 !py-[9px] leading-5 whitespace-nowrap"
              onClick={() => setIsCreatingInline(true)}
            >
              Add Position
            </Button>
          )}
        </div>
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.title}px`, width: `${columnWidths.title}px` }}
        >
          {isCreatingInline && (
            <Input
              {...register('title')}
              id="inline-title"
              type="text"
              placeholder="Title"
              error={errors.title?.message}
              onBlur={onSubmitForm}
            />
          )}
        </div>
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.employmentType}px`, width: `${columnWidths.employmentType}px` }}
        >
          {isCreatingInline && (
            <Controller
              control={control}
              name="employmentType"
              render={({ field, fieldState }) => (
                <Select
                  id="inline-employmentType"
                  options={EMPLOYMENT_TYPE_OPTIONS}
                  error={fieldState.error?.message}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  onBlur={onSubmitForm}
                />
              )}
            />
          )}
        </div>
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.departmentUuid}px`, width: `${columnWidths.departmentUuid}px` }}
        >
          {isCreatingInline && (
            <Controller
              control={control}
              name="departmentUuid"
              render={({ field, fieldState }) => (
                <SelectDepartment
                  id="inline-departmentUuid"
                  displayLabel={false}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  error={fieldState.error?.message}
                  onBlur={onSubmitForm}
                />
              )}
            />
          )}
        </div>
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.payRate}px`, width: `${columnWidths.payRate}px` }}
        >
          {isCreatingInline && (
            <Controller
              control={control}
              name="payRate"
              render={({ field, fieldState }) => (
                <Input
                  id="inline-payRate"
                  type="currency"
                  placeholder="Annual Comp."
                  includeDollarSign
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger('payRate');
                  }}
                  error={fieldState.error?.message}
                  onBlur={onSubmitForm}
                  info={{
                    infoContent: (
                      <div className="flex flex-col items-start">
                        <Typography color="white" weight="semibold">
                          Total Annual Compensation (Est.)
                        </Typography>
                        <Typography color="white">
                          Include salary, bonuses, and commissions. Estimate total annual pay for hourly employees.
                        </Typography>
                      </div>
                    ),
                    infoIcon: <QuestionMarkCircleIcon className="size-[22px] text-neutral-300" />,
                    infoButtonClassName: 'cursor-pointer',
                    infoPanelClassName: 'bg-black w-[350px] max-w-[350px] rounded-md px-4 py-2',
                    iconPositioning: 'top-2 right-1',
                  }}
                />
              )}
            />
          )}
        </div>
        <div
          style={{ minWidth: `${columnWidths.fullyBurdenedCost}px`, width: `${columnWidths.fullyBurdenedCost}px` }}
        />
        <div
          className="px-4 py-[5px]"
          style={{ minWidth: `${columnWidths.hireDate}px`, width: `${columnWidths.hireDate}px` }}
        >
          {isCreatingInline && (
            <Controller
              control={control}
              name="hireDate"
              render={({ field, fieldState }) => {
                // Create a default state for the date picker from the current field value
                const defaultDatePickerState: IDatePickerState = {
                  value: {
                    startDate: field.value,
                    endDate: field.value,
                  },
                  valid: !fieldState.error,
                  errorMessage: fieldState.error?.message,
                  touched: !!fieldState.error, // Mark as touched if there's an error
                  pristine: !fieldState.error, // Mark as not pristine if there's an error
                };

                // Wrap the setter so it supports both functional and direct updates
                const customSetDatePickerState: React.Dispatch<React.SetStateAction<IDatePickerState>> = (action) => {
                  const newState = typeof action === 'function' ? action(defaultDatePickerState) : action;
                  // Update the form field with the new start date value (adjust if needed)
                  field.onChange(newState.value.startDate);
                  trigger('hireDate');
                };

                return (
                  <DatePicker
                    id="inline-hireDate"
                    required
                    state={defaultDatePickerState}
                    setState={customSetDatePickerState}
                  />
                );
              }}
            />
          )}
        </div>
        <div style={{ minWidth: `${columnWidths.termDate}px`, width: `${columnWidths.termDate}px` }} />
        <div
          className="flex justify-center"
          style={{ minWidth: `${columnWidths.ellipsisDropdown}px`, width: `${columnWidths.ellipsisDropdown}px` }}
        >
          {isCreatingInline && (
            <TrashIcon
              className="size-[18px] hover:text-red-500 text-neutral-100 cursor-pointer my-3.5"
              onClick={() => {
                reset(
                  {
                    employeeName: undefined,
                    title: '',
                    employmentType: IEmploymentType.FullTime,
                    departmentUuid: undefined,
                    payRate: undefined,
                    hireDate: undefined,
                  },
                  {
                    keepErrors: false,
                    keepDirty: false,
                    keepIsSubmitted: false,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                  },
                );
                setIsCreatingInline(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InlineCreate;
