import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import Loom from './LoomExtension';
import { Markdown } from 'tiptap-markdown';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Heading from '@tiptap/extension-heading';
import TextAlign from '@tiptap/extension-text-align';
import { updatePendingChanges } from '~/store/drawerContentSlice';
import { useDispatch } from 'react-redux';
import { IPostWithReactions } from '~/services/parallel/api/helpCenter/requestSchemas';
import Toolbar from './Toolbar';
interface Props {
  isEditing: boolean;
  post: IPostWithReactions;
}

const Editor: React.FC<Props> = ({ isEditing, post }) => {
  const dispatch = useDispatch();

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
      }),
      Markdown.configure({
        transformPastedText: true,
        transformCopiedText: true,
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'text-blue-500 hover:underline',
        },
      }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right'],
      }),
      Loom,
    ],
    content: post.content || '',
    editable: isEditing,
    editorProps: {
      attributes: {
        class: `prose prose-neutral focus:outline-none min-h-[200px] ${isEditing ? 'bg-white border rounded-lg' : 'bg-transparent'}`,
      },
    },
    onUpdate: ({ editor }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      const content = editor.storage.markdown.getMarkdown();
      dispatch(
        updatePendingChanges({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          content,
        }),
      );
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      <EditorContent editor={editor} />
      <Toolbar editor={editor} />
    </>
  );
};

export default Editor;
