import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from './baseQuery';
import type { IDepartment } from '../departments.types';

interface IDepartmentsGetResponse {
  list: IDepartment[];
  dictionary: Record<string, IDepartment>;
}

interface IDepartmentsGetParams {
  orgUuid: string;
  scenarioUuid?: string;
}

export const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Department'],
  endpoints: (builder) => ({
    getDepartments: builder.query<IDepartmentsGetResponse, IDepartmentsGetParams>({
      query: ({ orgUuid, scenarioUuid }) => ({
        url: `organizations/${orgUuid}/groups`,
        params: {
          scenarioUuid,
        },
      }),
      transformResponse: (response: { data: IDepartment[] }) => {
        const dictionary = response.data.reduce(
          (acc, dept) => ({
            ...acc,
            [dept.departmentUuid]: dept,
          }),
          {} as Record<string, IDepartment>,
        );

        return {
          list: response.data,
          dictionary,
        };
      },
      providesTags: ['Department'],
    }),
  }),
});

export const { useGetDepartmentsQuery } = departmentsApi;
