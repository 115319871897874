import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';
import { IStringDate } from '~/utils/stringDate/types';
import { roundCalculatedValue } from '~/utils/roundCalculatedValue';
import * as stringDate from '~/utils/stringDate';

export const generateOverridesAndActuals = ({
  value,
  changeDate,
  consolidatedGraphData,
  activeScenarioUuid,
}: {
  value: number;
  changeDate: IStringDate;
  consolidatedGraphData: IConsolidatedGraphBody;
  activeScenarioUuid: string | null;
}): {
  overrides: {
    value: number;
    date: IStringDate;
  }[];
  actuals: {
    value: number;
    date: IStringDate;
  }[];
} => {
  let overrides: { date: IStringDate; value: number }[] = [];
  let actuals: { date: IStringDate; value: number }[] = [];

  if (
    activeScenarioUuid &&
    consolidatedGraphData.dataOverrides &&
    'activeScenario' in consolidatedGraphData.dataOverrides &&
    consolidatedGraphData.dataActuals &&
    'activeScenario' in consolidatedGraphData.dataActuals
  ) {
    overrides = consolidatedGraphData.dataOverrides['activeScenario'];
    actuals = consolidatedGraphData.dataActuals['activeScenario'];
  }
  if (
    !activeScenarioUuid &&
    consolidatedGraphData.dataOverrides &&
    'workingModel' in consolidatedGraphData.dataOverrides &&
    consolidatedGraphData.dataActuals &&
    'workingModel' in consolidatedGraphData.dataActuals
  ) {
    overrides = consolidatedGraphData.dataOverrides['workingModel'];
    actuals = consolidatedGraphData.dataActuals['workingModel'];
  }
  if (stringDate.isBefore({ dateToCheck: changeDate, comparison: stringDate.getStringDate() })) {
    const actualExistsIndex = actuals.findIndex((actual) =>
      stringDate.isSameMonth({ date1: actual.date, date2: changeDate }),
    );
    if (actualExistsIndex !== -1) {
      actuals[actualExistsIndex].value = value;
    } else {
      actuals.push({ value, date: changeDate });
    }
    return {
      overrides,
      actuals,
    };
  } else {
    const overrideExistsIndex = overrides.findIndex((override) =>
      stringDate.isSameMonth({ date1: override.date, date2: changeDate }),
    );
    const roundedValue = roundCalculatedValue({
      value,
      roundingInstructions: consolidatedGraphData.roundingInstructions,
    });
    if (overrideExistsIndex !== -1) {
      overrides[overrideExistsIndex].value = roundedValue;
    } else {
      overrides.push({ value: roundedValue, date: changeDate });
    }
    return {
      overrides: overrides.map((override) => {
        return {
          ...override,
          date: stringDate.getISO8601EndOfMonth(override.date),
        };
      }),
      actuals: actuals.map((actual) => {
        return {
          ...actual,
          date: stringDate.getISO8601EndOfMonth(actual.date),
        };
      }),
    };
  }
};
