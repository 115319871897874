import React from 'react';
import Typography from '~/components/Typography';
import finishGeneratingExpenses from '~/assets/finishGeneratingExpenses.svg';

export const FinalizeSection = (): React.ReactNode => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col items-center max-w-[430px] text-center mt-[20vh]">
        <img src={finishGeneratingExpenses} alt="finalizeExpenses" className="w-[230px] h-auto mb-6 mt-14" />
        <Typography weight="bold">All Expenses Forecasted</Typography>
        <Typography>{`We'll continually refine your forecast as additional details and complexity is added`}</Typography>
      </div>
    </div>
  );
};
