import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IHelpCenterPostCategory } from '~/services/parallel/api/helpCenter/requestSchemas';

export interface HelpPage {
  id: string;
  title: string;
  content: string;
  isPublished: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
}

export type DrawerTab = 'help-center' | 'notifications' | 'settings' | 'onboarding';

export interface PendingChanges {
  title?: string;
  content?: string | null;
  heroImageUrl?: string | null;
  category?: IHelpCenterPostCategory | null;
}

export interface DrawerContentState {
  isExpanded: boolean;
  activeTab: DrawerTab | null;
  helpCenter: {
    currentPageId: string | null;
    isEditing: boolean;
    pendingChanges: PendingChanges | null;
  };
}

const initialState: DrawerContentState = {
  isExpanded: false,
  activeTab: null,
  helpCenter: {
    currentPageId: null,
    isEditing: false,
    pendingChanges: null,
  },
};

export const drawerContentSlice = createSlice({
  name: 'drawerContent',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      if (!state.isExpanded) {
        state.activeTab = 'help-center';
      } else {
        state.activeTab = null;
      }
      state.isExpanded = !state.isExpanded;
    },
    setActiveTab: (state, action: PayloadAction<DrawerTab | null>) => {
      state.activeTab = action.payload;
      state.isExpanded = action.payload !== null;
    },
    setCurrentHelpPage: (state, action: PayloadAction<string | null>) => {
      state.helpCenter.currentPageId = action.payload;
      // Reset editing state when changing pages
      state.helpCenter.isEditing = false;
      state.helpCenter.pendingChanges = null;
    },
    setHelpCenterEditing: (state, action: PayloadAction<boolean>) => {
      state.helpCenter.isEditing = action.payload;
      if (!action.payload) {
        state.helpCenter.pendingChanges = null;
      }
    },
    updatePendingChanges: (state, action: PayloadAction<PendingChanges>) => {
      state.helpCenter.pendingChanges = {
        ...state.helpCenter.pendingChanges,
        ...action.payload,
      };
    },
    clearPendingChanges: (state) => {
      state.helpCenter.pendingChanges = null;
    },
    reset: () => initialState,
  },
});

export const {
  toggleDrawer,
  setActiveTab,
  setCurrentHelpPage,
  setHelpCenterEditing,
  updatePendingChanges,
  clearPendingChanges,
  reset,
} = drawerContentSlice.actions;

export default drawerContentSlice.reducer;
