import React, { useState } from 'react';
import { IChangeLogElement, IChangeLogElementTypeEnum, IChangeLogElementWithIndex } from '../entity/types';
import Typography from '~/components/Typography';
import { format } from 'date-fns/format';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import { IFormula } from '~/services/parallel/formulas.types';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import ChangesTable from './ChangesTable';
import Button from '~/components/Button';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ConfirmPrompt from '~/components/ConfirmPrompt';

const ChangeLogElement = ({
  changeLogElement,
  formulaDictionary,
  isSubElement = false,
  handleRollback,
}: {
  changeLogElement: IChangeLogElementWithIndex;
  formulaDictionary: Record<string, IFormula>;
  isSubElement?: boolean;
  handleRollback?: ({ rollbackIndex, rollbackDate }: { rollbackIndex: number; rollbackDate: string }) => Promise<void>;
}): React.ReactElement => {
  const [showChanges, setShowChanges] = useState<boolean>(false);
  const { role } = useSelector((state: State) => state.user);
  const scenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [isConfirmRollbackModalOpen, setIsConfirmRollbackModalOpen] = useState<boolean>(false);

  return (
    <div
      className={`flex flex-col w-full group/change-log ${((!isSubElement && role !== 'support') || scenarioUuid) && 'mb-2'}`}
    >
      <div
        className={`py-2 px-4 ${isSubElement ? 'bg-white border border-green-100 ' : 'bg-green-15 border border-green-100 '} rounded-xl flex flex-col shadow-[0px_1px_2px_0px_rgba(0,_0,_0,_0.10)]`}
      >
        <div
          className="flex justify-between h-full w-full items-center cursor-pointer"
          onClick={() => setShowChanges(!showChanges)}
        >
          {changeLogElement.type === IChangeLogElementTypeEnum.ChangeLogGroup ? (
            <div className="flex flex-col">
              <div className="flex gap-1">
                <Typography weight="bold">{changeLogElement.title} </Typography>
              </div>
              <Typography color="secondary" className="!leading-4">
                Changed by {changeLogElement.changedBy?.name ?? 'system'} on {format(changeLogElement.planDate, 'Pp')}
              </Typography>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex gap-1">
                <Typography weight="bold">
                  {camelCaseToNormal(changeLogElement.action)} {camelCaseToNormal(changeLogElement.type)}:{' '}
                </Typography>
                <Typography>{changeLogElement.title} </Typography>
              </div>
              <Typography color="secondary" className="!leading-4">
                Changed by {changeLogElement.changedBy?.name ?? 'system'} on {format(changeLogElement.planDate, 'Pp')}
              </Typography>
            </div>
          )}
          <ChevronRightIcon className={`w-4 h-4 transition-transform duration-100 ${showChanges && 'rotate-90'}`} />
        </div>
        {showChanges && (
          <div>
            {changeLogElement.changes.length ? (
              <div className="mt-2">
                <ChangesTable changes={changeLogElement.changes} formulaDictionary={formulaDictionary} />
              </div>
            ) : null}
            {changeLogElement.subElements.length ? (
              <div className="flex flex-col mt-2 gap-2">
                {changeLogElement.subElements.map((subElement: IChangeLogElement) => (
                  <ChangeLogElement
                    key={subElement.originalVersionUuid + subElement.newVersionUuid + subElement.action}
                    changeLogElement={subElement}
                    formulaDictionary={formulaDictionary}
                    isSubElement={true}
                  />
                ))}
              </div>
            ) : null}
          </div>
        )}
      </div>
      {!isSubElement &&
        !scenarioUuid &&
        role === 'support' &&
        typeof changeLogElement.index === 'number' &&
        handleRollback && (
          <Button
            onClick={() => setIsConfirmRollbackModalOpen(true)}
            fill="clear"
            className="!w-fit !px-2 !py-0.5 text-xs !max-h-2 opacity-0 overflow-hidden transition-all duration-200 group-hover/change-log:opacity-100 group-hover/change-log:!max-h-[22px]"
          >
            {`<< Rollback to this point`}
          </Button>
        )}
      <ConfirmPrompt
        isOpen={isConfirmRollbackModalOpen}
        onClose={() => setIsConfirmRollbackModalOpen(false)}
        title={`Rollback to ${format(changeLogElement.planDate, 'Pp')}`}
        message={
          <span>
            This rollback will<b> PERMANENTLY </b>revert your organization to the state at the selected point. All data
            added or updated in the working model and scenarios after this point will be permanently deleted and
            <b> CANNOT BE RECOVERED</b>. Are you sure you want to proceed?
          </span>
        }
        confirmButtonText="Confirm Rollback"
        modalSize="md"
        onConfirm={() => {
          if (handleRollback) {
            handleRollback({
              rollbackIndex: changeLogElement.index as number,
              rollbackDate: changeLogElement.planDate,
            });
          }
        }}
      />
    </div>
  );
};

export default ChangeLogElement;
