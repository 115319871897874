import React from 'react';
import ReactDOM from 'react-dom/client';
import { PostHogProvider } from 'posthog-js/react';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY as string}
      options={{ api_host: 'https://a.getparallel.com', ui_host: 'https://us.posthog.com' }}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
