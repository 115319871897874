import React from 'react';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { IPositionDetails } from '~/pages/Headcount/entity/types';

const CellOptions = ({
  position,
  onEdit,
  onDelete,
  onPayChange,
}: {
  position: IPositionDetails;
  onEdit: () => void;
  onDelete: () => void;
  onPayChange: () => void;
}): React.ReactNode => {
  return (
    <div>
      <EllipsisDropdown
        id={`headcount-ellipsis-dropdown-${position.positionUuid}`}
        options={[
          {
            label: 'Edit',
            onClick: onEdit,
            disabled: !position.isActive,
          },
          {
            label: 'Pay Change',
            onClick: onPayChange,
            disabled: !position.isActive,
          },
          {
            label: 'Term',
            onClick: (): void => {
              const termDateElement: HTMLElement | null = document.querySelector(
                `[data-testid="termDate-${position.positionUuid}-container"]`,
              );
              if (termDateElement) {
                termDateElement.click();
              }
            },
            disabled: !position.isActive,
          },
          {
            label: 'Delete',
            className: 'text-red-500',
            onClick: onDelete,
          },
        ]}
      />
    </div>
  );
};

export default CellOptions;
