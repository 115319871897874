import React, { useContext, useEffect, useRef, useState } from 'react';
import { IPositionDetails } from '../../entity/types';
import * as stringDate from '~/utils/stringDate';
import TogglePosition from '../TogglePosition';
import Typography from '~/components/Typography';
import formatCurrency from '~/utils/formatCurrency';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { TimelineContext } from '~/components/Timeline/TimelineContext';

const HeadcountTimelineNodeContent = ({
  position,
  hideSensitiveData,
  onEditPosition,
  onPayChange,
  onDeletePosition,
  onTogglePosition,
  onEditTermDate,
}: {
  position: IPositionDetails;
  hideSensitiveData: boolean;
  onEditPosition: () => void;
  onPayChange: () => void;
  onDeletePosition: () => void;
  onTogglePosition: (isActive: boolean) => Promise<void>;
  onEditTermDate: () => void;
}): React.ReactNode => {
  const { selection } = useContext(TimelineContext);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current, position]);

  const isSelected = selection.selected.includes(position.positionUuid);

  return (
    <>
      <div ref={containerRef} className="w-full flex group text-nowrap pl-3">
        <div className="flex-grow flex relative" style={{ maxWidth: containerWidth - 36 }}>
          <div className="left-0 flex-nowrap flex sticky gap-2.5 items-center">
            {(stringDate.isAfter({ dateToCheck: position.hireDate, comparison: stringDate.getStringDate() }) ||
              !position.isActive) && (
              <div onClick={(e) => e.stopPropagation()} className="position-toggle">
                <TogglePosition
                  positionUuid={position.positionUuid}
                  isActive={position.isActive}
                  onToggle={onTogglePosition}
                />
              </div>
            )}
            <Typography
              weight="semibold"
              color={position.isActive ? 'primary' : 'disabled'}
              className={`${!position.isActive && 'line-through'}`}
            >
              {position.title}
            </Typography>
            {position.employeeName && (
              <Typography
                color={position.isActive ? 'primary' : 'disabled'}
                className={`${!position.isActive && 'line-through'}`}
              >
                {position.employeeName}
              </Typography>
            )}
            {!hideSensitiveData && (
              <Typography
                color={position.isActive ? 'primary' : 'disabled'}
                className={`${!position.isActive && 'line-through'}`}
              >
                {formatCurrency(position.currentPayRate.value, false)}
              </Typography>
            )}
          </div>
        </div>
        <div className="sticky right-0 flex-none font-medium">
          <EllipsisDropdown
            className={`pr-1 ${isSelected ? '!bg-green-15' : 'bg-white'} ${position.scenarioUuid && '!bg-blue-15'}`}
            options={[
              {
                label: 'Edit',
                onClick: onEditPosition,
              },
              {
                label: 'Pay Change',
                onClick: onPayChange,
              },
              {
                label: 'Term',
                onClick: onEditTermDate,
              },
              {
                label: 'Delete',
                onClick: onDeletePosition,
                className: 'text-red-500',
              },
            ]}
            id={`${position.positionUuid}-ellipsis-dropdown`}
          />
        </div>
      </div>
    </>
  );
};

export default HeadcountTimelineNodeContent;
