import React from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import {
  CodeBracketIcon,
  LinkIcon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  BoldIcon,
} from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';

const AlignLeftIcon = ({ className = 'h-4 w-4' }: { className?: string }): React.ReactNode => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M15 12H3"></path>
    <path d="M17 18H3"></path>
    <path d="M21 6H3"></path>
  </svg>
);

const AlignCenterIcon = ({ className = 'h-4 w-4' }: { className?: string }): React.ReactNode => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M17 12H7"></path>
    <path d="M19 18H5"></path>
    <path d="M21 6H3"></path>
  </svg>
);

const AlignRightIcon = ({ className = 'h-4 w-4' }: { className?: string }): React.ReactNode => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M21 12H9"></path>
    <path d="M21 18H7"></path>
    <path d="M21 6H3"></path>
  </svg>
);

type Level = 1 | 2 | 3;

interface HeadingOption {
  label: string;
  value: Level | 0;
}

const HEADING_OPTIONS: HeadingOption[] = [
  { label: 'Heading 1', value: 1 },
  { label: 'Heading 2', value: 2 },
  { label: 'Heading 3', value: 3 },
  { label: 'Text', value: 0 },
];

type Alignment = 'left' | 'center' | 'right';

interface AlignmentOption {
  label: string;
  value: Alignment;
  icon: React.FC<{ className?: string }>;
}

const ALIGNMENT_OPTIONS: AlignmentOption[] = [
  { label: 'Left', value: 'left', icon: AlignLeftIcon },
  { label: 'Center', value: 'center', icon: AlignCenterIcon },
  { label: 'Right', value: 'right', icon: AlignRightIcon },
];

interface Props {
  editor: Editor;
}

const Toolbar: React.FC<Props> = ({ editor }: { editor: Editor }) => {
  const [showHeadingMenu, setShowHeadingMenu] = React.useState(false);
  const [showAlignMenu, setShowAlignMenu] = React.useState(false);
  const headingMenuRef = React.useRef<HTMLDivElement>(null);
  const alignMenuRef = React.useRef<HTMLDivElement>(null);

  const getActiveHeading = (): string => {
    if (editor.isActive('heading', { level: 1 })) return 'H1';
    if (editor.isActive('heading', { level: 2 })) return 'H2';
    if (editor.isActive('heading', { level: 3 })) return 'H3';
    return 'Text';
  };

  const handleHeadingChange = (level: Level | 0): void => {
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      // Only toggle if it's not already the active heading
      if (!editor.isActive('heading', { level })) {
        editor.chain().focus().setHeading({ level }).run();
      }
    }
    setShowHeadingMenu(false);
  };

  const getActiveAlignment = (): Alignment => {
    if (editor.isActive({ textAlign: 'center' })) return 'center';
    if (editor.isActive({ textAlign: 'right' })) return 'right';
    return 'left';
  };

  const handleAlignmentChange = (alignment: Alignment): void => {
    editor.chain().focus().setTextAlign(alignment).run();
    setShowAlignMenu(false);
  };

  const setLink = (): void => {
    const url = window.prompt('Enter URL');
    if (url) {
      editor.chain().focus().setLink({ href: url }).run();
    }
  };

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 100 }}
      className="flex gap-1 bg-white rounded-lg shadow-lg border border-neutral-50 p-1 items-center"
    >
      <div className="relative h-9" ref={headingMenuRef}>
        <button
          onClick={() => setShowHeadingMenu(!showHeadingMenu)}
          className={`p-1.5 rounded hover:bg-neutral-50 ${editor.isActive('heading') ? 'bg-neutral-50' : ''}`}
          title="Heading"
        >
          <Typography size="sm">{getActiveHeading()}</Typography>
        </button>
        {showHeadingMenu && (
          <div className="absolute left-0 mt-1 py-1 bg-white rounded-lg shadow-lg border border-neutral-50 min-w-[120px]">
            {HEADING_OPTIONS.map((option) => (
              <button
                key={option.value}
                onClick={() => handleHeadingChange(option.value)}
                className={`w-full px-3 py-1.5 text-left hover:bg-neutral-50 ${
                  (option.value === 0 && !editor.isActive('heading')) ||
                  (option.value !== 0 && editor.isActive('heading', { level: option.value }))
                    ? 'bg-neutral-50'
                    : ''
                }`}
              >
                <Typography size="sm">{option.label.replace('Heading ', 'H')}</Typography>
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Alignment Menu */}
      <div className="relative h-9" ref={alignMenuRef}>
        <button
          onClick={() => setShowAlignMenu(!showAlignMenu)}
          className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('textAlign') ? 'bg-neutral-50' : ''}`}
          title="Text Alignment"
        >
          {((): React.ReactNode => {
            const activeAlignment = getActiveAlignment();
            const ActiveIcon = ALIGNMENT_OPTIONS.find((opt) => opt.value === activeAlignment)?.icon || AlignLeftIcon;
            return <ActiveIcon />;
          })()}
        </button>
        {showAlignMenu && (
          <div className="absolute left-0 mt-1 bg-white rounded-lg shadow-lg border border-neutral-50 overflow-hidden">
            <div className="flex p-1">
              {ALIGNMENT_OPTIONS.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleAlignmentChange(option.value)}
                  className={`p-2 rounded hover:bg-neutral-50 ${
                    editor.isActive({ textAlign: option.value }) ? 'bg-neutral-50' : 'bg-white'
                  }`}
                  title={option.label}
                >
                  <option.icon />
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('bold') ? 'bg-neutral-50' : ''}`}
      >
        <BoldIcon className="h-4 w-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('italic') ? 'bg-neutral-50' : ''}`}
      >
        <ItalicIcon className="h-4 w-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('bulletList') ? 'bg-neutral-50' : ''}`}
      >
        <ListBulletIcon className="h-4 w-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('orderedList') ? 'bg-neutral-50' : ''}`}
      >
        <NumberedListIcon className="h-4 w-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('codeBlock') ? 'bg-neutral-50' : ''}`}
      >
        <CodeBracketIcon className="h-4 w-4" />
      </button>
      <button
        onClick={setLink}
        className={`p-1.5 rounded hover:bg-neutral-50 h-9 ${editor.isActive('link') ? 'bg-neutral-50' : ''}`}
      >
        <LinkIcon className="h-4 w-4" />
      </button>
    </BubbleMenu>
  );
};

export default Toolbar;
