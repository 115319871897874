import { IPositionDetails } from '~/pages/Headcount/entity/types';
import formatCurrency from '~/utils/formatCurrency';
import { format, toZonedTime } from 'date-fns-tz';
import { compareAsc } from 'date-fns';

export default ({
  positions,
}: {
  positions?: IPositionDetails[];
}): (string | number | boolean | null | undefined)[][] => {
  if (!positions?.length) return [];

  return positions
    .sort((a, b) => compareAsc(b.hireDate, a.hireDate))
    .map((position) => {
      return {
        Employee: position.employeeName,
        Title: position.title,
        Department: position.currentDepartment.name,
        Compensation: formatCurrency(position.currentPayRate.value),
        'Hire Date': format(toZonedTime(position.hireDate, 'UTC').toString(), 'MM/dd/yyyy'),
        'Term Date': position.termDate ? format(toZonedTime(position.termDate, 'UTC').toString(), 'MM/dd/yyyy') : null,
      };
    })
    .reduce(
      (output, position, index) => {
        if (index === 0) {
          output.push(Object.keys(position));
        }
        output.push(Object.values(position));
        return output;
      },
      [] as (string | number | boolean | null | undefined)[][],
    );
};
