import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  sortingFns,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable from '~/components/Table/Base/BaseTable';
import Typography from '~/components/Typography';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { State } from '~/store';
import * as Cell from './Cell';
import {
  IListPositionsParams,
  IUpdatePositionRequestBody,
} from '~/services/parallel/api/positions/positionsRequestSchemas';
import TotalCostColumnHeader from './TotalCostColumnHeader';
import { settingsSlice } from '~/store/settingsSlice';
import * as stringDate from '~/utils/stringDate';
import InlineCreate from './InlineCreate';
import { IPositionDetails } from '../../entity/types';
import HideSensitiveData from './HideSensitiveData';
import { headcountTableColumns } from './headcountTableColumns';
import HeadcountEmptyState from '../HeadcountEmptyState';

export interface IHeadcountTableRow {
  employeeName: string;
  title: string;
  employmentType: string;
  departmentUuid: string;
  payRate: string;
  fullyBurdenedCost: string;
  hireDate: string;
  termDate: string;
  ellipsisDropdown: React.ReactNode;
  [key: string]: string | React.ReactNode | boolean | undefined;
}

interface IExistingPositionModalState {
  isOpen: boolean;
  position: IPositionDetails | null;
}

interface IHeadcountTableProps {
  organizationUuid: string;
  scenarioUuid: string | null;
  positions: IPositionDetails[];
  setUpdatePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setDeletePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setUpdateCompensationModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  positionIndexes: Record<string, number>;
  setPositionIndexes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isCreatingInline: boolean;
  setIsCreatingInline: React.Dispatch<React.SetStateAction<boolean>>;
  isFiltering: boolean;
  listParams: IListPositionsParams;
}

const HeadcountTable = ({
  organizationUuid,
  scenarioUuid,
  positions,
  setUpdatePositionModalState,
  setDeletePositionModalState,
  setUpdateCompensationModalState,
  positionIndexes,
  setPositionIndexes,
  isCreatingInline,
  setIsCreatingInline,
  isFiltering,
  listParams,
}: IHeadcountTableProps): React.ReactNode => {
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const dispatch = useDispatch();
  const [updatePosition] = positionsApi.useUpdatePositionMutation();
  const departments = useSelector((state: State) => state.organization.departments);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

  const curriedUpdatePosition =
    ({ orgUuid, scenarioUuid }: { orgUuid: string; scenarioUuid?: string | null }) =>
    ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
      createScenario,
    }: {
      positionUuid: string;
      awaitCalculations?: boolean;
      positionUpdates: IUpdatePositionRequestBody;
      createScenario?: boolean;
    }): Promise<{ data: IPositionDetails; headers: Record<string, string> }> => {
      return updatePosition({
        params: {
          orgUuid,
          positionUuid,
        },
        query: {
          scenarioUuid,
          awaitCalculations,
          createScenario,
        },
        body: positionUpdates,
        listParams,
      }).unwrap();
    };

  const handleUpdatePosition = useCallback(
    ({
      positionUuid,
      awaitCalculations,
      positionUpdates,
      createScenario,
    }: {
      positionUuid: string;
      awaitCalculations?: boolean;
      positionUpdates: IUpdatePositionRequestBody;
      createScenario?: boolean;
    }): Promise<{ data: IPositionDetails; headers: Record<string, string> }> => {
      return curriedUpdatePosition({ orgUuid: organizationUuid, scenarioUuid: scenarioUuid ?? undefined })({
        positionUuid,
        awaitCalculations,
        positionUpdates,
        createScenario,
      });
    },
    [organizationUuid, scenarioUuid, positions],
  );

  const { tableData, tableColumns } = useMemo(() => {
    const columnHelper = createColumnHelper<IHeadcountTableRow>();

    const tableColumns = headcountTableColumns.map((col) =>
      columnHelper.accessor(col.id, {
        enableResizing: false,
        enablePinning: false,
        header: () => (
          <div className={col.className} data-column-id={col.id}>
            <Typography color="lightGray">{col.label.toUpperCase()}</Typography>
            {col.id === 'fullyBurdenedCost' && <TotalCostColumnHeader />}
            {col.id === 'payRate' && (
              <HideSensitiveData
                hideSensitiveData={hideSensitiveData}
                handleToggleHideSensitiveData={() =>
                  dispatch(
                    settingsSlice.actions.update({
                      hideSensitiveData: !hideSensitiveData,
                    }),
                  )
                }
              />
            )}
          </div>
        ),
        cell: (info) => (
          <Typography id={`headcount-${col.id}-${info.row.index}`}>{info.row.original[col.id]}</Typography>
        ),
        enableSorting: col.enableSorting ?? false,
        sortingFn: col.sortingFn ?? sortingFns.alphanumeric,
      }),
    );

    const today = stringDate.getStringDate();

    const tableData = positions
      .sort((a, b) => positionIndexes[a.positionUuid] - positionIndexes[b.positionUuid])
      .map((position) => ({
        isActive: (
          <Cell.CellToggle
            displayToggle={
              stringDate.isAfter({ comparison: today, dateToCheck: position.hireDate }) ||
              !position.isActive ||
              Boolean(position.scenarioUuid)
            }
            positionUuid={position.positionUuid}
            isActive={position.isActive}
            onUpdate={handleUpdatePosition}
          />
        ),
        employeeName: (
          <Cell.CellInput
            value={position.employeeName}
            isScenario={Boolean(position.scenarioUuid)}
            isActive={position.isActive}
            positionUuid={position.positionUuid}
            inputValidation={/^.*$/}
            onUpdate={handleUpdatePosition}
            id="employeeName"
          />
        ),
        title: (
          <Cell.CellInput
            value={position.title}
            isScenario={Boolean(position.scenarioUuid)}
            isActive={position.isActive}
            positionUuid={position.positionUuid}
            inputValidation={/^.*$/}
            onUpdate={handleUpdatePosition}
            id="title"
          />
        ),
        employmentType: (
          <Cell.CellEmploymentType
            id="employmentType"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            value={position.employmentType}
            positionUuid={position.positionUuid}
            onUpdate={handleUpdatePosition}
          />
        ),
        departmentUuid: (
          <Cell.CellDepartment
            id="departmentUuid"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            valueUuid={position.departmentUuid}
            value={departments.find((department) => department.departmentUuid === position.departmentUuid)?.name ?? ''}
            positionUuid={position.positionUuid}
            onUpdate={handleUpdatePosition}
          />
        ),
        payRate: (
          <Cell.CellTotalComp
            id="payRate"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            value={position.currentPayRate.value.toString()}
            positionUuid={position.positionUuid}
            changeHistory={position.payRates as { value: number; effectiveAt: string }[]}
            updateCompensation={() => {
              setUpdateCompensationModalState({
                isOpen: true,
                position: position,
              });
            }}
          />
        ),
        fullyBurdenedCost: (
          <Cell.CellTotalCost
            id="fullyBurdenedCost"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            value={position.fullyBurdenedCost?.toString() ?? null}
            positionUuid={position.positionUuid}
          />
        ),
        hireDate: (
          <Cell.CellDate
            id="hireDate"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            onUpdate={handleUpdatePosition}
            value={position.hireDate}
            positionUuid={position.positionUuid}
            maxDate={position.termDate ? position.termDate : undefined}
          />
        ),
        termDate: (
          <Cell.CellDate
            id="termDate"
            isActive={position.isActive}
            isScenario={Boolean(position.scenarioUuid)}
            onUpdate={handleUpdatePosition}
            value={position.termDate}
            positionUuid={position.positionUuid}
            minDate={position.hireDate}
          />
        ),
        ellipsisDropdown: (
          <Cell.CellOptions
            position={position}
            onEdit={() => {
              setUpdatePositionModalState({
                isOpen: true,
                position: position,
              });
              if (isCreatingInline) {
                setIsCreatingInline(false);
              }
            }}
            onDelete={() => {
              setDeletePositionModalState({
                isOpen: true,
                position: position,
              });
              if (isCreatingInline) {
                setIsCreatingInline(false);
              }
            }}
            onPayChange={() => {
              setUpdateCompensationModalState({
                isOpen: true,
                position: position,
              });
              if (isCreatingInline) {
                setIsCreatingInline(false);
              }
            }}
          />
        ),
      }));

    return { tableData, tableColumns };
  }, [positions, organizationUuid, scenarioUuid, hideSensitiveData]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const updateColumnWidths = useCallback(() => {
    const headerCells = document.querySelectorAll('[data-column-id]');
    const newWidths: Record<string, number> = {};
    headerCells.forEach((cell) => {
      // Find the closest <th> parent element for each cell
      const thElement = cell.closest('th');
      if (thElement) {
        const columnId = cell.getAttribute('data-column-id');
        // Measure the width of the <th> element
        if (columnId) {
          newWidths[columnId] = thElement.getBoundingClientRect().width;
        }
      }
    });
    setColumnWidths(newWidths);
  }, [table]);

  // Update widths on initial render
  useEffect(() => {
    updateColumnWidths();
  }, [updateColumnWidths]);

  // Update widths when window resizes
  useEffect(() => {
    if (tableContainerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        updateColumnWidths();
      });

      resizeObserver.observe(tableContainerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [updateColumnWidths]);

  let emptyState = <></>;
  if (isFiltering && !isCreatingInline) {
    emptyState = <div>No results found</div>;
  } else if (!isCreatingInline) {
    emptyState = <HeadcountEmptyState setIsCreatingInline={setIsCreatingInline} />;
  }

  return (
    <div ref={tableContainerRef} className="max-w-full overflow-scroll hide-scrollbar h-full pb-96 px-10">
      <BaseTable
        id="headcount-table"
        // @ts-expect-error - tanstack table doesn't like typed rows
        table={table}
        emptyState={emptyState}
        styles={{
          table: 'w-full',
          th: 'px-4 py-2 text-xs font-normal text-neutral-200 text-nowrap',
          td: 'h-14 px-4 border-t border-b border-gray-200 text-nowrap',
          tRow: (row) => {
            const title = row.original.title as React.ReactElement;
            if ('isScenario' in title.props) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              return title.props.isScenario ? 'bg-blue-15' : '';
            }
            return '';
          },
        }}
      />
      {(positions.length > 0 || isFiltering || isCreatingInline) && (
        <InlineCreate
          organizationUuid={organizationUuid}
          scenarioUuid={scenarioUuid}
          columnWidths={columnWidths}
          setPositionIndexes={setPositionIndexes}
          isCreatingInline={isCreatingInline}
          setIsCreatingInline={setIsCreatingInline}
          listParams={listParams}
        />
      )}
    </div>
  );
};

export default HeadcountTable;
