import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import Typography from '~/components/Typography';
import useDatePicker, { IDatePickerState } from './useDatePicker';
import './datePicker.css';
import { ZStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';
interface Props {
  id: string;
  className?: string;
  labelPlacement?: 'top' | 'left';
  format?: string;
  state: IDatePickerState;
  setState: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  label?: string;
  errorMessage?: string;
  minDate?: Date;
  maxDate?: Date;
  range?: boolean;
  required?: boolean;
  disabled?: boolean;
  datePickerClassName?: string;
}

const DatePicker = ({
  id,
  className,
  labelPlacement = 'top',
  format = 'MM/DD/YYYY',
  state,
  setState,
  label,
  errorMessage,
  minDate,
  maxDate,
  range = false,
  required = false,
  disabled = false,
  datePickerClassName,
}: Props): React.ReactElement => {
  const handleValueChange = (
    value: { startDate: Date | string | null; endDate: Date | string | null } | null,
  ): void => {
    if (!value) return;
    const { startDate, endDate } = value;
    const parsedStartDate = ZStringDate.nullable().parse(startDate);
    const parsedEndDate = ZStringDate.nullable().parse(endDate);

    setState((prevState) => {
      let valid;
      if (range) {
        valid = Boolean(parsedStartDate && parsedEndDate);
      } else if (!parsedStartDate && required) {
        valid = false;
      } else if (!startDate && !required) {
        valid = true;
      } else {
        valid = Boolean(parsedStartDate);
      }

      return {
        ...prevState,
        value: {
          startDate: parsedStartDate,
          endDate: range ? parsedEndDate : parsedStartDate,
        },
        valid,
        pristine: false,
        touched: true,
      };
    });
  };

  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <label
      htmlFor={id}
      data-testid={id}
      className={`w-full ${className}${labelPlacement === 'left' ? ' flex items-center gap-1' : ''}`}
    >
      {label && (
        <div className="flex flex-row">
          <Typography
            tag="span"
            className={`block truncate${state.value.startDate ? ' font-medium' : ' font-normal'}${label && ' mb-1'}`}
          >
            {label}
          </Typography>
          {required && (
            <Typography tag="span" size="xs" className={`${state.disabled || disabled ? ' text-neutral-75' : ''}`}>
              *
            </Typography>
          )}
        </div>
      )}
      <div className="datepicker" data-testid={`${id}-datepicker-wrapper`}>
        <Datepicker
          /*
           * Tailwinds datepicker automatically chooses the selected color -500,
           * we don't use sky anywhere so we made an arbitrary sky-500 that maps to green-300
           */
          primaryColor={'sky'}
          inputId={id}
          minDate={state.minDate ? new Date(state.minDate) : minDate}
          maxDate={state.maxDate ? new Date(state.maxDate) : maxDate}
          asSingle={!range}
          useRange={range}
          value={{
            startDate: state.value.startDate ? stringDate.format(state.value.startDate, 'mm/dd/yyyy') : null,
            endDate: state.value.endDate ? stringDate.format(state.value.endDate, 'mm/dd/yyyy') : null,
          }}
          startFrom={state.value.startDate ? new Date(state.value.startDate) : null}
          displayFormat={format}
          separator={range ? 'to' : undefined}
          onChange={handleValueChange}
          disabled={disabled || state.disabled}
          popoverDirection="down"
          inputClassName={`rounded-lg w-full border border-solid ${
            showError ? 'border-red-300' : 'border-neutral-50'
          } ${datePickerClassName} py-2 px-3 text-sm leading-5 disabled:bg-neutral-25 disabled:text-neutral-100 focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
          readOnly
          placeholder="Select Date"
        />
      </div>
      {showError && Boolean(state.errorMessage?.length) && (
        <Typography tag="span" color="warning" className="italic p-1" data-testid={`${id}-error`}>
          {state.errorMessage ?? errorMessage}
        </Typography>
      )}
    </label>
  );
};

export { useDatePicker };
export default DatePicker;
