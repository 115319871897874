import React from 'react';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import emptyHeadcount from '~/assets/emptyHeadcount.svg';

const HeadcountEmptyState = ({
  setIsCreatingInline,
}: {
  setIsCreatingInline: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactNode => {
  return (
    <div className="flex flex-col items-center justify-center h-full pt-6 ">
      <img src={emptyHeadcount} alt="Empty State" className="size-28" />
      <Typography weight="semibold" className="py-1" size="sm">
        Headcount
      </Typography>
      <Typography className="max-w-[400px] text-center" color="secondary">
        {`Manage your team's current and future headcount forecasting compensation and driving growth`}
      </Typography>
      <Button
        onClick={() => {
          setIsCreatingInline(true);
        }}
        className="!w-fit !px-3 !py-1.5 mt-3"
        id="create-position-button-inline"
      >
        Add Position
      </Button>
    </div>
  );
};

export default HeadcountEmptyState;
