import React from 'react';
import Typography from '~/components/Typography';
import logo from '~/assets/parallelWordmarkLogo.svg';
import Button from '~/components/Button';

const Unauthenticated = ({ type }: { type: 'notFound' | 'expired' }): React.ReactNode => {
  return (
    <div className="w-screen h-screen bg-white flex justify-center items-center">
      <div className="flex flex-col text-center items-center gap-16">
        <div className="flex flex-col gap-4 justify-center items-center">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32" cy="32" r="31.7096" fill="#F8F9F6" stroke="#D4DBD1" strokeWidth="0.580818" />
            <path
              d="M33.1822 29.6803C33.6312 29.8948 34.052 30.188 34.4239 30.5599C35.9229 32.0591 36.1433 34.3528 35.085 36.0855M25.3171 33.3032L23.5602 35.0604C21.8029 36.8179 21.8029 39.6674 23.5602 41.4249C25.3175 43.1825 28.1667 43.1825 29.924 41.4249L33.0526 38.296M38.6669 32.6811L40.4238 30.9239C42.1811 29.1664 42.1811 26.3169 40.4238 24.5594C38.6665 22.8018 35.8173 22.8018 34.06 24.5594L29.5601 29.0598C29.3017 29.3182 29.0814 29.6001 28.899 29.8988M30.8018 36.3041C30.3528 36.0895 29.932 35.7963 29.5601 35.4244C29.0684 34.9326 28.7143 34.3554 28.4977 33.7405M35.085 36.0855L40.9918 41.993M35.085 36.0855L28.899 29.8988M22.9922 23.9913L28.899 29.8988"
              stroke="#64755C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex flex-col gap-2 justify-center items-center">
            <Typography size="lg" weight="bold">
              {type === 'notFound' ? 'Invalid Link' : 'Link Expired'}
            </Typography>
            <Typography size="sm" color="secondary" className="text-center max-w-[325px]">
              {type === 'notFound'
                ? 'This link has been deleted. Contact the individual who shared this link.'
                : 'This link was set to expire after a set time. Contact the individual who shared this link.'}
            </Typography>
          </div>
        </div>
        <div className="bg-green-15 border border-green-50 rounded-3xl w-[479px] h-[234px] flex flex-col gap-4 items-center justify-center">
          <img src={logo} alt="logo" className="w-[140px] h-[35px]" />
          <Typography className="text-center" color="secondary">
            Stronger portfolio companies start with smarter financial strategies
          </Typography>
          <a href="https://getparallel.com" target="_blank" rel="noreferrer">
            <Button>Growth Planning for Founders</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Unauthenticated;
