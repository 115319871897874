import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { State } from '~/store/index';

export const getBaseUrl = (): string =>
  `${!import.meta.env.VITE_PARALLEL_API_URL.includes('http') ? 'https://' : ''}${import.meta.env.VITE_PARALLEL_API_URL}/v1`;

export const createBaseQuery = (): BaseQueryFn =>
  fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers, { getState }) => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      const organizationUuid = (getState() as State).organization.uuid;
      if (organizationUuid) {
        headers.set('Organization-Uuid', organizationUuid);
      }

      headers.set('Content-Type', 'application/json');
      return headers;
    },
    paramsSerializer: (params) => {
      const searchParams = new URLSearchParams();

      for (const key in params) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const value = params[key];

        if (Array.isArray(value)) {
          // Serialize arrays with the '[]' notation
          value.forEach((item) => {
            searchParams.append(`${key}[]`, item);
          });
        } else if (value !== undefined) {
          // Serialize other values
          searchParams.append(key, value);
        }
      }

      return searchParams.toString();
    },
  });
