import React, { useContext, useEffect } from 'react';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import { RatiosContext } from '../context/RatiosContext';
import Divider from '~/components/Divider';
import Input from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import RadioInput from '~/components/RadioInput';
import createRatio from '../utils/createRatio';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IRatio } from '../entity/types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import toast from 'react-hot-toast';
import { useFormulaInput } from '~/components/FormulaListInput/useFormulaListInput';
import FormulaListInput from '~/components/FormulaListInput';
import { IFormula } from '~/services/parallel/formulas.types';

const CreateRatioModal = (): React.ReactNode => {
  const {
    isCreateRatioModalOpen,
    setIsCreateRatioModalOpen,
    positionNeeded,
    amount,
    drivenByValue,
    setPositionNeeded,
    setAmount,
    setDrivenByValue,
    driverType,
    setDriverType,
    resetFormState,
    formulasList,
    ratiosList,
    setRatiosList,
    editRatioUuid,
    setEditRatioUuid,
    setFormStateFromIRatio,
  } = useContext(RatiosContext);

  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);

  const { filteredFormulaList, highlightedFormula, setHighlightedFormula, handleKeyDown, onSelectAttribute } =
    useFormulaInput({
      formulaList: formulasList,
      inputValue: drivenByValue.value,
      onSelectAttribute: (attribute: IFormula): void => {
        setDrivenByValue((prevState) => ({
          ...prevState,
          value: attribute.recipe.name,
        }));
      },
    });

  const amountHasError = !amount.valid && amount.touched && !amount.pristine;
  const drivenByValueHasError = !drivenByValue.valid && drivenByValue.touched && !drivenByValue.pristine;

  const successCallback = ({
    createdRatio,
    editedRatioUuid,
  }: {
    createdRatio: IRatio;
    editedRatioUuid: string | null;
  }): void => {
    setIsCreateRatioModalOpen(false);
    resetFormState();
    if (editedRatioUuid) {
      setRatiosList((prevState) =>
        prevState.map((ratio) => {
          return ratio.ratioUuid === editedRatioUuid ? createdRatio : ratio;
        }),
      );
    } else {
      setRatiosList((prevState) => [...prevState, createdRatio]);
    }
    setEditRatioUuid(null);
  };

  useEffect(() => {
    const assignFormState = async (): Promise<void> => {
      const ratio = ratiosList.find((ratio) => ratio.ratioUuid === editRatioUuid);
      if (ratio) {
        setFormStateFromIRatio(ratio);
      }
    };
    if (editRatioUuid) {
      assignFormState();
    }
  }, [editRatioUuid]);

  const handleDeleteRatio = async (): Promise<void> => {
    const response = (await request({
      method: 'DELETE',
      url: `/ratios/${editRatioUuid}`,
      params: {
        scenarioUuid: activeScenarioUuid ?? undefined,
      },
      headers: {
        'Organization-Uuid': organizationUuid,
      },
    })) as IAPIResponse<unknown>;

    if (response.status === 204) {
      setIsCreateRatioModalOpen(false);
      setEditRatioUuid(null);
      setRatiosList((prevState) =>
        prevState.filter((ratio) => {
          return ratio.ratioUuid !== editRatioUuid;
        }),
      );
      resetFormState();
      toast.success('Ratio deleted successfully');
    }
  };

  return (
    <Modal
      isOpen={isCreateRatioModalOpen}
      onClose={() => {
        setIsCreateRatioModalOpen(false);
        setEditRatioUuid(null);
      }}
      size="md"
      title={editRatioUuid ? 'Edit Ratio' : 'New Ratio'}
    >
      <div data-testid="create-ratio-modal" className="flex flex-col gap-4 w-full mt-1">
        <div>
          <Input
            state={positionNeeded}
            setState={setPositionNeeded}
            label="Position Needed"
            subLabel="This will tally every position that contains the specified text in its title"
            id="positionNeeded"
          />
        </div>
        <div className="flex w-full gap-4 items-center justify-center">
          <Divider className="flex-grow w-full" />
          <Typography color="empty" className="text-nowrap">
            For Every
          </Typography>
          <Divider className="flex-grow w-full" />
        </div>
        <div className="flex flex-row gap-4 text-nowrap items-center justify-start">
          <Typography>Driven by:</Typography>
          <RadioInput
            id="driverType"
            state={driverType}
            setState={setDriverType}
            flexDirection="flex-row"
            className="!w-fit !justify-start !items-start"
          />
        </div>
        <div className="flex flex-row w-full justify-between items-end gap-4">
          <div className={`w-[50%] ${drivenByValueHasError && !amountHasError ? 'mb-[29px]' : ''}`}>
            <Input state={amount} setState={setAmount} label="Amount" id="amount" type="currency" />
          </div>
          <div className={`${amountHasError || drivenByValueHasError ? 'mb-[41px]' : 'mb-3'}`}>
            <Typography color="empty">of</Typography>
          </div>
          {driverType.selected?.value === 'modelAttribute' ? (
            <FormulaListInput
              filteredFormulaList={filteredFormulaList}
              attributeTitle={drivenByValue}
              setAttributeTitle={setDrivenByValue}
              handleKeyDown={handleKeyDown}
              onSelectAttribute={onSelectAttribute}
              highlightedFormula={highlightedFormula}
              setHighlightedFormula={setHighlightedFormula}
              inputPlaceholder="Search"
              inputLabel={driverType.selected.label}
              className={`w-[50%] ${!drivenByValueHasError && amountHasError ? 'mb-[29px]' : ''}`}
            />
          ) : (
            <div className={`w-[50%] ${!drivenByValueHasError && amountHasError ? 'mb-[29px]' : ''}`}>
              <Input id="drivenByValue" state={drivenByValue} setState={setDrivenByValue} type="text" />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between gap-4">
          <Button
            fill="clear"
            className="!w-fit !px-0"
            onClick={() => {
              setIsCreateRatioModalOpen(false);
              setEditRatioUuid(null);
              resetFormState();
            }}
          >
            Cancel
          </Button>
          <div className="flex flex-row gap-4">
            {editRatioUuid && (
              <Button
                id="delete-ratio-button"
                fill="destructiveOutline"
                className="!w-fit !px-4"
                onClick={handleDeleteRatio}
              >
                Delete
              </Button>
            )}
            <Button
              className="!w-fit !px-4"
              id="submit-ratio-button"
              onClick={() =>
                createRatio({
                  startDate: defaultGraphStartDate,
                  endDate: defaultGraphEndDate,
                  organizationUuid,
                  activeScenarioUuid,
                  positionNeeded,
                  amount,
                  drivenByValue,
                  driverType,
                  formulasList,
                  setPositionNeeded,
                  setAmount,
                  setDrivenByValue,
                  setDriverType,
                  successCallback,
                  editRatioUuid,
                })
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRatioModal;
