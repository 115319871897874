import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';

export const ZScenarioPurpose = z.enum(['userCreated', 'pointInTimeView', 'expenseGen', 'revenueModelBuilder']);
export type IScenarioPurpose = z.infer<typeof ZScenarioPurpose>;

// A subset of scenarios that are displayed in the UI, typically for the dropdown
export const scenariosToDisplay: Record<string, IScenarioPurpose> = {
  userCreated: 'userCreated',
  revenueModelBuilder: 'revenueModelBuilder',
};

export const ZScenario = z.object({
  uuid: z.string(),
  changeDescription: z.string(),
  type: z.string(),
  purpose: ZScenarioPurpose,
  createdBy: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  organizationUuid: z.string(),
  markedAsStaleAt: z.string().nullable(),
  effectiveAt: ZStringDate,
  summary: z.string().nullable(),
});
export type IScenario = z.infer<typeof ZScenario>;
