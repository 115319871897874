import React, { useState, useEffect } from 'react';
import Typography from '~/components/Typography';

const recommendedPromptList = [
  'Review my model—are there any potential risks or errors?',
  'Highlight areas of overspending or underspending in my expenses.',
  'Which parts of my model have the most significant impact on cash runway?',
  'If I increase my marketing budget by 20%, how will it impact cash flow and revenue?',
  'Is my revenue forecast too optimistic or conservative based on past performance?',
  'Analyze and summarize trends in my spending.',
];

const RecommendedPrompts = ({
  sendRecommendedPrompt,
}: {
  sendRecommendedPrompt: (prompt: string) => Promise<void>;
}): React.ReactNode => {
  const getRandomPrompts = (list: string[], count: number): string[] => {
    const shuffled = list.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const [randomSelection, setRandomSelection] = useState<string[]>([]);

  useEffect(() => {
    setRandomSelection(getRandomPrompts(recommendedPromptList, 3));
  }, []);

  return (
    <div className="flex flex-col gap-2 justify-center items-center mt-4">
      {randomSelection.map((prompt) => (
        <div
          key={prompt}
          onClick={() => sendRecommendedPrompt(prompt)}
          className="flex flex-col cursor-pointer hover:bg-green-25 gap-2 justify-center items-start max-w-[400px] min-w-[400px] bg-green-15 border border-green-50 rounded-xl py-2 px-4"
        >
          <Typography color="green">{prompt}</Typography>
        </div>
      ))}
    </div>
  );
};

export default RecommendedPrompts;
