import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { IFormula } from '~/services/parallel/formulas.types';
import { useInput } from '~/components/Input/InputWrapper';
import DisplayAttributes from '~/components/DisplayAttributes';
import SegmentedControl from '~/components/SegmentedControl';
import { isEqual } from 'lodash';

const DisplayAttributesContainer = ({ relatedFormulas }: { relatedFormulas: string[] }): React.ReactNode => {
  const preferences = useSelector((state: State) => state.user.preferences);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [selectedFormulaValue, setSelectedFormulaValue] = useInput({ validation: /.*/ });
  const [isControlledChange, setIsControlledChange] = useState(false);
  const [relatedFormulasState, setRelatedFormulasState] = useState<string[]>(relatedFormulas);

  const { data: formulas } = formulasApi.useListFormulasQuery({
    startDate: preferences.defaultGraphStartDate,
    endDate: preferences.defaultGraphEndDate,
    scenarioUuid: activeScenarioUuid ?? undefined,
  });

  const formulaDictionary = useMemo(() => {
    return (
      formulas?.reduce(
        (acc, formula) => {
          acc[formula.formulaUuid] = formula;
          return acc;
        },
        {} as Record<string, IFormula>,
      ) ?? {}
    );
  }, [formulas]);

  useEffect(() => {
    setRelatedFormulasState(relatedFormulas);
  }, [relatedFormulas]);

  useEffect(() => {
    if (
      (selectedFormulaValue.value === '' || !isEqual(relatedFormulas, relatedFormulasState)) &&
      relatedFormulas[0] in formulaDictionary
    ) {
      setSelectedFormulaValue((prev) => ({ ...prev, value: formulaDictionary[relatedFormulas[0]].recipe.name }));
    }
  }, [formulas, selectedFormulaValue, formulaDictionary, relatedFormulas]);

  const potentialFormulasData = useMemo(() => {
    return formulas
      ?.filter((formula) => formula.recipe.name.toLowerCase() === selectedFormulaValue.value.toLowerCase())
      .map((formula) => ({
        formulaUuid: formula.formulaUuid,
        formulaName: formula.recipe.name,
      }));
  }, [formulas, selectedFormulaValue, relatedFormulas]);

  const segmentOptions = useMemo(() => {
    return relatedFormulas.reduce(
      (acc, formulaUuid) => {
        if (formulaUuid in formulaDictionary) {
          acc.push({
            value: formulaDictionary[formulaUuid].recipe.name,
            label: formulaDictionary[formulaUuid].recipe.name,
          });
        }
        return acc;
      },
      [] as { value: string; label: string }[],
    );
  }, [relatedFormulas, formulaDictionary]);

  return (
    <div className="flex flex-col gap-4 w-full h-[300px] min-h-[300px]">
      <DisplayAttributes
        searchFilter={selectedFormulaValue}
        setSearchFilter={setSelectedFormulaValue}
        formulaDictionary={formulaDictionary}
        potentialFormulasData={potentialFormulasData ?? []}
        isControlledChange={isControlledChange}
        preventScrolling={false}
      />
      <SegmentedControl
        name="formula-options"
        value={selectedFormulaValue.value}
        setValue={(val) => {
          setSelectedFormulaValue((prev) => ({
            ...prev,
            value: val,
          }));
          setIsControlledChange(true);
        }}
        segments={segmentOptions}
      />
    </div>
  );
};

export default DisplayAttributesContainer;
