import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import React from 'react';

const HideSensitiveData = ({
  hideSensitiveData,
  handleToggleHideSensitiveData,
}: {
  hideSensitiveData: boolean;
  handleToggleHideSensitiveData: () => void;
}): React.ReactNode => {
  return hideSensitiveData ? (
    <EyeSlashIcon
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleToggleHideSensitiveData();
      }}
      className="size-4 cursor-pointer"
    />
  ) : (
    <EyeIcon
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleToggleHideSensitiveData();
      }}
      className="size-4 cursor-pointer"
    />
  );
};

export default HideSensitiveData;
