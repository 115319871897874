import React from 'react';
import Modal from '~/components/Modal';
import UpdatePositionForm from './UpdatePositionForm';
import { IPositionDetails } from '../../entity/types';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

const UpdatePositionModal = ({
  isOpen,
  onClose,
  orgUuid,
  scenarioUuid,
  position,
  createScenario,
  reloadDashboard,
  awaitCalculations,
  listParams,
}: {
  isOpen: boolean;
  onClose: () => void;
  orgUuid: string;
  scenarioUuid?: string | null;
  position: IPositionDetails | null;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  return (
    <Modal id={'update-position-modal'} isOpen={isOpen} title={`Update ${position?.title ?? 'Position'}`} size="sm">
      <div data-testid="update-position-modal-container" className="mt-2 w-full">
        <UpdatePositionForm
          orgUuid={orgUuid}
          scenarioUuid={scenarioUuid}
          onClose={onClose}
          position={position}
          createScenario={createScenario}
          reloadDashboard={reloadDashboard}
          awaitCalculations={awaitCalculations}
          listParams={listParams}
        />
      </div>
    </Modal>
  );
};

export default UpdatePositionModal;
