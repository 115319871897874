import React from 'react';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import Typography from '~/components/Typography';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import * as stringDate from '~/utils/stringDate';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import toast from 'react-hot-toast';

const DiscontinueExpenseModal = ({
  isOpen,
  onClose,
  formulaUuid,
}: {
  isOpen: boolean;
  onClose: () => void;
  formulaUuid: string;
}): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { refreshData } = useFormulaContext();

  const { formula } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: activeScenarioUuid ?? undefined,
      types: [IFormulaTypeEnum.Expense, IFormulaTypeEnum.Headcount],
    },
    {
      selectFromResult: ({ data }) => ({
        formula: data?.find((f) => f.formulaUuid === formulaUuid),
      }),
    },
  );

  const [upsertExpenseFormula, { isLoading: isUpdating }] = formulasApi.useUpsertExpenseFormulaMutation();

  const [selectedDate, setSelectedDate] = usePeriodPicker({
    mode: 'month',
    startDate: stringDate.getStringDate(),
    endDate: stringDate.getStringDate(),
  });
  const [error, setError] = React.useState<string>('');

  const handleDiscontinue = async (): Promise<void> => {
    try {
      if (!formula?.recipe.startDate) {
        setError('An error occurred. Please try again.');
        return;
      }
      await upsertExpenseFormula({
        query: {
          scenarioUuid: activeScenarioUuid ?? undefined,
          awaitCalculations: false,
        },
        body: {
          formulaUuid,
          recipe: {
            name: formula.recipe.name,
            startDate: formula.recipe.startDate,
            endDate: selectedDate.endDate,
          },
        },
      }).unwrap();
      refreshData();
      toast.success('Expense discontinued');
      onClose();
    } catch (error) {
      setError('Failed to discontinue expense. Please try again.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Discontinue ${formula?.recipe.name ?? 'Expense'}`}
      showClose={true}
      id="discontinue-expense-modal"
      size="xxs"
    >
      <div className="flex flex-col gap-4">
        <div>
          <Typography color="secondary">
            Discontinue this expense will eliminate its impact on the forecast. Please specify the final month to
            include this expense.
          </Typography>
        </div>
        <div data-testid="discontinue-expense-period-picker">
          <PeriodPicker
            id="discontinue-expense-period-picker"
            label="Final Charge Date"
            state={selectedDate}
            setState={setSelectedDate}
            pickerAlignment="left"
          />
        </div>
        <div className="flex w-full justify-between">
          <div className="w-fit">
            <Button fill="clear" className="!w-fit !px-0" onClick={onClose} disabled={isUpdating}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button fill="destructive" onClick={() => handleDiscontinue()} loading={isUpdating}>
              Discontinue Expense
            </Button>
          </div>
        </div>
        {error && (
          <Typography color="warning" size="sm">
            {error}
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default DiscontinueExpenseModal;
