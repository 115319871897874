import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from './baseQuery';
import { IChangeLogResponse } from '~/components/ChangeLogModal/entity/types';

export const changeLogApi = createApi({
  reducerPath: 'changeLogApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['ChangeLog'],
  endpoints: (builder) => ({
    getChangeLog: builder.query<IChangeLogResponse, { scenarioUuid?: string | null }>({
      query: ({ scenarioUuid }) => {
        const params: { scenarioUuid?: string | null } = {};
        if (scenarioUuid) {
          params.scenarioUuid = scenarioUuid;
        }
        return {
          url: 'change-log',
          params,
        };
      },
      transformResponse: (response: { data: IChangeLogResponse }) => {
        return response.data;
      },
      providesTags: ['ChangeLog'],
    }),
  }),
});

export const { useGetChangeLogQuery } = changeLogApi;
