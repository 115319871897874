import { CellContext } from '@tanstack/react-table';
import React, { useState } from 'react';
import { ICellData, TableRow } from '../../entity/types';
import { format, toZonedTime } from 'date-fns-tz';
import numberWithCommas from '~/utils/numberWithCommas';
import RatioDetailModal from './RatioDetailModal';
import Typography from '~/components/Typography';
import { isBefore, startOfMonth } from 'date-fns';
import date from '~/utils/dates/date';

const RatioCell = ({ cellContext }: { cellContext: CellContext<TableRow, ICellData> }): React.ReactNode => {
  const { positionTitle, drivingValue, drivingElementTitle, needed, planned, relevantDate, actualDrivingValue } =
    cellContext.getValue();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const ratioStatus = ((): {
    color: string;
    stringStatus: string;
    status: React.ReactNode;
  } => {
    if (planned > needed) {
      return {
        color: 'text-purple',
        stringStatus: 'Overstaffed',
        status: (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row text-nowrap text-lg gap-1.5">
              <div className="text-purple font-bold">Overstaffed</div>
              <div className="text-purple ">by</div>
              <div className="text-purple font-bold">{planned - needed}</div>
            </div>
            <Typography color="secondary" weight="normal">
              You have more {positionTitle} than may be necessary, leading to inefficiencies and higher costs
            </Typography>
          </div>
        ),
      };
    } else if (planned < needed) {
      return {
        color: 'text-red-500',
        stringStatus: 'Understaffed',
        status: (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row text-nowrap text-lg gap-1.5">
              <div className="text-red-500 font-bold">Understaffed</div>
              <div className="text-red-500 ">by</div>
              <div className="text-red-500 font-bold">{needed - planned}</div>
            </div>
            <Typography color="secondary" weight="normal">
              You have fewer {positionTitle} than necessary, potentially leading to overwork & missed opportunities
            </Typography>
          </div>
        ),
      };
    } else {
      return {
        color: 'text-neutral-200',
        stringStatus: 'Properly Staffed',
        status: (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row text-nowrap text-lg">
              <div className="font-bold">Properly Staffed</div>
            </div>
            <Typography color="secondary" weight="normal">
              You have the appropriate amount of {positionTitle}, leading to a balanced ratio
            </Typography>
          </div>
        ),
      };
    }
  })();

  const isPast = isBefore(startOfMonth(toZonedTime(relevantDate, 'UTC')), startOfMonth(toZonedTime(date(), 'UTC')));

  return (
    <div className={`flex justify-end !h-full items-center relative px-4 ${isPast ? 'bg-neutral-25' : ''}`}>
      {isPast && <div className="absolute top-0 left-[-4px] h-full w-[8px] bg-neutral-25" />}
      <div
        className={`flex flex-row ${ratioStatus.color} hover:underline underline-offset-4 cursor-pointer`}
        onClick={() => setIsDetailModalOpen(true)}
        data-testid={`${positionTitle}-${drivingElementTitle}-ratio`}
      >
        <div>{numberWithCommas(planned)}</div>
        <div>/</div>
        <div>{numberWithCommas(needed)}</div>
      </div>
      {!isPast && format(toZonedTime(relevantDate, 'UTC'), 'MMM') === 'Dec' && (
        <div className="absolute top-0 right-[-1px] h-full w-px bg-gray-200" />
      )}
      {isPast && <div className="absolute top-0 right-[-4px] h-full w-[8px] bg-neutral-25" />}
      <RatioDetailModal
        isOpen={isDetailModalOpen}
        setIsOpen={setIsDetailModalOpen}
        drivingValue={drivingValue}
        drivingElementTitle={drivingElementTitle}
        planned={planned}
        needed={needed}
        relevantDate={relevantDate}
        actualDrivingValue={actualDrivingValue}
        positionTitle={positionTitle}
        ratioStatus={ratioStatus}
      />
    </div>
  );
};

export default RatioCell;
