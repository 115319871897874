import React from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

interface QueryParamsHook {
  queryParams: URLSearchParams;
  setQueryParams: (newParams: Record<string, string>) => void;
  removeQueryParams: (keys: string[]) => void;
}

const useQueryParams = (): QueryParamsHook => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParams = (newParams: Record<string, string>): void => {
    navigate(
      {
        pathname: window.location.pathname,
        search: createSearchParams(newParams).toString(),
      },
      { replace: true },
    );
  };

  const removeQueryParams = (keys: string[]): void => {
    const newParams: Record<string, string> = {};
    queryParams.forEach((value, paramKey) => {
      if (!keys.includes(paramKey)) {
        newParams[paramKey] = value;
      }
    });
    setQueryParams(newParams);
  };

  return { queryParams, setQueryParams, removeQueryParams };
};

export default useQueryParams;
