import React from 'react';

const PingNotification = ({ shouldAnimate }: { shouldAnimate?: boolean }): React.ReactElement => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative flex items-center justify-center h-[14px] w-[14px]">
        {shouldAnimate && (
          <span
            className={`absolute inline-flex h-[8px] w-[8px] rounded-full bg-red-400 opacity-75 animate-ping-slow`}
          ></span>
        )}
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-flex h-[6px] w-[6px] rounded-full bg-red-500"></span>
      </div>
    </div>
  );
};

export default PingNotification;
