import React, { useContext, useEffect, useMemo, useState } from 'react';
import PercentagePerMonthInput from './PercentagePerMonthInput';
import Button from '~/components/Button';
import Divider from '~/components/Divider';
import useInput from '~/components/Input/useInput';
import Input from '~/components/Input/InputWrapper';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import Typography from '~/components/Typography';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { IAPIResponse } from '~/utils/types';
import { useFormulaInput } from '~/components/FormulaListInput/useFormulaListInput';
import FormulaListInput from '~/components/FormulaListInput';

const RepRampingForm = ({ handleClose }: { handleClose: () => void }): React.ReactNode => {
  const { parsedFormulas, revalidate } = useContext(FinancialModelContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [percentageError, setPercentageError] = useState<string>('');
  const [percentagePerMonth, setPercentagePerMonth] = useState(['', '', '100']);
  const [repTitle, setRepTitle] = useInput({
    value: '',
    valid: false,
    touched: false,
    pristine: true,
    errorMessage: 'Please enter a valid title',
  });
  const [attributeTitle, setAttributeTitle] = useInput({});

  const [positions, setPositions] = useState<IPositionDetails[]>([]);

  const { filteredFormulaList, highlightedFormula, setHighlightedFormula, handleKeyDown, onSelectAttribute } =
    useFormulaInput({
      formulaList: parsedFormulas.list,
      inputValue: attributeTitle.value,
      onSelectAttribute: (attribute) => {
        setAttributeTitle((prev) => ({
          ...prev,
          value: attribute.recipe.name,
          valid: true,
          touched: true,
          pristine: false,
          errorMessage: '',
        }));
      },
    });

  useEffect(() => {
    const fetchPositions = async (): Promise<void> => {
      const response = (await request({
        method: 'GET',
        url: `/organizations/${organizationUuid}/positions`,
        params: {
          scenarioUuid: activeScenarioUuid,
        },
      })) as IAPIResponse<IPositionDetails[]>;

      setPositions(response.data.data);
    };

    fetchPositions();
  }, []);

  const matchingPositions = useMemo(() => {
    if (!repTitle.value.length) return [];
    return positions.filter((position) => position.title.toLowerCase().includes(repTitle.value.trim().toLowerCase()));
  }, [positions, repTitle.value]);

  const handleInputChange = ({ index, value }: { index: number; value: string }): void => {
    const newPercentages = [...percentagePerMonth];
    newPercentages[index] = value;
    setPercentagePerMonth(newPercentages);
  };

  const addMonth = (): void => {
    const newPercentages = [...percentagePerMonth];

    newPercentages.pop();

    newPercentages.push('');
    newPercentages.push('100');

    setPercentagePerMonth(newPercentages);
  };

  const removeMonth = (): void => {
    if (percentagePerMonth.length > 2) {
      const newPercentages = [...percentagePerMonth];

      newPercentages.pop();
      newPercentages.pop();

      newPercentages.push('100');

      setPercentagePerMonth(newPercentages);
    }
  };

  const getSideLabel = (index: number): string => {
    if (index === 0) {
      return '(Hired)';
    }
    return '';
  };

  const validateForm = (): boolean => {
    let isValid = true;
    if (!repTitle.value.trim()) {
      setRepTitle((prev) => ({ ...prev, valid: false, touched: true }));
      isValid = false;
    }
    if (!attributeTitle.value.trim()) {
      setAttributeTitle((prev) => ({ ...prev, valid: false, touched: true }));
      isValid = false;
    }

    const percentages = percentagePerMonth.slice(0, -1);
    const isPercentagesValid = percentages.every(
      (p) => p !== '' && !isNaN(Number(p)) && Number(p) >= 0 && Number(p) <= 100,
    );

    if (!isPercentagesValid) {
      setPercentageError('All percentages must be valid numbers between 0 and 100.');
      isValid = false;
    }

    if (isPercentagesValid) {
      setPercentageError('');
    }
    return isValid;
  };

  const handleSave = async ({
    repTitle,
    attributeTitle,
    percentagePerMonth,
  }: {
    repTitle: string;
    attributeTitle: string;
    percentagePerMonth: string[];
  }): Promise<void> => {
    if (validateForm()) {
      const repRamping = {
        repTitle: repTitle,
        attributeUuid: parsedFormulas.list.find((formula) => {
          return formula.recipe.name === attributeTitle;
        })?.formulaUuid,
        percentages: percentagePerMonth.slice(0, -1).map((value) => Number(value) / 100),
      };

      const response = await request({
        method: 'POST',
        url: `formulas/template/rep-ramping`,
        body: repRamping,
        params: {
          scenarioUuid: activeScenarioUuid,
        },
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      });

      if (response.status >= 400) {
        toast.error('Failed to create rep ramping');
        logger.error(new Error(response.data));
      } else {
        handleClose();
        revalidate();
      }
    } else {
      setRepTitle((prev) => ({ ...prev, pristine: false, touched: true }));
      setAttributeTitle((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
    }
  };

  useEffect(() => {
    if (percentageError) {
      setPercentageError('');
    }
  }, [percentagePerMonth]);

  return (
    <div className="w-full flex flex-col gap-2">
      <Typography color="secondary" className="mb-2">
        Set expectations for the time it takes for a new sales rep to be onboarded, trained, and gradually ramps up to
        full productivity
      </Typography>
      <div className="flex flex-col gap-1">
        <Input
          label="Rep Title"
          state={repTitle}
          setState={setRepTitle}
          id="rep-title-input"
          placeholder="AE, Enterprise, Partner, SDR, etc."
        />
        <Typography color="empty" className="italic">
          {matchingPositions.length} Matching Positions
        </Typography>
      </div>
      <FormulaListInput
        attributeTitle={attributeTitle}
        setAttributeTitle={setAttributeTitle}
        handleKeyDown={handleKeyDown}
        filteredFormulaList={filteredFormulaList}
        onSelectAttribute={onSelectAttribute}
        highlightedFormula={highlightedFormula}
        setHighlightedFormula={setHighlightedFormula}
        inputLabel="Quota Attribute"
        inputPlaceholder="Indicate which Attribute indicates Quota"
        inputId="repRamping"
      />
      <Divider className="my-1" />
      <div className="flex flex-col w-full gap-2 items-center">
        {percentagePerMonth.map((value, index) => (
          <PercentagePerMonthInput
            // eslint-disable-next-line react/no-array-index-key
            key={index === percentagePerMonth.length - 1 ? 'last' : index} // Updated key logic
            label={`Month ${index}${index === percentagePerMonth.length - 1 ? '+' : ''}`}
            sideLabel={getSideLabel(index)}
            value={value}
            onChange={(val) => handleInputChange({ index, value: val })}
            disabled={index === percentagePerMonth.length - 1}
          />
        ))}
        {percentageError && (
          <Typography className="w-full italic" color="warning">
            {percentageError}
          </Typography>
        )}
        <div className="flex flex-row gap-2 items-center w-full justify-start">
          <Button onClick={addMonth} fill="clear" className="!w-fit !px-0">
            Add
          </Button>
          <Divider orientation="vertical" className="text-neutral-50" />
          <Button onClick={removeMonth} fill="clear" className="!w-fit !px-0">
            Remove
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mt-1">
        <div className="w-fit">
          <Button fill="clear" className="!w-fit !px-0" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className="w-fit">
          <Button
            onClick={() =>
              handleSave({
                repTitle: repTitle.value,
                attributeTitle: attributeTitle.value,
                percentagePerMonth,
              })
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RepRampingForm;
