import React, { useMemo } from 'react';
import { IFormulaType } from '~/components/Formulas/context/types';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import xeroIcon from '~/assets/logos/xero.svg';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IFormula } from '~/services/parallel/formulas.types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { bvaApi } from '~/services/parallel/api/bvaApi';
import { IBvaReviewStatus } from '~/services/parallel/bva.types';
import PingNotification from '~/components/PingNotification';
import Typography from '~/components/Typography';

const integrationIconMapping = {
  'quickbooks-online': quickbooksIcon,
  xero: xeroIcon,
};

const ExpenseLabel = ({
  onEdit,
  onEditForBva,
  columnWidth,
  data,
  columnIndex,
  style = {},
}: {
  onEdit: (
    formulaUuid: string,
    modalType?:
      | 'create'
      | 'generate'
      | 'edit'
      | 'duplicate'
      | 'delete'
      | 'discontinue'
      | 'link'
      | 'edit-mappings'
      | null,
  ) => void;
  onEditForBva: (formulaUuid: string) => void;
  columnWidth: number;
  data: IFormulaType;
  columnIndex: number;
  style?: React.CSSProperties;
}): React.ReactElement => {
  const bvaResult = bvaApi.useGetBvaQuery();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { formulaDictionary } = useFormulaContext();

  const formula: IFormula | null = useMemo(
    () => (data.formulaUuid in formulaDictionary ? formulaDictionary[data.formulaUuid] : null),
    [formulaDictionary, data.formulaUuid],
  );

  let expenseNeedsReview = false;
  if (bvaResult.data?.bvaResult?.expenseReview && data.formulaUuid in bvaResult.data.bvaResult.expenseReview) {
    expenseNeedsReview = bvaResult.data.bvaResult.expenseReview[data.formulaUuid].status === IBvaReviewStatus.Pending;
  }

  const isTotalCompensation = formula?.recipe.name === 'Total Compensation';

  return (
    <div
      className="bg-white sticky z-10 top-0 left-0 flex pr-2 border-r border-neutral-50 group h-[48px] items-center text-nowrap"
      style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px`, ...style }}
    >
      {expenseNeedsReview && !activeScenarioUuid ? (
        <div
          onClick={() => onEditForBva(data.formulaUuid)}
          className={`flex flex-row cursor-pointer items-center overflow-hidden border border-neutral-50 py-1.5 pl-2.5 pr-4 ${data.label.integration ? 'mr-7' : ''} shadow-[0px_1px_2px_0px_rgba(0,_0,_0,_0.10)] rounded-lg`}
        >
          <div className="flex items-center justify-center pr-2">
            <PingNotification />
          </div>
          <Typography>{formula?.recipe.name}</Typography>
        </div>
      ) : (
        <button
          className="text-left flex-1 overflow-hidden hover:underline text-sm"
          onClick={() => {
            if (isTotalCompensation) {
              onEdit(data.formulaUuid, 'edit-mappings');
            } else {
              onEdit(data.formulaUuid);
            }
          }}
        >
          {formula?.recipe.name}
        </button>
      )}
      <div className="absolute right-0 top-[14px] flex items-center gap-1 bg-white px-2">
        {data.label.integration && (
          <div key={`${data.formulaUuid}-${data.label.integration}`}>
            <img
              className="size-5"
              src={integrationIconMapping[data.label.integration as keyof typeof integrationIconMapping]}
              alt={`Integration icon for ${data.label.integration}`}
            />
          </div>
        )}
      </div>
      <ColumnResize columnIndex={columnIndex} />
    </div>
  );
};

export default ExpenseLabel;
