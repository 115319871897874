import React from 'react';
import Modal from '~/components/Modal';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { ExpenseForm } from './ExpenseForm';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

export const ExpenseModal = ({
  isOpen,
  isDuplicating,
  onClose,
  formulaUuid,
  scenarioUuid,
  formOverrides,
  integrationsWithMappings,
  onDeleteExpense,
}: {
  isOpen: boolean;
  isDuplicating?: boolean;
  onClose: () => void;
  formulaUuid: string;
  scenarioUuid: string | null;
  formOverrides?: {
    datasourceUuid?: string[] | null;
  };
  integrationsWithMappings: IIntegrationWithMappings[];
  onDeleteExpense: () => void;
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { refreshData } = useFormulaContext();

  const { formula, formulaList } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: scenarioUuid ?? undefined,
    },
    {
      selectFromResult: ({ data }) => ({
        formula: data?.find((f) => f.formulaUuid === formulaUuid),
        formulaList: data ?? [],
      }),
    },
  );
  const { data: departments } = departmentsApi.useGetDepartmentsQuery({ orgUuid: organizationUuid });

  const getModalTitle = (): string => {
    if (isDuplicating) {
      return 'Duplicate Expense';
    }
    return formulaUuid ? 'Edit Expense' : 'New Expense';
  };

  const getModalId = (): string => {
    if (isDuplicating) {
      return 'duplicate-expense-modal';
    }
    return formulaUuid ? 'edit-expense-modal' : 'create-expense-modal';
  };

  return (
    <Modal id={getModalId()} isOpen={isOpen} title={getModalTitle()} size="sm">
      <div data-testid="create-expense-modal-container" className="mt-2 w-full">
        <ExpenseForm
          scenarioUuid={scenarioUuid ?? null}
          departments={departments?.list ?? []}
          formOverrides={formOverrides}
          initialFormulaData={{
            ...(formula ?? {}),
          }}
          expenseIntegrationsWithMappings={integrationsWithMappings}
          onClose={(props: { successfulSave: boolean }) => {
            onClose();
            if (props.successfulSave) {
              refreshData();
            }
          }}
          isDuplicating={isDuplicating}
          formulaList={formulaList}
          onDeleteExpense={onDeleteExpense}
        />
      </div>
    </Modal>
  );
};
