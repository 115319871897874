import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from './baseQuery';
import { IBvaResultResponse, IUpdatedBvaResultResponse } from '../bva.types';

interface ICompleteReviewRequest {
  body: {
    headcountReviewsToComplete?: string[];
    expenseReviewsToComplete?: string[];
    modelReviewsToComplete?: string[];
  };
}

export const bvaApi = createApi({
  reducerPath: 'bvaApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Bva'],
  endpoints: (builder) => ({
    getBva: builder.query<IBvaResultResponse, void>({
      query: () => {
        return {
          url: 'bva',
        };
      },
      transformResponse: (response: { data: IBvaResultResponse }) => {
        return response.data;
      },
      providesTags: ['Bva'],
    }),
    completeReview: builder.mutation<IUpdatedBvaResultResponse, ICompleteReviewRequest>({
      query: (data) => {
        return {
          url: 'bva/complete-review',
          method: 'PATCH',
          body: data.body,
        };
      },
      invalidatesTags: ['Bva'],
    }),
  }),
});

export const { useGetBvaQuery, useCompleteReviewMutation } = bvaApi;
