import React from 'react';
import { FormulasProvider } from '~/components/Formulas/context/FormulasContext';
import FinancialModelTable from './components/FinancialModelTable';
import FinancialModelHeader from './components/FinancialModelHeader';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const FinancialModelPage = (): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);

  return (
    <FormulasProvider mode={IFormulaTypeEnum.ModelBuilder}>
      <div className={`w-full bg-neutral-15 ${activeScenarioUuid ? 'h-[calc(100vh-65px)]' : 'h-screen'}`}>
        <FinancialModelHeader />
        <FinancialModelTable />
      </div>
    </FormulasProvider>
  );
};

export default FinancialModelPage;
