import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';

export enum IEmploymentType {
  FullTime = 'fulltime',
  PartTime = 'parttime',
  Contractor = 'contractor',
}

export const employmentTypeToLabel = {
  [IEmploymentType.FullTime]: 'Full Time',
  [IEmploymentType.PartTime]: 'Part Time',
  [IEmploymentType.Contractor]: 'Contractor',
};

export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    label: employmentTypeToLabel[IEmploymentType.FullTime],
    value: IEmploymentType.FullTime,
  },
  {
    label: employmentTypeToLabel[IEmploymentType.PartTime],
    value: IEmploymentType.PartTime,
  },
  {
    label: employmentTypeToLabel[IEmploymentType.Contractor],
    value: IEmploymentType.Contractor,
  },
];

export const ZCreatePositionForm = z.object({
  employeeName: z.string().nullable().optional(),
  title: z.string().min(1, 'Required'),
  employmentType: z.nativeEnum(IEmploymentType),
  departmentUuid: z.string().uuid(),
  payRate: z.string().min(1, 'Required'),
  hireDate: ZStringDate,
  numberToCreate: z.string().min(1, 'Required'),
});

export const ZUpdatePositionForm = z.object({
  employeeName: z.string().nullable().optional(),
  title: z.string().min(1, 'Required'),
  employmentType: z.nativeEnum(IEmploymentType),
  departmentUuid: z.string().uuid(),
  hireDate: ZStringDate,
});

export const ZEditTermDateForm = z.object({
  termDate: ZStringDate.optional().nullable(),
});

const ZDateForConsolidatedReportData = z.object({
  date: z.number(),
});
const ZConsolidatedData = ZDateForConsolidatedReportData.and(z.record(z.string(), z.number()));
const ZConsolidatedGraphBody = z.object({
  title: z.string(),
  data: z.array(ZConsolidatedData),
});
export const ZConsolidatedGraphData = z.record(z.string(), ZConsolidatedGraphBody);
export const ZBreakdownGraphData = z.object({
  compensationGraphData: ZConsolidatedGraphData,
  headcountGraphData: ZConsolidatedGraphData,
});
export type IBreakdownGraphData = z.infer<typeof ZBreakdownGraphData>;
export type ICreatePositionForm = z.infer<typeof ZCreatePositionForm>;
export type IUpdatePositionForm = z.infer<typeof ZUpdatePositionForm>;
export type IEditTermDateForm = z.infer<typeof ZEditTermDateForm>;
