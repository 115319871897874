import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowUpOnSquareIcon, HomeIcon } from '@heroicons/react/24/outline';
import { State } from '~/store';
import { setCurrentHelpPage, setHelpCenterEditing, clearPendingChanges } from '~/store/drawerContentSlice';
import Typography from '~/components/Typography';
import HelpCenterHome from './Home';
import {
  useGetPostQuery,
  useUpdatePostMutation,
  useCreatePostMutation,
} from '~/services/parallel/api/helpCenter/helpCenterApi';
import { IPostWithReactions } from '~/services/parallel/api/helpCenter/requestSchemas';
import HelpCenterMenu from './Menu';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import HelpCenterPost from './Post';
import toast from 'react-hot-toast';
import HoverPopover from '../HoverPopover';

const EMPTY_POST: IPostWithReactions = {
  uuid: '',
  createdBy: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
  publishedAt: null,
  title: '',
  content: '',
  keywords: [],
  category: null,
  heroImageUrl: null,
  numberOfPageVisits: 0,
  reactions: { thumbsUp: 0, thumbsDown: 0 },
  userReaction: null,
};

const HelpCenter: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);
  const { currentPageId, isEditing, pendingChanges } = useSelector((state: State) => state.drawerContent.helpCenter);
  const { data: post, isLoading, error } = useGetPostQuery({ uuid: currentPageId! }, { skip: !currentPageId });
  const [showPublishConfirm, setShowPublishConfirm] = React.useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = React.useState(false);
  const [updatePost] = useUpdatePostMutation();
  const [createPost] = useCreatePostMutation();
  const [pendingAction, setPendingAction] = React.useState<'back' | 'close' | null>(null);

  const isAdmin = user.role !== 'user';

  const handleBackToHome = (): void => {
    if (isEditing && pendingChanges) {
      setPendingAction('back');
      setShowUnsavedChangesDialog(true);
    } else {
      dispatch(setCurrentHelpPage(null));
    }
  };

  const handleCreateNew = (): void => {
    dispatch(setCurrentHelpPage(null)); // Clear current page
    dispatch(setHelpCenterEditing(true)); // Enter edit mode
  };

  const handlePublish = (): void => {
    if (!currentPageId) {
      toast.error('Please save the post before publishing.');
      return;
    }
    setShowPublishConfirm(true);
  };

  const confirmPublish = async (): Promise<void> => {
    if (!currentPageId) return;

    const toastId = toast.loading('Publishing post...');
    try {
      await updatePost({
        uuid: currentPageId,
        data: { publishedAt: new Date() },
      }).unwrap();
      toast.success('Post published successfully', { id: toastId });
      setShowPublishConfirm(false);
    } catch (error) {
      toast.error('Failed to publish post. Please try again.', { id: toastId });
    }
  };

  const handleSave = async (): Promise<void> => {
    if (!pendingChanges) {
      dispatch(setHelpCenterEditing(false));
      return;
    }

    const toastId = toast.loading(currentPageId ? 'Saving changes...' : 'Creating post...');

    try {
      if (currentPageId) {
        // Update existing post
        await updatePost({
          uuid: currentPageId,
          data: pendingChanges,
        }).unwrap();
        toast.success('Changes saved successfully', { id: toastId });
      } else {
        // Create new post
        const newPost = await createPost({
          ...EMPTY_POST,
          ...pendingChanges,
        }).unwrap();
        dispatch(setCurrentHelpPage(newPost.uuid));
        toast.success('Post created successfully', { id: toastId });
      }

      // Clear pending changes and exit edit mode only after successful save
      dispatch(clearPendingChanges());
      dispatch(setHelpCenterEditing(false));
    } catch (error) {
      toast.error(
        currentPageId ? 'Failed to save changes. Please try again.' : 'Failed to create post. Please try again.',
        { id: toastId },
      );
    }
  };

  const handleCancel = (): void => {
    if (pendingChanges) {
      setShowUnsavedChangesDialog(true);
    } else {
      dispatch(setHelpCenterEditing(false));
      if (!currentPageId) {
        dispatch(setCurrentHelpPage(null));
      }
    }
  };

  const handleConfirmDiscard = (): void => {
    dispatch(clearPendingChanges());
    dispatch(setHelpCenterEditing(false));
    if (pendingAction === 'back' || !currentPageId) {
      dispatch(setCurrentHelpPage(null));
    }
    setShowUnsavedChangesDialog(false);
    setPendingAction(null);
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="p-4 border-b flex items-center justify-between">
          <button
            onClick={handleBackToHome}
            className="p-2 hover:bg-neutral-50 rounded-lg transition-colors border border-neutral-100"
          >
            <HomeIcon className="h-5 w-5" />
          </button>
          <div className="flex flex-row gap-1">
            {currentPageId && post?.publishedAt && (
              <HoverPopover
                buttonContent={<ArrowUpOnSquareIcon className="w-5 h-5" />}
                panelContent={
                  <div className="pt-1.5 pb-2 px-3 bg-black whitespace-nowrap">
                    <Typography color="white">Share Link</Typography>
                  </div>
                }
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/dashboard?helpCenterPostId=${currentPageId}`,
                  );
                  toast.success('Link copied to clipboard');
                }}
                buttonClassName="p-1.5 rounded-lg transition-colors hover:bg-neutral-50"
                panelClassName="z-[50] shadow rounded-lg pt-1"
              />
            )}
            <div className="flex items-center gap-2">
              {isAdmin && !isEditing && <HelpCenterMenu onCreateNew={handleCreateNew} onPublish={handlePublish} />}
              {isAdmin && isEditing && (
                <>
                  <button
                    onClick={handleCancel}
                    className="px-3 py-1.5 rounded-lg hover:bg-neutral-50 transition-colors"
                  >
                    <Typography>Cancel</Typography>
                  </button>
                  <button
                    onClick={handleSave}
                    className="px-3 py-1.5 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                  >
                    <Typography color="white">Save</Typography>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          {/* Error state */}
          {currentPageId && error && (
            <div className="flex flex-col items-center gap-4 text-center">
              <Typography>Failed to load article</Typography>
              <button
                onClick={handleBackToHome}
                className="px-4 py-2 bg-neutral-50 hover:bg-neutral-100 rounded-lg transition-colors"
              >
                <Typography>Return to Help Center</Typography>
              </button>
            </div>
          )}

          {currentPageId && !isLoading && !error && !post && <Typography>Article not found</Typography>}

          {/* Editing existing post */}
          {(currentPageId || isEditing) && !isLoading && !error && (
            <HelpCenterPost post={currentPageId && post ? post : EMPTY_POST} />
          )}

          {/* Home page */}
          {!currentPageId && !isEditing && <HelpCenterHome />}
        </div>
      </div>

      {/* Publish Confirmation Dialog */}
      <Dialog open={showPublishConfirm} onClose={() => setShowPublishConfirm(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="mx-auto max-w-sm rounded-lg bg-white p-6">
            <DialogTitle>
              <Typography size="lg" weight="bold">
                Publish Post
              </Typography>
            </DialogTitle>
            <div className="mt-4">
              <Typography>Are you sure you want to publish this post? This action cannot be undone.</Typography>
            </div>
            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={() => setShowPublishConfirm(false)}
                className="px-4 py-2 rounded-lg hover:bg-neutral-50 transition-colors"
              >
                <Typography>Cancel</Typography>
              </button>
              <button
                onClick={confirmPublish}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              >
                <Typography color="white">Publish</Typography>
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Unsaved Changes Dialog */}
      <Dialog
        open={showUnsavedChangesDialog}
        onClose={() => setShowUnsavedChangesDialog(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="mx-auto max-w-sm rounded-lg bg-white p-6">
            <DialogTitle>
              <Typography size="lg" weight="bold">
                Unsaved Changes
              </Typography>
            </DialogTitle>
            <div className="mt-4">
              <Typography>You have unsaved changes. Are you sure you want to discard them?</Typography>
            </div>
            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={() => {
                  setShowUnsavedChangesDialog(false);
                  setPendingAction(null);
                }}
                className="px-4 py-2 rounded-lg hover:bg-neutral-50 transition-colors"
              >
                <Typography>Keep Editing</Typography>
              </button>
              <button
                onClick={handleConfirmDiscard}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                <Typography color="white">Discard Changes</Typography>
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default HelpCenter;
