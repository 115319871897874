import Typography from '~/components/Typography';
import { IActiveSelectionEnum } from '../../types';
import React from 'react';
import { InboxIcon } from '@heroicons/react/24/outline';
import type { IFormula } from '~/services/parallel/formulas.types';
import ProposedExpenseCard from '../ProposedExpenseCard';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import { useSelector } from 'react-redux';
import type { State } from '~/store';
import { sectionTagMap, subTitleOptions, titleOptions } from '../expGenConstants';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';

export const ExpenseSection = ({
  activeSection,
  setModalState,
  expenseFormulas,
  scenarioUuid,
  integrationsWithMappings,
}: {
  activeSection: IActiveSelectionEnum;
  setModalState: (formulaUuid: string) => void;
  expenseFormulas: IFormula[];
  scenarioUuid: string;
  integrationsWithMappings: IIntegrationWithMappings[];
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { data: departments } = departmentsApi.useGetDepartmentsQuery({ orgUuid: organizationUuid });
  const departmentDict = departments?.dictionary ?? {};

  // Filter proposedExpenses based on activeSection
  const activeSectionProposedExpenses = expenseFormulas.filter(
    (expense) => expense.context?.expenseContext?.tag === sectionTagMap[activeSection],
  );

  const hasProposedData = activeSectionProposedExpenses.length > 0;

  return (
    <div className="w-full flex justify-center mt-20 pb-20">
      <div className="flex flex-col gap-4 max-w-[850px] min-w-[50vw]">
        <div className="flex flex-col">
          <Typography weight="bold">{titleOptions[activeSection]}</Typography>
          <Typography className="max-w-[816px]">{subTitleOptions[activeSection]}</Typography>
          {hasProposedData && (
            <div className="flex flex-col gap-4 mt-3 max-w-[900px]">
              {activeSectionProposedExpenses.map((expenseFormula) => (
                <ProposedExpenseCard
                  key={expenseFormula.formulaUuid}
                  expenseFormula={expenseFormula}
                  departmentDict={departmentDict}
                  scenarioUuid={scenarioUuid}
                  integrationsWithMappings={integrationsWithMappings}
                  setModalState={setModalState}
                />
              ))}
            </div>
          )}
        </div>
        {!hasProposedData && (
          <div className="flex flex-row gap-4 my-2 items-center border border-neutral-50 rounded-lg px-8 py-6">
            <InboxIcon className="min-w-6 max-w-6 h-auto stroke-neutral-400" />
            <Typography color="secondary">
              {`No data source for ${sectionTagMap[activeSection]} was found. Please review your accounting to ensure a
              ${sectionTagMap[activeSection]} category exists, or click 'Add New' below and select the appropriate source`}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
