import { IBusinessContext, IBusinessContextObject } from '~/pages/Chat/components/Settings/entity/types';
import { ZBusinessContext } from '~/pages/Chat/components/Settings/entity/schemas';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const getBusinessContext = async ({
  organizationUuid,
}: {
  organizationUuid: string;
}): Promise<IBusinessContext> => {
  const response = (await request({
    url: `/business-contexts`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;

  return ZBusinessContext.parse(response.data.data);
};

export const updateBusinessContext = async ({
  organizationUuid,
  businessContext,
}: {
  organizationUuid: string;
  businessContext: Omit<IBusinessContextObject, 'summary'>;
}): Promise<IBusinessContext> => {
  const response = (await request({
    url: `/business-contexts`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: businessContext,
  })) as IAPIResponse;

  return ZBusinessContext.parse(response.data.data);
};
