import React from 'react';
import { ExpenseFrequencyEnum, IFormula, IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import ExpensesToolbar from './components/ExpensesToolbar';
import { IBvaResult, IBvaReviewStatus } from '~/services/parallel/bva.types';
import BvaDropdown from '~/components/BvaDropdown';
import ExpensesGraph from './components/Graph/ExpensesGraph';
import UnusedExpenseDatasources from './components/UnusedExpenseDatasources/UnusedExpenseDatasources';
import { IIntegration, IIntegrationMapping, IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { ModalState } from './ExpensesPage';
import ExpenseFormulasTable from './ExpenseFormulasTable';
import * as stringDate from '~/utils/stringDate';
import Divider from '~/components/Divider';
import ExpenseEmptyState from './components/EmptyState/ExpenseEmptyState';

const ExpensesPageBody = ({
  scenarioUuid,
  formulas,
  selectedCategory,
  setSelectedCategory,
  hidePastExpenses,
  setHidePastExpenses,
  setModalState,
  bvaResult,
  integrations,
  mappings,
  setBvaModalState,
}: {
  scenarioUuid?: string | null;
  formulas: IFormula[];
  selectedCategory: string;
  setSelectedCategory: (value: string | boolean | null) => void;
  hidePastExpenses: boolean;
  setHidePastExpenses: (value: string | boolean | null) => void;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  bvaResult?: IBvaResult | null;
  integrations?: IIntegration[];
  mappings?: IIntegrationMapping[];
  setBvaModalState: React.Dispatch<React.SetStateAction<string | null>>;
}): React.ReactNode => {
  const expensesNeedsReview = Object.values(bvaResult?.expenseReview ?? {}).some(
    (review) => review.status === IBvaReviewStatus.Pending,
  );

  const integrationsWithMappings = ((): IIntegrationWithMappings[] => {
    if (!mappings || !integrations) {
      return [];
    }
    return integrations.map((integration) => ({
      ...integration,
      mappings: mappings.filter((mapping) => mapping.integrationUuid === integration.uuid),
    }));
  })();

  const hasPastExpenses = formulas
    .filter((formula) => formula.type === IFormulaTypeEnum.Expense)
    .some(
      (formula) =>
        (formula.recipe.endDate &&
          stringDate.isBefore({
            dateToCheck: formula.recipe.endDate,
            comparison: stringDate.getStringDate(),
          })) ||
        (formula.context?.expenseContext?.frequency === ExpenseFrequencyEnum.OneTime &&
          stringDate.isBefore({
            dateToCheck: formula.recipe.startDate as string,
            comparison: stringDate.getStringDate(),
          })),
    );

  if (formulas.every((formula) => formula.type !== IFormulaTypeEnum.Expense)) {
    return <ExpenseEmptyState setModalState={setModalState} />;
  }

  return (
    <div className="flex flex-col h-full items-end justify-center w-full pt-6 max-sm px-0">
      {expensesNeedsReview && !scenarioUuid && (
        <div className="w-full px-10">
          <BvaDropdown
            mode="expense"
            forecast={bvaResult?.expenseAggregate?.forecastedValues ?? []}
            actuals={bvaResult?.expenseAggregate?.actualValues ?? []}
          />
        </div>
      )}
      <div className="w-full">
        <ExpensesGraph
          expenseFormulas={formulas}
          filter={{
            category: selectedCategory,
            hidePastExpenses,
          }}
        />
      </div>
      {!!formulas.length && (
        <div className="w-full">
          <UnusedExpenseDatasources
            expenseIntegrationsWithMappings={integrationsWithMappings}
            onAction={(modalType, datasourceUuid) =>
              setModalState({ type: modalType, formulaUuid: null, datasourceUuid })
            }
          />
        </div>
      )}
      <Divider className="w-full py-4 px-10 " />
      <div className="w-full mb-2">
        <div className="w-full px-10 mb-2">
          <ExpensesToolbar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            hidePastExpenses={hidePastExpenses}
            setHidePastExpenses={setHidePastExpenses}
            setModalState={setModalState}
            hasPastExpenses={hasPastExpenses}
          />
        </div>
        <div className="w-full pl-10">
          <ExpenseFormulasTable
            onEdit={(formulaUuid, modalType) => {
              setModalState({ type: modalType ?? 'edit', formulaUuid });
            }}
            onEditForBva={(formulaUuid) => {
              setBvaModalState(formulaUuid);
            }}
            filter={{
              category: selectedCategory,
              hidePastExpenses,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpensesPageBody;
