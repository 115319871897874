import React, { useMemo, useState } from 'react';
import { IPositionDetails } from '../../entity/types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';
import * as stringDate from '~/utils/stringDate';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import logger from '~/utils/logger';
import { IStringDate } from '~/utils/stringDate/types';
import Timeline from '~/components/Timeline';
import HeadcountTimelineNodeContent from './HeadcountTimelineNodeContent';
import toast from 'react-hot-toast';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

interface IExistingPositionModalState {
  isOpen: boolean;
  position: IPositionDetails | null;
}

interface IHeadcountTimelineProps {
  organizationUuid: string;
  scenarioUuid: string | null;
  positions: IPositionDetails[];
  setCreatePositionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setDeletePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setUpdateCompensationModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setEditTermDateModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  positionIndexes: Record<string, number>;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  onlyNewHires?: boolean;
  addNewPosition?: boolean;
  listParams: IListPositionsParams;
}

const HeadcountTimeline = ({
  organizationUuid,
  scenarioUuid,
  positions,
  setCreatePositionModalIsOpen,
  setUpdatePositionModalState,
  setDeletePositionModalState,
  setUpdateCompensationModalState,
  setEditTermDateModalState,
  positionIndexes,
  createScenario,
  reloadDashboard,
  onlyNewHires,
  addNewPosition,
  listParams,
}: IHeadcountTimelineProps): React.ReactNode => {
  const dispatch = useDispatch();
  const [updatePosition] = positionsApi.useUpdatePositionMutation();
  const [updatePositionsDates] = positionsApi.useUpdatePositionsDatesMutation();
  const [draggingChanges, setDraggingChanges] = useState<{
    positionIds: string[];
    daysToAdd: number;
  }>({ positionIds: [], daysToAdd: 0 });
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const {
    configuration: { monthsOutToForecast },
  } = useSelector((state: State) => state.organization);

  const handleDateChange = async ({
    positionIds,
    daysToAdd,
  }: {
    positionIds: string[];
    daysToAdd: number;
  }): Promise<void> => {
    setDraggingChanges({ positionIds, daysToAdd });
  };

  const handleTogglePosition = (positionUuid: string) => async (isActive: boolean) => {
    try {
      const query: {
        scenarioUuid?: string;
        createScenario?: boolean;
      } = {};
      if (scenarioUuid) query.scenarioUuid = scenarioUuid;

      if (!scenarioUuid && createScenario) {
        query.createScenario = true;
        dispatch(updateScenarioLoadingState('creating'));
        dispatch(updateScenarioMode('creating'));
      } else if (scenarioUuid) {
        dispatch(updateScenarioLoadingState('updating'));
      }

      const response = await updatePosition({
        params: {
          orgUuid: organizationUuid,
          positionUuid,
        },
        query,
        body: { isActive },
        listParams,
      }).unwrap();

      if (!scenarioUuid && createScenario && response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      toast.error('Failed to update position');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  const handleSave = async ({ positionIds }: { positionIds: string[] }): Promise<void> => {
    if (!scenarioUuid && createScenario) {
      dispatch(updateScenarioMode('creating'));
      dispatch(updateScenarioLoadingState('creating'));
    } else if (createScenario) {
      dispatch(updateScenarioLoadingState('updating'));
    }

    const { daysToAdd } = draggingChanges;

    const updates = positions.reduce(
      (acc, position) => {
        if (positionIds.includes(position.positionUuid)) {
          const updatedPosition = {
            ...position,
            hireDate: stringDate.addDays(position.hireDate, daysToAdd),
          };

          if (position.termDate) {
            updatedPosition.termDate = stringDate.addDays(position.termDate, daysToAdd);
          }

          acc.push({
            positionUuid: position.positionUuid,
            hireDate: updatedPosition.hireDate,
            termDate: updatedPosition.termDate,
          });

          return acc;
        }

        return acc;
      },
      [] as {
        positionUuid: string;
        hireDate: string;
        termDate: string | null;
      }[],
    );

    setDraggingChanges((prev) => ({
      ...prev,
      positionIds: [],
      daysToAdd: 0,
    }));

    try {
      const query: {
        scenarioUuid?: string;
        createScenario?: boolean;
      } = {};
      if (scenarioUuid) query.scenarioUuid = scenarioUuid;
      if (!scenarioUuid && createScenario) query.createScenario = true;

      const response = await updatePositionsDates({
        query,
        body: updates,
        listParams,
      }).unwrap();

      if (response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      logger.error(error as Error);
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  let startBoundary: IStringDate = useMemo(() => {
    if (onlyNewHires) {
      return stringDate.startOfMonth(stringDate.subtractMonths(stringDate.getStringDate(), 1));
    }

    return positions.length
      ? positions.reduce(
          (earliestDate: IStringDate, { hireDate }: IPositionDetails): IStringDate => {
            return stringDate.isBefore({ dateToCheck: hireDate, comparison: earliestDate })
              ? stringDate.subtractMonths(hireDate, 1)
              : earliestDate;
          },
          stringDate.subtractMonths(stringDate.getStringDate(), 1),
        )
      : stringDate.subtractMonths(stringDate.getStringDate(), 1);
  }, [onlyNewHires, positions]);
  startBoundary = stringDate.startOfMonth(startBoundary);

  const endBoundary: IStringDate = stringDate.endOfMonth(
    stringDate.addMonths(stringDate.getStringDate(), monthsOutToForecast),
  );

  const timelineHeight = useMemo(() => {
    if (scenarioUuid) {
      return 'max-h-[calc(100vh_-_270px)]';
    } else {
      return 'max-h-[calc(100vh_-_214px)]';
    }
  }, [scenarioUuid]);

  const timelineNodes = positions
    .sort((a, b) => positionIndexes[a.positionUuid] - positionIndexes[b.positionUuid])
    .map((position) => {
      let alteredStartDate: string = position.hireDate;
      let alteredEndDate: string | null = position.termDate;
      if (draggingChanges.positionIds.includes(position.positionUuid)) {
        alteredStartDate = stringDate.addDays(position.hireDate, draggingChanges.daysToAdd);
        alteredEndDate = position.termDate ? stringDate.addDays(position.termDate, draggingChanges.daysToAdd) : null;
      }

      return (
        <Timeline.Node
          key={position.positionUuid}
          id={position.positionUuid}
          startDate={alteredStartDate}
          endDate={alteredEndDate ?? undefined}
          isScenario={!!position.scenarioUuid}
        >
          <HeadcountTimelineNodeContent
            position={position}
            hideSensitiveData={hideSensitiveData}
            onEditPosition={() => setUpdatePositionModalState({ isOpen: true, position })}
            onPayChange={() => setUpdateCompensationModalState({ isOpen: true, position })}
            onDeletePosition={() => setDeletePositionModalState({ isOpen: true, position })}
            onTogglePosition={handleTogglePosition(position.positionUuid)}
            onEditTermDate={() => setEditTermDateModalState({ isOpen: true, position })}
          />
        </Timeline.Node>
      );
    });

  const emptyTimeline = onlyNewHires ? (
    <div className="w-full h-[calc(90dvh_-_374px)] left-1/2 transform translate-x-1/3 ">
      <div className="sticky top-1/2 transform -translate-y-1/2 justify-center items-center flex px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
        <Typography color="empty">No Future Hires</Typography>
      </div>
    </div>
  ) : (
    <div className="sticky top-1/2 left-1/2 transform translate-x-1/2 -translate-y-1/2 justify-center items-center flex gap-2 px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
      <Typography color="empty">No Positions</Typography>
    </div>
  );

  return (
    <Timeline.Container
      startDateBoundary={startBoundary}
      endDateBoundary={endBoundary}
      onChange={handleDateChange}
      onSave={handleSave}
      className={timelineHeight}
      availableSelections={positions.map((position) => position.positionUuid)}
    >
      {timelineNodes.length > 0 ? timelineNodes : emptyTimeline}
      {addNewPosition && (
        <Button
          id="create-position-button"
          onClick={() => setCreatePositionModalIsOpen(true)}
          fill="outlineSolid"
          className="sticky bottom-8 left-5 ml-5 !w-fit z-10"
        >
          Create Position
        </Button>
      )}
    </Timeline.Container>
  );
};

export default HeadcountTimeline;
