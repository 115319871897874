import React, { useMemo } from 'react';
import { SelectMultiple } from '~/components/UncontrolledComponents/SelectMultiple';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { IFormula } from '~/services/parallel/formulas.types';
import Button from '~/components/Button';
import { useDispatch } from 'react-redux';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';

const ZMappingsForm = z.object({
  dataSourceUuids: z.array(z.string()),
});

type IMappingFormData = z.infer<typeof ZMappingsForm>;

const IntegrationMappingsForm = ({
  onClose,
  scenarioUuid,
  expenseIntegrationsWithMappings,
  initialFormulaData,
  formulaList,
}: {
  onClose: ({ successfulSave }: { successfulSave: boolean }) => void;
  scenarioUuid: string | null;
  expenseIntegrationsWithMappings: IIntegrationWithMappings[];
  initialFormulaData?: Partial<IFormula>;
  formulaList: IFormula[];
}): React.ReactNode => {
  const dispatch = useDispatch();
  const [updateFormula] = formulasApi.useUpdateFormulaMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<IMappingFormData>({
    defaultValues: {
      dataSourceUuids: initialFormulaData?.dataSourceUuids ?? [],
    },
    resolver: zodResolver(ZMappingsForm),
    mode: 'all',
  });

  const usedIntegrations = useMemo(() => {
    return formulaList.reduce((acc: Set<string>, formula) => {
      if (formula.dataSourceUuids.length) {
        formula.dataSourceUuids.forEach((uuid) => {
          acc.add(uuid);
        });
      }
      return acc;
    }, new Set<string>());
  }, [formulaList]);

  const onSubmitForm = handleSubmit(async (data) => {
    if (!initialFormulaData?.formulaUuid) return;
    try {
      if (scenarioUuid) {
        dispatch(updateScenarioLoadingState('updating'));
      }

      const updateData: {
        formulaUuid: string;
        dataSourceUuids: string[];
      } = {
        formulaUuid: initialFormulaData.formulaUuid,
        dataSourceUuids: data.dataSourceUuids,
      };

      const query: {
        scenarioUuid?: string;
      } = {};

      if (scenarioUuid) query.scenarioUuid = scenarioUuid;

      await updateFormula({
        query,
        body: updateData,
      }).unwrap();

      onClose({ successfulSave: true });
    } catch (error) {
      setError('root', {
        message: 'Failed to update integration. Please try again.',
      });
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  });

  return (
    <div className="w-full">
      <Controller
        control={control}
        name="dataSourceUuids"
        render={({ field, fieldState }) => (
          <SelectMultiple
            label="Data Sources"
            id="expense-sync-with-actuals"
            disabled={expenseIntegrationsWithMappings.length === 0}
            placeholder={
              expenseIntegrationsWithMappings.length === 0 ? 'Setup connection in settings to sync' : 'Select options'
            }
            onChange={(value) => field.onChange(value)}
            options={expenseIntegrationsWithMappings.flatMap((integration) =>
              integration.mappings.map((mapping) => ({
                value: mapping.uuid,
                label: mapping.name,
                disabled: usedIntegrations.has(mapping.uuid),
              })),
            )}
            value={field.value}
            error={fieldState.error?.message}
            includeSearch
          />
        )}
      />
      <div className="flex flex-col mt-6 gap-5">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex justify-between gap-5">
          <Button
            id="cancel-edit-mappings"
            className="!w-auto !px-0"
            fill="clear"
            onClick={() => onClose({ successfulSave: false })}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            id="save-edit-mappings"
            className="!w-auto"
            loading={isSubmitting}
            onClick={() => {
              onSubmitForm();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationMappingsForm;
