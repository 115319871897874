import React from 'react';
import EmptyExpenseIcon from './EmptyExpenseIcon';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { ModalState } from '../../ExpensesPage';

const ExpenseEmptyState = ({
  setModalState,
}: {
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
}): React.ReactElement => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full mt-16 gap-4">
      <div className="flex flex-col items-center justify-center w-[440px] gap-2">
        <EmptyExpenseIcon />
        <Typography weight="semibold">Forecasting Expenses</Typography>
        <Typography className="text-center" color="secondary">
          This will help you anticipate costs, manage cash flow, and make informed decisions on hiring and growth —
          ensuring better resource allocation.
        </Typography>
        <Button
          className="!w-fit"
          onClick={() =>
            setModalState({
              type: 'generate',
              formulaUuid: null,
            })
          }
        >
          Auto-Generated Expenses
        </Button>
        <Button
          className="!w-fit"
          fill="clear"
          onClick={() =>
            setModalState({
              type: 'create',
              formulaUuid: null,
            })
          }
          id="new-expense-button"
        >
          Manually Add Expenses
        </Button>
      </div>
    </div>
  );
};

export default ExpenseEmptyState;
