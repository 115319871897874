import { UsersIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Divider from '~/components/Divider';
import DepartmentOptions from './DepartmentOptions';
import { useInput } from '~/components/Input';
import { IDepartment } from '~/services/parallel/departments.types';

export interface DepartmentProps {
  department: IDepartment;
  refreshDepartments: () => void;
}

const DepartmentListItem: React.FC<DepartmentProps> = ({ department, refreshDepartments }) => {
  const [newDepartmentName, setNewDepartmentName] = useInput({});
  return (
    <div className="w-full">
      <Divider />
      <div className="w-full my-4 flex flex-row justify-between items-center">
        <div className="w-full flex flex-row gap-2 items-center">
          <UsersIcon className="size-4" />
          <div data-testid={`department-item-${department.departmentUuid}`} className="text-sm">
            {department.name}
          </div>
        </div>
        <DepartmentOptions
          refreshDepartments={refreshDepartments}
          department={department}
          newDepartmentName={newDepartmentName}
          setNewDepartmentName={setNewDepartmentName}
        />
      </div>
    </div>
  );
};

export default DepartmentListItem;
