import React, { useEffect } from 'react';
import Select from '~/components/Select';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { SelectState, SelectType } from '~/components/Select/Select.types';

interface Props {
  departmentOptions: SelectState;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  className?: string;
  buttonClassName?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
  required?: boolean;
  id?: string;
  onBlur?: () => void;
  errorMessage?: string;
  onFocus?: () => void;
  thin?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * Undefined is the value for the first 2 because no parent means no padding necessary, if you have 1 level of parenting
 * the 24px of padding that we need to add is taken up by the arrow right icon
 */

const SelectDepartment = ({
  departmentOptions,
  setDepartmentOptions,
  className = '',
  buttonClassName = '',
  listBoxClassName = '',
  label,
  placeholder,
  allowSelectAll = false,
  required = false,
  id = 'department',
  onBlur,
  errorMessage = 'Please make a selection',
  onFocus,
  thin = false,
  disabled = false,
  loading = false,
}: Props): React.ReactNode => {
  const departments = useSelector((state: State) => state.organization.departments);

  useEffect(() => {
    const options: SelectType[] = [];
    if (allowSelectAll && departments.length > 1) {
      options.push({ label: 'All Departments', value: null });
    }
    setDepartmentOptions((prevState) => {
      // Determine the initial selected option
      let selected = prevState.selected;

      if (selected && !selected.value) {
        // Attempt to find a matching department by label
        const matchedDepartment = departments.find((department) => department.name === selected?.label);
        if (matchedDepartment) {
          selected = {
            label: matchedDepartment.name,
            value: matchedDepartment.departmentUuid,
          };
        }
      } else if (allowSelectAll) {
        selected = { label: 'All Departments', value: null };
      } else if (departments.length === 1) {
        selected = {
          label: departments[0].name,
          value: departments[0].departmentUuid,
        };
      }

      return {
        ...prevState,
        selected: selected,
        options: [
          ...options,
          ...departments.map((department) => ({
            label: department.name,
            value: department.departmentUuid,
          })),
        ],
      };
    });
  }, [departments, allowSelectAll, setDepartmentOptions]);

  return (
    <Select
      id={`${id}-select`}
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      buttonClassName={buttonClassName}
      placeholder={placeholder}
      listBoxClassName={listBoxClassName}
      required={required}
      onBlur={onBlur}
      errorMessage={errorMessage}
      onFocus={onFocus}
      thin={thin}
      disabled={disabled}
      loading={loading}
    />
  );
};

export default SelectDepartment;
