import React, { useMemo } from 'react';
import { IChangeLogDescription } from '../entity/types';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Typography from '~/components/Typography';
import BaseTable from '~/components/Table/Base/BaseTable';
import ChangeCell from './ChangeCell';
import { IFormula } from '~/services/parallel/formulas.types';

const ChangesTable = ({
  changes,
  formulaDictionary,
}: {
  changes: IChangeLogDescription[];
  formulaDictionary: Record<string, IFormula>;
}): React.ReactElement => {
  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'changedField', label: 'Attribute' },
      { id: 'originalValue', label: 'Original' },
      { id: 'newValue', label: 'Change' },
    ];
    const columnHelper = createColumnHelper<IChangeLogDescription>();

    const tableColumns = columns.map((col) =>
      columnHelper.accessor(col.id as 'changedField' | 'originalValue' | 'newValue', {
        header: () => <Typography color="empty">{col.label}</Typography>,
        enableResizing: false,
        enablePinning: false,
        enableSorting: false,
        cell: (info) => (
          <ChangeCell
            change={info.row.original}
            formulaDictionary={formulaDictionary}
            isOriginal={col.id === 'originalValue'}
            isField={col.id === 'changedField'}
          />
        ),
      }),
    );

    const tableData = changes;

    return { tableData, tableColumns };
  }, [changes, formulaDictionary]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData as unknown as IChangeLogDescription[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full overflow-x-scroll overflow-y-scroll hide-scrollbar bg-white rounded-xl border">
      <BaseTable
        id="changes-table"
        // @ts-expect-error - BaseTable doesn't like typed rows
        table={table}
        emptyState={<></>}
        styles={{
          th: 'pt-2 px-4 text-xs font-normal text-neutral-200 text-nowrap',
          table: 'mb-2',
          td: 'px-4 text-nowrap',
        }}
      />
    </div>
  );
};

export default ChangesTable;
