import React from 'react';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
interface Props {
  id?: string;
  className?: string;
  children: string | React.ReactNode;
  tag?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: Size;
  weight?: 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  color?:
    | 'invert'
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'empty'
    | 'warning'
    | 'special'
    | 'lightGray'
    | 'primaryGreen'
    | 'green'
    | 'subHead'
    | 'lightBlue'
    | 'blue'
    | 'orange'
    | 'purple'
    | 'white'
    | 'turquoise'
    | 'actualBlue'
    | 'lightGreen'
    | 'lightestBlue'
    | 'mediumBlue'
    | 'percentGreen';
}

const Typography = React.forwardRef(
  (
    { id = '', className = '', children, tag = 'span', size = 'sm', weight = 'normal', color = 'primary' }: Props,
    forwardedRef: React.Ref<HTMLElement | undefined>,
  ) => {
    const lineHeight: { [K in typeof size]: string } = {
      xs: 'leading-4',
      sm: 'leading-5',
      md: 'leading-6',
      lg: 'leading-7',
      xl: 'leading-8',
      '2xl': 'leading-9',
    };

    const fontWeight: { [K in typeof weight]: string } = {
      thin: 'font-thin',
      extralight: 'font-extralight',
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    };
    const textSize: { [K in Size]: string } = {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    };
    const fontColor: { [K in typeof color]: string } = {
      invert: 'text-neutral-0',
      primary: 'text-neutral-800',
      secondary: 'text-neutral-400',
      disabled: 'text-neutral-100',
      empty: 'text-neutral-200',
      warning: 'text-red-500',
      special: 'text-orange',
      lightGray: 'text-neutral-300',
      primaryGreen: 'text-green-400',
      green: 'text-green-500',
      subHead: 'text-neutral-700',
      lightBlue: 'text-blue-300',
      blue: 'text-blue-500',
      orange: 'text-orange',
      purple: 'text-purple',
      white: 'text-white',
      turquoise: 'text-[#45A59F]',
      actualBlue: 'text-[#3C98C0]',
      lightGreen: 'text-green-200',
      lightestBlue: 'text-blue-200',
      mediumBlue: 'text-blue-400',
      percentGreen: 'text-[#579246]',
    };

    const Tag = tag;

    return (
      <Tag
        ref={forwardedRef}
        title={className.includes('truncate') && typeof children === 'string' ? children : undefined}
        className={`tracking-normal ${fontColor[color]} ${textSize[size]} ${fontWeight[weight]} ${lineHeight[size]} ${className}`}
        data-testid={id}
      >
        {children}
      </Tag>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
