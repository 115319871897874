import React from 'react';
import Typography from '~/components/Typography';
import * as stringDate from '~/utils/stringDate';

const GraphFooter = ({ xMin, xMax }: { xMin: number; xMax: number }): React.ReactElement => {
  return (
    <div className="w-full flex items-center justify-between pl-[15%]">
      <Typography color="empty">
        {stringDate.format(stringDate.getStringDate(new Date(xMin)), "MMM 'yy").toUpperCase()}
      </Typography>
      <Typography color="empty">
        {stringDate.format(stringDate.getStringDate(new Date(xMax)), "MMM 'yy").toUpperCase()}
      </Typography>
    </div>
  );
};

export default GraphFooter;
