import React, { useEffect, useRef } from 'react';
import { SelectState, SelectType } from '~/components/Select/Select.types';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Typography from '~/components/Typography';
import useSelect from './useSelect';
import { v4 } from 'uuid';
import Spinner from '../Spinner';

interface Props {
  id: string;
  state: SelectState;
  setState: React.Dispatch<React.SetStateAction<SelectState>>;
  label?: string;
  className?: string;
  buttonClassName?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: 'below' | 'above';
  required?: boolean;
  listBoxClassName?: string;
  onBlur?: (selected?: SelectType) => void;
  onFocus?: () => void;
  activeBackgroundColor?: string;
  onChange?: (value: SelectType) => void;
  buttonIconColor?: string;
  thin?: boolean;
  loading?: boolean;
}

const Select = ({
  id,
  label,
  state,
  setState,
  className,
  buttonClassName,
  errorMessage = 'Please make a selection',
  placeholder = 'Select an option',
  disabled = false,
  checkmark = true,
  direction = 'below',
  required = false,
  listBoxClassName = '',
  onBlur,
  onFocus,
  activeBackgroundColor = 'bg-green-100',
  onChange,
  buttonIconColor = 'text-gray-400',
  thin = false,
  loading = false,
}: Props): React.ReactNode => {
  const showError = state.touched && !state.pristine && !state.valid;

  let spanClass = `truncate block truncate text-sm leading-5`;
  if (state.disabled || disabled) {
    spanClass += ' text-neutral-100';
  } else if (state.selected?.value) {
    spanClass += ' text-neutral-900';
  } else {
    spanClass += ' text-neutral-400';
  }
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onBlur?.(state.selected);
    }
  }, [state.selected]);

  return (
    <div className={`w-full ${className ?? ''}`} data-testid={id}>
      {label && (
        <div className="flex flex-row mb-1">
          <Typography tag="span" className={`${state.disabled || disabled ? ' !text-neutral-200' : ''}`}>
            {label}
          </Typography>
          {required && (
            <Typography tag="span" size="xs" className={`${state.disabled || disabled ? ' !text-neutral-200' : ''}`}>
              *
            </Typography>
          )}
        </div>
      )}
      <Listbox
        disabled={state.disabled || disabled}
        value={state.selected}
        onChange={
          onChange ??
          ((selections: SelectType): void => {
            const selected = state.options.find((option) => option.value === selections.value);
            if (selected) {
              setState((prevState) => ({
                ...prevState,
                valid: true,
                pristine: false,
                touched: true,
                selected,
              }));
            }
          })
        }
      >
        <div className="relative">
          <Listbox.Button
            data-testid={`${id}-button`}
            onClick={() => {
              if (onFocus) {
                onFocus();
              }
            }}
            className={`relative cursor-pointer disabled:cursor-not-allowed w-full max-w-full truncate border border-solid ${
              showError ? 'border-red-300' : 'border-gray-300'
            } 
            ${buttonClassName ?? ''}
            ${!state.selected?.value && '!text-neutral-75'}
            rounded shadow-sm bg-white disabled:bg-neutral-25 disabled:text-neutral-100 pl-3 pr-10 text-left focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 ${
              thin ? ' py-1' : ' py-2'
            }`}
          >
            <span data-testid={`${id}-selection`} className={spanClass}>
              {state.selected?.node ?? state.selected?.label ?? placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              {loading ? (
                <Spinner strokeColor={'#AAB7A4'} size={16} />
              ) : (
                <ChevronUpDownIcon className={`size-5 ${buttonIconColor}`} aria-hidden="true" />
              )}
            </span>
          </Listbox.Button>
          <Transition as={'div'} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className={`absolute ${direction === 'below' ? 'top-full' : 'bottom-full'} w-full z-20`}>
              {direction === 'below' && <div className="w-full h-1 bg-transparent" />}
              <Listbox.Options
                className={`max-h-60 rounded w-full overflow-auto bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listBoxClassName}`}
              >
                {state.options.map((option) => {
                  const isSelected = option.value === state.selected?.value;

                  if (option.value === 'divider') {
                    return (
                      <Listbox.Option
                        key={`${option.value}-${v4()}`}
                        disabled={!!true}
                        className={`relative cursor-pointer select-none py-2 pl-3 pr-4 text-xs`}
                        value={option}
                      >
                        <hr className="border-gray-300" />
                      </Listbox.Option>
                    );
                  }

                  return (
                    <Listbox.Option
                      data-testid={`${id}-option-${option.value}`}
                      key={`${option.value}-${v4()}`}
                      disabled={option.disabled}
                      className={({ active }) =>
                        `relative text-sm leading-5 cursor-pointer select-none py-2${
                          checkmark ? `${thin ? ' pl-8' : ' pl-10'}` : ` ${thin ? 'pl-2' : 'pl-3'}`
                        } pr-4${active && ` ${activeBackgroundColor} text-green-900`}${
                          isSelected ? ` ${!checkmark && activeBackgroundColor} text-green-300` : ' text-gray-900'
                        }${option.disabled && ' !text-neutral-75'}`
                      }
                      value={option}
                    >
                      <span className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'}`}>
                        {option.node ?? option.label}
                      </span>
                      {isSelected && checkmark && (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center ${thin ? 'pl-2' : 'pl-3'} text-green-600`}
                        >
                          <CheckIcon className={thin ? 'size-4' : 'size-5'} aria-hidden="true" />
                        </span>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
              {direction === 'above' && <div className="w-full h-1 bg-transparent" />}
            </div>
          </Transition>
          {showError && Boolean(errorMessage.length) && (
            <p className="text-red-500 text-xs italic p-1" data-testid={`${id}-select-error`}>
              {errorMessage}
            </p>
          )}
        </div>
      </Listbox>
    </div>
  );
};

export { useSelect };
export default Select;
