import { IActiveSelectionEnum } from '../types';

export const titleOptions: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: 'People & Facilities',
  [IActiveSelectionEnum.COGS]: 'Cost of Goods Sold (ie. COGS)',
  [IActiveSelectionEnum.SOFTWARE]: 'Software',
  [IActiveSelectionEnum.MARKETING]: 'Marketing',
  [IActiveSelectionEnum.OTHER]: 'Other',
  [IActiveSelectionEnum.FINALIZE]: '',
};

export const subTitleOptions: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: `These are forecasted expenses that relate to your employees and facility, often making up a large portion of your spend due to taxes, benefits, and rent.`,
  [IActiveSelectionEnum.COGS]: `These expenses are forecasted using historical spending patterns in relation to revenue. As you refine your model, the forecast can become more granular, with expenses driven by other factors like customer count.`,
  [IActiveSelectionEnum.SOFTWARE]: `To streamline your software expense forecasting, we start by forecasting it as a single category. You can expand it into more detailed projections whenever it's beneficial.`,
  [IActiveSelectionEnum.MARKETING]:
    'This category can be a lot more volatile than other expenses, make sure to add in any future conferences or campaigns that would significantly impact your budget.',
  [IActiveSelectionEnum.OTHER]: `This category includes items that don't fit into others, such as legal fees and services. Unrecognized vendors may also appear here; please reassign them to the correct category.`,
  [IActiveSelectionEnum.FINALIZE]: '',
};

export const sectionTagMap: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: 'People & Facilities',
  [IActiveSelectionEnum.COGS]: 'Cost of Goods Sold',
  [IActiveSelectionEnum.SOFTWARE]: 'Software',
  [IActiveSelectionEnum.MARKETING]: 'Marketing',
  [IActiveSelectionEnum.OTHER]: 'Other',
  [IActiveSelectionEnum.FINALIZE]: '',
};

export const categoryTagMap: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: 'People & Facilities',
  [IActiveSelectionEnum.COGS]: 'COGS',
  [IActiveSelectionEnum.SOFTWARE]: 'Software',
  [IActiveSelectionEnum.MARKETING]: 'Marketing',
  [IActiveSelectionEnum.OTHER]: 'Other',
  [IActiveSelectionEnum.FINALIZE]: '',
};
