import { z } from 'zod';
import request from '~/utils/request';
import {
  IIntegration,
  IIntegrationMapping,
  ZIntegration,
  ZIntegrationMapping,
  IIntegrationMappingScope,
} from './integrations.types';
import { IAPIResponse } from '~/utils/types';

export const list = async ({
  organizationUuid,
  accessTokenOverride,
}: {
  organizationUuid: string;
  accessTokenOverride?: string | null;
}): Promise<IIntegration[]> => {
  const integrations = (await request({
    url: `/integrations`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    accessTokenOverride,
  })) as IAPIResponse;

  return z.array(ZIntegration).parse(integrations.data.data);
};

export const listMappings = async ({
  organizationUuid,
  integrationUuid,
  scope = Object.values(IIntegrationMappingScope),
  accessTokenOverride,
}: {
  organizationUuid: string;
  integrationUuid: string;
  scope?: IIntegrationMappingScope[];
  accessTokenOverride?: string | null;
}): Promise<IIntegrationMapping[]> => {
  const mappings = (await request({
    url: `/integrations/${integrationUuid}/mappings`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: { scope },
    accessTokenOverride,
  })) as IAPIResponse;

  return z.array(ZIntegrationMapping).parse(mappings.data.data);
};
