import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  IEmploymentType,
  EMPLOYMENT_TYPE_OPTIONS,
  ZCreatePositionForm,
  ICreatePositionForm,
} from '~/services/parallel/headcount.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import Input from '~/components/UncontrolledComponents/Input';
import { Select } from '~/components/UncontrolledComponents/Select';
import { SelectDepartment } from '~/components/UncontrolledComponents/SelectDepartment';
import DatePicker from '~/components/DatePicker';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import toast from 'react-hot-toast';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import Typography from '~/components/Typography';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { useDispatch } from 'react-redux';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { IOnboardingStepNameEnum, IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const CreatePositionForm = ({
  orgUuid,
  scenarioUuid,
  onClose,
  createScenario,
  reloadDashboard,
  awaitCalculations,
  listParams,
  setPositionIndexes,
}: {
  orgUuid: string;
  scenarioUuid?: string | null;
  onClose: () => void;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
  setPositionIndexes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery();
  const [updateOnboardingStep] = onboardingStepsApi.useUpdateOnboardingStepMutation();
  const [createPositions, { isLoading: isCreatingPositions }] = positionsApi.useCreatePositionsMutation();
  const [createMultiple, setCreateMultiple] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
    formState: { errors },
    reset,
  } = useForm<ICreatePositionForm>({
    defaultValues: {
      employeeName: undefined,
      title: '',
      employmentType: IEmploymentType.FullTime,
      departmentUuid: undefined,
      payRate: undefined,
      hireDate: undefined,
      numberToCreate: '1',
    },
    resolver: zodResolver(ZCreatePositionForm),
    mode: 'all',
    shouldFocusError: false,
  });

  const onSubmitForm = handleSubmit(async (data) => {
    if (!scenarioUuid && createScenario) {
      dispatch(updateScenarioLoadingState('creating'));
    } else if (scenarioUuid) {
      dispatch(updateScenarioLoadingState('updating'));
    }
    try {
      const creationData = {
        employeeName: null,
        title: data.title,
        employmentType: data.employmentType,
        departmentUuid: data.departmentUuid,
        payRate: [{ value: Number(data.payRate) * CENTS_PER_DOLLAR, effectiveAt: 'onHire' }],
        hireDate: data.hireDate,
      };

      const query: {
        scenarioUuid?: string;
        numberToCreate: number;
        createScenario?: boolean;
        awaitCalculations?: boolean;
      } = {
        numberToCreate: Number(data.numberToCreate),
      };

      if (scenarioUuid) query.scenarioUuid = scenarioUuid;
      if (!scenarioUuid && createScenario) query.createScenario = true;
      if (awaitCalculations) query.awaitCalculations = true;

      const response = await createPositions({
        params: {
          orgUuid,
        },
        query,
        body: creationData,
        listParams,
        updateIndexes: setPositionIndexes,
      }).unwrap();

      if (!scenarioUuid && createScenario && response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      reset(
        {
          employeeName: undefined,
          title: '',
          employmentType: IEmploymentType.FullTime,
          departmentUuid: undefined,
          payRate: undefined,
          hireDate: undefined,
          numberToCreate: '1',
        },
        {
          keepErrors: false,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false,
        },
      );

      const onboardingStep = onboardingSteps.data?.dictionary[IOnboardingStepNameEnum.AddHeadcount];
      if (onboardingStep && onboardingStep.status === IOnboardingStepStatusEnum.Ready) {
        await updateOnboardingStep({
          onboardingStepUuid: onboardingStep.uuid,
          body: {
            status: IOnboardingStepStatusEnum.Active,
          },
        });
      }

      onClose();
      toast.success('Position created successfully');
      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      setError('root', {
        message: 'Failed to create position. Please try again.',
      });
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  });

  return (
    <div className="flex flex-col w-full gap-4">
      <Input
        {...register('title')}
        id="title"
        placeholder="Title"
        label="Title"
        type="text"
        error={errors.title?.message}
      />
      <Controller
        control={control}
        name="employmentType"
        render={({ field, fieldState }) => (
          <Select
            id="employmentType"
            label="Employment Type"
            options={EMPLOYMENT_TYPE_OPTIONS}
            error={fieldState.error?.message}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="departmentUuid"
        render={({ field, fieldState }) => (
          <SelectDepartment
            id="departmentUuid"
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
            }}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="payRate"
        render={({ field, fieldState }) => (
          <Input
            id="payRate"
            label="Annual Compensation"
            type="currency"
            includeDollarSign
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              trigger('payRate');
            }}
            placeholder="Annual Compensation"
            info={{
              infoContent: (
                <div className="flex flex-col items-start">
                  <Typography color="white" weight="semibold">
                    Total Annual Compensation (Est.)
                  </Typography>
                  <Typography color="white">
                    Include salary, bonuses, and commissions. Estimate total annual pay for hourly employees.
                  </Typography>
                </div>
              ),
              infoIcon: <QuestionMarkCircleIcon className="size-[22px] text-neutral-300" />,
              infoButtonClassName: 'cursor-pointer',
              infoPanelClassName: 'bg-black w-[350px] max-w-[350px] rounded-md px-4 py-2',
              iconPositioning: {
                top: '2',
                right: '1',
              },
            }}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="hireDate"
        render={({ field, fieldState }) => {
          // Create a default state for the date picker from the current field value
          const defaultDatePickerState: IDatePickerState = {
            value: {
              startDate: field.value,
              endDate: field.value,
            },
            valid: !fieldState.error,
            errorMessage: fieldState.error?.message,
            touched: !!fieldState.error, // Mark as touched if there's an error
            pristine: !fieldState.error, // Mark as not pristine if there's an error
          };

          // Wrap the setter so it supports both functional and direct updates
          const customSetDatePickerState: React.Dispatch<React.SetStateAction<IDatePickerState>> = (action) => {
            const newState = typeof action === 'function' ? action(defaultDatePickerState) : action;
            // Update the form field with the new start date value (adjust if needed)
            field.onChange(newState.value.startDate);
            trigger('hireDate');
          };

          return (
            <DatePicker
              id="inline-hireDate"
              state={defaultDatePickerState}
              setState={customSetDatePickerState}
              label="Hire Date"
            />
          );
        }}
      />
      <label className="flex flex-row gap-2 items-center mt-2">
        <Checkbox
          id="create-multiple-positions"
          checked={createMultiple}
          toggleValue={() => {
            setCreateMultiple((prev) => {
              const newValue = !prev;
              setValue('numberToCreate', '1');
              return newValue;
            });
          }}
        />
        <Typography color="secondary" className="text-sm">
          Create Multiple Positions
        </Typography>
      </label>
      <div
        className={`transition-all duration-300 ${createMultiple ? 'max-h-[91px] min-h-[67px] h-[67px] opacity-100 overflow-visible' : 'max-h-0 min-h-0 h-0 opacity-0 overflow-hidden'}`}
      >
        <Controller
          control={control}
          name="numberToCreate"
          render={({ field, fieldState }) => (
            <Input
              id="numberToCreate"
              label="Count"
              type="currency"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-4 mt-2">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex flex-row justify-between">
          <Button
            fill="clear"
            className="!w-fit !px-0 !py-2"
            onClick={() => {
              onClose();
              reset(
                {
                  employeeName: undefined,
                  title: '',
                  employmentType: IEmploymentType.FullTime,
                  departmentUuid: undefined,
                  payRate: undefined,
                  hireDate: undefined,
                  numberToCreate: '1',
                },
                {
                  keepErrors: false,
                  keepDirty: false,
                  keepIsSubmitted: false,
                  keepTouched: false,
                  keepIsValid: false,
                  keepSubmitCount: false,
                },
              );
            }}
          >
            Cancel
          </Button>
          <Button
            className="!w-fit !px-4 !py-2"
            onClick={() => {
              onSubmitForm();
            }}
            loading={isCreatingPositions}
          >
            Create Position
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePositionForm;
