import { z } from 'zod';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

export type IIntegrationSources = 'quickbooks-online' | 'xero';
export const IIntegrationSources = {
  Quickbooks: 'quickbooks-online',
  Xero: 'xero',
} as const;

export const ZIntegrationEntity = z.enum([
  'IncomeStatement',
  'CashFlowStatement',
  'BalanceSheet',
  'GeneralLedgerTransaction',
  'Account',
]);
export type IIntegrationEntity = z.infer<typeof ZIntegrationEntity>;

export const ZIntegrationCategory = z.enum(['accounting']);

export type IIntegrationCategory = z.infer<typeof ZIntegrationCategory>;

export const ZIntegrationSource = z.object({
  uuid: z.string(),
  createdAt: iso8601Validator,
  updatedAt: iso8601Validator.nullable(),
  name: z.string(),
  slug: z.nativeEnum(IIntegrationSources),
  category: z.lazy(() => ZIntegrationCategory),
  entities: z.array(ZIntegrationEntity),
});

export type IIntegrationSource = z.infer<typeof ZIntegrationSource>;

export const ZCreateIntegrationSource = z.object({
  name: z.string(),
  slug: z.nativeEnum(IIntegrationSources),
  category: z.lazy(() => ZIntegrationCategory),
  entities: z.array(ZIntegrationEntity),
});

export type ICreateIntegrationSource = z.infer<typeof ZCreateIntegrationSource>;

export enum IIntegrationMappingScope {
  ModelBuilder = 'modelBuilder',
  Expense = 'expense',
  Headcount = 'headcount',
}
export const ZIntegrationMappingScope = z.nativeEnum(IIntegrationMappingScope);

export const ZIntegrationMapping = z.object({
  uuid: z.string(),
  createdAt: iso8601Validator,
  integrationUuid: z.string(),
  updatedAt: iso8601Validator.nullable(),
  name: z.string(),
  remoteId: z.string(),
  entity: z.string(),
  scope: ZIntegrationMappingScope,
  currentlyInUse: z.boolean(),
});

export type IIntegrationMapping = z.infer<typeof ZIntegrationMapping>;

export type IEntitySyncStatus = 'syncing' | 'synced';
export const IEntitySyncStatus = {
  SYNCING: 'syncing',
  SYNCED: 'synced',
} as const;

export type IRemoteOptions = 'merge';
export const IRemoteOptions = {
  Merge: 'merge',
} as const;

export interface IIntegrationCreate {
  uuid?: string;
  organizationUuid: string;
  lastSyncAttempt?: Date;
  lastSyncSuccess?: Date;
  category: IIntegrationCategory;
  sourceUuid: string;
  entities: IIntegrationEntity[];
  integrationMetadata: IIntegrationMetadata;
  isCredentialsValid: boolean;
}

export type IIntegration = z.infer<typeof ZIntegration>;

export type ICreateIntegrationMapping = Omit<IIntegrationMapping, 'uuid' | 'createdAt' | 'updatedAt'>;

export const ZIntegrationMetadata = z.object({
  remoteId: z.string(),
  accountToken: z.string(),
  entitySyncStatus: z.array(
    z.object({
      name: z.string(),
      slug: z.string(),
      status: z.nativeEnum(IEntitySyncStatus),
      lastSyncStartAt: z.string(),
    }),
  ),
});

export type IIntegrationMetadata = z.infer<typeof ZIntegrationMetadata>;

export const ZIntegration = z.object({
  uuid: z.string(),
  createdAt: iso8601Validator,
  updatedAt: iso8601Validator,
  organizationUuid: z.string().uuid(),
  lastSyncAttempt: iso8601Validator.nullable(),
  lastSyncSuccess: iso8601Validator.nullable(),
  integrationMetadata: ZIntegrationMetadata,
  sourceUuid: z.string(),
  category: ZIntegrationCategory,
  entities: z.array(z.string()),
  isCredentialsValid: z.boolean(),
  source: ZIntegrationSource.optional(),
  lastPulled: iso8601Validator.nullable(),
  isPulling: z.boolean(),
});

export type IIntegrationWithMappings = IIntegration & { mappings: IIntegrationMapping[] };
