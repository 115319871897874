import { isSameMonth } from 'date-fns';
import React from 'react';
import LineGraph from '~/components/LineGraph';
import { IDataArrayDictionary } from '~/components/LineGraph/entity/types';
import { getDecimalBasedOnValue } from '~/pages/Dashboard/utils/getDecimalBasedOnValue';
import { IDateValue } from '~/services/parallel/bva.types';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import { toZonedTime } from 'date-fns-tz';

const BvaGraph = ({
  forecast,
  actuals,
  xAxisPadding,
}: {
  forecast: IDateValue[];
  actuals: IDateValue[];
  xAxisPadding?: { left: number; right: number };
}): React.ReactNode => {
  const months = forecast.map(({ date }) => date);
  const dataToUse = months.reduce((acc, month) => {
    const forecastValue = forecast.find(({ date }) => isSameMonth(date, month))?.value;
    const actualValue = actuals.find(({ date }) => isSameMonth(date, month))?.value;
    acc.push({
      date: toZonedTime(month, 'UTC').getTime(),
      forecast: typeof forecastValue === 'number' ? forecastValue * 100 : null,
      actuals: typeof actualValue === 'number' ? actualValue * 100 : null,
    });
    return acc;
  }, [] as IDataArrayDictionary[]);
  const dataKeysToUse = ['date', 'actuals', 'forecast'];
  const linesToUse = [
    { dataKey: 'forecast', stroke: '#999999', isDashed: false },
    { dataKey: 'actuals', stroke: '#DC960A', isDashed: false },
  ];

  const yFormatter = (value: number | null): string => {
    return formatToLetterAbbreviatedNumber({
      value: value ?? 0,
      decimal: getDecimalBasedOnValue(value),
    });
  };

  return (
    <LineGraph
      data={dataToUse.sort((a, b) => (b.date ?? 0) - (a.date ?? 0))}
      dataKeys={dataKeysToUse}
      yFormatter={yFormatter}
      lines={linesToUse}
      roundTicksToMoney={true}
      shouldRenderXAxisLabels
      areaChartMargin={{ right: 32 }}
      xAxisPadding={xAxisPadding}
      renderAllDots={true}
    />
  );
};

export default BvaGraph;
