import { uniqueId } from 'lodash';
import React from 'react';
import Typography from '~/components/Typography';
import { IFormula, IRecipe, IVariableTypeEnum } from '~/services/parallel/formulas.types';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import TimeModifier from './TimeModifier';

const FormulaContent = ({
  recipe,
  formulaDictionary,
}: {
  recipe: IRecipe | null;
  formulaDictionary: Record<string, IFormula>;
}): React.ReactElement => {
  if (!recipe) return <Typography>-</Typography>;

  return (
    <div className="flex items-center justify-start gap-1 flex-wrap">
      {recipe.expression.split(/(\$\d+)/g).map((part) => {
        if (/\$\d+/.test(part)) {
          const variable = part.slice(1);
          const variableType = recipe.variables[`$${variable}`].type;
          const bubbleClassName =
            'flex flex-row text-nowrap border border-neutral-100 rounded-full bg-neutral-15 px-2 py-0.5';
          switch (variableType) {
            case IVariableTypeEnum.Formula: {
              const formulaUuid = recipe.variables[`$${variable}`].formulaUuid;
              if (typeof formulaUuid === 'string' && formulaUuid in formulaDictionary) {
                return (
                  <div className={bubbleClassName}>
                    <Typography key={variable}>{formulaDictionary[formulaUuid].recipe.name}</Typography>
                    <TimeModifier timeModifier={recipe.variables[`$${variable}`].timeModifier} />
                  </div>
                );
              } else {
                return (
                  <div className={bubbleClassName}>
                    <Typography key={variable}>{variable}</Typography>
                  </div>
                );
              }
            }
            case IVariableTypeEnum.Self: {
              return (
                <div className={bubbleClassName}>
                  <Typography key={variable}>{recipe.name}</Typography>
                  <TimeModifier timeModifier={recipe.variables[`$${variable}`].timeModifier} />
                </div>
              );
            }
            case IVariableTypeEnum.Constant: {
              return <Typography key={variable}>{recipe.variables[`$${variable}`].constantValue}</Typography>;
            }
            case IVariableTypeEnum.Calculated: {
              return (
                <div className={bubbleClassName}>
                  <Typography key={variable}>
                    {camelCaseToNormal(recipe.variables[`$${variable}`].calculationType ?? '')}
                  </Typography>
                  <Typography color="empty" className="italic">
                    &nbsp;{recipe.variables[`$${variable}`].calculationModifier?.jobTitle}
                  </Typography>
                </div>
              );
            }
            default: {
              return <Typography key={variable}>{variable}</Typography>;
            }
          }
        }
        return <Typography key={part + uniqueId()}>{part}</Typography>;
      })}
    </div>
  );
};

export default FormulaContent;
