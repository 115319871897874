import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Typography from '../Typography';
import PopoverComponent from '~/components/Popover';
import { Link } from 'react-router-dom';

const UserNavigationPopover = ({
  setShowChangeLogModal,
  sideMenuExpanded,
  handleClickLink,
  onOpenChange,
}: {
  setShowChangeLogModal: React.Dispatch<React.SetStateAction<boolean>>;
  sideMenuExpanded: boolean;
  handleClickLink: (to?: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
}): React.ReactNode => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenChange = (isOpen: boolean): void => {
    setOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  const { name } = useSelector((state: State) => state.user);

  const nameArray = name.split(' ');
  const firstNameInitial = nameArray[0][0];
  let lastNameInitial;
  if (nameArray.length > 1) {
    lastNameInitial = nameArray[nameArray.length - 1][0];
  }

  const firstAndLastNameInitials = `${firstNameInitial}${lastNameInitial ?? ''}`;

  const isMac = navigator.userAgent.includes('Macintosh');

  const options = [
    {
      id: 'feedback-bug-report',
      label: 'Feedback / Bug Report',
      onClick: (): void => {
        window.open('https://1usj4xv96c2.typeform.com/to/UAqn7Dbv', '_blank');
      },
      className: 'w-full text-neutral-800',
    },
    {
      id: 'change-log-button',
      label: (
        <div className="flex items-center justify-between w-full">
          <Typography className="whitespace-nowrap overflow-hidden">Change History </Typography>
          <Typography color="empty" className="whitespace-nowrap overflow-hidden">
            {isMac ? '⌘' : 'Ctrl+'}O
          </Typography>
        </div>
      ),
      onClick: () => setShowChangeLogModal((prev) => !prev),
      className: '!w-[225px]',
    },
    {
      id: 'settings-page',
      label: (
        <Link
          to="/settings"
          className="flex px-5 py-4 w-full h-full justify-start items-start"
          data-testid="settings-link"
        >
          <Typography>Settings</Typography>
        </Link>
      ),
      onClick: () => handleClickLink('/settings'),
      className: '!w-full text-neutral-800 !px-0 !py-0',
    },
  ];

  return (
    <div className="w-full relative">
      <PopoverComponent
        fill="clear"
        options={options}
        className="truncate w-full !px-1 !py-1 overflow-hidden outline-none border-green-75 border-2 hover:bg-white hover:border-white"
        direction="above"
        maxPanelHeight="max-h-[180px] max-sm:max-h-[180px]"
        onOpenChange={handleOpenChange}
      >
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row gap-2 items-center">
            <div className="flex items-center justify-center bg-white rounded-full !w-[44px] !h-[44px] border-[1.38px] border-neutral-50">
              <Typography color="empty" className="uppercase">
                {firstAndLastNameInitials}
              </Typography>
            </div>
            <Typography
              color="green"
              className={`${sideMenuExpanded || open ? '' : 'hidden group-hover/sidemenu:block'} whitespace-nowrap max-w-40 truncate`}
            >
              {name}
            </Typography>
          </div>
          {open ? (
            <ChevronUpIcon
              className={`w-4 h-4 stroke-green-500 mr-2 ${sideMenuExpanded ? '' : 'hidden group-hover/sidemenu:block'}`}
            />
          ) : (
            <ChevronDownIcon
              className={`w-4 h-4 stroke-green-500 mr-2 ${sideMenuExpanded ? '' : 'hidden group-hover/sidemenu:block'}`}
            />
          )}
        </div>
      </PopoverComponent>
    </div>
  );
};

export default UserNavigationPopover;
