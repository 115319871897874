import React, { forwardRef, useEffect, useState } from 'react';
import { Select, SelectOption } from './Select';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import { useSelector } from 'react-redux';
import { State } from '~/store';

interface Props {
  id: string;
  displayLabel?: boolean;
  className?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: 'below' | 'above';
  required?: boolean;
  listBoxClassName?: string;
  value?: string | null;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  name?: string;
  activeBackgroundColor?: string;
}

export const SelectDepartment = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      displayLabel = true,
      className,
      error,
      placeholder = 'Select Department',
      disabled,
      checkmark,
      direction,
      required,
      listBoxClassName,
      value,
      onChange,
      onBlur,
      onFocus,
      name,
      activeBackgroundColor,
    },
    ref,
  ) => {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
    const { activeScenarioUuid: scenarioUuid } = useSelector((state: State) => state.scenario);
    const { data: departments } = departmentsApi.useGetDepartmentsQuery(
      {
        orgUuid: organizationUuid,
        scenarioUuid: scenarioUuid ?? undefined,
      },
      {
        skip: !organizationUuid,
        refetchOnMountOrArgChange: true,
      },
    );

    useEffect(() => {
      if (departments) {
        setOptions(
          departments.list.map((department) => ({
            value: department.departmentUuid,
            label: department.name,
          })),
        );
      }
    }, [departments]);

    return (
      <Select
        id={id}
        options={options}
        label={displayLabel ? 'Department' : undefined}
        className={className}
        error={error}
        placeholder={placeholder}
        disabled={disabled}
        checkmark={checkmark}
        direction={direction}
        required={required}
        listBoxClassName={listBoxClassName}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        activeBackgroundColor={activeBackgroundColor}
        ref={ref}
      />
    );
  },
);

SelectDepartment.displayName = 'SelectDepartment';
