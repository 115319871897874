import React from 'react';
import HeadcountToolbar from './components/HeadcountToolbar';
import { IPositionDetails } from './entity/types';
import { IBvaResultResponse, IBvaReviewStatus } from '~/services/parallel/bva.types';
import BvaDropdown from '~/components/BvaDropdown';
import HeadcountGraphs from './components/HeadcountGraphs/HeadcountGraphs';
import { SelectState } from '~/components/Select/Select.types';
import HeadcountTimeline from './components/HeadcountTimeline/HeadcountTimeline';
import * as stringDate from '~/utils/stringDate';
import Divider from '~/components/Divider';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';
import HeadcountTableContainer from './components/HeadcountTable/HeadcountTableContainer';
import { useFeatureFlagPosthog } from '~/utils/hooks/useFeatureFlag';
import HeadcountTable from './components/HeadcountTable/HeadcountTable';

interface IExistingPositionModalState {
  isOpen: boolean;
  position: IPositionDetails | null;
}

interface IHeadcountPageBodyProps {
  organizationUuid: string;
  isLoadingPositions: boolean;
  positions?: IPositionDetails[];
  scenarioUuid: string | null;
  bvaResult?: IBvaResultResponse;
  headcountView: string;
  setHeadcountView: React.Dispatch<React.SetStateAction<string>>;
  positionIndexes: Record<string, number>;
  setPositionIndexes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  search: Types.InputState;
  setSearch: React.Dispatch<React.SetStateAction<Types.InputState>>;
  departments: SelectState;
  setDepartments: React.Dispatch<React.SetStateAction<SelectState>>;
  setCreatePositionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setDeletePositionModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setUpdateCompensationModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  setEditTermDateModalState: React.Dispatch<React.SetStateAction<IExistingPositionModalState>>;
  hideTermedPositions: boolean;
  setHideTermedPositions: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingInline: boolean;
  setIsCreatingInline: React.Dispatch<React.SetStateAction<boolean>>;
  isFiltering: boolean;
  listParams: IListPositionsParams;
  isFetchingPositions: boolean;
  changedFilters: {
    departments: boolean;
    search: boolean;
    hideTerms: boolean;
  };
}

const HeadcountPageBody = ({
  organizationUuid,
  scenarioUuid,
  positions,
  isLoadingPositions,
  bvaResult,
  headcountView,
  setHeadcountView,
  positionIndexes,
  setPositionIndexes,
  search,
  setSearch,
  departments,
  setDepartments,
  setCreatePositionModalIsOpen,
  setUpdatePositionModalState,
  setDeletePositionModalState,
  setUpdateCompensationModalState,
  setEditTermDateModalState,
  hideTermedPositions,
  setHideTermedPositions,
  isCreatingInline,
  setIsCreatingInline,
  isFiltering,
  listParams,
  isFetchingPositions,
  changedFilters,
}: IHeadcountPageBodyProps): React.ReactNode => {
  const headcountNeedsReview = Object.values(bvaResult?.bvaResult?.headcountReview ?? {}).some(
    (review) => review.status === IBvaReviewStatus.Pending,
  );
  const virtualizeHeadcountTable = useFeatureFlagPosthog('virtualize_headcount_table');

  return (
    <div className="flex flex-col max-w-full h-full items-end justify-center w-full pt-4 max-sm:px-0">
      <div className="w-full h-full mt-2">
        {headcountNeedsReview && !scenarioUuid && (
          <div className="w-full px-10">
            <BvaDropdown
              mode="headcount"
              forecast={bvaResult?.bvaResult?.headcountAggregate?.forecastedValues ?? []}
              actuals={bvaResult?.bvaResult?.headcountAggregate?.actualValues ?? []}
            />
          </div>
        )}
        <HeadcountGraphs
          organizationUuid={organizationUuid}
          scenarioUuid={scenarioUuid}
          search={search}
          departments={departments}
        />
        <Divider className="w-full py-4 px-10 " />
        <div className="w-full px-10 mb-2">
          <HeadcountToolbar
            headcountView={headcountView}
            setHeadcountView={setHeadcountView}
            selectedDepartment={departments}
            setSelectedDepartment={setDepartments}
            hideTermedPositions={hideTermedPositions}
            setHideTermedPositions={setHideTermedPositions}
            positions={positions}
            search={search}
            setSearch={setSearch}
            setCreatePositionModalIsOpen={setCreatePositionModalIsOpen}
            isCreatingInline={isCreatingInline}
            setIsCreatingInline={setIsCreatingInline}
            isLoading={isLoadingPositions}
            isFetchingPositions={isFetchingPositions}
            changedFilters={changedFilters}
          />
        </div>
        {(!positions ||
          !positions
            .filter((position) => !position.positionUuid.startsWith('temp-'))
            .some((position) => typeof positionIndexes[position.positionUuid] !== 'number')) &&
          (headcountView === 'table' ? (
            virtualizeHeadcountTable ? (
              <HeadcountTableContainer
                organizationUuid={organizationUuid}
                scenarioUuid={scenarioUuid}
                positions={positions ?? []}
                setUpdatePositionModalState={setUpdatePositionModalState}
                setDeletePositionModalState={setDeletePositionModalState}
                setUpdateCompensationModalState={setUpdateCompensationModalState}
                positionIndexes={positionIndexes}
                setPositionIndexes={setPositionIndexes}
                isCreatingInline={isCreatingInline}
                setIsCreatingInline={setIsCreatingInline}
                isFiltering={isFiltering}
                listParams={listParams}
                isLoading={isLoadingPositions}
              />
            ) : (
              <HeadcountTable
                organizationUuid={organizationUuid}
                scenarioUuid={scenarioUuid}
                positions={positions ?? []}
                setUpdatePositionModalState={setUpdatePositionModalState}
                setDeletePositionModalState={setDeletePositionModalState}
                setUpdateCompensationModalState={setUpdateCompensationModalState}
                positionIndexes={positionIndexes}
                setPositionIndexes={setPositionIndexes}
                isCreatingInline={isCreatingInline}
                setIsCreatingInline={setIsCreatingInline}
                isFiltering={isFiltering}
                listParams={listParams}
              />
            )
          ) : (
            <div className="w-full px-10">
              <HeadcountTimeline
                organizationUuid={organizationUuid}
                scenarioUuid={scenarioUuid}
                positions={
                  positions?.filter((position) =>
                    stringDate.isAfter({ comparison: stringDate.getStringDate(), dateToCheck: position.hireDate }),
                  ) ?? []
                }
                setCreatePositionModalIsOpen={setCreatePositionModalIsOpen}
                setUpdatePositionModalState={setUpdatePositionModalState}
                setDeletePositionModalState={setDeletePositionModalState}
                setUpdateCompensationModalState={setUpdateCompensationModalState}
                setEditTermDateModalState={setEditTermDateModalState}
                positionIndexes={positionIndexes}
                listParams={listParams}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default HeadcountPageBody;
