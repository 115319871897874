import { z } from 'zod';

export const departmentSchema = z.object({
  uuid: z.string().uuid(),
  departmentUuid: z.string().uuid(),
  name: z.string(),
  organizationUuid: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const ZNewDepartmentResponseData = z.object({
  data: departmentSchema,
});

export type IDepartment = z.infer<typeof departmentSchema>;

export interface IDepartmentResponse {
  data: {
    data: IDepartment[];
  } | null;
  status: number;
}

export type IDepartmentDict = Record<string, IDepartment>;
