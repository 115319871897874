import React from 'react';
import {
  IFormulaSegment,
  IUpdateCalculationModifier,
  IUpdateTimeModifier,
  IValueToAdd,
  IVariables,
} from '../entity/types';
import ConstantElement from '../components/FormulaBuilder/ConstantElement';
import { ICalculationTypeEnum, TimeModifierFunctionEnum, VariableTypeEnum } from '../entity/schemas';
import { v4 } from 'uuid';
import FormulaElement from '../components/FormulaBuilder/FormulaElement';
import CalculatedFormulaElement from '../components/FormulaBuilder/CalculatedFormulaElement';

const calculatedTitlesToTypes: Record<string, ICalculationTypeEnum> = {
  Headcount: ICalculationTypeEnum.HeadcountNumber,
  'New Hires': ICalculationTypeEnum.NewHireNumber,
  'Software Expenses': ICalculationTypeEnum.SoftwareExpenses,
  'Total Compensation': ICalculationTypeEnum.TotalCompensation,
  Bonuses: ICalculationTypeEnum.Bonuses,
  Commissions: ICalculationTypeEnum.Commissions,
  'Other Expenses': ICalculationTypeEnum.OtherExpenses,
  'People & Facilities Expenses': ICalculationTypeEnum.PeopleFacilities,
  'Cost of Goods Sold Expenses': ICalculationTypeEnum.COGS,
  'Marketing Expenses': ICalculationTypeEnum.Marketing,
};

interface IProps {
  valuesToAdd: IValueToAdd[];
  formulaCopy: IFormulaSegment[];
  variablesCopy: IVariables;
  formulaUuid?: string;
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier?: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
}

const updateFormulaAndVariables = ({
  valuesToAdd,
  formulaCopy,
  variablesCopy,
  formulaUuid,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
}: IProps): {
  newFormula: IFormulaSegment[];
  newVariables: IVariables;
} => {
  const newFormulaRef = React.createRef();
  let variableCounter = Object.keys(variablesCopy).length;
  valuesToAdd.map((valueToAdd) => {
    const { type, value, newIndex } = valueToAdd;
    variableCounter++;
    const textValue = `$${variableCounter}`;
    switch (type) {
      case 'constant':
      case 'invalid': {
        formulaCopy.splice(newIndex, 0, {
          element: (
            <ConstantElement
              key={`${value}-${v4()}`}
              ref={newFormulaRef}
              renderedValue={value}
              formulaIndex={newIndex}
              selectable
              valid={type === 'constant' ? true : false}
            />
          ),
          ref: newFormulaRef,
          textValue,
          type: type,
        });
        variablesCopy[textValue] = {
          type: type === 'constant' ? VariableTypeEnum.Constant : VariableTypeEnum.Invalid,
          formulaUuid: null,
          constantValue: type === 'constant' ? Number(value) : value,
          timeModifier: {},
          calculationType: null,
        };
        break;
      }
      case 'operator': {
        formulaCopy.splice(newIndex, 0, {
          element: (
            <ConstantElement
              key={`${value}-${v4()}`}
              ref={newFormulaRef}
              renderedValue={value}
              formulaIndex={newIndex}
              selectable
              valid
            />
          ),
          ref: newFormulaRef,
          textValue: value,
          type: 'operator',
        });
        break;
      }
      case 'formula': {
        if (!valueToAdd.associatedFormula) break;
        const formulaUuidToUse = valueToAdd.associatedFormula.formulaUuid;

        const isSelf = formulaUuidToUse === formulaUuid || formulaUuidToUse === 'self';

        formulaCopy.splice(newIndex, 0, {
          element: (
            <FormulaElement
              key={`${formulaUuidToUse}-${v4()}`}
              ref={newFormulaRef}
              selectedFormula={valueToAdd.associatedFormula}
              handleUpdateTimeModifier={handleUpdateTimeModifier}
              timeModifier={
                isSelf
                  ? {
                      period: 1,
                      function: TimeModifierFunctionEnum.Previous,
                    }
                  : {}
              }
              formulaTextValue={textValue}
              selectable
            />
          ),
          ref: newFormulaRef,
          textValue,
          type: 'formula',
        });
        variablesCopy[textValue] = {
          type: isSelf ? VariableTypeEnum.Self : VariableTypeEnum.Formula,
          formulaUuid: isSelf ? null : formulaUuidToUse,
          constantValue: null,
          timeModifier: isSelf
            ? {
                period: 1,
                function: TimeModifierFunctionEnum.Previous,
              }
            : {},
          calculationModifier: {},
          calculationType: null,
        };

        break;
      }
      case 'calculated': {
        if (!valueToAdd.associatedFormula) break;
        const formulaUuidToUse = valueToAdd.associatedFormula.formulaUuid;

        formulaCopy.splice(newIndex, 0, {
          element: (
            <CalculatedFormulaElement
              key={`${formulaUuidToUse}-${v4()}`}
              ref={newFormulaRef}
              selectedFormula={valueToAdd.associatedFormula}
              handleUpdateCalculationModifier={handleUpdateCalculationModifier}
              calculationModifier={{}}
              formulaTextValue={textValue}
              selectable
            />
          ),
          ref: newFormulaRef,
          textValue,
          type: 'calculated',
        });
        variablesCopy[textValue] = {
          type: VariableTypeEnum.Calculated,
          formulaUuid: null,
          constantValue: null,
          timeModifier: {},
          calculationModifier: {},
          calculationType: calculatedTitlesToTypes[valueToAdd.associatedFormula.recipe.name],
        };
        break;
      }
    }
  });

  return { newFormula: formulaCopy, newVariables: variablesCopy };
};

export default updateFormulaAndVariables;
