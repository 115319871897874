import React from 'react';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';

interface IReactivateUserProps {
  isOpen: boolean;
  close: () => void;
  reactivate: () => void;
}

const ReactivateUser = ({ isOpen, close, reactivate }: IReactivateUserProps): React.ReactNode => {
  return (
    <Modal isOpen={isOpen} title="Reactivate this user?">
      <div className="flex flex-col mt-1">
        <Typography size="sm" color="secondary">
          This email is associated with a user whose access has been revoked. Reactivating this will update the users
          information and access. Are you sure?
        </Typography>
        <div className="flex items-center justify-between w-full mt-8">
          <div className="w-fit">
            <Button fill="clear" className="!w-fit !px-0" onClick={() => close()}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button onClick={() => reactivate()}>Yes, Reactivate</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReactivateUser;
