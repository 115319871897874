import React from 'react';
import Divider from '~/components/Divider';
import { IDataArrayDictionary, ILineProps } from '~/components/LineGraph/entity/types';
import Typography from '~/components/Typography';
import { IDepartment } from '~/services/parallel/departments.types';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import { formatDate } from 'date-fns';

interface IProps {
  active?: boolean;
  payload?: { payload: IDataArrayDictionary }[];
  label?: number;
  lines: ILineProps[];
  graphData: Record<
    string,
    {
      data: ({
        date: number;
      } & Record<string, number>)[];
      title: string;
    }
  >;
  departmentData?: {
    list: IDepartment[];
    dictionary: Record<string, IDepartment>;
  };
  selectedSegment: string;
}

const HeadcountGraphTooltip = ({ label, graphData, departmentData, selectedSegment }: IProps): React.ReactNode => {
  let displayDataForTotal: { departmentName: string; workingModel: number; activeScenario?: number } | undefined;

  const monthFormatter = (value: number | null): string => {
    if (!value) return '';
    return formatDate(value, 'MMM yyyy');
  };

  const displayData = Object.entries(graphData).reduce(
    (acc, [key, value]) => {
      const dataForActiveDate = value.data.find((item) => item.date === label);
      if (!dataForActiveDate) return acc;
      if (key === 'total') {
        displayDataForTotal = {
          departmentName: 'Total',
          workingModel: dataForActiveDate.workingModel,
        };

        if ('activeScenario' in dataForActiveDate) {
          displayDataForTotal.activeScenario = dataForActiveDate.activeScenario;
        }
      } else {
        const department = departmentData?.dictionary[key];
        if (!department) return acc;
        const result: { departmentName: string; workingModel: number; activeScenario?: number } = {
          departmentName: department.name,
          workingModel: dataForActiveDate.workingModel,
        };

        if ('activeScenario' in dataForActiveDate) {
          result.activeScenario = dataForActiveDate.activeScenario;
        }

        acc.push(result);
      }
      return acc;
    },
    [] as { departmentName: string; workingModel: number; activeScenario?: number }[],
  );

  const sortedDisplayData = displayData.sort((a, b) => b.workingModel - a.workingModel);

  const getPercentOfTotal = ({
    departmentValue,
    totalValue,
  }: {
    departmentValue: number;
    totalValue: number;
  }): string => {
    if (totalValue === 0) return '-';
    return `${Math.round((departmentValue / totalValue) * 100)}%`;
  };

  const getFormattedNumber = ({ value, selectedSegment }: { value: number; selectedSegment: string }): string => {
    if (selectedSegment === 'comp') {
      let decimalPlaces = 1;
      if (value >= 10000000 && value < 100000000) {
        decimalPlaces = 0;
      }
      return formatToLetterAbbreviatedNumber({
        value,
        decimal: decimalPlaces,
      });
    }
    return value.toString();
  };

  const currencyWidth = selectedSegment === 'comp' ? 'w-[45px]' : 'w-[20px]';

  return (
    <div className=" bg-white rounded-lg border border-gray-200 px-3.5 py-1">
      <Typography color="empty" size="xs">
        {monthFormatter(label ?? null)}
      </Typography>
      <div className={`flex flex-col gap-1`}>
        {sortedDisplayData.map((item) => (
          <div className="flex flex-row justify-between w-full" key={`${item.departmentName}-${label}`}>
            <Typography className=" pr-3">{item.departmentName}</Typography>
            <div className="flex flex-row w-fit">
              <Typography color="primaryGreen" className={`${currencyWidth} text-right`}>
                {getFormattedNumber({ value: item.workingModel, selectedSegment })}
              </Typography>
              {(selectedSegment === 'comp' || 'activeScenario' in item) && (
                <Divider className="text-neutral-50 px-1" orientation="vertical" />
              )}
              {'activeScenario' in item ? (
                <Typography color="mediumBlue" className={`${currencyWidth} text-right`}>
                  {getFormattedNumber({ value: item.activeScenario ?? 0, selectedSegment })}
                </Typography>
              ) : (
                selectedSegment === 'comp' && (
                  <Typography color="empty" className="w-[35px] text-right">
                    {getPercentOfTotal({
                      departmentValue: item.workingModel,
                      totalValue: displayDataForTotal?.workingModel ?? 1,
                    })}
                  </Typography>
                )
              )}
            </div>
          </div>
        ))}
        <Divider className="my-1" />
        {displayDataForTotal && (
          <div
            className={`flex flex-row ${'activeScenario' in displayDataForTotal || selectedSegment !== 'comp' ? 'justify-between' : ''} w-full`}
          >
            <Typography weight="medium" className="whitespace-nowrap pr-3">
              {selectedSegment === 'comp' ? 'Total Comp.' : 'Total'}
            </Typography>
            <div className="flex flex-row w-fit">
              <Typography color="primaryGreen" weight="medium" className={`${currencyWidth} text-right`}>
                {getFormattedNumber({ value: displayDataForTotal.workingModel, selectedSegment })}
              </Typography>
              {'activeScenario' in displayDataForTotal && (
                <>
                  <Divider className="text-neutral-50 px-1" orientation="vertical" />
                  <Typography color="mediumBlue" weight="medium" className={`${currencyWidth} text-right`}>
                    {getFormattedNumber({ value: displayDataForTotal.activeScenario ?? 0, selectedSegment })}
                  </Typography>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeadcountGraphTooltip;
