import React, { useContext, useEffect, useRef, useState } from 'react';
import Details from './details';
import Header from '~/components/Header';
import UserDateRange from '~/components/UserDateRange';
import ContractsHeader from './components/ContractsHeader';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { useNavigate } from 'react-router-dom';
import ContractTabularTable from './components/ContractTabularTable';
import useQueryParams from '~/utils/hooks/useQueryParams';
import ContractModal from './components/ContractModal';
import DeleteContractModal from './components/DeleteContractModal';
import DiscontinueContractModal from './components/DiscontinueContractModal';
import { ContractsContext, ContractsContextProvider } from './context/ContractsContext';
import ScenarioDropdown from '~/components/ScenarioDropdown';

const Contracts = (): React.ReactNode => {
  const contractsEnabled = useSelector((state: State) => state.organization.configuration.contractsEnabled);
  const { revalidate } = useContext(ContractsContext);
  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef.current]);

  if (!contractsEnabled) {
    navigate('/dashboard');
  }

  return (
    <div className="w-full flex flex-col items-center justify-start">
      <div className="w-full" ref={headerRef}>
        <Header
          title="Contracts"
          startChildren={
            <div className="flex flex-row gap-2">
              <ScenarioDropdown reload={revalidate} />
            </div>
          }
          endChildren={
            <div className="flex w-full justify-end items-center gap-2">
              <UserDateRange pickerAlignment="right" />
            </div>
          }
        />
        <ContractsHeader />
      </div>

      {queryParams.get('view') === 'details' ? (
        <Details />
      ) : (
        <div
          className="w-full flex flex-col items-center justify-start"
          style={{ height: `calc(95vh - ${headerHeight}px)` }}
        >
          <ContractTabularTable mode={queryParams.get('view') === 'collections' ? 'collections' : 'revenue'} />
        </div>
      )}
      <ContractModal />
      <DiscontinueContractModal />
      <DeleteContractModal />
    </div>
  );
};

const Page = (): React.ReactNode => {
  return (
    <ContractsContextProvider>
      <Contracts />
    </ContractsContextProvider>
  );
};

export default Page;
