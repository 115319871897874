import { IDataArrayDictionary } from '~/components/LineGraph/entity/types';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';
import type { IFormula } from '~/services/parallel/formulas.types';

export const graphDataByType = ({
  expenseFormulas,
  startDate,
  endDate,
}: {
  expenseFormulas: IFormula[];
  startDate: IStringDate;
  endDate: IStringDate;
}): {
  totalData: IDataArrayDictionary[];
} => {
  const totalData: IDataArrayDictionary[] = [];

  const monthArray = stringDate.createMonthArrayBetweenDates({
    startDate,
    endDate,
  });
  monthArray.forEach((month) => {
    const time = new Date(month).getTime();
    const monthTotalData: Record<string, number> = {
      date: time,
      total: 0,
    };

    expenseFormulas.forEach((expenseFormula) => {
      if (expenseFormula.recipe.name === 'Total Compensation') {
        monthTotalData.total +=
          expenseFormula.calculations.find((calc) => stringDate.isSameMonth({ date1: month, date2: calc.date }))
            ?.value ?? 0;

        return;
      }
      const expenseContext = expenseFormula.context?.expenseContext;
      if (!expenseContext) {
        return;
      }

      monthTotalData.total +=
        expenseFormula.calculations.find((calc) => stringDate.isSameMonth({ date1: month, date2: calc.date }))?.value ??
        0;
    });

    totalData.push(monthTotalData);
  });

  return { totalData };
};
