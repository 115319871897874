import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { DrawerTab } from '~/store/drawerContentSlice';
import BaseDrawerButton from '../Drawer/BaseDrawerButton';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';

const OnboardingDrawerButton = ({
  isActive,
  handleTabClick,
  tabId,
  tabLabel,
  tabIcon,
}: {
  isActive: boolean;
  handleTabClick: (id: DrawerTab) => void;
  tabId: DrawerTab;
  tabLabel: string;
  tabIcon: typeof ClipboardDocumentCheckIcon;
}): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery(undefined, {
    skip: !organizationUuid,
  });

  useEffect(() => {
    if (organizationUuid) {
      onboardingSteps.refetch();
    }
  }, [organizationUuid]);

  const allOnboardingStepsCompleted = onboardingSteps.data?.list.every(
    (step) => step.status === IOnboardingStepStatusEnum.Completed,
  );

  if (!onboardingSteps.data || allOnboardingStepsCompleted || !onboardingSteps.data.list.length) {
    return null;
  }

  return (
    <div className="w-fit h-fit relative">
      <BaseDrawerButton
        isActive={isActive}
        handleTabClick={handleTabClick}
        tabId={tabId}
        tabIcon={tabIcon}
        tabLabel={tabLabel}
      />
      <div className="absolute top-5 right-2.5 w-1.5 h-1.5 bg-red-400 rounded-full" />
    </div>
  );
};

export default OnboardingDrawerButton;
