import React, { useState } from 'react';
import Header from '~/components/Header';
import UserDateRange from '~/components/UserDateRange';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import Skeleton from 'react-loading-skeleton';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import ActualsAndOverridesLegend from '~/components/ActualsAndOverridesLegend/ActualsAndOverridesLegend';
import { FormulasProvider } from '~/components/Formulas/context/FormulasContext';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import PullAccountingActuals from '~/components/PullAccountingActuals';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { ExpenseModal } from './components/ExpenseForm/ExpenseModal';
import { integrationsApi } from '~/services/parallel/api/integrationsApi';
import DeleteExpenseModal from './components/DeleteExpenseModal';
import DiscontinueExpenseModal from './components/DiscontinueExpenseModal';
import LinkDatasourceToExistingExpenseModal from './components/UnusedExpenseDatasources/LinkDatasourceToExistingExpenseModal';
import GenerateExpensesModal from './components/GenerateExpenses/GenerateExpensesModal';
import { bvaApi } from '~/services/parallel/api/bvaApi';
import { BvaExpenseModal } from './components/BvaExpenseForm/BvaExpenseModal';
import { compareAsc } from 'date-fns';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import ExpensesPageBody from './ExpensesPageBody';
import useQueryParams from '~/utils/hooks/useQueryParams';
import IntegrationMappingsModal from './components/IntegrationMappingsForm/IntegrationMappingsModal';

export interface ModalState {
  type: 'create' | 'generate' | 'edit' | 'duplicate' | 'delete' | 'discontinue' | 'link' | 'edit-mappings' | null;
  formulaUuid: string | null;
  datasourceUuid?: string | null;
}

const ExpensesPage = (): React.ReactNode => {
  const preferences = useSelector((state: State) => state.user.preferences);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [modalState, setModalState] = useState<ModalState>({ type: null, formulaUuid: null });
  const [bvaModalState, setBvaModalState] = useState<string | null>(null);
  const { data: bvaData } = bvaApi.useGetBvaQuery();

  const { queryParams, setQueryParams } = useQueryParams();
  const selectedCategory = queryParams.get('category') ?? 'all';
  const hidePastExpenses = queryParams.get('hidePastExpenses') === 'true';

  // TODO: integration list endpoint should have an ability to include mappings and we should handle multiple integrations
  const integrations = integrationsApi.useGetIntegrationsQuery();
  const mappings = integrationsApi.useGetIntegrationMappingsQuery(
    {
      integrationUuid: integrations.data?.[0]?.uuid ?? '',
      scope: ['expense', 'headcount'],
    },
    { skip: !integrations.data?.length },
  );
  const integrationsWithMappings = ((): IIntegrationWithMappings[] => {
    const integrationsData = integrations.data;
    const mappingsData = mappings.data;
    if (!mappingsData || !integrationsData) {
      return [];
    }
    return integrationsData.map((integration) => ({
      ...integration,
      mappings: mappingsData.filter((mapping) => mapping.integrationUuid === integration.uuid),
    }));
  })();

  const { formulas, isLoading: formulasLoading } = formulasApi.useListFormulasQuery(
    {
      startDate: preferences.defaultGraphStartDate,
      endDate: preferences.defaultGraphEndDate,
      scenarioUuid: activeScenarioUuid ?? undefined,
      types: [IFormulaTypeEnum.Expense, IFormulaTypeEnum.Headcount],
      includes: ['calculations'],
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        formulas: data ? [...data].sort((a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt))) : [],
        isLoading,
      }),
    },
  );

  const handleFilterChange = (key: string, value: string | boolean | null): void => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (value) {
      currentParams[key] = String(value);
    } else {
      delete currentParams[key];
    }
    setQueryParams(currentParams);
  };

  const handleFilterChangeFor = (filterType: 'category' | 'hidePastExpenses') => (value: string | boolean | null) => {
    handleFilterChange(filterType, value);
  };

  if (formulasLoading) {
    return (
      <div className="w-full max-sm:min-h-screen max-sm:pb-32" data-testid="expenses-page-loading">
        <Header title="Expenses" />
        <div className="px-10 mt-10 mb-4">
          <Skeleton className="w-full h-[250px]" baseColor="#F8F9F6" />
        </div>
        <div className="px-10 max-w-full w-full">
          <Skeleton height={40} count={20} className="mb-4" baseColor="#F8F9F6" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-sm:min-h-screen pb-32">
      <Header
        title="Expenses"
        startChildren={
          <div className="flex flex-row gap-2">
            <ScenarioDropdown />
            <ActualsAndOverridesLegend />
          </div>
        }
        endChildren={
          <div className="flex w-full justify-end items-center gap-4">
            <PullAccountingActuals />
            <UserDateRange pickerAlignment="right" />
          </div>
        }
      />
      <div>
        <FormulasProvider mode={IFormulaTypeEnum.Expense}>
          <ExpensesPageBody
            scenarioUuid={activeScenarioUuid}
            formulas={formulas}
            selectedCategory={selectedCategory}
            setSelectedCategory={handleFilterChangeFor('category')}
            hidePastExpenses={hidePastExpenses}
            setHidePastExpenses={handleFilterChangeFor('hidePastExpenses')}
            setModalState={setModalState}
            bvaResult={bvaData?.bvaResult}
            integrations={integrations.data}
            mappings={mappings.data}
            setBvaModalState={setBvaModalState}
          />
          <DiscontinueExpenseModal
            isOpen={modalState.type === 'discontinue'}
            onClose={() => setModalState({ type: null, formulaUuid: null })}
            formulaUuid={modalState.formulaUuid ?? ''}
          />
          <DeleteExpenseModal
            isOpen={modalState.type === 'delete'}
            onClose={() => setModalState({ type: null, formulaUuid: null })}
            formulaUuid={modalState.formulaUuid ?? ''}
          />
          <ExpenseModal
            isOpen={['create', 'edit', 'duplicate'].includes(modalState.type ?? '')}
            isDuplicating={modalState.type === 'duplicate'}
            onClose={() => {
              setModalState({ type: null, formulaUuid: null });
            }}
            formulaUuid={modalState.formulaUuid ?? ''}
            scenarioUuid={activeScenarioUuid ?? null}
            formOverrides={{
              datasourceUuid: modalState.datasourceUuid ? [modalState.datasourceUuid] : null,
            }}
            integrationsWithMappings={integrationsWithMappings}
            onDeleteExpense={() => setModalState((prev) => ({ type: 'delete', formulaUuid: prev.formulaUuid }))}
          />
          <BvaExpenseModal
            isOpen={!!bvaModalState && !activeScenarioUuid}
            onClose={() => setBvaModalState(null)}
            formulaUuid={bvaModalState}
            scenarioUuid={activeScenarioUuid ?? null}
          />
          <LinkDatasourceToExistingExpenseModal
            isOpen={modalState.type === 'link'}
            onClose={() => setModalState({ type: null, formulaUuid: null, datasourceUuid: null })}
            datasourceUuid={modalState.datasourceUuid ?? ''}
            integrationsWithMappings={integrationsWithMappings}
          />
          <GenerateExpensesModal
            isOpen={modalState.type === 'generate'}
            onClose={() => setModalState({ type: null, formulaUuid: null })}
            integrationsWithMappings={integrationsWithMappings}
          />
          <IntegrationMappingsModal
            isOpen={modalState.type === 'edit-mappings'}
            onClose={() => setModalState({ type: null, formulaUuid: null })}
            formulaUuid={modalState.formulaUuid ?? ''}
            scenarioUuid={activeScenarioUuid ?? null}
            expenseIntegrationsWithMappings={integrationsWithMappings}
          />
        </FormulasProvider>
      </div>
    </div>
  );
};

const Page = (): React.ReactNode => {
  return <ExpensesPage />;
};

export default Page;
