import React, { useContext, useMemo } from 'react';
import Typography from '~/components/Typography';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { addMonths, isSameMonth } from 'date-fns';
import date from '~/utils/dates/date';
import { DashboardPageContext } from '../../context/DashboardContext';
import formatCurrency from '~/utils/formatCurrency';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import Divider from '~/components/Divider';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { IConsolidatedData } from '~/services/parallel/reports.types';

enum MetricColor {
  GREEN = 'green',
  BLUE = 'blue',
  ORANGE = 'orange',
  PURPLE = 'purple',
  TURQUOISE = 'turquoise',
  GRAY = 'disabled',
}

const ConsolidatedMetricCard = ({
  metricTitle,
  metricValues,
  metricFormatting,
  overrideUserActiveIndex,
}: {
  metricTitle: string;
  metricValues: IConsolidatedData[];
  metricFormatting?: IFormattingEnum | null;
  overrideUserActiveIndex?: number;
}): React.ReactNode => {
  const { userActiveIndex } = useContext(DashboardPageContext);
  const activeIndex = useMemo(
    () => overrideUserActiveIndex ?? userActiveIndex,
    [overrideUserActiveIndex, userActiveIndex],
  );
  const { defaultGraphStartDate } = useSelector((state: State) => state.user.preferences);
  const activeHoverMonth = activeIndex !== -1 ? addMonths(defaultGraphStartDate, activeIndex + 1) : date();
  const textColor: Record<string, MetricColor> = {
    workingModel: MetricColor.GREEN,
    activeScenario: MetricColor.BLUE,
    scenario1: MetricColor.ORANGE,
    scenario2: MetricColor.PURPLE,
    scenario3: MetricColor.TURQUOISE,
    pointInTimeViewScenario: MetricColor.GRAY,
  };

  const metricValuesForActiveMonth = metricValues.find((value) => isSameMonth(value.date, activeHoverMonth));

  const renderedMetricValues = metricValuesForActiveMonth
    ? Object.entries(metricValuesForActiveMonth).map(([key, value], index, array) => {
        if (key === 'date') {
          return null;
        }
        const HUNDRED_WITH_CENTS = 10000;
        let formattedValue: string | number = value;
        if (metricFormatting === IFormattingEnum.Currency) {
          formattedValue =
            array.length > 2
              ? formatToLetterAbbreviatedNumber({
                  value: value,
                  decimal: value > HUNDRED_WITH_CENTS ? 1 : 2,
                })
              : formatCurrency(value, false);
        } else if (metricFormatting === IFormattingEnum.Percent) {
          formattedValue = parseFloat(value.toFixed(2)) + '%';
        } else if (metricFormatting === IFormattingEnum.Number) {
          formattedValue = formatToLetterAbbreviatedNumber({
            value: value,
            decimal: value > HUNDRED_WITH_CENTS ? 1 : 2,
            showCurrency: false,
          });
        }

        return (
          <div className="flex no-wrap" key={`${key}-${value}`}>
            <Typography weight="semibold" color={textColor[key]}>
              {formattedValue}
            </Typography>
            {index < array.length - 1 && <Divider orientation="vertical" className="text-neutral-75 mx-1.5" />}
          </div>
        );
      })
    : null;

  return (
    <div
      className="flex flex-col border border-green-50 py-2.5 px-5 rounded-xl shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]"
      data-testid={`metric-card-${metricTitle}`}
    >
      <Typography color="secondary">{metricTitle}</Typography>
      <div className="flex flex-wrap">{renderedMetricValues}</div>
    </div>
  );
};

export default ConsolidatedMetricCard;
