import React, { useContext } from 'react';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import Typography from '~/components/Typography';
import { ChatContext } from '../../context/ChatContext';
import Button from '~/components/Button';

const ErrorMessage = ({
  message,
  previousMessage,
  conversationUuid,
}: {
  message: string;
  previousMessage: string;
  conversationUuid: string | null;
}): React.ReactElement => {
  const { sendMessage } = useContext(ChatContext);

  return (
    <div className="w-full flex justify-start items-center gap-4">
      <div className="size-8 rounded-full bg-green-25 flex items-center justify-center">
        <img src={ParallelLogo} alt="parallel-logo" className="size-5" />
      </div>
      <div className="flex-1">
        <div className="w-full bg-neutral-25 border border-neutral-50 rounded-xl px-4 py-3">
          <div className="flex justify-between items-center">
            <Typography size="sm" color="secondary">
              {message}
            </Typography>
            <Button
              fill="outlineSolid"
              className="max-w-fit !px-2 !py-0.5"
              onClick={() => sendMessage({ conversationUuid, message: previousMessage, isRetry: true })}
            >
              <Typography size="sm" color="secondary">
                Retry
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
