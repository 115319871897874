import React, { useContext, useMemo } from 'react';
import { useInput } from '~/components/Input';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import PeriodPicker from '~/components/PeriodPicker';
import Divider from '~/components/Divider';
import Typography from '~/components/Typography';
import { DashboardPageContext } from '../../context/DashboardContext';
import { ICompanyGoal } from '../../entity/types';
import Button from '~/components/Button';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as stringDate from '~/utils/stringDate';
import { IStringDate } from '~/utils/stringDate/types';
import formatPercent from '~/utils/formatPercent';
import { ILineProps } from '~/components/LineGraph/entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { createGoal } from '../../utils/goals/createGoal';
import { StatusCodes } from 'http-status-codes';
import { updateGoal } from '../../utils/goals/updateGoal';
import { generateRevenueGoalGraphData } from '../../utils/goals/generateRevenueGoalGraphData';
import { getRevenueGoalMoMPercentageAndMonths } from '../../utils/goals/getRevenueGoalMoMPercentageAndMonths';
import RevenueGoalGraph from './RevenueGoalGraph';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';

const RevenueGoal = ({
  revenueReport,
  revenueReportUuid,
  currentRevenueGoal,
  goBack,
}: {
  revenueReport: IConsolidatedGraphBody | null;
  revenueReportUuid: string | null;
  currentRevenueGoal: ICompanyGoal | null;
  goBack: () => void;
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { reload } = useContext(DashboardPageContext);

  const [revenueTarget, setRevenueTarget, resetRevenueTarget] = useInput({
    value: currentRevenueGoal?.targetValue ? (currentRevenueGoal.targetValue / CENTS_PER_DOLLAR).toString() : '',
  });
  const [revenueTargetDate, setRevenueTargetDate] = usePeriodPicker({
    startDate: currentRevenueGoal?.targetDate ?? null,
    endDate: currentRevenueGoal?.targetDate ? stringDate.endOfMonth(currentRevenueGoal.targetDate) : null,
    mode: 'month',
  });
  const { momGrowthPercentage, months } = useMemo((): {
    momGrowthPercentage: number | null;
    months: IStringDate[] | null;
  } => {
    return getRevenueGoalMoMPercentageAndMonths({
      revenueReport,
      revenueTarget,
      revenueTargetDate,
      currentRevenueGoal,
    });
  }, [revenueReport, revenueTarget, revenueTargetDate, currentRevenueGoal]);

  const { lines, dataKeys, data } = useMemo((): {
    lines: ILineProps[] | null;
    dataKeys: string[] | null;
    data: IConsolidatedGraphBody['data'] | null;
  } => {
    return generateRevenueGoalGraphData({
      revenueReport,
      momGrowthPercentage,
      months,
    });
  }, [revenueReport, momGrowthPercentage, months]);

  const handleSave = async ({
    revenueReport,
    revenueReportUuid,
    currentRevenueGoalUuid,
    organizationUuid,
  }: {
    revenueReport: IConsolidatedGraphBody | null;
    revenueReportUuid: string | null;
    currentRevenueGoalUuid: string | null;
    organizationUuid: string;
  }): Promise<void> => {
    try {
      let isValid = true;
      if (!revenueTargetDate.startDate) {
        setRevenueTargetDate((prev) => ({ ...prev, errorMessage: 'Please select a date', valid: false }));
        isValid = false;
      }
      if (!revenueTarget.value) {
        setRevenueTarget((prev) => ({
          ...prev,
          errorMessage: 'Please enter a revenue target',
          valid: false,
          pristine: false,
          touched: true,
        }));
        isValid = false;
      }

      if (isValid && revenueReport && revenueReportUuid) {
        const response = currentRevenueGoalUuid
          ? await updateGoal({
              goalUuid: currentRevenueGoalUuid,
              targetValue: Number(revenueTarget.value) * CENTS_PER_DOLLAR,
              targetDate: revenueTargetDate.startDate,
              organizationUuid,
              formulaUuid: revenueReportUuid,
            })
          : await createGoal({
              targetValue: Number(revenueTarget.value) * CENTS_PER_DOLLAR,
              targetDate: revenueTargetDate.startDate,
              organizationUuid,
              formulaUuid: revenueReportUuid,
            });
        if (response.status === StatusCodes.CREATED || response.status === StatusCodes.OK) {
          toast.success(`Revenue goal ${currentRevenueGoalUuid ? 'updated' : 'created'} successfully`);
          await reload();
          goBack();
        } else {
          toast.error('Failed to create revenue goal');
        }
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to save revenue goal');
    }
  };

  const handleReset = ({ currentRevenueGoal }: { currentRevenueGoal: ICompanyGoal | null }): void => {
    if (!currentRevenueGoal) {
      setRevenueTargetDate((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
        errorMessage: '',
        valid: true,
      }));
      setRevenueTarget((prev) => ({
        ...prev,
        value: '',
        errorMessage: '',
        valid: true,
        pristine: true,
        touched: false,
      }));
    } else {
      setRevenueTargetDate((prev) => ({
        ...prev,
        startDate: currentRevenueGoal.targetDate,
        endDate: currentRevenueGoal.targetDate,
        errorMessage: '',
        valid: true,
      }));
      resetRevenueTarget();
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full pt-4">
      {lines && dataKeys && data && (
        <RevenueGoalGraph
          data={data}
          dataKeys={dataKeys}
          lines={lines}
          revenueTargetDate={revenueTargetDate}
          revenueTarget={revenueTarget}
          revenueReport={revenueReport ?? undefined}
          goalValue={revenueTarget.value ? Number(revenueTarget.value) * CENTS_PER_DOLLAR : undefined}
        />
      )}
      <CurrencyInput id="target-value" label="Revenue Target" state={revenueTarget} setState={setRevenueTarget} />
      <div className="w-full">
        <PeriodPicker
          id="target-date"
          label="Date"
          state={revenueTargetDate}
          setState={setRevenueTargetDate}
          defaultInputDisplay="Select Date"
          beBefore={stringDate.addMonths(stringDate.getStringDate(), 12)}
          beAfter={stringDate.getStringDate()}
        />
      </div>
      <Divider className="w-full my-4" />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Typography size="sm" weight="semibold">
            Month-over-Month Growth
          </Typography>
          <Typography size="sm" color="empty">
            Target Monthly Growth Rate:{' '}
            {typeof momGrowthPercentage !== 'number' || !months
              ? '-'
              : formatPercent({ value: momGrowthPercentage, decimalToRound: 0 })}
          </Typography>
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <Button fill="clear" onClick={goBack} className="!w-fit !p-0">
          Back
        </Button>
        <div className="flex gap-4">
          <Button fill="destructiveClear" className="!w-fit !p-0" onClick={() => handleReset({ currentRevenueGoal })}>
            Reset
          </Button>
          <Button
            onClick={() =>
              handleSave({
                revenueReport,
                revenueReportUuid,
                currentRevenueGoalUuid: currentRevenueGoal?.goalUuid ?? null,
                organizationUuid,
              })
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RevenueGoal;
