import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';

export enum IBvaReviewStatus {
  Pending = 'pending',
  Completed = 'completed',
}

export const ZDateValue = z.object({
  value: z.number().nullable(),
  date: ZStringDate,
});

export const ZAverageValuePerMonthSpan = z.object({
  value: z.number(),
  monthSpan: z.number(),
});

export const ZForecastedValuesDetails = z.object({
  monthsAgo: z.number(),
  asOfDate: ZStringDate,
  values: z.array(ZDateValue),
});

export const ZForecastedValues = z.array(ZForecastedValuesDetails);

export const ZBvaReviewItem = z.object({
  status: z.nativeEnum(IBvaReviewStatus),
  actualAverageValues: z.array(ZAverageValuePerMonthSpan),
  forecastedValues: ZForecastedValues,
  actualValues: z.array(ZDateValue),
});

export const ZAggregatedValues = z.object({
  forecastedValues: ZForecastedValues,
  actualValues: z.array(ZDateValue),
});

export const ZBvaReview = z.record(z.string(), ZBvaReviewItem);

export const ZBvaResult = z.object({
  uuid: z.string(),
  headcountReview: ZBvaReview,
  expenseReview: ZBvaReview,
  modelReview: ZBvaReview,
  expenseAggregate: ZAggregatedValues.optional().nullable(),
  headcountAggregate: ZAggregatedValues.optional().nullable(),
});

export const ZBvaResultResponse = z.object({
  bvaResult: z.union([ZBvaResult, z.null()]),
});

export const ZUpdatedBvaResultResponse = z.object({
  updatedBvaResult: z.union([ZBvaResult, z.null()]),
});

export type IDateValue = z.infer<typeof ZDateValue>;
export type IAverageValuePerMonthSpan = z.infer<typeof ZAverageValuePerMonthSpan>;
export type IForecastedValuesDetails = z.infer<typeof ZForecastedValuesDetails>;
export type IForecastedValues = z.infer<typeof ZForecastedValues>;
export type IBvaReviewItem = z.infer<typeof ZBvaReviewItem>;
export type IBvaReview = z.infer<typeof ZBvaReview>;
export type IBvaResult = z.infer<typeof ZBvaResult>;
export type IAggregatedValues = z.infer<typeof ZAggregatedValues>;
export type IBvaResultResponse = z.infer<typeof ZBvaResultResponse>;
export type IUpdatedBvaResultResponse = z.infer<typeof ZUpdatedBvaResultResponse>;
