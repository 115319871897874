import React from 'react';
import { IOnboardingStep, IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';
import Typography from '../Typography';
import { CheckIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const OnboardingStep = ({
  step,
  goTo,
  stepNumber,
  stepDescription,
  isActive,
}: {
  step: IOnboardingStep;
  goTo?: string;
  stepNumber: number;
  stepDescription: string;
  isActive: boolean;
}): React.ReactNode => {
  const userUuid = useSelector((state: State) => state.user.uuid);
  const [updateOnboardingStep] = onboardingStepsApi.useUpdateOnboardingStepMutation();

  let borderColor, backgroundColor, stepIcon;
  let numberColor: 'white' | 'primary' | 'disabled';
  let titleColor: 'lightGreen' | 'primary' | 'disabled';

  switch (step.status) {
    case IOnboardingStepStatusEnum.Completed:
      borderColor = 'green-100';
      backgroundColor = 'green-100';
      numberColor = 'white';
      titleColor = 'lightGreen';
      stepIcon = <CheckIcon className="h-4 w-4" />;
      break;
    case IOnboardingStepStatusEnum.Processing:
      borderColor = 'green-25';
      backgroundColor = 'white';
      numberColor = 'disabled';
      titleColor = 'disabled';
      stepIcon = <Spinner size={16} strokeColor="#AAB7A4" />;
      break;
    default:
      borderColor = isActive ? 'green-75' : 'green-25';
      backgroundColor = 'white';
      numberColor = isActive ? 'primary' : 'disabled';
      titleColor = isActive ? 'primary' : 'disabled';
      stepIcon = stepNumber;
  }

  const handleCompleteStep = async (): Promise<void> => {
    await updateOnboardingStep({
      onboardingStepUuid: step.uuid,
      body: {
        status: IOnboardingStepStatusEnum.Completed,
        completedAt: new Date().toISOString(),
        completedBy: userUuid,
      },
    });
  };

  return (
    <div className="flex flex-row gap-2">
      <div
        className={`flex h-[30px] min-w-[30px] border border-${borderColor} items-center justify-center rounded-md bg-${backgroundColor}`}
      >
        <Typography color={numberColor}>{stepIcon}</Typography>
      </div>
      <div className="flex flex-col gap-0.5 pt-[3px]">
        <div className="flex flex-row w-full justify-between">
          <Typography color={titleColor} weight="medium">
            {step.name}
          </Typography>
          {isActive &&
            goTo &&
            (step.status === IOnboardingStepStatusEnum.Ready ? (
              <Link to={goTo} className="flex flex-row justify-center">
                <Button fill="clear" className="!w-fit !py-0 !px-2">
                  Go
                  <ChevronRightIcon className="h-3.5 w-3.5 stroke-2" />
                </Button>
              </Link>
            ) : (
              <Button onClick={handleCompleteStep} fill="clear" className="!w-fit !py-0 !px-2">
                Finish
              </Button>
            ))}
        </div>
        <div className={`${isActive ? '' : 'h-0 overflow-hidden'}`}>
          <Typography color="secondary">{stepDescription}</Typography>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep;
