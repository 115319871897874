import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  IEmploymentType,
  EMPLOYMENT_TYPE_OPTIONS,
  ZUpdatePositionForm,
  IUpdatePositionForm,
} from '~/services/parallel/headcount.types';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import Input from '~/components/UncontrolledComponents/Input';
import { Select } from '~/components/UncontrolledComponents/Select';
import { SelectDepartment } from '~/components/UncontrolledComponents/SelectDepartment';
import DatePicker from '~/components/DatePicker';
import toast from 'react-hot-toast';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import Button from '~/components/Button';
import { IPositionDetails } from '../../entity/types';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { useDispatch } from 'react-redux';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

const UpdatePositionForm = ({
  onClose,
  orgUuid,
  scenarioUuid,
  position,
  createScenario,
  reloadDashboard,
  awaitCalculations,
  listParams,
}: {
  onClose: () => void;
  orgUuid: string;
  scenarioUuid?: string | null;
  position: IPositionDetails | null;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const [updatePosition, { isLoading: isUpdatingPosition }] = positionsApi.useUpdatePositionMutation();
  const {
    control,
    handleSubmit,
    register,
    setError,
    trigger,
    formState: { errors },
    reset,
  } = useForm<IUpdatePositionForm>({
    defaultValues: {
      employeeName: position?.employeeName ?? undefined,
      title: position?.title ?? undefined,
      employmentType: position?.employmentType ?? undefined,
      departmentUuid: position?.departmentUuid ?? undefined,
      hireDate: position?.hireDate ?? undefined,
    },
    resolver: zodResolver(ZUpdatePositionForm),
    mode: 'all',
    shouldFocusError: false,
  });

  // If the position prop could change after mount, reset the form with the new values.
  useEffect(() => {
    reset({
      employeeName: position?.employeeName ?? undefined,
      title: position?.title ?? undefined,
      employmentType: position?.employmentType ?? undefined,
      departmentUuid: position?.departmentUuid ?? undefined,
      hireDate: position?.hireDate ?? undefined,
    });
  }, [position, reset]);

  const onSubmitForm = handleSubmit(async (data) => {
    if (!position) return;
    if (!scenarioUuid && createScenario) {
      dispatch(updateScenarioLoadingState('creating'));
    } else if (scenarioUuid) {
      dispatch(updateScenarioLoadingState('updating'));
    }
    try {
      const updateData: {
        employeeName?: string | null;
        title?: string;
        employmentType?: IEmploymentType;
        departmentUuid?: string;
        hireDate?: string;
      } = {};
      if ((data.employeeName || position.employeeName) && data.employeeName !== position.employeeName)
        updateData.employeeName = data.employeeName;
      if (data.title !== position.title) updateData.title = data.title;
      if (data.employmentType !== position.employmentType) updateData.employmentType = data.employmentType;
      if (data.departmentUuid !== position.departmentUuid) updateData.departmentUuid = data.departmentUuid;
      if (data.hireDate !== position.hireDate) updateData.hireDate = data.hireDate;

      const query: {
        scenarioUuid?: string;
        awaitCalculations?: boolean;
        createScenario?: boolean;
      } = {};
      if (scenarioUuid) query.scenarioUuid = scenarioUuid;
      if (!scenarioUuid && createScenario) query.createScenario = true;
      if (awaitCalculations) query.awaitCalculations = true;

      const response = await updatePosition({
        params: {
          orgUuid,
          positionUuid: position.positionUuid,
        },
        query,
        body: updateData,
        listParams,
      }).unwrap();

      if (!scenarioUuid && createScenario && response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      toast.success('Position updated successfully');
      onClose();
      if (reloadDashboard) {
        await reloadDashboard();
      }
    } catch (error) {
      setError('root', {
        message: 'Failed to update position. Please try again.',
      });
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  });

  return (
    <div className="flex flex-col w-full gap-4">
      <Input
        {...register('employeeName')}
        id="employeeName"
        placeholder="Employee Name"
        label="Employee"
        type="text"
        error={errors.employeeName?.message}
        optional
      />
      <Input
        {...register('title')}
        id="title"
        placeholder="Title"
        label="Title"
        type="text"
        error={errors.title?.message}
      />
      <Controller
        control={control}
        name="employmentType"
        render={({ field, fieldState }) => (
          <Select
            id="employmentType"
            label="Employment Type"
            options={EMPLOYMENT_TYPE_OPTIONS}
            error={fieldState.error?.message}
            value={field.value}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />
      <Controller
        control={control}
        name="departmentUuid"
        render={({ field, fieldState }) => (
          <SelectDepartment
            id="departmentUuid"
            value={field.value}
            onChange={(value) => field.onChange(value)}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="hireDate"
        render={({ field, fieldState }) => {
          const defaultDatePickerState: IDatePickerState = {
            value: { startDate: field.value, endDate: field.value },
            valid: !fieldState.error,
            errorMessage: fieldState.error?.message,
            touched: !!fieldState.error,
            pristine: !fieldState.error,
          };

          const customSetDatePickerState: React.Dispatch<React.SetStateAction<IDatePickerState>> = (action) => {
            const newState = typeof action === 'function' ? action(defaultDatePickerState) : action;
            field.onChange(newState.value.startDate);
            trigger('hireDate');
          };

          return (
            <DatePicker
              id="inline-hireDate"
              state={defaultDatePickerState}
              setState={customSetDatePickerState}
              label="Hire Date"
            />
          );
        }}
      />

      {/* Render other fields as needed—for example, employeeName or others */}
      <div className="flex flex-col gap-4 mt-2">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex flex-row justify-between">
          <Button
            fill="clear"
            className="!w-fit !px-0 !py-2"
            onClick={() => {
              onClose();
              reset({
                employeeName: position?.employeeName ?? undefined,
                title: position?.title ?? undefined,
                employmentType: position?.employmentType ?? undefined,
                departmentUuid: position?.departmentUuid ?? undefined,
                hireDate: position?.hireDate ?? undefined,
              });
            }}
          >
            Cancel
          </Button>
          <Button className="!w-fit !px-4 !py-2" onClick={onSubmitForm} loading={isUpdatingPosition}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePositionForm;
