import { TrashIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Typography from '~/components/Typography';
import useHover from '~/utils/hooks/useHover';
import { DragIconSortableItem } from '~/components/DragIconSortableItem/DragIconSortableItem';

const CustomizationTile = ({
  title,
  formulaUuid,
  type,
  onRemove,
}: {
  title: string;
  formulaUuid: string;
  type: 'metrics' | 'graphs' | 'levers';
  onRemove: (args: { formulaUuid: string; type: 'metrics' | 'graphs' | 'levers' }) => void;
}): React.ReactNode => {
  const [ref, hovering] = useHover();

  return (
    <DragIconSortableItem
      id={formulaUuid}
      className="border border-neutral-50 bg-white rounded flex flex-row justify-start items-center"
    >
      <div
        className="w-full h-full flex flex-row pr-2 py-2 items-center justify-between cursor-default overflow-hidden"
        ref={ref}
      >
        <Typography color="secondary" className="truncate">
          {title}
        </Typography>
        {!(title === 'Revenue' && type === 'graphs') && (
          <TrashIcon
            className={`flex-shrink-0 size-[18px] hover:text-red-500 text-neutral-100 cursor-pointer ${
              hovering ? 'visible' : 'invisible'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onRemove({ formulaUuid, type });
            }}
          />
        )}
      </div>
    </DragIconSortableItem>
  );
};

export default CustomizationTile;
