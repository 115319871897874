import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from '../baseQuery';
import { IOnboardingStep, IOnboardingStepNameEnum } from '../../onboardingSteps.types';
import { IOnboardingStepsResponse, IUpdateOnboardingStepRequest } from './onboardingStepsRequestSchemas';

interface IOnboardingStepsGetResponse {
  list: IOnboardingStep[];
  dictionary: Record<IOnboardingStepNameEnum, IOnboardingStep>;
}

export const onboardingStepsApi = createApi({
  reducerPath: 'onboardingStepsApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['OnboardingStep'],
  endpoints: (builder) => ({
    getOnboardingSteps: builder.query<IOnboardingStepsGetResponse, void>({
      query: () => {
        return {
          url: 'onboarding-steps',
        };
      },
      transformResponse: (response: { data: IOnboardingStepsResponse }) => {
        const dictionary = response.data.onboardingSteps.reduce(
          (acc, step) => ({
            ...acc,
            [step.name]: step,
          }),
          {} as Record<string, IOnboardingStep>,
        );
        return { list: response.data.onboardingSteps, dictionary };
      },
      providesTags: ['OnboardingStep'],
    }),

    updateOnboardingStep: builder.mutation<IOnboardingStep, IUpdateOnboardingStepRequest>({
      query: (data) => {
        return {
          url: `onboarding-steps/${data.onboardingStepUuid}`,
          method: 'PATCH',
          body: data.body,
        };
      },
      invalidatesTags: ['OnboardingStep'],
    }),
  }),
});

export const { useGetOnboardingStepsQuery, useUpdateOnboardingStepMutation } = onboardingStepsApi;
