import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { DrawerTab, setActiveTab } from '~/store/drawerContentSlice';
import HelpCenter from '../HelpCenter';
import Onboarding from '../Onboarding';
import { QuestionMarkCircleIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useFeatureFlagPosthog } from '~/utils/hooks/useFeatureFlag';
import { useLocation } from 'react-router-dom';
import OnboardingDrawerButton from '../Onboarding/OnboardingDrawerButton';
import BaseDrawerButton from './BaseDrawerButton';
import { useGetOnboardingStepsQuery } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';

interface TabConfig {
  id: DrawerTab;
  label: string;
  icon: typeof QuestionMarkCircleIcon;
  component: React.FC;
  tabButton: React.FC<{
    isActive: boolean;
    handleTabClick: (id: DrawerTab) => void;
    tabId: DrawerTab;
    tabLabel: string;
    tabIcon: typeof QuestionMarkCircleIcon;
  }>;
}

const TABS: TabConfig[] = [
  {
    id: 'help-center',
    label: 'Help Center',
    icon: QuestionMarkCircleIcon,
    component: HelpCenter,
    tabButton: BaseDrawerButton,
  },
  {
    id: 'onboarding',
    label: 'Onboarding',
    icon: ClipboardDocumentCheckIcon,
    component: Onboarding,
    tabButton: OnboardingDrawerButton,
  },
];

const Drawer: React.FC = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const { isExpanded, activeTab } = useSelector((state: State) => state.drawerContent);
  const helpCenterEnabled = useFeatureFlagPosthog('help_center');
  const location = useLocation();
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { data: onboardingSteps } = useGetOnboardingStepsQuery(undefined, {
    skip: !organizationUuid,
  });

  useEffect(() => {
    /**
     * Show Onboarding until the steps are completed.
     * Refreshing the page, logging out, etc. expands the drawer to onboarding steps by default unless another tab is active.
     * Closing it will respect the user's preference until a refresh.
     */
    if (isFirstRender.current && onboardingSteps) {
      isFirstRender.current = false;
      const allOnboardingStepsCompleted = onboardingSteps.list.every(
        (step) => step.status === IOnboardingStepStatusEnum.Completed,
      );
      if (!allOnboardingStepsCompleted && !isExpanded) {
        dispatch(setActiveTab('onboarding'));
      }
    }
  }, [onboardingSteps]);

  const isShareLinkPreview = location.pathname.startsWith('/preview');

  const handleTabClick = (tabId: DrawerTab): void => {
    if (activeTab === tabId) {
      dispatch(setActiveTab(null));
    } else {
      if (!helpCenterEnabled && tabId === 'help-center') {
        window.open(
          'https://docs.google.com/document/d/1eUt3ePxGGkKwxD0XCWXVnrxZCPv7jiTxR3a1I2uEZ7E/edit?usp=sharing',
          '_blank',
        );
        return;
      }

      dispatch(setActiveTab(tabId));
    }
  };

  const ActiveComponent = TABS.find((tab) => tab.id === activeTab)?.component || ((): null => null);

  if (isShareLinkPreview) {
    return null;
  }

  return (
    <div className={`flex h-screen ${isExpanded ? 'w-[500px]' : 'w-[47px]'}`}>
      <div className="h-full flex border-l border-green-25 z-50 sidebar-bg">
        {/* Collapsed state tabs */}
        <div
          className="h-full flex items-center w-[47px] border-r border-green-25"
          style={{
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
          }}
        >
          <div className="flex gap-2 mt-2">
            {TABS.map((tab) => (
              <tab.tabButton
                key={tab.id}
                isActive={activeTab === tab.id}
                handleTabClick={handleTabClick}
                tabId={tab.id}
                tabLabel={tab.label}
                tabIcon={tab.icon}
              />
            ))}
          </div>
        </div>

        {/* Expanded drawer */}
        {isExpanded && (
          <div className="w-[453px] h-full overflow-y-auto">
            <ActiveComponent />
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
