import React, { forwardRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import Input from './Input';

export interface SelectMultipleOption {
  value: string;
  label: string;
  node?: React.ReactNode;
  disabled?: boolean;
}

interface Props {
  id: string;
  options: SelectMultipleOption[];
  label?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  name?: string;
  includeSearch?: boolean;
}

export const SelectMultiple = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      label,
      options,
      className = '',
      error,
      placeholder = 'Select options',
      disabled = false,
      required = false,
      value = [],
      onChange,
      onBlur,
      onFocus,
      name,
      includeSearch = false,
    },
    ref,
  ) => {
    const [searchTerm, setSearchTerm] = useState('');
    const selected = options.filter((option) => value.includes(option.value));

    const handleChange = (selectedOptions: SelectMultipleOption[]): void => {
      if (onChange) {
        onChange(selectedOptions.map((option) => option.value));
      }
    };

    const handleRemoveOption = (optionValue: string): void => {
      if (onChange) {
        onChange(value.filter((v) => v !== optionValue));
      }
    };

    const handleClearAll = (): void => {
      if (onChange) {
        onChange([]);
      }
    };

    const handleSelectAll = (): void => {
      if (onChange) {
        const newValue = value.length === options.length ? [] : options.map((opt) => opt.value);
        onChange(newValue);
      }
    };

    const filteredOptions = options.filter((option) =>
      option.label.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
      <div className={`w-full ${className}`} data-testid={id}>
        <div className="flex justify-between items-center mb-1">
          <div className="flex flex-row">
            {label && (
              <Typography tag="span" color={disabled ? 'disabled' : 'primary'}>
                {label}
              </Typography>
            )}
            {required && (
              <Typography tag="span" size="xs" color={disabled ? 'disabled' : 'warning'}>
                *
              </Typography>
            )}
          </div>
          <Button onClick={handleSelectAll} fill="clear" className="!p-0 !w-auto" disabled={disabled}>
            <Typography id={`${id}-select-all`} color={disabled ? 'disabled' : 'primaryGreen'}>
              {value.length === options.length ? 'Deselect All' : 'Select All'}
            </Typography>
          </Button>
        </div>
        <Listbox disabled={disabled} value={selected} onChange={handleChange} name={name} multiple>
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button
                  ref={ref}
                  data-testid={`${id}-button`}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={`relative w-full flex items-center min-h-[38px] border border-solid rounded
                ${error ? 'border-red-300' : 'border-gray-300'}`}
                >
                  {/* Main content area with right padding for controls */}
                  <div className="flex-1 flex flex-wrap items-center gap-1 p-1.5 pr-16">
                    {selected.length > 0 ? (
                      selected.map((option) => (
                        <span
                          key={option.value}
                          className="inline-flex items-center gap-1.5 px-2 py-1 bg-green-100 text-neutral-700 
        rounded-md border border-neutral-200 hover:bg-green-200 transition-colors"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Typography className="">{option.label}</Typography>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveOption(option.value);
                            }}
                            className="text-neutral-400 hover:text-neutral-600 rounded-full 
          transition-colors focus:outline-none focus:ring-2 focus:ring-offset-1 
          focus:ring-neutral-400"
                          >
                            <XMarkIcon className="size-4" />
                          </button>
                        </span>
                      ))
                    ) : (
                      <Typography color="empty">{placeholder}</Typography>
                    )}
                  </div>

                  {/* Fixed position controls at top right */}
                  <div className="absolute right-0 flex items-center gap-0.5 px-2 top-0 h-[38px]">
                    {selected.length > 0 && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearAll();
                        }}
                        className="p-1.5 hover:bg-neutral-100 rounded-full"
                        aria-label="Clear all selected options"
                      >
                        <XMarkIcon className="size-5 text-neutral-500" />
                      </button>
                    )}
                    <div className="flex items-center justify-center h-[38px]">
                      {open ? (
                        <ChevronUpIcon className="size-5 text-gray-400" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                      )}
                    </div>
                  </div>
                </Listbox.Button>

                <Transition
                  as={React.Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute z-20 mt-1 w-full rounded bg-white 
    shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
    flex flex-col max-h-60"
                  >
                    {includeSearch && (
                      <div className="sticky top-0 p-2 bg-white z-30 border-b border-gray-100">
                        <Input
                          id={`${id}-search`}
                          type="search"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    )}
                    <div className="overflow-auto flex-1">
                      {filteredOptions.map((option) => (
                        <Listbox.Option
                          key={option.value}
                          value={option}
                          disabled={option.disabled}
                          className={({ active }) =>
                            `relative ${!option.disabled && 'cursor-pointer'} select-none py-2 pl-10 pr-4
                          ${active ? 'bg-green-100 text-green-900' : 'text-gray-900'}
                          `
                          }
                        >
                          {({ selected }) => (
                            <>
                              <Typography
                                color={option.disabled ? 'disabled' : selected ? 'green' : 'primary'}
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {option.node ?? option.label}
                              </Typography>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                  <CheckIcon className="size-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </div>
                  </Listbox.Options>
                </Transition>
              </div>
              {error && (
                <Typography id={`${id}-select-error`} tag="span" color="warning" className="italic p-1">
                  {error}
                </Typography>
              )}
            </>
          )}
        </Listbox>
      </div>
    );
  },
);

SelectMultiple.displayName = 'SelectMultiple';
