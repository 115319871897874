import React from 'react';
import { ILockedIndex } from './entity/types';

interface IProps {
  cx?: number;
  cy?: number;
  stroke?: string;
  index?: number;
  height?: number;
  activeIndex: number;
  lockedIndexes: ILockedIndex[];
  hoverIcon?: React.ReactElement;
  isPrimaryLine: boolean;
  hoverLockedIndexIcon?: React.ReactElement;
  currentMonthIndex: number;
  externalActiveIndex?: number;
  renderAllDots?: boolean;
}

const LineGraphDot = ({
  stroke,
  index,
  activeIndex,
  cx,
  cy,
  height,
  lockedIndexes,
  hoverIcon,
  isPrimaryLine,
  hoverLockedIndexIcon,
  currentMonthIndex,
  externalActiveIndex,
  renderAllDots,
}: IProps): React.ReactElement | null => {
  const lockedIndex = lockedIndexes.findIndex((li) => li.index === index);

  if (renderAllDots && cx && cy && stroke && height) {
    return (
      <>
        {activeIndex === index && isPrimaryLine && (
          <line
            x1={cx}
            y1={hoverIcon ? 32 : 0}
            x2={cx}
            y2={height}
            stroke="#BCBCBC"
            strokeWidth={1.5}
            strokeDasharray="8 8"
          />
        )}
        <>
          <line x1={cx} y1={cy - 5} x2={cx} y2={cy + 5} stroke={stroke} strokeWidth={1.5} />
        </>
        <circle r={6} cx={cx} cy={cy} fill={stroke} strokeWidth={2} stroke="white" />
      </>
    );
  } else if (
    activeIndex === index &&
    activeIndex !== -1 &&
    lockedIndex !== -1 &&
    hoverLockedIndexIcon &&
    cx &&
    cy &&
    stroke &&
    height
  ) {
    return (
      <>
        <svg x={cx - 16} y={0} width={32} height={32}>
          {hoverLockedIndexIcon}
        </svg>
        {isPrimaryLine && (
          <line x1={cx} y1={32} x2={cx} y2={height} stroke="#BCBCBC" strokeWidth={1.5} strokeDasharray="8 8" />
        )}
      </>
    );
  } else if (lockedIndex !== -1 && cx && cy && stroke && height) {
    return (
      <>
        {isPrimaryLine && (
          <line
            x1={cx}
            y1={lockedIndexes[lockedIndex].icon ? 32 : 0}
            x2={cx}
            y2={height}
            stroke="#BCBCBC"
            strokeWidth={1.5}
            strokeDasharray="8 8"
          />
        )}
        {lockedIndexes[lockedIndex]?.icon?.props?.state &&
        lockedIndexes[lockedIndex]?.icon?.props?.state === 'changed' ? null : (
          <circle r={6} cx={cx} cy={cy} fill={stroke} strokeWidth={2} stroke="white" />
        )}
        <svg x={cx - 16} y={0} width={32} height={32}>
          {lockedIndexes[lockedIndex]?.icon ?? null}
        </svg>
      </>
    );
  } else if (index === activeIndex && cx && cy && stroke && height) {
    return (
      <>
        {isPrimaryLine && (
          <line
            x1={cx}
            y1={hoverIcon ? 32 : 0}
            x2={cx}
            y2={height}
            stroke="#BCBCBC"
            strokeWidth={1.5}
            strokeDasharray="8 8"
          />
        )}
        <circle r={6} cx={cx} cy={cy} fill={stroke} strokeWidth={2} stroke="white" />
        {hoverIcon && (
          <svg x={cx - 16} y={0} width={32} height={32}>
            {hoverIcon}
          </svg>
        )}
      </>
    );
  } else if (index === currentMonthIndex && currentMonthIndex !== 0 && cx && cy && stroke && height) {
    return (
      <>
        <line x1={cx} y1={cy - 5} x2={cx} y2={cy + 5} stroke={stroke} strokeWidth={1.5} />
      </>
    );
  } else if (externalActiveIndex === index && cx && cy && stroke && height) {
    return (
      <>
        <line x1={cx} y1={cy - 7} x2={cx} y2={cy + 7} stroke={'#999999'} strokeWidth={1.5} />
      </>
    );
  }

  return null;
};

export default LineGraphDot;
