import React, { useRef } from 'react';
import { RadioInputState, RadioInputType } from '~/components/RadioInput/RadioInput.types';
import Typography from '~/components/Typography';

interface Props {
  id: string;
  state: RadioInputState;
  setState: (option: RadioInputType) => void;
  label: string;
  description?: string;
  disabled: boolean;
  required?: boolean;
}

/**
 * @deprecated Use UncontrolledRadioInputTile instead
 */
const RadioInputTile = ({ id, state, setState, label, disabled, required }: Props): React.ReactNode => {
  const shouldShowError = required && !state.valid && state.touched && state.errorMessage;
  const lastItemRef = useRef<HTMLLabelElement | null>(null);
  return (
    <div className="flex flex-col flex-wrap w-full">
      <div className="flex flex-row flex-wrap w-full">
        <Typography tag="span" color={state.disabled ? 'disabled' : 'primary'} className={`${label ? 'mb-1' : ''}`}>
          {label}
        </Typography>
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {state.options.map((option) => {
          return (
            <label key={option.value} ref={lastItemRef} className={`flex items-center cursor-pointer`}>
              <input
                type="radio"
                value={option.value}
                id={option.value}
                name={id}
                checked={state.selected?.value === option.value}
                onChange={() => setState(option)}
                className="sr-only peer"
                disabled={disabled}
              />
              <span
                className="flex items-center bg-transparent py-2 px-4 text-gray-800 border border-gray-300 rounded transition duration-300 ease-in-out
              peer-checked:bg-green-500 peer-checked:text-white peer-checked:border-green-400
              peer-hover:bg-transparent peer-hover:text-green-400 peer-hover:border-gray-400
              peer-checked:peer-hover:bg-green-500 peer-checked:peer-hover:text-white peer-checked:peer-hover:border-green-400"
                data-testid={`${id}-option-${option.value}`}
              >
                {option.icon}
                <span className={`whitespace-nowrap ${option.icon ? 'ml-2' : ''}`}>{option.label}</span>
              </span>
            </label>
          );
        })}
      </div>
      {shouldShowError && (
        <Typography id={`${id}-input-error`} tag="span" color={'warning'} className={`italic p-1`}>
          {state.errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default RadioInputTile;
