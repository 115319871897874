import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '~/components/Typography';
import Input from '~/components/Input/InputWrapper';
import useInput from '~/components/Input/useInput';
import { setCurrentHelpPage } from '~/store/drawerContentSlice';
import { useGetPostsQuery } from '~/services/parallel/api/helpCenter/helpCenterApi';
import { debounce } from 'lodash';

const HelpCenterHome: React.FC = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useInput({
    validation: /.*/,
  });
  const [debouncedSearch, setDebouncedSearch] = useState('');

  // Fetch search results
  const { data: searchResults, isFetching: isSearching } = useGetPostsQuery(
    { search: debouncedSearch },
    {
      skip: !debouncedSearch || debouncedSearch.length < 2,
    },
  );

  // Debounce search input
  useEffect(() => {
    const debouncedSetSearch = debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300);

    debouncedSetSearch(search.value);
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [search.value]);

  const renderContent = (): React.ReactNode => {
    if (isSearching) {
      return (
        <div className="flex justify-center">
          <Typography>Loading...</Typography>
        </div>
      );
    }

    if (debouncedSearch && debouncedSearch.length >= 2) {
      return (
        <>
          <Typography weight="semibold">Search Results</Typography>
          <div className="flex flex-col gap-2">
            {searchResults && searchResults.length > 0 ? (
              searchResults.map((result) => (
                <button
                  key={result.uuid}
                  onClick={() => dispatch(setCurrentHelpPage(result.uuid))}
                  className="text-left p-3 hover:bg-neutral-50 rounded-lg transition-colors"
                >
                  <Typography>{result.title}</Typography>
                </button>
              ))
            ) : (
              <Typography>No results found for &ldquo;{debouncedSearch}&rdquo;</Typography>
            )}
          </div>
        </>
      );
    }

    return (
      <div className="flex justify-center">
        <Typography>No articles found</Typography>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Input
          id="help-center-search"
          type="search"
          placeholder="Search help articles..."
          state={search}
          setState={setSearch}
        />
      </div>

      <div className="flex flex-col gap-4">{renderContent()}</div>
    </div>
  );
};

export default HelpCenterHome;
