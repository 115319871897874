import { z } from 'zod';
import { IUserRole } from '~/utils/waitForStoreRehydration';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

export const ZUserPreference = z.object({
  userUuid: z.string(),
  primaryOrganizationUuid: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  sideMenuExpanded: z.boolean(),
  defaultGraphStartDate: z.date().nullable(),
  defaultGraphEndDate: z.date().nullable(),
});

export const ZDepartmentAccessList = z.array(z.union([z.string(), z.object({ label: z.string(), value: z.string() })]));

export const ZUserPermission = z.object({
  uuid: z.string(),
  userUuid: z.string(),
  departmentAccessList: ZDepartmentAccessList,
  role: z.string(),
  organizationUuid: z.string(),
  isActive: z.boolean(),
  receiveAutomatedEmails: z.boolean(),
});

export const ZUser = z.object({
  uuid: z.string(),
  createdAt: iso8601Validator,
  updatedAt: iso8601Validator,
  name: z.string(),
  email: z.string(),
  password: z.string().nullable().optional(),
  role: z.nativeEnum(IUserRole),
  preferences: z.lazy(() => ZUserPreference).optional(),
  permissions: z.lazy(() => z.array(ZUserPermission)).optional(),
});

export type IUser = z.infer<typeof ZUser>;
