import React, { useState, useEffect, useMemo } from 'react';
import Button from '~/components/Button';
import { Select } from '~/components/UncontrolledComponents/Select';
import { SelectMultiple } from '~/components/UncontrolledComponents/SelectMultiple';
import {
  ExpenseDriverEnum,
  ExpenseFrequencyEnum,
  IFormula,
  IFormulaTypeEnum,
  IRecipeVariables,
} from '~/services/parallel/formulas.types';
import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { RadioInputTile } from '~/components/UncontrolledComponents/RadioInputTile';
import Input from '~/components/UncontrolledComponents/Input';
import { SegmentedControl } from '~/components/UncontrolledComponents/SegmentedControl';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { transformExpenseFormForSubmission } from '../ExpenseForm/transformExpenseFormForSubmission';
import {
  EMPLOYMENT_TYPE_OPTIONS,
  EXPENSE_TYPE_OPTIONS,
  getCurrencyLabel,
  getFrequencyOptionsFromSegmentType,
  getSegmentTypeFromDriver,
  HEADCOUNT_DRIVER_OPTIONS,
} from '../ExpenseForm/formConstants';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import FormulaBuilderInput from '~/pages/FinancialModelDeprecated/components/FormulaBuilder/FormulaBuilderInput';
import { IFormulaSegment } from '~/pages/FinancialModelDeprecated/entity/types';
import Typography from '~/components/Typography';
import Checkbox from '~/components/Checkbox';

export type SegmentType = 'setCost' | 'headcountDriven' | 'custom';

export interface IFormulaState {
  topLevelFormulaUuid?: string;
  formula: string;
  variables: IRecipeVariables;
  formulaList: IFormula[];
  editable?: boolean;
}

export interface IFormulaInputState {
  formulaState?: IFormulaState;
  variables?: IRecipeVariables;
  formulaTitle?: string;
  formulaUuid?: string;
  isCustom: boolean;
}

const ZExpenseForm = z
  .object({
    segmentType: z.enum(['setCost', 'headcountDriven', 'custom']),
    expenseDriver: z.nativeEnum(ExpenseDriverEnum).nullable(),
    amount: z.string().nullable(),
    percentage: z.string().nullable(),
    employmentTypes: z.array(z.string()),
    frequency: z.nativeEnum(ExpenseFrequencyEnum).optional(),
    min: z.string().nullable().optional(),
    max: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      if (data.segmentType === 'headcountDriven') {
        return data.employmentTypes.length > 0;
      }
      return true;
    },
    {
      message: 'Employment type is required for headcount driven expenses',
      path: ['employmentTypes'], // This will make the error show up on the employmentTypes field
    },
  )
  .superRefine((data, ctx) => {
    if (data.min && data.max) {
      if (!(parseFloat(data.min) <= parseFloat(data.max))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['min'],
          message: 'Min must be less than max',
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['max'],
          message: 'Max must be greater than min',
        });
      }
    }
  });

export type IExpenseFormData = z.infer<typeof ZExpenseForm>;

interface Props {
  onClose: ({ successfulSave }: { successfulSave: boolean }) => void;
  initialFormulaData?: Partial<IFormula>;
  formulaUuid: string | null;
  scenarioUuid: string | null;
  formulaList: IFormula[];
  completeReview: () => Promise<void>;
  allDepartments: string[];
}

export const BvaExpenseForm = ({
  onClose,
  initialFormulaData,
  formulaUuid,
  scenarioUuid,
  formulaList,
  completeReview,
  allDepartments,
}: Props): React.ReactNode => {
  const initialExpenseContext = initialFormulaData?.context?.expenseContext;

  // State for FormulaBuilderInput
  const [variables, setVariables] = useState<IRecipeVariables>({});
  const [formula, setFormula] = useState<IFormulaSegment[]>([]);
  const [updatedFormula, setUpdatedFormula] = useState<IFormulaSegment[]>([]);
  const [formulaValue, setFormulaValue] = useState<string>('');
  const [displayFormulaError, setDisplayFormulaError] = useState<{
    isDisplayed: boolean;
    message: string;
  }>({
    isDisplayed: false,
    message: '',
  });
  const initialHasMinMax = Boolean(initialFormulaData?.recipe?.min || initialFormulaData?.recipe?.max);
  const [addMinMax, setAddMinMax] = useState<boolean>(initialHasMinMax);

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<IExpenseFormData>({
    defaultValues: {
      segmentType: getSegmentTypeFromDriver(initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost),
      expenseDriver: initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost,
      amount: initialExpenseContext?.amount ? (initialExpenseContext.amount / CENTS_PER_DOLLAR).toString() : '',
      percentage:
        initialExpenseContext?.driver === 'headcountPercentCompensation' && initialExpenseContext.amount !== undefined
          ? (initialExpenseContext.amount / 100).toString()
          : '',
      employmentTypes: initialExpenseContext?.employmentTypes ?? [],
      frequency: initialExpenseContext?.frequency ?? undefined,
      min: initialFormulaData?.recipe?.min ?? undefined,
      max: initialFormulaData?.recipe?.max ?? undefined,
    },
    resolver: zodResolver(ZExpenseForm),
    mode: 'all',
  });

  const [formulaInputState, setFormulaInputState] = useState<IFormulaInputState>();

  const [upsertExpenseFormula] = formulasApi.useUpsertExpenseFormulaMutation();

  const frequency = watch('frequency');
  const segmentType = watch('segmentType');
  const expenseDriver = watch('expenseDriver');
  const amount = watch('amount');
  const percentage = watch('percentage');
  const employmentTypes = watch('employmentTypes');
  const min = watch('min');
  const max = watch('max');

  useEffect(() => {
    if (initialFormulaData?.formulaUuid) {
      if (initialExpenseContext?.driver === ExpenseDriverEnum.Custom) {
        setFormulaInputState((prev) => ({
          ...prev,
          formulaState: {
            ...prev?.formulaState,
            formula: initialFormulaData.recipe?.expression ?? '',
            variables: initialFormulaData.recipe?.variables ?? {},
            formulaList: formulaList,
            editable: true,
            topLevelFormulaUuid: initialFormulaData.formulaUuid,
          },
          variables: initialFormulaData.recipe?.variables ?? {},
          formulaUuid: initialFormulaData.formulaUuid,
          formulaTitle: initialFormulaData.recipe?.name,
          isCustom: true,
        }));
      } else {
        setFormulaInputState({
          formulaState: {
            topLevelFormulaUuid: initialFormulaData.formulaUuid,
            formula: '',
            variables: {},
            formulaList: formulaList,
            editable: true,
          },
          variables: {},
          isCustom: false,
          formulaUuid: initialFormulaData.formulaUuid,
        });
      }
    } else {
      const segmentType = watch('segmentType');
      setFormulaInputState((prev) => ({
        ...prev,
        formulaState: {
          ...prev?.formulaState,
          formula: formula.map((f) => f.textValue).join(''),
          variables: variables,
          formulaList: formulaList,
          editable: true,
        },
        variables: variables,
        isCustom: segmentType === 'custom',
        formulaUuid: undefined,
      }));
    }
  }, [initialFormulaData, formula, variables, formulaList]);

  const onSubmitForm = handleSubmit(async (data) => {
    try {
      if (!formulaUuid) throw new Error('Problem finding expense');
      if (!initialFormulaData?.recipe?.startDate) throw new Error('Error with initial expense data');
      const transformedData = transformExpenseFormForSubmission({
        formData: {
          ...data,
          name: initialFormulaData.recipe.name,
          departments: initialExpenseContext?.departments ?? [],
          category: initialExpenseContext?.tag ?? 'Other',
          dataSourceUuids: initialFormulaData.dataSourceUuids ?? [],
          startDate: initialFormulaData.recipe.startDate,
          endDate: initialFormulaData.recipe.endDate,
        },
        formulaUuid,
        scenarioUuid: scenarioUuid ?? undefined,
        customFormula: segmentType === 'custom' ? (updatedFormula.length > 0 ? updatedFormula : formula) : undefined,
        customVariables: segmentType === 'custom' ? variables : undefined,
        allDepartments,
      });

      await upsertExpenseFormula(transformedData).unwrap();

      await completeReview();
      onClose({ successfulSave: true });
    } catch (error) {
      setError('root', {
        message: 'Expense failed to save. Please try again.',
      });
    }
  });

  const changesHaveBeenMade = useMemo(() => {
    if (!formulaUuid || !initialFormulaData?.recipe?.startDate) return false;

    // Transform initial data
    const initialTransformed = transformExpenseFormForSubmission({
      formData: {
        segmentType: getSegmentTypeFromDriver(initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost),
        expenseDriver: initialExpenseContext?.driver ?? ExpenseDriverEnum.SetCost,
        amount: initialExpenseContext?.amount ? (initialExpenseContext.amount / CENTS_PER_DOLLAR).toString() : '',
        percentage:
          initialExpenseContext?.driver === 'headcountPercentCompensation' && initialExpenseContext.amount !== undefined
            ? (initialExpenseContext.amount / 100).toString()
            : '',
        employmentTypes: initialExpenseContext?.employmentTypes ?? [],
        frequency: initialExpenseContext?.frequency ?? undefined,
        name: initialFormulaData.recipe.name,
        departments: initialExpenseContext?.departments ?? [],
        category: initialExpenseContext?.tag ?? 'Other',
        dataSourceUuids: initialFormulaData.dataSourceUuids ?? [],
        startDate: initialFormulaData.recipe.startDate,
        endDate: initialFormulaData.recipe.endDate,
        min: initialFormulaData.recipe.min,
        max: initialFormulaData.recipe.max,
      },
      formulaUuid,
      scenarioUuid: scenarioUuid ?? undefined,
      allDepartments,
    });

    // Transform current data
    const currentTransformed = transformExpenseFormForSubmission({
      formData: {
        segmentType,
        expenseDriver,
        amount,
        percentage,
        employmentTypes,
        frequency,
        min,
        max,
        name: initialFormulaData.recipe.name,
        departments: initialExpenseContext?.departments ?? [],
        category: initialExpenseContext?.tag ?? 'Other',
        dataSourceUuids: initialFormulaData.dataSourceUuids ?? [],
        startDate: initialFormulaData.recipe.startDate,
        endDate: initialFormulaData.recipe.endDate,
      },
      formulaUuid,
      scenarioUuid: scenarioUuid ?? undefined,
      allDepartments,
    });

    const formulaExpression = updatedFormula.map((f) => f.textValue).join('');
    const initialExpression = initialFormulaData.recipe.expression;

    const formulaExpressionChanged = segmentType === 'custom' && formulaExpression !== initialExpression;

    const originalVariables = initialFormulaData.recipe.variables;
    const currentVariables = variables;

    const variablesChanged =
      segmentType === 'custom' && JSON.stringify(originalVariables) !== JSON.stringify(currentVariables);

    return (
      JSON.stringify(initialTransformed) !== JSON.stringify(currentTransformed) ||
      formulaExpressionChanged ||
      variablesChanged
    );
  }, [
    frequency,
    segmentType,
    expenseDriver,
    amount,
    percentage,
    employmentTypes,
    min,
    max,
    initialFormulaData,
    updatedFormula,
    formulaValue,
    variables,
    formulaInputState,
  ]);

  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <Controller
            control={control}
            name="segmentType"
            render={({ field }) => (
              <SegmentedControl
                id="expense-type-control"
                name="segmentType"
                segments={EXPENSE_TYPE_OPTIONS}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value as 'setCost' | 'headcountDriven' | 'custom');
                  if (value !== 'custom') {
                    setValue('min', '');
                    setValue('max', '');
                  }

                  if (value === 'setCost') {
                    setValue('expenseDriver', ExpenseDriverEnum.SetCost);
                  } else if (value === 'custom') {
                    setValue('expenseDriver', ExpenseDriverEnum.Custom);
                    if (initialHasMinMax) {
                      setAddMinMax(true);
                      setValue('min', initialFormulaData?.recipe?.min ?? '');
                      setValue('max', initialFormulaData?.recipe?.max ?? '');
                    }
                  }
                }}
              />
            )}
          />
          {segmentType === 'headcountDriven' && (
            <Controller
              control={control}
              name="expenseDriver"
              render={({ field, fieldState }) => (
                <Select
                  label="Type"
                  id={'expenseHeadcountDriver'}
                  options={HEADCOUNT_DRIVER_OPTIONS}
                  value={field.value}
                  onChange={(value) => field.onChange(value as ExpenseDriverEnum)}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {/* If the expense driver is headcount percent compensation, show the percentage input */}
          {expenseDriver === 'headcountPercentCompensation' && (
            <Controller
              control={control}
              name="percentage"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  id="expenseAmount"
                  type="percentage"
                  label="Percentage"
                  error={fieldState.error?.message}
                  onChange={(value) => field.onChange(value)}
                  value={field.value ?? undefined}
                />
              )}
            />
          )}
          {/* If the expense driver is set cost or headcount fixed, show the currency input */}
          {[ExpenseDriverEnum.SetCost, ExpenseDriverEnum.HeadcountFixed].includes(
            expenseDriver as ExpenseDriverEnum,
          ) && (
            <Controller
              control={control}
              name="amount"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  type="currency"
                  includeDollarSign
                  id="expenseAmount"
                  label={`${getCurrencyLabel({
                    type: segmentType,
                    frequency,
                  })}`}
                  error={fieldState.error?.message}
                  onChange={(value) => field.onChange(value)}
                  value={field.value ?? undefined}
                />
              )}
            />
          )}
          {segmentType === 'headcountDriven' && (
            <Controller
              control={control}
              name="employmentTypes"
              render={({ field, fieldState }) => (
                <SelectMultiple
                  id="employmentType"
                  label="Employment Type"
                  options={EMPLOYMENT_TYPE_OPTIONS}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {segmentType !== 'custom' && (
            <Controller
              control={control}
              name="frequency"
              render={({ field, fieldState }) => (
                <RadioInputTile
                  {...field}
                  id="expenseFrequency"
                  label="Frequency"
                  options={getFrequencyOptionsFromSegmentType(segmentType)}
                  error={fieldState.error?.message}
                  disabled={false}
                  value={field.value}
                />
              )}
            />
          )}
          {segmentType === 'custom' && (
            <>
              <FormulaBuilderInput
                formulaState={formulaInputState?.formulaState}
                variablesState={formulaInputState?.variables}
                formulaUuid={formulaUuid ?? undefined}
                formulaTitle={formulaInputState?.formulaTitle}
                variables={variables}
                setVariables={setVariables}
                formula={formula}
                setFormula={setFormula}
                updatedFormula={updatedFormula}
                setUpdatedFormula={setUpdatedFormula}
                isOpen={formulaInputState?.isCustom ?? false}
                value={formulaValue}
                setValue={setFormulaValue}
                formulaList={formulaList}
                inputAttributeTitle={initialFormulaData?.recipe?.name ?? ''}
                displayFormulaError={displayFormulaError}
                setDisplayFormulaError={setDisplayFormulaError}
                attributeType={IFormulaTypeEnum.Expense}
              />
              <div className="flex flex-col">
                <label className="flex flex-row gap-2 items-center mb-2">
                  <Checkbox
                    id="add-min-max"
                    checked={addMinMax}
                    className=" !ml-0 "
                    toggleValue={() => {
                      setAddMinMax((prev) => {
                        const newValue = !prev;
                        if (!newValue) {
                          setValue('min', '');
                          setValue('max', '');
                        }
                        return newValue;
                      });
                    }}
                  />
                  <Typography className="text-sm">Add min/max values</Typography>
                </label>
                <div
                  className={`transition-all duration-300 ${addMinMax ? 'max-h-[62px] min-h-[38px] h-auto opacity-100 overflow-visible flex flex-col' : 'max-h-0 min-h-0 h-0 opacity-0 overflow-hidden'}`}
                >
                  <div className="flex flex-row gap-2">
                    <Controller
                      control={control}
                      name="min"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          value={field.value ?? undefined}
                          type="currency"
                          includeDollarSign
                          placeholder="Min (optional)"
                          id="expenseMin"
                          error={fieldState.error?.message ? 'Min must be less than max' : undefined}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="max"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          value={field.value ?? undefined}
                          type="currency"
                          includeDollarSign
                          placeholder="Max (optional)"
                          id="expenseMax"
                          error={fieldState.error?.message ? 'Max must be greater than min' : undefined}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errors.root && <div className="text-red-500 text-sm">{errors.root.message}</div>}
        <div className="flex justify-between gap-5">
          <Button
            className="!w-auto !px-0"
            id="cancel-create-expense"
            fill="destructiveClear"
            onClick={() => {
              onClose({ successfulSave: false });
            }}
          >
            {changesHaveBeenMade ? 'Cancel' : 'Close'}
          </Button>
          <Button
            id="save-expense"
            onClick={() => {
              if (changesHaveBeenMade) {
                onSubmitForm();
              } else {
                completeReview();
                onClose({ successfulSave: true });
              }
            }}
            className={`!w-auto ${changesHaveBeenMade ? '' : '!px-0'}`}
            loading={isSubmitting}
            fill={changesHaveBeenMade ? 'solid' : 'clear'}
          >
            {changesHaveBeenMade ? 'Apply Changes' : 'Dismiss'}
          </Button>
        </div>
      </div>
    </div>
  );
};
