import { SortingFn } from '@tanstack/react-table';
import { IHeadcountTableRow } from '../HeadcountTable';
import React, { ReactElement } from 'react';
import * as stringDate from '~/utils/stringDate';

export const sortDateFn: SortingFn<IHeadcountTableRow> = (rowA, rowB, columnId) => {
  // Extract date value from rowA
  let rowAValueToCompare: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
  if (React.isValidElement(rowA.getValue(columnId)) && (rowA.getValue(columnId) as ReactElement).props.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
    rowAValueToCompare = (rowA.getValue(columnId) as ReactElement).props.value;
  }

  // Extract date value from rowB
  let rowBValueToCompare: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
  if (React.isValidElement(rowB.getValue(columnId)) && (rowB.getValue(columnId) as ReactElement).props.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
    rowBValueToCompare = (rowB.getValue(columnId) as ReactElement).props.value;
  }

  // Special handling for null/empty values
  if (rowAValueToCompare === null && rowBValueToCompare === null) {
    return 0; // Both are null, so they're equal
  }
  if (rowAValueToCompare === null) {
    return 1; // Null values should be sorted to the end
  }
  if (rowBValueToCompare === null) {
    return -1; // Null values should be sorted to the end
  }

  // Normal date comparison for non-null values
  return stringDate.isBefore({ comparison: rowAValueToCompare, dateToCheck: rowBValueToCompare }) ? 1 : -1;
};

export const sortActiveFn: SortingFn<IHeadcountTableRow> = (rowA, rowB, columnId) => {
  // Extract the React elements from each row.
  const elementA = rowA.getValue(columnId);
  const elementB = rowB.getValue(columnId);

  // Default values if the element is not as expected.
  let aIsActive = false;
  let aDisplayToggle = true;
  let bIsActive = false;
  let bDisplayToggle = true;

  if (React.isValidElement(elementA)) {
    aIsActive = (elementA.props as { isActive: boolean }).isActive;
    aDisplayToggle = (elementA.props as { displayToggle: boolean }).displayToggle;
  }
  if (React.isValidElement(elementB)) {
    bIsActive = (elementB.props as { isActive: boolean }).isActive;
    bDisplayToggle = (elementB.props as { displayToggle: boolean }).displayToggle;
  }

  // Compute the special flag: rows where displayToggle is false and isActive is true.
  const aSpecial = !aDisplayToggle && aIsActive;
  const bSpecial = !bDisplayToggle && bIsActive;

  // First, sort by the special flag.
  if (aSpecial !== bSpecial) {
    return aSpecial ? -1 : 1;
  }

  // Then, sort by isActive (true first).
  if (aIsActive === bIsActive) {
    return 0;
  }
  return aIsActive ? -1 : 1;
};

export const elementAlphanumericSortFn: SortingFn<IHeadcountTableRow> = (rowA, rowB, columnId) => {
  const elementA = rowA.getValue(columnId);
  const elementB = rowB.getValue(columnId);

  let aValueToCompare: string | null = null;
  let bValueToCompare: string | null = null;
  if (React.isValidElement(elementA)) {
    aValueToCompare = (elementA.props as { value: string }).value;
  }
  if (React.isValidElement(elementB)) {
    bValueToCompare = (elementB.props as { value: string }).value;
  }

  return aValueToCompare?.localeCompare(bValueToCompare ?? '') ?? 0;
};
