import React, { useEffect, useState } from 'react';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { ZTemporalValue } from '~/pages/Headcount/entity/schemas';
import { z } from 'zod';
import ConfirmPrompt from '~/components/ConfirmPrompt';
import { useDatePicker } from '~/components/DatePicker';
import { useInput } from '~/components/Input/InputWrapper';
import Compensation from './Compensation';
import NewCompensationForm from './NewCompensationForm';
import { useBlocker } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

interface Props {
  organizationUuid: string;
  scenarioUuid: string | null;
  isOpen: boolean;
  onClose: () => void;
  position: IPositionDetails | null;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
}

const UpdateCompensationModal = ({
  organizationUuid,
  scenarioUuid,
  isOpen,
  onClose,
  position,
  createScenario,
  reloadDashboard,
  awaitCalculations,
  listParams,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const [updatePosition, { isLoading: isUpdatingPosition }] = positionsApi.useUpdatePositionMutation();
  const [effectiveDate, setEffectiveDate] = useDatePicker({
    errorMessage: 'Effective date is required',
    minDate: null,
  });
  const [updatedCompensations, setUpdatedCompensations] = useState<
    { value: number; effectiveAt: 'onHire' | string; updatedEffectiveDate?: string }[]
  >([]);
  const [compensation, setCompensation] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
  });
  const [compensationsRequireUpdate, setCompensationsRequireUpdate] = useState<boolean>(false);
  const [addNewCompensation, setAddNewCompensation] = useState<boolean>(false);
  const [showBlocker, setShowBlocker] = useState(false);

  useEffect(() => {
    if (position) {
      const parsedChangeHistory = z
        .array(
          ZTemporalValue.extend({
            effectiveAt: z.union([z.string(), z.literal('onHire')]),
            value: z.number({ coerce: true }),
          }),
        )
        .parse(position.payRates);

      setUpdatedCompensations(parsedChangeHistory);

      setEffectiveDate((prev) => ({
        ...prev,
        minDate:
          position.currentPayRate.effectiveAt === 'onHire' ? position.hireDate : position.currentPayRate.effectiveAt,
      }));
    }
  }, [position]);

  const resetCompensationForm = (): void => {
    setEffectiveDate((prev) => ({
      ...prev,
      value: {
        startDate: null,
        endDate: null,
      },
    }));
    setCompensation((prev) => ({
      ...prev,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
    }));
  };

  const onCloseModal = (): void => {
    onClose();
    setTimeout(() => {
      setAddNewCompensation(false);
      setUpdatedCompensations([]);
      setCompensationsRequireUpdate(false);
      resetCompensationForm();
    }, 300);
  };

  const handleCreateCompensationChange = async (): Promise<void> => {
    if (!position) return;
    if (!scenarioUuid && createScenario) {
      dispatch(updateScenarioLoadingState('creating'));
    }
    try {
      let compensationsForUpdate = [...updatedCompensations];
      if (addNewCompensation) {
        // Remove an existing compensation if it is for the same effective date
        const isOverrideToInitialCompensation = isSameDay(position.hireDate, effectiveDate.value.startDate as string);
        compensationsForUpdate = compensationsForUpdate.filter((compensationForUpdate) => {
          return (
            !isOverrideToInitialCompensation &&
            !isSameDay(compensationForUpdate.effectiveAt, new Date(effectiveDate.value.startDate as string))
          );
        });

        compensationsForUpdate.push({
          value: Number(compensation.value) * CENTS_PER_DOLLAR,
          effectiveAt: isOverrideToInitialCompensation ? 'onHire' : (effectiveDate.value.startDate as string),
        });
      }
      if (compensationsRequireUpdate || addNewCompensation) {
        const updatedBody = compensationsForUpdate.map((compensation) => {
          if (compensation.updatedEffectiveDate) {
            const updatedCompensation = {
              ...compensation,
              effectiveAt: compensation.updatedEffectiveDate,
            };
            delete updatedCompensation.updatedEffectiveDate;
            return updatedCompensation;
          }
          return compensation;
        });

        const query: {
          scenarioUuid?: string;
          createScenario?: boolean;
          awaitCalculations?: boolean;
        } = {
          scenarioUuid: scenarioUuid ?? undefined,
        };

        if (scenarioUuid) query.scenarioUuid = scenarioUuid;
        if (!scenarioUuid && createScenario) query.createScenario = createScenario;
        if (awaitCalculations) query.awaitCalculations = true;

        const response = await updatePosition({
          params: {
            orgUuid: organizationUuid,
            positionUuid: position.positionUuid,
          },
          query,
          body: {
            payRate: updatedBody,
          },
          listParams,
        }).unwrap();

        if (response.headers['scenario-uuid']) {
          await handleCreateScenario({
            scenarioUuid: response.headers['scenario-uuid'],
          });
        }

        if (reloadDashboard) {
          await reloadDashboard();
        }

        onCloseModal();
      }
    } catch (error) {
      toast.error('Failed to make changes');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      compensationsRequireUpdate && currentLocation.pathname !== nextLocation.pathname,
  );

  return (
    <Modal id={'update-compensation-modal'} title={'Total Compensation'} isOpen={isOpen} size={'xxs'}>
      <div className="flex flex-col w-full">
        <ConfirmPrompt
          isOpen={blocker.state === 'blocked' || showBlocker}
          onClose={() => (blocker.state === 'blocked' ? blocker.reset() : setShowBlocker(false))}
          onConfirm={() => {
            if (blocker.state === 'blocked') {
              blocker.proceed();
            } else {
              setShowBlocker(false);
              onCloseModal();
            }
          }}
          title="Changes Will Not be Saved"
          message="Any changes made will not be saved. Are you sure you want to Cancel"
          cancelButtonText="Continue Editing"
          confirmButtonText="Yes, Cancel"
        />
        <div className="flex flex-col w-full" data-testid="pay-change-modal">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row mt-2 gap-2">
              <Typography color="secondary" className="w-[46%]">
                Annual Pay
              </Typography>
              <Typography color="secondary" className="w-[46%]">
                Effective On
              </Typography>
            </div>
            <div data-testid="pay-history-list" className="flex flex-col gap-2">
              {position &&
                updatedCompensations
                  .sort((versionA, versionB) => {
                    if (versionA.effectiveAt === 'onHire') return -1;
                    if (versionB.effectiveAt === 'onHire') return 1;
                    return new Date(versionA.effectiveAt).getTime() - new Date(versionB.effectiveAt).getTime();
                  })
                  .map((version) => {
                    return (
                      <Compensation
                        key={`${position.positionUuid}-${version.effectiveAt}`}
                        effectiveAt={version.effectiveAt === 'onHire' ? 'onHire' : version.effectiveAt}
                        hireDate={position.hireDate}
                        currentValue={version.value}
                        updatedCompensations={updatedCompensations}
                        setUpdatedCompensations={setUpdatedCompensations}
                        compensationsRequireUpdate={compensationsRequireUpdate}
                        setCompensationsRequireUpdate={setCompensationsRequireUpdate}
                      />
                    );
                  })}
            </div>
            <NewCompensationForm
              addNewCompensation={addNewCompensation}
              setAddNewCompensation={setAddNewCompensation}
              effectiveDate={effectiveDate}
              setEffectiveDate={setEffectiveDate}
              compensation={compensation}
              setCompensation={setCompensation}
              onCancel={() => {
                resetCompensationForm();
                setAddNewCompensation(false);
              }}
            />
          </div>
          <div className="flex flex-row w-full justify-between mt-2">
            <Button
              id="cancel-pay-change"
              onClick={() => (compensationsRequireUpdate ? setShowBlocker(true) : onCloseModal())}
              fill="clear"
              className="!w-fit !px-0"
            >
              Cancel
            </Button>
            <Button
              id="save-pay-change"
              className="!w-auto"
              onClick={() => {
                if (addNewCompensation) {
                  if (effectiveDate.valid && compensation.valid && effectiveDate.value.startDate) {
                    handleCreateCompensationChange();
                  } else {
                    setEffectiveDate({
                      ...effectiveDate,
                      pristine: false,
                      touched: true,
                    });
                    setCompensation({ ...compensation, pristine: false, touched: true });
                  }
                } else {
                  handleCreateCompensationChange();
                }
              }}
              disabled={!compensationsRequireUpdate && !addNewCompensation}
              loading={isUpdatingPosition}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCompensationModal;
