import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '~/components/Modal';
import type { State } from '~/store';
import Input, { useInput } from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import toast from 'react-hot-toast';
import * as api from '~/services/parallel/index';
import logger from '~/utils/logger';
import { ChatContext } from '../../context/ChatContext';

const UpdateBusinessContextModal = (): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { businessContext, setBusinessContext, showBusinessContextModal, setShowBusinessContextModal } =
    useContext(ChatContext);

  const [savingUpdate, setSavingUpdate] = useState<boolean>(false);
  const [companyUrl, setCompanyUrl] = useInput({});
  const [companyDescription, setCompanyDescription] = useInput({});
  const [keyGoals, setKeyGoals] = useInput({});
  const [offeringDetails, setOfferingDetails] = useInput({});
  const [fundingHistory, setFundingHistory] = useInput({});
  const [location, setLocation] = useInput({});

  useEffect(() => {
    setCompanyUrl((prevState) => ({
      ...prevState,
      value: businessContext.context.companyUrl,
      valid: true,
      pristine: true,
      touched: false,
    }));
    setCompanyDescription((prevState) => ({
      ...prevState,
      value: businessContext.context.companyDescription,
      valid: true,
      pristine: true,
      touched: false,
    }));
    setKeyGoals((prevState) => ({
      ...prevState,
      value: businessContext.context.keyGoals,
      valid: true,
      pristine: true,
      touched: false,
    }));
    setOfferingDetails((prevState) => ({
      ...prevState,
      value: businessContext.context.offeringDetails,
      valid: true,
      pristine: true,
      touched: false,
    }));
    setFundingHistory((prevState) => ({
      ...prevState,
      value: businessContext.context.fundingHistory,
      valid: true,
      pristine: true,
      touched: false,
    }));
    setLocation((prevState) => ({
      ...prevState,
      value: businessContext.context.location ?? '',
      valid: true,
      pristine: true,
      touched: false,
    }));
  }, [businessContext]);

  const updateBusinessContext = async (): Promise<void> => {
    try {
      setSavingUpdate(true);
      const updateResponse = await api.businessContext.updateBusinessContext({
        organizationUuid,
        businessContext: {
          companyUrl: companyUrl.value,
          companyDescription: companyDescription.value,
          keyGoals: keyGoals.value,
          offeringDetails: offeringDetails.value,
          fundingHistory: fundingHistory.value,
          location: location.value,
        },
      });

      setBusinessContext(updateResponse);
      setShowBusinessContextModal(false);
      setSavingUpdate(false);
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Unable to update your business context. Try again later.');
      setSavingUpdate(false);
    }
  };

  return (
    <Modal
      isOpen={showBusinessContextModal}
      onClose={() => setShowBusinessContextModal(false)}
      showClose
      title="Business Context"
      size="lg"
    >
      <div className="flex flex-col gap-3 w-full mt-1">
        <Input
          className="!w-auto text-neutral-800"
          id="companyUrl"
          label="Company URL"
          type="text"
          state={companyUrl}
          setState={setCompanyUrl}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="companyDescription"
          label="Company Description (industry, market sector, size, business model, differentiators, etc.)"
          type="textarea"
          state={companyDescription}
          setState={setCompanyDescription}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="keyGoals"
          label="Key Company Goals and Milestones"
          type="textarea"
          state={keyGoals}
          setState={setKeyGoals}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="offeringDetails"
          label="Offering details (key products or offerings, revenue streams, target customers, etc.)"
          type="textarea"
          state={offeringDetails}
          setState={setOfferingDetails}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="fundingHistory"
          label="Funding History and Needs (previous funding rounds, next round requirements, etc.)"
          type="textarea"
          state={fundingHistory}
          setState={setFundingHistory}
        />
        <Input
          state={location}
          setState={setLocation}
          className="text-neutral-800"
          id="location"
          label="Location"
          type="text"
        />
        <div className="flex flex-row justify-between mt-3">
          <Button
            onClick={() => setShowBusinessContextModal(false)}
            id="cancel-navigate-away-button"
            fill="clear"
            className="!w-fit !px-0"
          >
            Cancel
          </Button>
          <Button
            id="continue-to-next-page-button"
            className="!w-auto"
            onClick={updateBusinessContext}
            loading={savingUpdate}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBusinessContextModal;
