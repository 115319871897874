import { z } from 'zod';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';
import { ZStringDate } from '~/utils/stringDate/types';
import { ZGraphData } from '~/services/parallel/reports.types';

/**
 * @deprecated Use ZScenario from parallel/scenarios.types.ts instead
 */
export const ZScenario = z.object({
  uuid: z.string(),
  changeDescription: z.string(),
  type: z.string(),
  purpose: z.enum(['userCreated', 'pointInTimeView', 'expenseGen', 'revenueModelBuilder']),
  createdBy: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  organizationUuid: z.string(),
  markedAsStaleAt: z.string().nullable(),
  effectiveAt: ZStringDate,
  summary: z.string().nullable(),
});

export const ZCompanyGoal = z.object({
  uuid: z.string().uuid(),
  goalUuid: z.string().uuid(),
  organizationUuid: z.string().uuid(),
  formulaUuid: z.string().uuid().nullable(),
  targetValue: z.union([z.number(), z.string().transform((val) => parseFloat(val))]),
  targetDate: ZStringDate.nullable(),
  planDate: iso8601Validator,
  createdAt: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  createdBy: z.string().uuid().nullable(),
  deletedBy: z.string().uuid().nullable(),
});

export const ZDashboardLoader = z.object({
  reportData: z.lazy(() => ZGraphData),
});

export const ZDashboardConfiguration = z.object({
  metrics: z.array(z.string()),
  graphs: z.array(z.string().uuid()),
  levers: z.array(z.string().uuid()),
});
