import React, { useContext } from 'react';
import { Popover } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import { ChatContext } from '../../context/ChatContext';

const Settings = (): React.ReactElement => {
  const { setShowBusinessContextModal } = useContext(ChatContext);

  return (
    <>
      <Popover className="absolute top-4 right-16">
        <Popover.Button className="focus:outline-none">
          <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green-15 border border-green-50">
            <Cog6ToothIcon className="size-4 text-green-400" />
          </div>
        </Popover.Button>
        <Popover.Panel className="absolute right-0 mt-2">
          <div
            className="flex flex-col items-center justify-center gap-2 bg-white p-4 rounded-lg shadow-lg min-w-[200px] cursor-pointer"
            onClick={() => setShowBusinessContextModal(true)}
          >
            <div className="flex w-full items-center justify-center">
              <Typography size="sm">Business Context</Typography>
            </div>
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default Settings;
