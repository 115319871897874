import React from 'react';
import { store } from '~/store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import router from './Router';
import './styles.css';
import FullPageLoading from './components/FullPageLoading';

const App = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <FFContextProvider
        apiKey={import.meta.env.VITE_HARNESS_API_KEY as string}
        target={{ name: 'Parallel Web App', identifier: 'reactclientsdk' }}
        fallback={<FullPageLoading isVisible color="green" text="" opacity="1" size="size-[75px]" isStatic />}
      >
        <RouterProvider router={router} />
      </FFContextProvider>
    </Provider>
  );
};

export default App;
