import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { DrawerTab } from '~/store/drawerContentSlice';
import Typography from '../Typography';

const BaseDrawerButton = (props: {
  isActive: boolean;
  handleTabClick: (id: DrawerTab) => void;
  tabId: DrawerTab;
  tabIcon: typeof QuestionMarkCircleIcon;
  tabLabel: string;
}): React.ReactNode => {
  const { isActive, handleTabClick, tabId, tabLabel } = props;
  return (
    <button
      id={tabId}
      data-testid={`${tabId}-button`}
      onClick={() => handleTabClick(tabId)}
      className={`
        sidebar-bg px-2 py-4 mr-[2px] mt-[4px] border-2 border-transparent hover:border-neutral-50 rounded-md hover:bg-neutral-15 transition-colors items-center
        ${isActive ? 'bg-white !border-green-50' : ''}
      
      `}
    >
      <div className="flex items-center gap-2">
        <props.tabIcon className="h-5 w-5 stroke-green-400 rotate-90" />
        <Typography color="primaryGreen" className="!leading-4">
          {tabLabel}
        </Typography>
      </div>
    </button>
  );
};

export default BaseDrawerButton;
