import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from './baseQuery';
import type { IIntegration, IIntegrationMapping, IIntegrationMappingScope } from '../integrations.types';

interface IGetIntegrationMappingsParams {
  integrationUuid: string;
  scope: IIntegrationMappingScope | IIntegrationMappingScope[];
}

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Integration'],
  endpoints: (builder) => ({
    getIntegrationMappings: builder.query<IIntegrationMapping[], IGetIntegrationMappingsParams>({
      query: ({ integrationUuid, scope }) => ({
        url: `/integrations/${integrationUuid}/mappings`,
        params: {
          scope,
        },
      }),
      transformResponse: (response: { data: IIntegrationMapping[] }) => {
        return response.data;
      },
      providesTags: ['Integration'],
    }),
    getIntegrations: builder.query<IIntegration[], void>({
      query: () => ({
        url: `/integrations`,
      }),
      transformResponse: (response: { data: IIntegration[] }) => {
        return response.data;
      },
      providesTags: ['Integration'],
    }),
  }),
});

export const { useGetIntegrationMappingsQuery, useGetIntegrationsQuery } = integrationsApi;
