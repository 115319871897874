import React from 'react';
import { SelectType } from '~/components/Select/Select.types';
import { IEmploymentType, employmentTypeToLabel } from '~/services/parallel/headcount.types';
import Select, { useSelect } from '~/components/Select';
import { IUpdatePositionRequestBody } from '~/services/parallel/api/positions/positionsRequestSchemas';
import toast from 'react-hot-toast';
import { IPositionDetails } from '~/pages/Headcount/entity/types';

const CellEmploymentType = React.memo(
  ({
    id,
    onUpdate,
    isActive,
    isScenario,
    value,
    positionUuid,
  }: {
    id: string;
    onUpdate: ({
      positionUuid,
      positionUpdates,
      awaitCalculations,
    }: {
      positionUuid: string;
      positionUpdates: IUpdatePositionRequestBody;
      awaitCalculations?: boolean;
    }) => Promise<{ data: IPositionDetails; headers: Record<string, string> }>;
    isActive: boolean;
    isScenario: boolean;
    value: IEmploymentType;
    positionUuid: string;
  }): React.ReactNode => {
    const [selectEmploymentType, setSelectEmploymentType] = useSelect({
      selected: {
        value: value,
        label: employmentTypeToLabel[value],
      },
      options: [
        {
          value: IEmploymentType.FullTime,
          label: employmentTypeToLabel[IEmploymentType.FullTime],
        },
        {
          value: IEmploymentType.PartTime,
          label: employmentTypeToLabel[IEmploymentType.PartTime],
        },
        {
          value: IEmploymentType.Contractor,
          label: employmentTypeToLabel[IEmploymentType.Contractor],
        },
      ],
    });

    const onBlur = async (selected?: SelectType): Promise<void> => {
      try {
        if (selected?.value && selected.value !== value) {
          await onUpdate({
            positionUuid,
            positionUpdates: { [id]: selected.value },
          });
        }
      } catch (error) {
        toast.error('Failed to update employment type');
        setSelectEmploymentType((prev) => ({
          ...prev,
          selected: {
            value: value,
            label: employmentTypeToLabel[value],
          },
        }));
      }
    };

    return (
      <div className="w-full group/employment-type" data-testid={`${id}-${positionUuid}`}>
        <div
          className={`text-nowrap overflow-hidden text-ellipsis ${!isActive ? 'text-neutral-100 cursor-default' : 'group-hover/employment-type:hidden'} 
        ${isScenario && ' text-blue-400'}`}
        >
          {selectEmploymentType.selected?.label ?? '-'}
        </div>
        <div
          className={`hidden ${isActive && 'group-hover/employment-type:block'}`}
          data-testid={`${id}-${positionUuid}-selector`}
        >
          <Select id="employmentType" state={selectEmploymentType} setState={setSelectEmploymentType} onBlur={onBlur} />
        </div>
      </div>
    );
  },
);

CellEmploymentType.displayName = 'CellEmploymentType';

export default CellEmploymentType;
