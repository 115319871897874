import React from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { IDataActual, IDataOverride } from '~/services/parallel/reports.types';
import { State } from '~/store';
import logger from '~/utils/logger';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { isEqual } from 'lodash';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';
import * as stringDate from '~/utils/stringDate';

const DraggableGraphResetButton = ({
  dataOverrides,
  dataActuals,
  formulaUuid,
  reload,
}: {
  dataOverrides: IDataOverride;
  dataActuals: IDataActual;
  formulaUuid: string;
  reload: () => Promise<void>;
}): React.ReactElement | null => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const dispatch = useDispatch();

  const resetDataOverrides = async (): Promise<void> => {
    try {
      if (!activeScenarioUuid) return;
      if (Object.keys(dataOverrides).includes('workingModel') && Object.keys(dataActuals).includes('workingModel')) {
        dispatch(updateScenarioLoadingState('updating'));
        const workingModelOverrides = dataOverrides.workingModel.map((override) => ({
          ...override,
          date: stringDate.getISO8601EndOfMonth(override.date),
        }));
        const workingModelActuals = dataActuals.workingModel.map((actual) => ({
          ...actual,
          date: stringDate.getISO8601EndOfMonth(actual.date),
        }));

        const response = await request({
          url: `/formulas/${formulaUuid}`,
          method: 'PATCH',
          body: {
            overrides: workingModelOverrides,
            actuals: workingModelActuals,
          },
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          headers: {
            'Organization-Uuid': organizationUuid,
          },
        });
        if (response.status === StatusCodes.OK) {
          await reload();
        } else {
          toast.error('Failed to reset levers');
        }
      } else if (
        Object.keys(dataOverrides).includes('activeScenario') &&
        Object.keys(dataActuals).includes('activeScenario')
      ) {
        dispatch(updateScenarioLoadingState('updating'));
        const activeScenarioOverrides: IDataOverride['activeScenario'] = [];
        const activeScenarioActuals: IDataActual['activeScenario'] = [];

        const response = await request({
          url: `/formulas/${formulaUuid}`,
          method: 'PATCH',
          body: {
            overrides: activeScenarioOverrides,
            actuals: activeScenarioActuals,
          },
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          headers: {
            'Organization-Uuid': organizationUuid,
          },
        });

        if (response.status === StatusCodes.OK) {
          await reload();
        } else {
          toast.error('Failed to reset levers');
        }
      } else {
        logger.error(new Error('Working model overrides not found'));
        toast.error('Failed to reset levers');
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to reset levers');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  const renderButton = Boolean(
    activeScenarioUuid &&
      (!isEqual(dataActuals.activeScenario, dataActuals.workingModel) ||
        !isEqual(dataOverrides.activeScenario, dataOverrides.workingModel)),
  );

  if (!renderButton) return null;

  return (
    <HoverPopover
      buttonContent={
        <div className="flex items-center justify-center bg-transparent py-1">
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
            onClick={resetDataOverrides}
          >
            <path
              d="M4.31821 4.23225H0.989806V4.23107M1.68771 7.42355C2.47389 10.3576 5.48974 12.0988 8.42381 11.3126C9.39164 11.0533 10.2297 10.5514 10.89 9.88979C12.4471 8.14972 12.7356 6.98197 12.3129 4.57654C11.5267 1.64248 8.51086 -0.0987267 5.5768 0.687453C4.60895 0.946785 3.77091 1.44873 3.11056 2.11032L0.989806 4.23107M0.989806 0.903852V4.23107"
              stroke="#5A8496"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      }
      panelContent={
        <div className="pt-1.5 pb-2 px-3 bg-black">
          <Typography color="white">Reset Changes</Typography>
        </div>
      }
      panelClassName="rounded-lg shadow-md"
      anchor="top"
    />
  );
};

export default DraggableGraphResetButton;
