import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import EllipsisDropdown from '../EllipsisDropdown';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import {
  update,
  updateActiveScenarioData,
  updateScenarioList,
  updateScenarioLoadingState,
} from '~/store/scenarioSlice';
import { deleteScenario as deleteScenarioService } from '~/services/parallel/scenarios';
import logger from '~/utils/logger';
import Button from '../Button';
import ScenarioModeToast from '../ScenarioMode/components/toasts/ScenarioToast';
import { useNavigate } from 'react-router-dom';
import NameScenarioModal from '../ScenarioMode/components/NameScenarioModal';
import { useInput } from '../Input/InputWrapper';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import { IScenario } from '~/services/parallel/scenarios.types';
import posthog from 'posthog-js';

const ScenarioButton = ({
  scenario,
  onClick,
  reload,
  closePopover,
}: {
  scenario: IScenario;
  onClick: (scenario: IScenario) => Promise<void>;
  reload?: () => void;
  closePopover?: () => void;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeScenarioUuid, activeScenarioData, selectedScenarioUuids, scenarioMode, scenarios } = useSelector(
    (state: State) => state.scenario,
  );
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [showNameScenarioModal, setShowNameScenarioModal] = useState<boolean>(false);
  const [originalTitle, setOriginalTitle] = useState<string>(activeScenarioData?.changeDescription ?? '');
  const [createScenario] = scenariosApi.useCreateScenarioMutation();
  const [updateScenario] = scenariosApi.useUpdateScenarioMutation();
  const [mergeScenario] = scenariosApi.useMergeScenarioMutation();
  const [cancelScenario] = scenariosApi.useCancelScenarioMutation();
  const [restoreScenario] = scenariosApi.useRestoreScenarioMutation();
  const [deleteScenario] = scenariosApi.useDeleteScenarioMutation();

  const [scenarioTitle, setScenarioTitle, resetScenarioTitle] = useInput({
    value: activeScenarioData?.changeDescription ?? '',
    valid: true,
    validation: /.*/,
  });
  const isActive = scenario.uuid === activeScenarioUuid;

  useEffect(() => {
    if (scenarioTitle.value !== activeScenarioData?.changeDescription && activeScenarioData) {
      dispatch(
        updateActiveScenarioData({
          ...activeScenarioData,
          changeDescription: scenarioTitle.value,
        }),
      );
      dispatch(
        updateScenarioList(
          scenarios.map((s) =>
            s.uuid === activeScenarioUuid ? { ...activeScenarioData, changeDescription: scenarioTitle.value } : s,
          ),
        ),
      );
    }
  }, [scenarioTitle.value]);

  useEffect(() => {
    if (activeScenarioData?.changeDescription !== scenarioTitle.value) {
      setScenarioTitle({
        ...scenarioTitle,
        value: activeScenarioData?.changeDescription ?? '',
      });
      setOriginalTitle(activeScenarioData?.changeDescription ?? '');
    }
  }, [activeScenarioData?.changeDescription]);

  const handleRestoreScenario = async ({ scenarioUuid }: { scenarioUuid: string }): Promise<void> => {
    try {
      const scenarioData = await restoreScenario({
        orgUuid: organizationUuid,
        scenarioUuid,
      }).unwrap();

      dispatch(updateActiveScenarioData(scenarioData));
      dispatch(
        update({
          activeScenarioUuid: scenarioUuid,
          activeScenarioData: scenarioData,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          scenarioMode,
          selectedScenarioUuids,
          scenarios,
        }),
      );
      if (reload) {
        reload();
      }
    } catch (error) {
      logger.error(error as Error);
      toast.error('Failed to restore scenario');
    }
  };

  const handleCancelScenario = async (): Promise<void> => {
    if (activeScenarioUuid) {
      try {
        await cancelScenario({
          orgUuid: organizationUuid,
          scenarioUuid: activeScenarioUuid,
        }).unwrap();

        dispatch(
          update({
            activeScenarioUuid: null,
            activeScenarioData: null,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            scenarioLoadingState: 'exiting',
            scenarioMode,
            scenarios,
          }),
        );
        if (reload) {
          reload();
        }
        toast.custom(() => <ScenarioModeToast message="Scenario changes have been cancelled" />, {
          position: 'bottom-center',
          duration: 6000,
        });
      } catch (error) {
        logger.error(error as Error);
        toast.error('Failed to cancel scenario changes');
      }
    }
  };

  const updateScenarioTitle = async (): Promise<boolean> => {
    try {
      if (scenarioTitle.value.trim().length && scenarioTitle.valid) {
        if (activeScenarioData?.changeDescription !== originalTitle && activeScenarioUuid) {
          const scenarioData = await updateScenario({
            orgUuid: organizationUuid,
            scenarioUuid: activeScenarioUuid,
            scenarioTitle: scenarioTitle.value,
          }).unwrap();
          setShowNameScenarioModal(false);
          dispatch(updateActiveScenarioData(scenarioData));
          dispatch(updateScenarioList(scenarios.map((s) => (s.uuid === activeScenarioUuid ? scenarioData : s))));
        }
      } else {
        setScenarioTitle({
          ...scenarioTitle,
          value: originalTitle,
        });
      }
      return true;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to update scenario title');
      return false;
    }
  };

  const handleDiscardScenario = async (): Promise<void> => {
    if (activeScenarioUuid) {
      try {
        await deleteScenarioService({
          organizationUuid: scenario.organizationUuid,
          scenarioUuid: activeScenarioUuid,
        });
        dispatch(
          update({
            activeScenarioUuid: null,
            activeScenarioData: null,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            scenarioLoadingState: 'exiting',
            scenarioMode,
            scenarios: scenarios.filter((s) => s.uuid !== activeScenarioUuid),
          }),
        );
        if (reload) {
          reload();
        }
        toast.custom(
          (t) => (
            <ScenarioModeToast
              message="Scenario has been discarded"
              button={{
                text: 'Undo',
                callback: () => {
                  handleRestoreScenario({ scenarioUuid: activeScenarioUuid });
                  toast.dismiss(t.id);
                },
              }}
            />
          ),
          {
            position: 'bottom-center',
            duration: 6000,
          },
        );
      } catch (error) {
        logger.error(error as Error);
      }
    }
  };

  const saveActiveScenario = async (): Promise<void> => {
    const updated = await updateScenarioTitle();
    if (updated) {
      // Track the save event
      if (activeScenarioData) {
        posthog.capture('save_scenario_clicked', {
          scenarioUuid: activeScenarioUuid,
          scenarioTitle: activeScenarioData.changeDescription,
          scenarioType: activeScenarioData.type,
          organizationUuid,
          mode: scenarioMode,
        });
      }

      toast.custom(
        (t) => (
          <ScenarioModeToast
            message="Scenario has been saved"
            button={{
              text: 'View',
              callback: () => {
                if (activeScenarioUuid) {
                  dispatch(
                    update({
                      activeScenarioUuid: null,
                      activeScenarioData: null,
                      activeScenarioHasChanges: false,
                      scenarioLoadingState: 'idle',
                      scenarioMode,
                      leverChanges: [],
                      cashBalanceLockedIndexes: [],
                      cashBalanceSelectedPoint: null,
                      selectedScenarioUuids: [activeScenarioUuid, ...selectedScenarioUuids],
                      scenarios,
                    }),
                  );
                }
                navigate(`/dashboard`);
                toast.dismiss(t.id);
              },
            }}
          />
        ),
        {
          position: 'bottom-center',
          duration: 4000,
        },
      );
      if (reload) {
        reload();
      }
      dispatch(
        update({
          activeScenarioUuid: null,
          activeScenarioData: null,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          scenarioLoadingState: 'exiting',
          scenarioMode,
          scenarios,
        }),
      );

      if (showNameScenarioModal) {
        setShowNameScenarioModal(false);
      }
    }
  };

  const handleCompleteAction = (): void => {
    if (
      activeScenarioData &&
      (activeScenarioData.changeDescription.trim().toLowerCase() === 'untitled scenario' ||
        !activeScenarioData.changeDescription.trim())
    ) {
      setShowNameScenarioModal(true);
    } else if (activeScenarioData?.changeDescription.trim()) {
      saveActiveScenario();
    }
  };

  const handleMergeScenarioIntoMain = async (): Promise<void> => {
    if (activeScenarioUuid) {
      dispatch(updateScenarioLoadingState('merging'));
      try {
        await mergeScenario({
          orgUuid: organizationUuid,
          scenarioUuid: activeScenarioUuid,
          scenariosToAwait: selectedScenarioUuids,
        }).unwrap();
        toast.success('Scenario merged into main model');
        if (reload) {
          reload();
        }
        dispatch(
          update({
            activeScenarioUuid: null,
            activeScenarioData: null,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            scenarioMode,
            scenarioLoadingState: 'idle',
            scenarios: scenarios.filter((s) => s.uuid !== activeScenarioUuid),
          }),
        );
      } catch (error) {
        logger.error(error as Error);
        toast.error('Failed to merge scenario');
      } finally {
        dispatch(updateScenarioLoadingState('idle'));
      }
    }
  };
  const handleCopyScenario = async (scenario: IScenario): Promise<void> => {
    try {
      const scenarioData = await createScenario({
        orgUuid: scenario.organizationUuid,
        params: {
          scenarioUuidToDuplicate: scenario.uuid,
        },
        body: {
          type: 'dynamic',
        },
      }).unwrap();
      toast.success('Scenario Copied');
      dispatch(updateScenarioList([scenarioData, ...scenarios]));
      if (reload) {
        reload();
      }
    } catch (error) {
      logger.error(error as Error);
      toast.error('Failed to copy scenario');
    }
  };

  const handleDeleteScenario = async (scenario: IScenario): Promise<void> => {
    try {
      await deleteScenario({
        orgUuid: scenario.organizationUuid,
        scenarioUuid: scenario.uuid,
      }).unwrap();

      dispatch(updateScenarioList(scenarios.filter((s) => s.uuid !== scenario.uuid)));
      toast.success('Scenario Deleted');
      if (reload) {
        reload();
      }
    } catch (error) {
      logger.error(error as Error);
      toast.error('Failed to delete scenario');
    }
  };

  const options = [
    {
      label: 'Duplicate',
      onClick: (event?: React.MouseEvent<HTMLButtonElement>): void => {
        event?.stopPropagation();
        handleCopyScenario(scenario);
      },
    },
    {
      label: 'Delete',
      onClick: (event?: React.MouseEvent<HTMLButtonElement>): void => {
        event?.stopPropagation();
        handleDeleteScenario(scenario);
      },
      className: 'text-red-500',
    },
  ];

  let style;
  if (isActive) {
    style = 'bg-blue-15 border-blue-100 cursor-default';
  } else if (activeScenarioUuid) {
    style = 'border-neutral-50 cursor-default';
  } else {
    style = 'hover:bg-blue-15 hover:border-blue-100 border-neutral-50 cursor-pointer ';
  }

  let textColor;
  if (isActive) {
    textColor = '!text-blue-600';
  } else if (activeScenarioUuid) {
    textColor = '!text-neutral-100';
  } else {
    textColor = 'group-hover/options:!text-blue-600';
  }

  return (
    <div
      className={`flex flex-col gap-3 group/options border ${style} items-center justify-between rounded-lg pl-6 pr-4 py-3`}
      onClick={() => {
        if (!activeScenarioUuid) {
          onClick(scenario);
        }
        if (closePopover) {
          closePopover();
        }
      }}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <Typography className={`${isActive ? 'max-w-[260px]' : 'max-w-[330px]'} truncate ${textColor}`}>
          {scenario.changeDescription}
        </Typography>
        {isActive ? (
          <Typography color="lightestBlue">{`(In Progress)`}</Typography>
        ) : (
          <EllipsisDropdown
            color="text-blue-200 hover:text-blue-400"
            className={`${activeScenarioUuid ? 'hidden' : 'group-hover/options:opacity-100 opacity-0'}`}
            options={options}
          />
        )}
      </div>
      {isActive && (
        <div className="flex flex-row gap-3">
          {['editing'].includes(scenarioMode) ? (
            <Button onClick={handleCancelScenario} className="!w-fit !px-0 py-2 text-nowrap" fill="clearBlue">
              Cancel
            </Button>
          ) : (
            <Button onClick={handleDiscardScenario} className="!w-fit !px-0 py-2 text-nowrap" fill="clearBlue">
              Discard
            </Button>
          )}
          <Button onClick={handleCompleteAction} className="!w-fit !px-3.5 py-2 text-nowrap" fill="solidBlue">
            Save Scenario
          </Button>
          <Button
            onClick={handleMergeScenarioIntoMain}
            className="!w-fit !px-3.5 py-2 text-nowrap"
            fill="darkBlueDefault"
          >
            Apply to Model
          </Button>
        </div>
      )}
      <NameScenarioModal
        isOpen={showNameScenarioModal}
        cancel={() => {
          resetScenarioTitle();
          setShowNameScenarioModal(false);
        }}
        confirm={saveActiveScenario}
        scenarioTitle={scenarioTitle}
        setScenarioTitle={setScenarioTitle}
        scenarioType={'dynamic'}
      />
    </div>
  );
};

export default ScenarioButton;
