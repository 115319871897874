import React, { useMemo } from 'react';
import LineGraph from '~/components/LineGraph';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';
import { ILineProps } from '~/components/LineGraph/entity/types';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { IPeriodPickerState } from '~/components/PeriodPicker/usePeriodPicker';

const RevenueGoalGraph = ({
  data,
  dataKeys,
  lines,
  revenueTargetDate,
  revenueTarget,
  revenueReport,
  goalValue,
  cardClassName,
}: {
  lines: ILineProps[];
  dataKeys: string[];
  data: IConsolidatedGraphBody['data'];
  revenueTargetDate?: IPeriodPickerState;
  revenueTarget?: Types.InputState;
  revenueReport?: IConsolidatedGraphBody;
  goalValue?: number;
  cardClassName?: string;
}): React.ReactElement => {
  const timeboundGoalCushion = useMemo((): number => {
    const maxValue = Math.max(...data.map((d) => d.workingModel), goalValue ?? 0);
    return maxValue * 0.2;
  }, [revenueReport, data, goalValue]);

  return (
    <div className="w-full h-[200px]">
      <LineGraph
        id="revenue-graph"
        card={{
          title: 'Revenue',
        }}
        data={data}
        dataKeys={dataKeys}
        lines={lines}
        timeboundGoals={
          revenueTargetDate?.startDate && revenueTarget?.value
            ? [
                {
                  targetValue: Number(revenueTarget.value) * CENTS_PER_DOLLAR,
                  targetDate: revenueTargetDate.startDate,
                },
              ]
            : revenueReport?.companyGoals
                .filter((goal) => goal.targetDate)
                .map((goal) => ({
                  ...goal,
                  targetDate: goal.targetDate as string,
                }))
        }
        multiLineGradient
        timeboundGoalCushion={timeboundGoalCushion}
        cardClassName={cardClassName}
      />
    </div>
  );
};

export default RevenueGoalGraph;
