import React, { useState, useEffect } from 'react';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import toast from 'react-hot-toast';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const DeleteExpenseModal = ({
  isOpen,
  onClose,
  formulaUuid,
}: {
  isOpen: boolean;
  onClose: () => void;
  formulaUuid: string;
}): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [error, setError] = useState<string | null>(null);
  const [formulaName, setFormulaName] = useState<string>('Expense');
  const { refreshData } = useFormulaContext();

  const { formula } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: activeScenarioUuid ?? undefined,
      types: [IFormulaTypeEnum.Expense, IFormulaTypeEnum.Headcount],
    },
    {
      selectFromResult: ({ data }) => ({
        formula: data?.find((f) => f.formulaUuid === formulaUuid),
      }),
    },
  );

  useEffect(() => {
    if (formula) {
      setFormulaName(formula.recipe.name);
    }
  }, [formula]);

  const [softDeleteExpenseFormula, { isLoading: isDeleting }] = formulasApi.useSoftDeleteExpenseFormulaMutation();

  const handleDelete = async (): Promise<void> => {
    try {
      await softDeleteExpenseFormula({
        formulaUuid,
        scenarioUuid: activeScenarioUuid ?? undefined,
      }).unwrap();
      toast.success('Expense deleted successfully');
      refreshData();
      onClose();
    } catch (error) {
      setError('Failed to delete expense. Please try again.');
    }
  };

  return (
    <Modal
      title={`Delete ${formulaName} & Purge History`}
      isOpen={isOpen}
      onClose={onClose}
      id="delete-expense-modal"
      size="xs"
    >
      <div className="flex flex-col gap-4">
        <div>
          <Typography color="secondary">
            Deleting this expense will remove all related data, including all historical impact.
          </Typography>
        </div>
        <div className="flex w-full justify-between">
          <div className="w-fit">
            <Button disabled={isDeleting} fill="clear" className="!w-fit !px-0" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button fill="destructive" loading={isDeleting} onClick={handleDelete}>
              Confirm, Delete
            </Button>
          </div>
        </div>
        {error && (
          <Typography color="warning" size="sm">
            {error}
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default DeleteExpenseModal;
