import React, { useContext, useMemo, useState } from 'react';
import CompanyGoals from './CompanyGoals';
import Typography from '~/components/Typography';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import RevenueGoal from './RevenueGoal';
import { ICompanyGoal } from '../../entity/types';
import { DashboardPageContext } from '../../context/DashboardContext';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';
import useQueryParams from '~/utils/hooks/useQueryParams';

const CompanyGoalsModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }): React.ReactNode => {
  const { reports } = useContext(DashboardPageContext);
  const { setQueryParams } = useQueryParams();
  const [showRevenueGoal, setShowRevenueGoal] = useState(false);

  const { revenueReport, revenueReportUuid } = useMemo((): {
    revenueReport: IConsolidatedGraphBody | null;
    revenueReportUuid: string | null;
  } => {
    if (!reports.consolidated) return { revenueReport: null, revenueReportUuid: null };

    const revenueReportIndex = Object.values(reports.consolidated).findIndex((report) => report.title === 'Revenue');
    if (revenueReportIndex === -1) return { revenueReport: null, revenueReportUuid: null };

    return {
      revenueReport: Object.values(reports.consolidated)[revenueReportIndex],
      revenueReportUuid: Object.keys(reports.consolidated)[revenueReportIndex],
    };
  }, [reports]);
  const currentRevenueGoal = useMemo((): ICompanyGoal | null => {
    return revenueReport?.companyGoals.find((goal) => goal.targetDate) ?? null;
  }, [revenueReport]);

  const handleClose = (): void => {
    setQueryParams({ showRevenueGoal: 'false' });
    close();
  };

  return (
    <>
      <Modal title={showRevenueGoal ? 'Revenue Goal' : 'Company Goals'} isOpen={isOpen} size="sm" onClose={handleClose}>
        <div className="flex flex-col w-full gap-4">
          {!showRevenueGoal && (
            <Typography size="sm" color="secondary" className="mb-4">
              Goals are connected to specific attributes of your model and will be displayed on the dashboard
            </Typography>
          )}
          {showRevenueGoal ? (
            <RevenueGoal
              revenueReport={revenueReport}
              revenueReportUuid={revenueReportUuid}
              currentRevenueGoal={currentRevenueGoal}
              goBack={() => setShowRevenueGoal(false)}
            />
          ) : (
            <CompanyGoals
              revenueReport={revenueReport}
              currentRevenueGoal={currentRevenueGoal}
              setShowRevenueGoal={setShowRevenueGoal}
            />
          )}
          {!showRevenueGoal && (
            <Button fill="clear" className="!w-fit !p-0" onClick={handleClose}>
              Close
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CompanyGoalsModal;
