import React, { useEffect, useMemo, useState } from 'react';
import Modal from '~/components/Modal';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { BvaExpenseForm } from './BvaExpenseForm';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import Select, { useSelect } from '~/components/Select';
import { IBvaReviewItem, IDateValue } from '~/services/parallel/bva.types';
import { bvaApi } from '~/services/parallel/api/bvaApi';
import toast from 'react-hot-toast';
import Typography from '~/components/Typography';
import BvaGraph from '~/components/BvaDropdown/components/BvaGraph';
import DisplayExpenseAverages from './DisplayExpenseAverages';

export const BvaExpenseModal = ({
  isOpen,
  onClose,
  formulaUuid,
  scenarioUuid,
}: {
  isOpen: boolean;
  onClose: () => void;
  formulaUuid: string | null;
  scenarioUuid: string | null;
}): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { refreshData } = useFormulaContext();
  const bvaResult = bvaApi.useGetBvaQuery();
  const [completeReview] = bvaApi.useCompleteReviewMutation();

  const { formula, formulaList } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: scenarioUuid ?? undefined,
    },
    {
      selectFromResult: ({ data }) => ({
        formula: data?.find((f) => f.formulaUuid === formulaUuid),
        formulaList: data ?? [],
      }),
    },
  );
  const { data: departments } = departmentsApi.useGetDepartmentsQuery({ orgUuid: organizationUuid });

  const reviewForExpense = useMemo((): IBvaReviewItem | null => {
    if (
      !formulaUuid ||
      !bvaResult.data?.bvaResult?.expenseReview ||
      !(formulaUuid in bvaResult.data.bvaResult.expenseReview)
    )
      return null;
    const review = bvaResult.data.bvaResult.expenseReview[formulaUuid];
    return review;
  }, [formulaUuid, bvaResult]);

  const forecastAsOfOptions = useMemo(
    () =>
      reviewForExpense?.forecastedValues.map(({ monthsAgo }) => ({
        label: monthsAgo === 1 ? 'Last Month' : `${monthsAgo} Months Ago`,
        value: `${monthsAgo}`,
      })),
    [reviewForExpense?.forecastedValues],
  );

  const [forecastAsOf, setForecastAsOf] = useSelect({
    options: forecastAsOfOptions ?? [],
    selected: forecastAsOfOptions?.[0],
  });

  const [forecastValues, setForecastValues] = useState<IDateValue[]>([]);

  useEffect(() => {
    if (reviewForExpense?.forecastedValues && forecastAsOf.selected) {
      const forecastValues = reviewForExpense.forecastedValues.find(
        ({ monthsAgo }) => monthsAgo === Number(forecastAsOf.selected?.value),
      )?.values;

      setForecastValues(forecastValues ?? []);
    }
  }, [reviewForExpense?.forecastedValues, forecastAsOf]);

  useEffect(() => {
    if (forecastAsOfOptions && forecastAsOfOptions.length > 0 && !forecastAsOf.selected?.label) {
      setForecastAsOf((prev) => ({
        ...prev,
        options: forecastAsOfOptions,
        selected: forecastAsOfOptions[0],
      }));
    }
  }, [forecastAsOfOptions, forecastAsOf.selected]);

  const completeReviewForExpense = async (): Promise<void> => {
    if (!formulaUuid) return;

    try {
      await completeReview({
        body: {
          expenseReviewsToComplete: [formulaUuid],
        },
      }).unwrap();
    } catch (error) {
      toast.error('Failed to complete expense review');
    }
  };

  return (
    <Modal id="bva-update-expense" isOpen={isOpen} title={`${formula?.recipe.name ?? ''}`} size="sm">
      <div data-testid="create-expense-modal-container" className="mt-2 w-full">
        <div className="w-full h-[205px] flex flex-col border border-neutral-50 rounded-lg mb-3 py-3">
          <div className="flex flex-row justify-between items-center mb-2 px-1">
            <div>
              <Select
                className="max-w-[165px] !py-0 !border-none !shadow-none !text-neutral-400"
                state={forecastAsOf}
                setState={setForecastAsOf}
                id="forecast-as-of-expense"
                placeholder="Select forecast"
                buttonIconColor="text-neutral-400"
              />
            </div>
            <div className="flex flex-row items-center gap-4 mr-2">
              <div className="flex flex-row items-center gap-2 pb-1">
                <div className="w-3.5 h-[2px] bg-orange" />
                <Typography color="empty">Actuals</Typography>
              </div>
              <div className="flex flex-row items-center gap-2 pb-1">
                <div className="w-3.5 h-[2px] bg-neutral-200" />
                <Typography color="empty">Forecast</Typography>
              </div>
            </div>
          </div>
          <BvaGraph
            forecast={forecastValues}
            actuals={reviewForExpense?.actualValues ?? []}
            xAxisPadding={{ left: 48, right: 12 }}
          />
        </div>
        <DisplayExpenseAverages actualAverageValues={reviewForExpense?.actualAverageValues ?? []} />
        <BvaExpenseForm
          scenarioUuid={scenarioUuid ?? null}
          initialFormulaData={{
            ...(formula ?? {}),
          }}
          onClose={(props: { successfulSave: boolean }) => {
            onClose();
            if (props.successfulSave) {
              refreshData();
            }
          }}
          formulaUuid={formulaUuid}
          formulaList={formulaList}
          completeReview={completeReviewForExpense}
          allDepartments={departments?.list.map((d) => d.departmentUuid) ?? []}
        />
      </div>
    </Modal>
  );
};
