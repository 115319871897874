import React from 'react';
import Typography from '~/components/Typography';
import { IAverageValuePerMonthSpan } from '~/services/parallel/bva.types';
import formatCurrency from '~/utils/formatCurrency';

const DisplayExpenseAverages = ({
  actualAverageValues,
}: {
  actualAverageValues: IAverageValuePerMonthSpan[];
}): React.ReactNode => {
  const threeMonthAverage = actualAverageValues.find((value) => value.monthSpan === 3)?.value;
  const twelveMonthAverage = actualAverageValues.find((value) => value.monthSpan === 12)?.value;
  return (
    <div className="flex flex-row gap-6 mb-4">
      <div className="flex flex-col border-r border-neutral-50 pr-6 pl-2">
        <Typography color="empty">Historical</Typography>
        <Typography color="empty">Averages</Typography>
      </div>
      <div className="flex flex-col">
        <Typography color="empty">3 Month Avg</Typography>
        <Typography color="orange">
          {typeof threeMonthAverage === 'number' ? formatCurrency(threeMonthAverage * 100, false) : '-'}
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography color="empty">12 Month Avg</Typography>
        <Typography color="orange">
          {typeof twelveMonthAverage === 'number' ? formatCurrency(twelveMonthAverage * 100, false) : '-'}
        </Typography>
      </div>
    </div>
  );
};

export default DisplayExpenseAverages;
