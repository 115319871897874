import React from 'react';
import Modal from '~/components/Modal';
import EditTermDateForm from './EditTermDateForm';
import { IPositionDetails } from '../../entity/types';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

const EditTermDateModal = ({
  isOpen,
  onClose,
  orgUuid,
  scenarioUuid,
  position,
  createScenario,
  reloadDashboard,
  awaitCalculations,
  listParams,
}: {
  isOpen: boolean;
  onClose: () => void;
  orgUuid: string;
  scenarioUuid?: string | null;
  position: IPositionDetails | null;
  createScenario?: boolean;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  return (
    <Modal id={'new-create-position-modal'} isOpen={isOpen} title={'Create Position'} size="sm">
      <div data-testid="create-position-modal-container" className="mt-2 w-full">
        <EditTermDateForm
          orgUuid={orgUuid}
          scenarioUuid={scenarioUuid}
          onClose={onClose}
          position={position}
          awaitCalculations={awaitCalculations}
          createScenario={createScenario}
          reloadDashboard={reloadDashboard}
          listParams={listParams}
        />
      </div>
    </Modal>
  );
};

export default EditTermDateModal;
