import { z } from 'zod';

export const ZBusinessContextObject = z.object({
  companyUrl: z.string(),
  summary: z.string(),
  companyDescription: z.string(),
  keyGoals: z.string(),
  offeringDetails: z.string(),
  fundingHistory: z.string(),
  location: z.string().optional(),
});

export const ZBusinessContext = z.object({
  updatedBy: z.string().nullable(),
  updatedAt: z.date({ coerce: true }),
  context: ZBusinessContextObject,
});
