import React, { useState } from 'react';
import Typography from '~/components/Typography';
import formatPercent from '~/utils/formatPercent';
import formatCurrency from '~/utils/formatCurrency';
import { convertDepartmentUuidsToDisplayName } from '~/pages/Expenses/utils/convertDepartmentUuidsToDisplayName';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Divider from '~/components/Divider';
import HoverPopover from '~/components/HoverPopover';
import toast from 'react-hot-toast';
import { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { getProposedCardAcronym } from '../../utils/getProposedCardAcronym';
import type { IFormula } from '~/services/parallel/formulas.types';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import type { IDepartment } from '~/services/parallel/departments.types';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
  custom: 'Custom',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

const ProposedExpenseCard = ({
  expenseFormula,
  departmentDict,
  scenarioUuid,
  integrationsWithMappings,
  setModalState,
}: {
  expenseFormula: IFormula;
  departmentDict: Record<string, IDepartment>;
  scenarioUuid: string;
  integrationsWithMappings: IIntegrationWithMappings[];
  setModalState: (formulaUuid: string) => void;
}): React.ReactNode => {
  const [displayDataSources, setDisplayDataSources] = useState<boolean>(false);
  const [displayForecastReasoning, setDisplayForecastReasoning] = useState<boolean>(false);
  const [softDeleteExpenseFormula] = formulasApi.useSoftDeleteExpenseFormulaMutation();

  const handleDoNotForecastDataSource = async (): Promise<void> => {
    try {
      await softDeleteExpenseFormula({
        formulaUuid: expenseFormula.formulaUuid,
        scenarioUuid,
      }).unwrap();
    } catch (error) {
      toast.error('Failed to remove from forecast');
    }
  };

  return (
    <div className="flex flex-col border border-neutral-50 rounded-lg">
      <div className="flex flex-row w-full px-6 py-4">
        <div className="max-h-[46px] max-w-[46px] min-h-[46px] min-w-[46px] text-[16.5px] text-neutral-200 bg-neutral-15 border border-neutral-50 rounded-full flex items-center justify-center mr-4">
          {getProposedCardAcronym(expenseFormula.recipe.name)}
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col w-1/3">
            <Typography weight="semibold">{expenseFormula.recipe.name}</Typography>
            <Typography color="secondary">
              {expenseFormula.context?.expenseContext?.driver
                ? TYPE_DISPLAY_MAP[expenseFormula.context.expenseContext.driver]
                : ''}
            </Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Amount</Typography>
            {expenseFormula.context?.expenseContext?.amount !== undefined ? (
              <Typography>
                {expenseFormula.context.expenseContext.driver === 'headcountPercentCompensation'
                  ? formatPercent({ value: expenseFormula.context.expenseContext.amount / 10000 })
                  : formatCurrency(expenseFormula.context.expenseContext.amount)}
              </Typography>
            ) : (
              <div className="flex flex-row gap-1">
                <Typography>Formula</Typography>
                <HoverPopover
                  buttonContent={
                    <div className="text-left flex items-center gap-1">
                      <InformationCircleIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
                    </div>
                  }
                  panelContent={
                    <div className="py-3 px-4 bg-black text-left whitespace-nowrap">
                      {/* <ReadableFormulaDisplay formula={expenseFormula.readableFormulaExpression ?? ''} /> */}
                    </div>
                  }
                  panelClassName="shadow-md rounded-lg"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Frequency</Typography>
            <Typography>
              {expenseFormula.context?.expenseContext?.frequency
                ? FREQUENCY_DISPLAY_MAP[expenseFormula.context.expenseContext.frequency]
                : ''}
            </Typography>
          </div>
          <div className="flex flex-col w-1/6">
            <Typography color="empty">Departments</Typography>
            <Typography>
              {expenseFormula.context?.expenseContext?.departments[0] === 'ALL'
                ? 'All'
                : convertDepartmentUuidsToDisplayName({
                    departmentUuids: expenseFormula.context?.expenseContext?.departments ?? [],
                    departmentDict,
                  })}
            </Typography>
          </div>
          <div className="flex flex-row gap-4 w-1/6">
            {!!expenseFormula.dataSourceUuids.length && (
              <div className="flex justify-center items-center">
                <div
                  onClick={() => setDisplayDataSources(!displayDataSources)}
                  className="flex flex-row cursor-pointer hover:bg-neutral-15 hover:border-neutral-75 items-center border border-neutral-50 justify-center rounded-lg p-2 gap-1"
                >
                  <img src={quickbooksIcon} className="size-5" />
                  <Typography color="lightGray">{expenseFormula.dataSourceUuids.length}</Typography>
                  {displayDataSources ? (
                    <ChevronUpIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  ) : (
                    <ChevronDownIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  )}
                </div>
              </div>
            )}
            <EllipsisDropdown
              className="flex items-center justify-center"
              options={[
                {
                  label: 'Edit',
                  onClick: () => setModalState(expenseFormula.formulaUuid),
                  id: `edit-proposed-expense-${expenseFormula.recipe.name}`,
                },
                {
                  label: 'Do not forecast',
                  className: 'text-red-400',
                  onClick: handleDoNotForecastDataSource,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {expenseFormula.context?.expenseContext?.forecastReasoning && (
        <div className={`flex flex-col bg-neutral-15 ${displayDataSources ? '' : 'rounded-b-lg'}`}>
          <Divider />
          <div
            onClick={() => setDisplayForecastReasoning(!displayForecastReasoning)}
            className={`flex flex-row justify-between items-center px-6 ${displayForecastReasoning ? 'pt-2.5 pb-1' : 'py-2.5'} cursor-pointer`}
          >
            <Typography color="lightGray">Forecast Reasoning</Typography>
            {displayForecastReasoning ? (
              <ChevronUpIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
            ) : (
              <ChevronDownIcon className="size-5 stroke-neutral-400 stroke-[1.5]" />
            )}
          </div>
          {displayForecastReasoning && (
            <div className="flex flex-col px-6 mb-2.5">
              <Typography color="empty">{expenseFormula.context.expenseContext.forecastReasoning}</Typography>
            </div>
          )}
        </div>
      )}
      {displayDataSources && !!expenseFormula.dataSourceUuids.length && (
        <div className="flex flex-col bg-neutral-15 rounded-b-lg">
          <Divider />
          <div className="flex flex-col px-6 py-3">
            {expenseFormula.dataSourceUuids.map((dataSourceUuid, index) => {
              const dataSource = integrationsWithMappings
                .flatMap((integration) => integration.mappings)
                .find((mapping) => mapping.uuid === dataSourceUuid);
              const isLastItem = index === expenseFormula.dataSourceUuids.length - 1;
              return (
                <div className="flex flex-col" key={dataSourceUuid}>
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex gap-2 items-center">
                      <Typography weight="light" color="empty" className="italic">
                        {dataSource?.name}
                      </Typography>
                    </div>
                  </div>
                  {!isLastItem && <Divider className="my-2" />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposedExpenseCard;
