import React from 'react';
import Modal from '~/components/Modal';
import IntegrationMappingsForm from './IntegrationMappingsForm';
import type { IIntegrationWithMappings } from '~/services/parallel/integrations.types';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const IntegrationMappingsModal = ({
  isOpen,
  onClose,
  formulaUuid,
  scenarioUuid,
  expenseIntegrationsWithMappings,
}: {
  isOpen: boolean;
  onClose: () => void;
  formulaUuid: string;
  scenarioUuid: string | null;
  expenseIntegrationsWithMappings: IIntegrationWithMappings[];
}): React.ReactNode => {
  const { refreshData } = useFormulaContext();
  const { formula, formulaList } = formulasApi.useListFormulasQuery(
    {
      scenarioUuid: scenarioUuid ?? undefined,
    },
    {
      selectFromResult: ({ data }) => ({
        formula: data?.find((f) => f.formulaUuid === formulaUuid),
        formulaList: data ?? [],
      }),
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Edit Data Sources" size="sm">
      <div data-testid="integration-mappings-modal-container" className="mt-2 w-full">
        <IntegrationMappingsForm
          onClose={(props: { successfulSave: boolean }) => {
            onClose();
            if (props.successfulSave) {
              refreshData();
            }
          }}
          scenarioUuid={scenarioUuid}
          expenseIntegrationsWithMappings={expenseIntegrationsWithMappings}
          initialFormulaData={{
            ...(formula ?? {}),
          }}
          formulaList={formulaList}
        />
      </div>
    </Modal>
  );
};

export default IntegrationMappingsModal;
