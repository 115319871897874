import React from 'react';
import Typography from '~/components/Typography';
import { ClipboardDocumentIcon, TableCellsIcon, ForwardIcon } from '@heroicons/react/24/outline';

export const InitialSection = (): React.ReactNode => {
  return (
    <div className="w-full flex justify-center items-center mt-20">
      <div className="flex flex-col gap-6 max-w-[640px] mb-24">
        <div className="flex flex-col">
          <Typography size="lg" weight="bold">
            Generate Expense Forecast
          </Typography>
          <Typography color="secondary">
            {`We've created forecasted expenses based off your actuals and headcount info. This walkthrough will verify your
          forecasted spend and display expense categories.`}
          </Typography>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <ForwardIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Effortless Data Import</Typography>
            <Typography color="secondary">Instantly pull in real data for accurate, up-to-date expense data</Typography>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <ClipboardDocumentIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Simplified Upkeep</Typography>
            <Typography color="secondary">
              With expenses linked to actuals, they can quickly pull in real data
            </Typography>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <TableCellsIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Automatically Flows into Forecast</Typography>
            <Typography color="secondary">
              Your expenses, like salaries, will flow automatically into your model
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
