import { IPeriodPickerState } from '~/components/PeriodPicker/usePeriodPicker';
import { ICompanyGoal } from '../../entity/types';
import { getMoMGrowthPercentageToReachGoal } from '../getMoMGrowthPercentageToReachGoal';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as stringDate from '~/utils/stringDate';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';

export const getRevenueGoalMoMPercentageAndMonths = ({
  revenueReport,
  revenueTarget,
  revenueTargetDate,
  currentRevenueGoal,
}: {
  revenueReport: IConsolidatedGraphBody | null;
  revenueTarget?: Types.InputState;
  revenueTargetDate?: IPeriodPickerState;
  currentRevenueGoal: ICompanyGoal | null;
}): {
  momGrowthPercentage: number | null;
  months: string[] | null;
} => {
  if (!revenueReport) return { momGrowthPercentage: null, months: null };

  let startMonthIndex = revenueReport.data.findIndex((item) =>
    stringDate.isSameMonth({
      date1: stringDate.getStringDate(new Date(item.date)),
      date2: stringDate.getStringDate(),
    }),
  );

  let targetValue = null;
  let targetMonth = null;

  if (revenueTarget?.value && revenueTargetDate?.startDate) {
    targetValue = Number(revenueTarget.value);
    targetMonth = revenueTargetDate.startDate;
  } else if (currentRevenueGoal) {
    targetValue = currentRevenueGoal.targetValue / CENTS_PER_DOLLAR;
    targetMonth = currentRevenueGoal.targetDate;
  }

  if (!targetValue || !targetMonth) return { momGrowthPercentage: null, months: null };

  if (startMonthIndex === -1) startMonthIndex = 0;

  return {
    momGrowthPercentage: getMoMGrowthPercentageToReachGoal({
      currentValue: revenueReport.data[startMonthIndex].workingModel / CENTS_PER_DOLLAR,
      targetValue,
      startMonth: stringDate.getStringDate(new Date(revenueReport.data[startMonthIndex].date)),
      targetMonth,
    }),
    months: stringDate.createMonthArrayBetweenDates({
      startDate: stringDate.getStringDate(),
      endDate: targetMonth,
    }),
  };
};
