import React, { useEffect } from 'react';
import Typography from '~/components/Typography';
import { IHelpCenterPostCategory, IPostWithReactions } from '~/services/parallel/api/helpCenter/requestSchemas';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '~/store';
import { useInput } from '../Input/InputWrapper';
import { updatePendingChanges } from '~/store/drawerContentSlice';
import { Select, SelectOption } from '../UncontrolledComponents/Select';
import Input from '~/components/Input/InputWrapper';
// import Reactions from './Reactions';
import Editor from './Editor/Editor';

const CATEGORY_OPTIONS: SelectOption[] = [
  { label: 'General', value: 'GENERAL' },
  { label: 'Getting Started', value: 'GETTING_STARTED' },
  { label: 'Troubleshooting', value: 'TROUBLESHOOTING' },
  { label: 'Features', value: 'FEATURES' },
  { label: 'Best Practices', value: 'BEST_PRACTICES' },
];

interface Props {
  post: IPostWithReactions;
  isEditing?: boolean;
}

const PostContainer: React.FC<Props> = ({ post }) => {
  const dispatch = useDispatch();
  const { isEditing, pendingChanges } = useSelector((state: State) => state.drawerContent.helpCenter);

  const [title, setTitle] = useInput({
    validation: /.+/,
    value: post.title,
  });

  const [category, setCategory] = React.useState<IHelpCenterPostCategory | undefined>(post.category ?? undefined);

  // Update pending changes when title changes
  useEffect(() => {
    if (title.value !== post.title) {
      dispatch(
        updatePendingChanges({
          title: title.value,
        }),
      );
    }
  }, [title.value, post.title, dispatch]);

  // Update pending changes when category changes
  useEffect(() => {
    if (category !== post.category) {
      dispatch(
        updatePendingChanges({
          category,
        }),
      );
    }
  }, [category, post.category, dispatch]);

  return (
    <div className="flex flex-col gap-8">
      {/* Title and Status Badge */}
      <div className="flex items-center justify-between">
        {isEditing ? (
          <div className="flex flex-col gap-2">
            <Typography weight="semibold">Title</Typography>
            <Input id="help-center-title" type="text" placeholder="Enter title..." state={title} setState={setTitle} />
          </div>
        ) : (
          <h1 className="text-2xl font-bold">{post.title}</h1>
        )}
        <div
          className={`px-2 py-1 rounded-md text-xs font-medium ml-auto ${
            post.publishedAt ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
          }`}
        >
          {post.publishedAt ? 'Published' : 'Unpublished'}
        </div>
      </div>

      {/* Hero Image */}
      {/* {post.heroImageUrl && (
        <div className="w-full h-48 bg-neutral-50 rounded-lg overflow-hidden">
          <img src={post.heroImageUrl} alt={post.title} className="w-full h-full object-cover" />
        </div>
      )} */}

      {/* Category Select */}
      {isEditing && (
        <div className="flex flex-col gap-2">
          <Typography weight="semibold">Category</Typography>
          <Select
            id="category-select"
            value={category}
            onChange={(value: string) => {
              setCategory(value as IHelpCenterPostCategory);
              dispatch(
                updatePendingChanges({
                  category: value as IHelpCenterPostCategory,
                }),
              );
            }}
            options={CATEGORY_OPTIONS}
            placeholder="Select a category"
          />
        </div>
      )}

      {/* Rich Text Viewer/Editor */}
      <div className="flex flex-col gap-2">
        <div className="relative w-full">
          <Editor isEditing={isEditing} post={post} />
        </div>
      </div>

      {/* Unsaved Changes Indicator */}
      {pendingChanges && (
        <div className="bg-yellow-50 text-yellow-800 px-4 py-2 rounded-lg">
          <Typography>You have unsaved changes</Typography>
        </div>
      )}

      {/* {!isEditing && <Reactions post={post} />} */}
    </div>
  );
};

export default PostContainer;
