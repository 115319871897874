import { z } from 'zod';
import { ZDashboardConfiguration } from '~/pages/Dashboard/entity/schemas';
import { ZStringDate } from '~/utils/stringDate/types';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

export const ZShareLinkAllowedResources = z.object({
  formulas: z.array(z.union([z.string().uuid(), z.literal('*')])).optional(),
  positions: z.array(z.union([z.string().uuid(), z.literal('*')])).optional(),
  expenses: z.array(z.union([z.string().uuid(), z.literal('*')])).optional(),
  contracts: z.array(z.union([z.string().uuid(), z.literal('*')])).optional(),
  ratios: z.array(z.union([z.string().uuid(), z.literal('*')])).optional(),
  dashboardConfiguration: ZDashboardConfiguration.optional(),
  startDate: ZStringDate,
  endDate: ZStringDate,
  financialModel: z.boolean().optional().default(true),
});

export const ZShareLink = z.object({
  uuid: z.string().uuid(),
  createdAt: iso8601Validator,
  createdBy: z.string().uuid().nullable(),
  organizationUuid: z.string().uuid(),
  planDate: iso8601Validator,
  allowedResources: ZShareLinkAllowedResources,
  organizationName: z.string().optional(),
  title: z.string(),
  viewCount: z.number(),
  expirationDate: iso8601Validator,
  scenarioUuid: z.string().uuid().nullable(),
  includeFinancialModel: z.boolean().optional(),
});

export const ZShareLinkWithModel = ZShareLink.extend({
  model: z.string(),
});
