import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '~/components/Typography';
import { State } from '~/store';
import formatCurrency from '~/utils/formatCurrency';

const CellTotalCost = React.memo(
  ({
    id,
    isActive,
    isScenario,
    value,
    positionUuid,
  }: {
    id: string;
    isActive: boolean;
    isScenario: boolean;
    value?: string | null;
    positionUuid: string;
  }): React.ReactNode => {
    const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);

    if (hideSensitiveData) {
      return (
        <div className="text-right" data-testid={`${id}-${positionUuid}`}>
          <Typography color={isScenario ? 'blue' : undefined}>{'*********'}</Typography>
        </div>
      );
    }

    return (
      <div className="text-right" data-testid={`${id}-${positionUuid}`}>
        <Typography color={!isActive ? 'disabled' : isScenario ? 'blue' : undefined}>
          {isActive
            ? // @ts-expect-error - typescript is dumb and doesn't recognize that I check the type
              formatCurrency(value, false)
            : '-'}
        </Typography>
      </div>
    );
  },
);

CellTotalCost.displayName = 'CellTotalCost';

export default CellTotalCost;
