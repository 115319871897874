import { ILineProps } from '~/components/LineGraph/entity/types';
import * as stringDate from '~/utils/stringDate';
import { IConsolidatedGraphBody } from '~/services/parallel/reports.types';

export const generateRevenueGoalGraphData = ({
  revenueReport,
  momGrowthPercentage,
  months,
  baseData,
}: {
  revenueReport: IConsolidatedGraphBody | null;
  momGrowthPercentage: number | null;
  months: string[] | null;
  baseData?: {
    lines: ILineProps[];
    dataKeys: string[];
    data: IConsolidatedGraphBody['data'];
  };
}): {
  lines: ILineProps[] | null;
  dataKeys: string[] | null;
  data: IConsolidatedGraphBody['data'] | null;
} => {
  if (!revenueReport) return { lines: null, dataKeys: null, data: null };

  if (!baseData) {
    baseData = {
      lines: [{ dataKey: 'workingModel', stroke: '#64755C' }],
      dataKeys: ['date', 'workingModel'],
      data: revenueReport.data,
    };
  }

  if (momGrowthPercentage && months) {
    let goalLineStartIndex = revenueReport.data.findIndex((item) =>
      stringDate.isSameMonth({
        date1: stringDate.getStringDate(new Date(item.date)),
        date2: months[0],
      }),
    );
    let goalLineEndIndex = revenueReport.data.findIndex((item) =>
      stringDate.isSameMonth({
        date1: stringDate.getStringDate(new Date(item.date)),
        date2: months[months.length - 1],
      }),
    );

    // This start and end index should always be found, but if they aren't for some reason this will handle it gracefully
    if (goalLineStartIndex === -1) goalLineStartIndex = 0;
    if (goalLineEndIndex === -1) goalLineEndIndex = revenueReport.data.length - 1;

    const goalLineData = [revenueReport.data[goalLineStartIndex].workingModel];

    for (let i = 1; i < months.length; i++) {
      const goalLineValue = goalLineData[i - 1] * (1 + momGrowthPercentage);
      goalLineData.push(goalLineValue);
    }

    return {
      lines: [...baseData.lines, { dataKey: 'goalLine', stroke: '#AAB7A4', isDashed: true }],
      dataKeys: [...baseData.dataKeys, 'goalLine'],
      data: baseData.data.map((item, index) => {
        if (index < goalLineStartIndex || index > goalLineEndIndex) return item;
        return {
          ...item,
          goalLine: goalLineData[index - goalLineStartIndex],
        };
      }),
    };
  } else {
    return baseData;
  }
};
