import React from 'react';
import Typography from '~/components/Typography';

const TargetValueIcon = (): React.ReactElement => {
  return (
    <div className="flex items-center w-full">
      <div className="flex flex-col w-[130px]">
        <Typography weight="bold">Target Value</Typography>
        <Typography color="secondary">Only has a value specified</Typography>
      </div>
      <div className="w-[50%] flex items-end justify-end">
        <svg width="97" height="78" viewBox="0 0 97 78" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="49" cy="38.7619" r="38.2505" fill="#EAEFE8" />
          <path
            d="M3.21924 24.2415C3.21924 21.1977 5.68669 18.7303 8.73045 18.7303H91.2429C94.2866 18.7303 96.7541 21.1977 96.7541 24.2415V64.4243C96.7541 67.468 94.2866 69.9355 91.2429 69.9355H8.73045C5.68669 69.9355 3.21924 67.468 3.21924 64.4243V24.2415Z"
            fill="#DFE5DC"
          />
          <path
            d="M0.832732 22.1932C0.832732 19.3397 3.14597 17.0265 5.9995 17.0265H88.5119C91.3654 17.0265 93.6787 19.3397 93.6787 22.1932V62.376C93.6787 65.2295 91.3654 67.5428 88.5119 67.5428H5.99949C3.14597 67.5428 0.832732 65.2295 0.832732 62.376V22.1932Z"
            fill="white"
            stroke="#C6C6C6"
            strokeWidth="0.688902"
          />
          <line x1="19.6047" y1="27.3222" x2="84.4647" y2="27.3222" stroke="#F3F3F3" strokeWidth="0.56733" />
          <line x1="19.6047" y1="41.6596" x2="84.4647" y2="41.6596" stroke="#F3F3F3" strokeWidth="0.56733" />
          <line x1="19.6047" y1="57.3626" x2="84.4647" y2="57.3626" stroke="#F3F3F3" strokeWidth="0.56733" />
          <rect x="7.99854" y="26.7204" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <rect x="7.99854" y="30.4486" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <rect x="7.99854" y="34.1768" width="6.32412" height="1.45885" rx="0.729425" fill="#F1F1F1" />
          <path
            d="M24.2761 56.0381C38.596 56.0381 38.2588 44.193 57.432 42.2559C75.0333 40.4775 73.8053 28.9182 85.676 27.5845"
            stroke="#BFC9BA"
            strokeWidth="1.13466"
            strokeLinecap="round"
          />
          <line
            x1="20.0632"
            y1="34.9803"
            x2="85.5965"
            y2="34.9803"
            stroke="#7F9376"
            strokeWidth="0.851082"
            strokeDasharray="3.4 3.4"
          />
        </svg>
      </div>
    </div>
  );
};

export default TargetValueIcon;
