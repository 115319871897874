import { useEffect, useState } from 'react';
import { IPageData, pageData } from './pageData';
import { IDashboardConfiguration } from '~/store/organizationSlice';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';
import jwtDecode from 'jwt-decode';
import { ZAccessToken } from '~/services/parallel/accessToken.types';
import { IConsolidatedGraphData } from '~/services/parallel/reports.types';

export const useShareLinkData = (
  shareLinkAccessToken?: string | null,
): {
  data: IPageData | null;
  dashboardConfiguration: IDashboardConfiguration;
  startDate: IStringDate;
  endDate: IStringDate;
  pageLoading: boolean;
  organizationUuid: string;
  permissionedFormulaUuids: string[];
  organizationName: string;
  financialModelAvailable: boolean;
} => {
  const [data, setData] = useState<IPageData | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  let accessToken;
  if (shareLinkAccessToken) {
    const decodedToken = jwtDecode(shareLinkAccessToken);
    accessToken = ZAccessToken.parse(decodedToken);
  } else {
    const rawAccessToken = localStorage.getItem('accessToken');
    if (!rawAccessToken) {
      accessToken = null;
    } else {
      const decodedToken = jwtDecode(rawAccessToken);
      accessToken = ZAccessToken.parse(decodedToken);
    }
  }

  const {
    organizationUuid,
    allowedResources: { dashboardConfiguration, startDate, endDate },
  } = accessToken?.link ?? {
    organizationUuid: '',
    allowedResources: {
      dashboardConfiguration: {
        metrics: [],
        graphs: [],
        levers: [],
      },
      startDate: stringDate.getStringDate(),
      endDate: stringDate.getStringDate(),
    },
  };

  if (!dashboardConfiguration) throw new Error('No dashboard configuration in allowed resources');

  const getData = async ({ organizationUuid }: { organizationUuid: string }): Promise<void> => {
    setPageLoading(true);
    const { reportData } = await pageData({ organizationUuid, accessTokenOverride: shareLinkAccessToken });
    // setData(reportData);

    /** Filter goals from share link */
    const filteredData = {
      consolidated: reportData.consolidated
        ? Object.entries(reportData.consolidated).reduce((acc, [key, value]) => {
            acc[key] = { ...value, companyGoals: [] };
            return acc;
          }, {} as IConsolidatedGraphData)
        : {},
      static: reportData.static,
    };
    setData({ reportData: filteredData });

    setPageLoading(false);
  };

  useEffect(() => {
    if (organizationUuid) {
      getData({ organizationUuid });
    }
  }, [organizationUuid]);

  if (!accessToken?.link) {
    return {
      data: null,
      dashboardConfiguration: {
        metrics: [],
        graphs: [],
        levers: [],
      },
      startDate: stringDate.getStringDate(),
      endDate: stringDate.getStringDate(),
      pageLoading: false,
      organizationUuid: '',
      permissionedFormulaUuids: [],
      organizationName: '',
      financialModelAvailable: false,
    };
  }

  return {
    data,
    dashboardConfiguration,
    startDate,
    endDate,
    pageLoading,
    organizationUuid,
    permissionedFormulaUuids: accessToken.link.allowedResources.formulas ?? [],
    organizationName: accessToken.link.organizationName ?? '',
    financialModelAvailable: accessToken.link.allowedResources.financialModel,
  };
};
