import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, { useRef } from 'react';
import Typography from '~/components/Typography';
import './styles.css';
import TimeseriesModifier from './TimeseriesModifier';
import CalculationModifier from './CalculationModifier';
import useClickOutside from '~/utils/hooks/useClickOutside';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import DepartmentCalculationModifier from './DepartmentCalculationModifier';

const timeModifierMap: Record<string, string> = {
  'previous-0': 'This Month',
  'previous-1': 'Last Month',
  'previous-2': '2 Months Ago',
  'previous-3': '3 Months Ago',
  'previous-4': '4 Months Ago',
  'previous-5': '5 Months Ago',
  'previous-6': '6 Months Ago',
  'previous-7': '7 Months Ago',
  'previous-8': '8 Months Ago',
  'previous-9': '9 Months Ago',
  'previous-10': '10 Months Ago',
  'previous-11': '11 Months Ago',
  'previous-12': '12 Months Ago',
};

const calculatedFormulaTitleMap: Record<string, string> = {
  headcountNumber: 'Headcount',
  newHireNumber: 'New Hires',
  totalCompensation: 'Total Compensation',
  bonuses: 'Bonuses',
  commissions: 'Commissions',
  otherExpenses: 'Other Expenses',
  softwareExpenses: 'Software Expenses',
  peopleAndFacilities: 'People & Facilities Expenses',
  cogs: 'Cost of Goods Sold Expenses',
  marketing: 'Marketing Expenses',
  departmentExpenses: 'Department Expenses',
};

const FormulaNodeView = ({ node, selected, updateAttributes, editor }: NodeViewProps): React.ReactNode => {
  const isPulling = useSelector((state: State) => state.integrations.isPulling);
  const { viewOnly } = useFormulaContext();

  const {
    label,
    type,
    formulaUuid,
    timeModifier,
    calculationModifier,
    calculationModifierType,
    isEditorWrapped,
    viewOnly: componentLevelViewOnly,
  } = node.attrs;

  const isComponentLevelViewOnly = componentLevelViewOnly === 'true';

  const isViewOnly = Boolean(viewOnly || isPulling || isComponentLevelViewOnly);
  const nodeRef = useRef<HTMLDivElement>(null);

  useClickOutside(nodeRef, () => {
    if (selected) {
      updateAttributes({ selected: false });
    }
  });

  const updateTimeModifier = (modifier: string): void => {
    updateAttributes({ timeModifier: modifier });
  };

  const updateCalculationModifier = (modifier: string): void => {
    updateAttributes({ calculationModifier: modifier });
  };

  const classStyle = selected && !viewOnly ? 'border-green-400 bg-green-25 ' : 'border-neutral-100 bg-neutral-15';

  let calculationModifierString = '';
  if (calculationModifier) {
    calculationModifierString = calculationModifier as string;
  } else if (type === 'calculated' && (label === 'headcountNumber' || label === 'newHireNumber')) {
    calculationModifierString = 'All';
  } else {
    calculationModifierString = '';
  }

  return (
    <NodeViewWrapper
      ref={nodeRef}
      className={`formula-node inline-flex items-center ${isEditorWrapped && 'pt-0.5 pb-1.5'}`}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        if (viewOnly) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div
        data-testid={`formula-node-${formulaUuid}`}
        className={`rounded-full whitespace-nowrap border ${classStyle} inline-flex items-center`}
      >
        <div
          className={`px-3 ${!isEditorWrapped && 'py-0.5'} ${timeModifier || calculationModifierString ? 'border-r border-neutral-100 rounded-full' : ''}`}
        >
          <Typography className="inline" color={selected ? 'green' : 'primary'}>
            {type === 'calculated' && typeof label === 'string' ? calculatedFormulaTitleMap[label] : label}
          </Typography>
        </div>
        {type !== 'calculated' && typeof timeModifier === 'string' && (
          <TimeseriesModifier
            editor={editor}
            timeseriesValue={timeModifierMap[timeModifier]}
            updateTimeModifier={updateTimeModifier}
            viewOnly={isViewOnly}
          />
        )}
        {calculationModifierString && calculationModifierType === 'jobTitle' && (
          <CalculationModifier
            editor={editor}
            buttonVal={calculationModifierString}
            updateCalculationModifier={updateCalculationModifier}
            viewOnly={isViewOnly}
          />
        )}
        {calculationModifierString && calculationModifierType === 'departmentUuids' && (
          <DepartmentCalculationModifier
            departmentUuidsString={calculationModifierString}
            editor={editor}
            viewOnly={isViewOnly}
            updateCalculationModifier={updateCalculationModifier}
          />
        )}
      </div>
    </NodeViewWrapper>
  );
};

export default FormulaNodeView;
