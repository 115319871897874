import React, { forwardRef, useEffect, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Typography from '~/components/Typography';
import HoverPopover from '../HoverPopover';

interface Props {
  id: string;
  type?: 'text' | 'password' | 'currency' | 'percentage' | 'textarea' | 'chat' | 'search';
  placeholder?: string;
  label?: string;
  subLabel?: string;
  sideLabel?: string;
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  error?: string;
  prepend?: React.ReactNode;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  name?: string;
  borderOnFocus?: boolean;
  decimalsLimit?: number;
  includeDollarSign?: boolean;
  append?: React.ReactNode;
  info?: {
    infoContent: string | React.ReactNode;
    infoIcon: React.ReactNode;
    iconPositioning?: string;
    infoAnchor?: 'top' | 'bottom';
    infoButtonClassName?: string;
    infoPanelClassName?: string;
  };
}

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      id,
      type = 'text',
      label,
      subLabel,
      sideLabel,
      error,
      className = '',
      placeholder = '',
      value,
      onChange,
      onBlur,
      onKeyDown,
      onFocus,
      prepend,
      required,
      optional,
      disabled,
      name,
      borderOnFocus = false,
      decimalsLimit,
      includeDollarSign,
      append,
      info,
    },
    ref,
  ) => {
    const chatBoxRef = useRef<HTMLTextAreaElement>(null);
    const combinedRef = (type === 'chat' ? chatBoxRef : ref) as any;

    // Auto-resize chat textarea
    useEffect(() => {
      if (type === 'chat' && chatBoxRef.current) {
        chatBoxRef.current.style.height = 'auto';
        chatBoxRef.current.style.height = `${chatBoxRef.current.scrollHeight + 2}px`;
      }
    }, [type, value]);

    const getCommonClassNames = (): string => `
      ${className} 
      ${prepend ? 'pl-[3.5rem]' : 'px-[0.57rem]'}
      ${error ? '!border-red-300' : 'border-gray-300'}
      ${
        borderOnFocus
          ? 'border-transparent hover:border hover:border-solid hover:border-gray-300 focus:border focus:border-solid focus:border-gray-300 shadow-none'
          : 'border border-solid border-gray-300'
      }
      disabled:bg-neutral-25 disabled:text-neutral-75 
      focus:outline-none focus-visible:border-green-500 
      focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
      focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 
      rounded shadow-sm text-sm leading-5
    `;

    const handleSearchClear = (): void => {
      if (onChange) {
        onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
        if (ref && 'current' in ref && ref.current) {
          ref.current.blur();
        }
      }
    };

    return (
      <div className="flex flex-col justify-start w-full">
        <label htmlFor={`${id}-input`} className="inline-flex flex-col">
          {/* Label Section */}
          {(label || required || optional || sideLabel) && (
            <div className="flex flex-col">
              <div className="flex flex-row">
                <Typography tag="span" className={`${disabled ? '!text-neutral-75' : ''} ${label ? 'mb-1' : ''}`}>
                  {label}
                </Typography>
                {required && (
                  <Typography tag="span" size="xs" className={disabled ? '!text-neutral-75' : ''}>
                    *
                  </Typography>
                )}
                {sideLabel && (
                  <Typography className="ml-1" tag="span" color="empty">
                    {sideLabel}
                  </Typography>
                )}
                {optional && (
                  <Typography className="ml-1" tag="span" color="empty">
                    {'(optional)'}
                  </Typography>
                )}
              </div>
              {subLabel && (
                <Typography tag="span" color="empty" className="mb-1.5">
                  {subLabel}
                </Typography>
              )}
            </div>
          )}

          {/* Input Section */}
          <div className="relative flex-col w-full">
            {prepend && (
              <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">{prepend}</div>
            )}
            {info && !value && (
              <div className={`absolute ${info.iconPositioning ?? ''} flex items-center`}>
                <HoverPopover
                  buttonContent={info.infoIcon}
                  panelContent={
                    typeof info.infoContent === 'string' ? (
                      <Typography tag="span">{info.infoContent}</Typography>
                    ) : (
                      info.infoContent
                    )
                  }
                  anchor={info.infoAnchor || 'top'}
                  buttonClassName={info.infoButtonClassName}
                  panelClassName={info.infoPanelClassName}
                />
              </div>
            )}

            {/* Text/Password Input */}
            {(type === 'text' || type === 'password') && (
              <input
                id={`${id}-input`}
                name={name || id}
                type={type}
                className={`${getCommonClassNames()} px-[0.57rem] text-sm leading-5 w-full`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                required={required}
                ref={ref as React.RefObject<HTMLInputElement>}
                autoComplete="off"
                data-testid={`${id}-input`}
              />
            )}

            {/* Search Input */}
            {type === 'search' && (
              <div className="relative">
                <input
                  id={`${id}-input`}
                  name={name || id}
                  type="text"
                  className={`${getCommonClassNames()} px-[0.57rem] text-sm leading-5 w-full`}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                  onFocus={onFocus}
                  disabled={disabled}
                  required={required}
                  ref={ref as React.RefObject<HTMLInputElement>}
                  autoComplete="off"
                  data-testid={`${id}-input`}
                />
                {value ? (
                  <XMarkIcon
                    className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 cursor-pointer"
                    onClick={handleSearchClear}
                  />
                ) : (
                  <MagnifyingGlassIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 pointer-events-none" />
                )}
              </div>
            )}

            {/* Textarea Input */}
            {type === 'textarea' && (
              <textarea
                id={`${id}-textarea`}
                name={name || id}
                className={`${getCommonClassNames()} w-full text-sm leading-5 p-2.5`}
                rows={3}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                required={required}
                ref={ref as React.RefObject<HTMLTextAreaElement>}
                autoComplete="off"
                data-testid={`${id}-textarea`}
              />
            )}

            {/* Chat Input */}
            {type === 'chat' && (
              <textarea
                id={`${id}-chat`}
                name={name || id}
                className={`${className} w-full text-sm leading-5 flex flex-grow max-h-[150px] border border-solid border-neutral-100 
                  placeholder:text-neutral-100 focus:outline-none focus-visible:border-green-500 
                  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
                  focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded-lg 
                  py-3.5 pl-5 pr-[54px] shadow-sm block resize-none no-scrollbar`}
                rows={1}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                required={required}
                ref={combinedRef}
                autoComplete="off"
                data-testid={`${id}-chat`}
              />
            )}

            {/* Currency Input */}
            {type === 'currency' && (
              <NumericFormat
                id={`${id}-input`}
                name={name || id}
                getInputRef={ref}
                autoComplete="off"
                data-testid={`${id}-input`}
                className={`${className} ${error ? '!border-red-300' : 'border-gray-300'} 
      px-[0.57rem] text-sm leading-5 w-full border border-solid disabled:bg-neutral-25 disabled:text-neutral-75 
      focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white 
      focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 
      rounded shadow-sm`}
                thousandSeparator=","
                decimalScale={decimalsLimit}
                allowNegative={false}
                placeholder={placeholder}
                value={value}
                onValueChange={(values) => {
                  if (onChange) {
                    onChange({
                      target: { value: values.value },
                    } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
                prefix={includeDollarSign ? '$ ' : ''}
                disabled={disabled}
                required={required}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
              />
            )}

            {/* Percentage Input */}
            {type === 'percentage' && (
              <NumericFormat
                id={`${id}-input`}
                name={name || id}
                className={getCommonClassNames()}
                placeholder={placeholder}
                value={value}
                onValueChange={(values) => {
                  if (onChange) {
                    onChange({
                      target: { value: values.value },
                    } as React.ChangeEvent<HTMLInputElement>);
                  }
                }}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                required={required}
                suffix="%"
                allowLeadingZeros
                allowNegative={false}
                autoComplete="off"
                data-testid={`${id}-input`}
                getInputRef={ref}
              />
            )}

            {append && <div className="absolute inset-y-0 right-0 pr-2 flex items-center">{append}</div>}

            {/* Error Message */}
            {error && (
              <Typography id={`${id}-input-error`} tag="span" color="warning" className="italic p-1">
                {error}
              </Typography>
            )}
          </div>
        </label>
      </div>
    );
  },
);

Input.displayName = 'Input';
export default Input;
