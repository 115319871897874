import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';

export enum IOnboardingStepStatusEnum {
  Locked = 'locked',
  Ready = 'ready',
  Active = 'active',
  Processing = 'processing',
  Completed = 'completed',
}

export enum IOnboardingStepNameEnum {
  ConnectAccounting = 'Connect Accounting',
  GenerateExpenses = 'Generate Expenses',
  AddHeadcount = 'Add Headcount',
  RevenueModel = 'Build Revenue Model',
}

export const ZOnboardingStep = z.object({
  uuid: z.string(),
  organizationUuid: z.string(),
  name: z.nativeEnum(IOnboardingStepNameEnum),
  status: z.nativeEnum(IOnboardingStepStatusEnum),
  completedAt: ZStringDate.nullable(),
  completedBy: z.string().nullable(),
  createdAt: ZStringDate,
  updatedAt: ZStringDate,
  deletedAt: ZStringDate.nullable(),
});

export type IOnboardingStep = z.infer<typeof ZOnboardingStep>;
