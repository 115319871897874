import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage';
import authReducer, { AuthState } from './authSlice';
import organizationReducer, { IOrganizationState } from './organizationSlice';
import userReducer, { UserState } from './userSlice';
import scenarioReducer, { ScenarioState } from './scenarioSlice';
import settingsReducer, { SettingsState } from './settingsSlice';
import hydrationReducer, { HydrationState } from './hydrationSlice';
import socketReducer, { SocketState } from './socketSlice';
import createFilter from 'redux-persist-transform-filter';
import integrationsReducer, { IIntegrationState } from './integrationsSlice';
import { formulasApi } from '~/services/parallel/api/formulas/formulasApi';
import { departmentsApi } from '~/services/parallel/api/departmentsApi';
import { integrationsApi } from '~/services/parallel/api/integrationsApi';
import drawerContentReducer, { DrawerContentState } from './drawerContentSlice';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { helpCenterApi } from '~/services/parallel/api/helpCenter/helpCenterApi';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import { bvaApi } from '~/services/parallel/api/bvaApi';
import { changeLogApi } from '~/services/parallel/api/changeLogApi';
import { organizationApi } from '~/services/parallel/api/organization/organizationApi';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';

const settingsFilter = createFilter('settings', [
  'hideSensitiveData',
  'isInteractiveSectionCollapsed',
  'collapseInteractiveSection',
]);

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['scenario', 'settings', 'drawerContent'],
  blacklist: [
    '_sockets',
    '_hydration',
    formulasApi.reducerPath,
    departmentsApi.reducerPath,
    integrationsApi.reducerPath,
    onboardingStepsApi.reducerPath,
    helpCenterApi.reducerPath,
    scenariosApi.reducerPath,
    bvaApi.reducerPath,
    changeLogApi.reducerPath,
    organizationApi.reducerPath,
    positionsApi.reducerPath,
  ],
  transforms: [settingsFilter],
};

const rootReducer = combineReducers({
  _sockets: socketReducer,
  _hydration: hydrationReducer,
  auth: authReducer,
  user: userReducer,
  organization: organizationReducer,
  scenario: scenarioReducer,
  settings: settingsReducer,
  integrations: integrationsReducer,
  drawerContent: drawerContentReducer,
  [formulasApi.reducerPath]: formulasApi.reducer,
  [departmentsApi.reducerPath]: departmentsApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [onboardingStepsApi.reducerPath]: onboardingStepsApi.reducer,
  [helpCenterApi.reducerPath]: helpCenterApi.reducer,
  [scenariosApi.reducerPath]: scenariosApi.reducer,
  [bvaApi.reducerPath]: bvaApi.reducer,
  [changeLogApi.reducerPath]: changeLogApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [positionsApi.reducerPath]: positionsApi.reducer,
});

const persistedReducer = persistReducer<State>(persistConfig, rootReducer);

export interface State {
  _sockets: SocketState;
  _hydration: HydrationState;
  auth: AuthState;
  user: UserState;
  organization: IOrganizationState;
  scenario: ScenarioState;
  settings: SettingsState;
  integrations: IIntegrationState;
  drawerContent: DrawerContentState;
  [formulasApi.reducerPath]: ReturnType<typeof formulasApi.reducer>;
  [departmentsApi.reducerPath]: ReturnType<typeof departmentsApi.reducer>;
  [integrationsApi.reducerPath]: ReturnType<typeof integrationsApi.reducer>;
  [onboardingStepsApi.reducerPath]: ReturnType<typeof onboardingStepsApi.reducer>;
  [helpCenterApi.reducerPath]: ReturnType<typeof helpCenterApi.reducer>;
  [scenariosApi.reducerPath]: ReturnType<typeof scenariosApi.reducer>;
  [bvaApi.reducerPath]: ReturnType<typeof bvaApi.reducer>;
  [changeLogApi.reducerPath]: ReturnType<typeof changeLogApi.reducer>;
  [organizationApi.reducerPath]: ReturnType<typeof organizationApi.reducer>;
  [positionsApi.reducerPath]: ReturnType<typeof positionsApi.reducer>;
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', '_socket/setPusher'],
        ignoredPaths: ['_sockets.pusher'],
      },
    }).concat(
      formulasApi.middleware,
      departmentsApi.middleware,
      integrationsApi.middleware,
      helpCenterApi.middleware,
      onboardingStepsApi.middleware,
      scenariosApi.middleware,
      bvaApi.middleware,
      changeLogApi.middleware,
      organizationApi.middleware,
      positionsApi.middleware,
    ),
});

export const persistor = persistStore(store);

let previousHydratedState = store.getState()._hydration.hydrated;

store.subscribe(() => {
  const currentHydratedState = store.getState()._hydration.hydrated;
  if (!previousHydratedState && currentHydratedState) {
    window.dispatchEvent(new Event('reduxHydrated'));
  }
  previousHydratedState = currentHydratedState;
});

export type AppDispatch = typeof store.dispatch;

export default store;
