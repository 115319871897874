import React, { ReactNode } from 'react';
import spinner from '~/assets/spinner.svg';

interface Props {
  id?: string;
  children: ReactNode | string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  fill?:
    | 'clear'
    | 'clearBlue'
    | 'outline'
    | 'outlineSolid'
    | 'lightGreenOutlineGreenText'
    | 'solid'
    | 'destructive'
    | 'destructiveOutline'
    | 'destructiveClear'
    | 'disabledToClear'
    | 'disabledToDestructiveClear'
    | 'solidBlue'
    | 'blueDestructive'
    | 'darkBlueDefault'
    | 'blueOutline'
    | 'default';
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const buttonFillStyles = {
  clear:
    'bg-transparent enabled:text-green-400 enabled:hover:text-green-500 border-green-400 border-transparent disabled:text-neutral-100',
  clearBlue:
    'bg-transparent enabled:text-blue-400 enabled:hover:text-blue-500 border-blue-400 border-transparent disabled:text-neutral-100 disabled:cursor-default',
  outline:
    'bg-transparent text-green-400 hover:text-green-500 border-green-400 enabled:hover:border-green-500 border disabled:border-neutral-50 disabled:text-neutral-100 disabled:bg-neutral-25',
  outlineSolid:
    'bg-white text-green-400 hover:text-green-500 border-green-400 enabled:hover:border-green-500 border disabled:border-neutral-50 disabled:text-neutral-100 disabled:bg-neutral-25',
  lightGreenOutlineGreenText:
    'bg-transparent text-green-400 hover:text-green-500 rounded-lg border-green-50 border disabled:border-neutral-50 disabled:text-neutral-100 disabled:bg-neutral-25',
  solid:
    'bg-green-400 enabled:hover:bg-green-500 text-gray-50 border-transparent disabled:bg-neutral-75 disabled:text-white',
  destructive: 'bg-red-400 enabled:hover:bg-red-500 text-gray-50 border-transparent disabled:text-white',
  destructiveOutline:
    'bg-transparent text-red-400 border-red-400 border enabled:hover:border-red-500 enabled:hover:text-red-500 disabled:text-neutral-100',
  destructiveClear:
    'bg-transparent text-red-400 enabled:hover:text-red-500 disabled:cursor-default disabled:text-neutral-100',
  disabledToDestructiveClear: 'bg-transparent text-neutral-100 enabled:hover:text-red-400',
  disabledToClear: 'bg-transparent text-neutral-100 enabled:hover:text-green-400',
  solidBlue: 'bg-blue-400 border-blue-400 border hover:enabled:bg-blue-500 hover:enabled:border-blue-500 text-white',
  blueDestructive:
    'bg-blue-600 enabled:hover:bg-blue-700 text-gray-50 border border-blue-600 hover:border-blue-700 disabled:bg-neutral-75 disabled:border-neutral-50',
  darkBlueDefault:
    'bg-blue-500 enabled:hover:bg-blue-600 text-white border border-blue-500 hover:border-blue-600 disabled:bg-neutral-75 disabled:border-neutral-50 disabled:text-white',
  blueOutline:
    'bg-transparent text-blue-300 hover:bg-blue-15 hover:text-blue-500 border-blue-100 enabled:hover:border-blue-200 border disabled:border-neutral-50 disabled:text-neutral-100 disabled:bg-neutral-25',
  default:
    'bg-green-400 enabled:hover:bg-green-500 text-gray-50 border border-green-400 hover:border-green-500 disabled:bg-neutral-75 disabled:border-neutral-50 disabled:text-white',
};
const Button = ({
  id,
  onClick,
  className,
  fill = 'default',
  disabled = false,
  children,
  loading = false,
}: Props): React.ReactElement => (
  <button
    type="button"
    data-testid={id}
    onClick={onClick}
    className={`text-sm py-2 w-full flex gap-1 items-center justify-center rounded${
      loading ? ' px-5' : ' px-[32px]'
    } ${buttonFillStyles[fill]} ${className ?? ''}`}
    disabled={disabled || loading}
  >
    {children}
    {loading && <img src={spinner} alt="loading spinner" className="size-5" />}
  </button>
);

export default Button;
