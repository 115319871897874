import React, { useEffect, useMemo } from 'react';
import {
  IOnboardingStep,
  IOnboardingStepNameEnum,
  IOnboardingStepStatusEnum,
} from '~/services/parallel/onboardingSteps.types';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import OnboardingStep from './OnboardingStep';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import CompletionBar from './CompletionBar';
import { setActiveTab } from '~/store/drawerContentSlice';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Typography from '../Typography';
import Button from '../Button';
import { Channel } from 'pusher-js';

const onboardingStepsOrdered = [
  {
    title: IOnboardingStepNameEnum.ConnectAccounting,
    stepDescription: 'Link your accounting to pull in historical data & kickstart your expense forecasts',
    goTo: '/settings',
  },
  {
    title: IOnboardingStepNameEnum.GenerateExpenses,
    stepDescription: "We'll forecast your expenses to provide a clear view of your projected spending",
    goTo: '/expenses',
  },
  {
    title: IOnboardingStepNameEnum.AddHeadcount,
    stepDescription: 'Add your current team and planned hires to forecast headcount related expenses',
    goTo: '/headcount',
  },
  {
    title: IOnboardingStepNameEnum.RevenueModel,
    stepDescription: 'Build your revenue model and account for every part of your financial forecast',
    goTo: '/financial-model',
  },
];

const determineActiveStep = ({
  orderedSteps,
  stepDictionary,
}: {
  orderedSteps: { title: IOnboardingStepNameEnum; stepDescription: string }[];
  stepDictionary?: Record<IOnboardingStepNameEnum, IOnboardingStep>;
}): IOnboardingStepNameEnum | null => {
  const stepsToSkip = [
    IOnboardingStepStatusEnum.Locked,
    IOnboardingStepStatusEnum.Completed,
    IOnboardingStepStatusEnum.Processing,
  ];

  if (!stepDictionary || Object.keys(stepDictionary).length === 0) {
    return null;
  }

  for (const step of orderedSteps) {
    const stepData = stepDictionary[step.title];
    if (stepsToSkip.includes(stepData.status)) {
      continue;
    }
    return step.title;
  }
  return null;
};

const Onboarding = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery(undefined, {
    skip: !organizationUuid,
  });
  const pusher = useSelector((state: State) => state._sockets.pusher);

  useEffect(() => {
    if (organizationUuid) {
      onboardingSteps.refetch();
    }
  }, [organizationUuid]);

  useEffect(() => {
    let onboardingStepChannel: Channel | null = null;
    if (organizationUuid) {
      if (pusher) {
        onboardingStepChannel = pusher.subscribe(organizationUuid);

        onboardingStepChannel.bind('onboarding-step-updated', () => {
          onboardingSteps.refetch();
        });
      }
    }
    return () => {
      if (onboardingStepChannel) onboardingStepChannel.unbind('onboarding-step-updated');
    };
  }, [pusher, organizationUuid]);

  const activeStep = useMemo(
    () =>
      determineActiveStep({ orderedSteps: onboardingStepsOrdered, stepDictionary: onboardingSteps.data?.dictionary }),
    [onboardingSteps.data],
  );

  const allOnboardingStepsCompleted = useMemo(
    () => onboardingSteps.data?.list.every((step) => step.status === IOnboardingStepStatusEnum.Completed) ?? false,
    [onboardingSteps.data],
  );

  if (!onboardingSteps.data?.dictionary || onboardingSteps.data.list.length === 0) {
    return null;
  }

  const completedStepCount = onboardingSteps.data.list.filter(
    (step) => step.status === IOnboardingStepStatusEnum.Completed,
  ).length;
  const totalStepCount = onboardingStepsOrdered.length;

  const handleClose = (): void => {
    dispatch(setActiveTab(null));
  };

  return (
    <div className="h-full px-6 pt-6 relative">
      <XMarkIcon
        className="absolute cursor-pointer top-6 right-6 h-6 w-6 stroke-1.5 stroke-green-400 hover:stroke-green-500"
        onClick={handleClose}
      />
      {allOnboardingStepsCompleted ? (
        <div className="flex flex-col">
          <Typography weight="semibold">Onboarding Complete</Typography>
          <Typography color="secondary">{`You're all set! Reach out to us anytime, we're here to support you!`}</Typography>
          <Button onClick={handleClose} fill="clear" className="!w-fit !px-0 !py-2">
            Close
          </Button>
        </div>
      ) : (
        <div className="h-full flex flex-col gap-4 overflow-y-auto">
          <CompletionBar completedStepCount={completedStepCount} totalStepCount={totalStepCount} />
          {onboardingStepsOrdered.map(
            (step, index) =>
              onboardingSteps.data?.dictionary[step.title] && (
                <OnboardingStep
                  key={step.title}
                  step={onboardingSteps.data.dictionary[step.title]}
                  stepNumber={index + 1}
                  stepDescription={step.stepDescription}
                  isActive={activeStep === step.title}
                  goTo={step.goTo}
                />
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default Onboarding;
