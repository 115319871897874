import React, { useState } from 'react';
import Button from '~/components/Button';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { IActiveSelectionEnum } from '../../types';
import toast from 'react-hot-toast';
import { scenariosApi } from '~/services/parallel/api/scenarios/scenariosApi';
import { useSelector } from 'react-redux';
import type { State } from '~/store';
import { onboardingStepsApi } from '~/services/parallel/api/onboardingSteps/onboardingStepsApi';
import { IOnboardingStepNameEnum, IOnboardingStepStatusEnum } from '~/services/parallel/onboardingSteps.types';

interface FooterControlsProps {
  activeSection: IActiveSelectionEnum;
  setActiveSection: (section: IActiveSelectionEnum) => void;
  scenarioUuid: string;
  onClose: () => void;
}

export const FooterControls: React.FC<FooterControlsProps> = ({
  activeSection,
  setActiveSection,
  scenarioUuid,
  onClose,
}) => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [isLoading, setIsLoading] = useState(false);
  const [mergeScenario] = scenariosApi.useMergeScenarioMutation();
  const onboardingSteps = onboardingStepsApi.useGetOnboardingStepsQuery();
  const [updateOnboardingStep] = onboardingStepsApi.useUpdateOnboardingStepMutation();

  const handleNext = (): void => {
    const sections = [
      IActiveSelectionEnum.INITIAL,
      IActiveSelectionEnum.PEOPLE_AND_FACILITIES,
      IActiveSelectionEnum.COGS,
      IActiveSelectionEnum.SOFTWARE,
      IActiveSelectionEnum.MARKETING,
      IActiveSelectionEnum.OTHER,
      IActiveSelectionEnum.FINALIZE,
    ];
    const currentIndex = sections.indexOf(activeSection);
    const nextIndex = (currentIndex + 1) % sections.length;
    setActiveSection(sections[nextIndex]);
  };

  const handleBack = (): void => {
    const sections = [
      IActiveSelectionEnum.INITIAL,
      IActiveSelectionEnum.PEOPLE_AND_FACILITIES,
      IActiveSelectionEnum.COGS,
      IActiveSelectionEnum.SOFTWARE,
      IActiveSelectionEnum.MARKETING,
      IActiveSelectionEnum.OTHER,
      IActiveSelectionEnum.FINALIZE,
    ];
    const currentIndex = sections.indexOf(activeSection);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    setActiveSection(sections[prevIndex]);
  };

  const handleFinish = async (): Promise<void> => {
    const onboardingStep = onboardingSteps.data?.dictionary[IOnboardingStepNameEnum.GenerateExpenses];
    try {
      setIsLoading(true);
      await updateOnboardingStep({
        onboardingStepUuid: onboardingStep?.uuid ?? '',
        body: {
          status: IOnboardingStepStatusEnum.Completed,
        },
      });
      await mergeScenario({
        orgUuid: organizationUuid,
        scenarioUuid,
        scenariosToAwait: [scenarioUuid],
      }).unwrap();

      onClose();
    } catch {
      updateOnboardingStep({
        onboardingStepUuid: onboardingStep?.uuid ?? '',
        body: {
          status: IOnboardingStepStatusEnum.Ready,
        },
      });
      toast.error('Failed to finalize expenses. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sticky bottom-0 left-0 right-0 bg-green-15 py-4 px-8 flex justify-end items-center z-10">
      <div className="flex gap-3">
        {activeSection !== IActiveSelectionEnum.INITIAL && (
          <Button fill="outline" onClick={handleBack} className="!w-fit !px-4">
            Back
          </Button>
        )}
        {((): React.ReactNode => {
          switch (activeSection) {
            case IActiveSelectionEnum.INITIAL:
              return (
                <Button
                  onClick={() => setActiveSection(IActiveSelectionEnum.PEOPLE_AND_FACILITIES)}
                  className="!w-fit !px-4 flex flex-row items-center"
                >
                  Start
                  <ChevronRightIcon className="size-4 ml-1" />
                </Button>
              );
            case IActiveSelectionEnum.FINALIZE:
              return (
                <Button onClick={handleFinish} loading={isLoading} className="!w-fit !px-4">
                  Finish
                </Button>
              );
            default:
              return (
                <Button className="!w-fit !px-4" onClick={handleNext}>
                  Next
                </Button>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default FooterControls;
