import React from 'react';
import { IPositionDetails } from '../entity/types';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { positionsApi } from '~/services/parallel/api/positions/positionsApi';
import toast from 'react-hot-toast';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { useDispatch } from 'react-redux';
import { updateScenarioLoadingState } from '~/store/scenarioSlice';
import { IListPositionsParams } from '~/services/parallel/api/positions/positionsRequestSchemas';

const DeletePositionModal = ({
  isOpen,
  onClose,
  position,
  orgUuid,
  scenarioUuid,
  createScenario,
  setPositionIndexes,
  reloadDashboard,
  awaitCalculations,
  listParams,
}: {
  isOpen: boolean;
  onClose: () => void;
  position: IPositionDetails | null;
  orgUuid: string;
  scenarioUuid?: string | null;
  createScenario?: boolean;
  setPositionIndexes: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  reloadDashboard?: () => Promise<void>;
  awaitCalculations?: boolean;
  listParams: IListPositionsParams;
}): React.ReactNode => {
  const dispatch = useDispatch();
  const [deletePosition, { isLoading: deleteIsLoading }] = positionsApi.useDeletePositionMutation();

  const handleDelete = async (): Promise<void> => {
    if (!position) return;
    try {
      const query: {
        scenarioUuid?: string | null;
        createScenario?: boolean;
        awaitCalculations?: boolean;
      } = {};

      if (scenarioUuid) query.scenarioUuid = scenarioUuid;
      if (!scenarioUuid && createScenario) query.createScenario = createScenario;
      if (awaitCalculations) query.awaitCalculations = true;

      const response = await deletePosition({
        params: {
          orgUuid,
          positionUuid: position.positionUuid,
        },
        query,
        listParams,
      }).unwrap();

      if (!scenarioUuid && createScenario && response.headers['scenario-uuid']) {
        await handleCreateScenario({
          scenarioUuid: response.headers['scenario-uuid'],
        });
      }

      if (reloadDashboard) {
        await reloadDashboard();
      }

      onClose();
      setPositionIndexes((prev) => {
        const newPositionIndexes = { ...prev };
        delete newPositionIndexes[position.positionUuid];
        return newPositionIndexes;
      });
    } catch (error) {
      toast.error('Failed to delete position');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  return (
    <Modal id={'delete-position-modal'} isOpen={isOpen} title={`Delete ${position?.title ?? 'Position'}`} size="sm">
      <div className="flex flex-col w-full mt-1" data-testid="delete-position-container">
        <div className="flex flex-col gap-6 mb-4">
          <Typography color="secondary">
            Deleting this position will remove all related data, including all historical impact.
          </Typography>
          <Typography color="secondary">
            To close the position and preserve its impact on the budget, assign an appropriate termination date.
          </Typography>
        </div>
        <div className="flex flex-row w-full justify-between">
          <Button id={'cancel-delete-position'} onClick={onClose} fill="clear" className="!w-fit !px-0">
            Cancel
          </Button>
          <Button
            id={'confirm-delete-position'}
            fill="destructive"
            className="!w-auto"
            onClick={handleDelete}
            loading={deleteIsLoading}
          >
            Confirm, Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePositionModal;
