import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import type { ISegmentState } from '../../types';
import Typography from '~/components/Typography';

export const Segment = ({
  segmentState,
  icon: Icon,
  label,
  setAsActiveSegment,
  isFinalize,
}: {
  segmentState: ISegmentState;
  icon: React.ElementType;
  label: string;
  setAsActiveSegment: () => void;
  isFinalize?: boolean;
}): React.ReactNode => {
  const displayCheckIcon = segmentState.touched && !segmentState.selected;
  const strokeColor = segmentState.selected ? 'stroke-neutral-800' : 'stroke-neutral-100';
  const segmentColor = segmentState.selected ? 'primary' : segmentState.touched ? 'green' : 'disabled';

  const handleClick = (): void => {
    if (segmentState.touched && !isFinalize) {
      setAsActiveSegment();
    }
  };

  return (
    <div
      className={`flex flex-row items-center gap-2 text-nowrap ${
        segmentState.touched && !isFinalize ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={handleClick}
    >
      {displayCheckIcon ? (
        <div className="flex justify-center items-center bg-green-200 size-8 border border-green-25 rounded">
          <CheckIcon className="size-4 stroke-white" />
        </div>
      ) : (
        <div className="flex justify-center items-center bg-white size-8 border border-green-25 rounded">
          <Icon className={`size-4 ${strokeColor}`} />
        </div>
      )}
      <Typography weight="medium" color={segmentColor}>
        {label}
      </Typography>
    </div>
  );
};
