import React, { useContext } from 'react';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { ChatContext } from '../../context/ChatContext';

const MissingBusinessModelWarning = (): React.ReactElement => {
  const { setShowBusinessContextModal } = useContext(ChatContext);

  return (
    <div className="border border-red-50 rounded-xl py-4 px-4 flex flex-col items-center justify-center gap-2 text-center w-[350px] mt-4">
      <Typography size="sm" weight="semibold">
        Missing Business Context
      </Typography>
      <Typography size="sm" color="secondary">
        To generate accurate insights, please provide information about your business
      </Typography>
      <Button fill="clear" className="!w-fit !px-0" onClick={() => setShowBusinessContextModal(true)}>
        + Add Context
      </Button>
    </div>
  );
};

export default MissingBusinessModelWarning;
