import React from 'react';
import Typography from '~/components/Typography';

const Legend: React.FC = () => {
  return (
    <div className="flex ml-3 gap-3 items-center max-lg:max-w-[0px] max-lg:overflow-hidden">
      <div className="flex items-center gap-1">
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4.65685" r="4" transform="rotate(45 4 4.65685)" fill="#DC960A" />
        </svg>
        <Typography size="sm" color="secondary">
          Actuals
        </Typography>
      </div>
      <div className="flex items-center gap-1">
        <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4.65685" r="4" transform="rotate(45 4 4.65685)" fill="#5A8496" />
        </svg>
        <Typography size="sm" color="secondary">
          Overrides
        </Typography>
      </div>
    </div>
  );
};

export default Legend;
