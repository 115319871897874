import request from '~/utils/request';
import { IUser, ZUser } from './user.types';
import { IAPIResponse } from '~/utils/types';

export const updateUserPermission = async ({
  userUuid,
  organizationUuid,
  updateParams,
}: {
  userUuid: string;
  organizationUuid: string;
  updateParams: {
    receiveAutomatedEmails?: boolean;
    departmentAccessList?: string[];
    role?: string;
    name?: string;
    email?: string;
  };
}): Promise<IUser> => {
  const response = (await request({
    method: 'PATCH',
    url: `/organizations/${organizationUuid}/users/${userUuid}`,
    body: updateParams,
  })) as IAPIResponse<IUser>;
  return ZUser.parse(response.data.data);
};
