import React, { useContext, useMemo } from 'react';
import Typography from '~/components/Typography';
import Divider from '~/components/Divider';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { addMonths, differenceInCalendarMonths, format } from 'date-fns';
import date from '~/utils/dates/date';
import { DashboardPageContext } from '../../context/DashboardContext';
import { IStaticReportData } from '~/services/parallel/reports.types';

enum MetricColor {
  GREEN = 'green',
  BLUE = 'blue',
  ORANGE = 'orange',
  PURPLE = 'purple',
  TURQUOISE = 'turquoise',
  GRAY = 'disabled',
}

const StaticMetricCard = ({
  metricTitle,
  metricValues,
  overrideUserActiveIndex,
}: {
  metricTitle: string;
  metricValues: Record<string, IStaticReportData>;
  overrideUserActiveIndex?: number;
}): React.ReactNode => {
  const isRunwayMetric = metricTitle === 'Runway';
  const { userActiveIndex } = useContext(DashboardPageContext);
  const activeIndex = useMemo(
    () => overrideUserActiveIndex ?? userActiveIndex,
    [overrideUserActiveIndex, userActiveIndex],
  );
  const { defaultGraphStartDate } = useSelector((state: State) => state.user.preferences);
  const { monthsOutToForecast } = useSelector((state: State) => state.organization.configuration);
  const activeRunwayDifference =
    activeIndex >= 0 ? differenceInCalendarMonths(defaultGraphStartDate, date()) + activeIndex + 1 : 0;

  const textColor: Record<string, MetricColor> = {
    workingModel: MetricColor.GREEN,
    activeScenario: MetricColor.BLUE,
    scenario1: MetricColor.ORANGE,
    scenario2: MetricColor.PURPLE,
    scenario3: MetricColor.TURQUOISE,
    pointInTimeViewScenario: MetricColor.GRAY,
  };
  const renderedMetricValues = Object.entries(metricValues).map(([key, value], index, array) => {
    let renderedValue;
    if (isRunwayMetric) {
      const isSingleValue = array.length === 1;
      if (value.value === null) {
        if (value.isTrendingPositive) {
          renderedValue = isSingleValue ? (
            <div className="flex flex-row gap-2 text-nowrap items-center flex-wrap">
              <Typography weight="semibold" color="green">
                {`∞`} Months
              </Typography>
              <Typography color="lightGreen">{format(addMonths(date(), activeRunwayDifference), 'MMM yy')}</Typography>
            </div>
          ) : (
            '∞'
          );
        } else {
          renderedValue = isSingleValue ? (
            <div className="flex flex-row gap-2 items-center flex-wrap">
              <Typography weight="semibold" color="green">
                {`${monthsOutToForecast - activeRunwayDifference}+`} Months
              </Typography>
              <Typography color="lightGreen">{format(addMonths(date(), activeRunwayDifference), 'MMM yy')}</Typography>
            </div>
          ) : (
            `${monthsOutToForecast - activeRunwayDifference}+`
          );
        }
      } else {
        renderedValue = isSingleValue ? (
          <div className="flex flex-row gap-2 items-center flex-wrap">
            <Typography weight="semibold" color="green">
              {`${value.value - activeRunwayDifference}`} Months
            </Typography>
            <Typography color="lightGreen">{format(addMonths(date(), activeRunwayDifference), 'MMM yy')}</Typography>
          </div>
        ) : (
          value.value - activeRunwayDifference
        );
      }
    } else {
      renderedValue = value.value;
    }
    return (
      <div className="flex no-wrap" key={`${key}-${value.value ?? '∞'}`}>
        <Typography weight="semibold" color={textColor[key]}>
          {renderedValue}
        </Typography>
        {index < array.length - 1 && <Divider orientation="vertical" className="text-neutral-75 mx-1.5" />}
      </div>
    );
  });
  return (
    <div
      className="flex flex-col border border-green-50 rounded-lg py-2.5 px-5 rounded-xl shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]"
      data-testid={`metric-card-${metricTitle}`}
    >
      <Typography color="secondary">{metricTitle}</Typography>
      <div className="flex flex-wrap">{renderedMetricValues}</div>
    </div>
  );
};

export default StaticMetricCard;
