import { ChartBarIcon, PlusIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import React, { useRef } from 'react';
import Checkbox from '~/components/Checkbox';
import SegmentedControl from '~/components/SegmentedControl';
import SelectDepartment from '~/components/SelectDepartment';
import Input from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import ExportData from '~/components/ExportData';
import * as stringDate from '~/utils/stringDate';
import { SelectState } from '~/components/Select/Select.types';
import { IPositionDetails } from '../entity/types';
import formatHeadcountCsv from '../utils/formatHeadcountCsv';

const HeadcountToolbar = ({
  headcountView,
  setHeadcountView,
  selectedDepartment,
  setSelectedDepartment,
  hideTermedPositions,
  setHideTermedPositions,
  positions,
  search,
  setSearch,
  setCreatePositionModalIsOpen,
  isCreatingInline,
  setIsCreatingInline,
  isLoading,
  isFetchingPositions,
  changedFilters,
}: {
  headcountView: string;
  setHeadcountView: React.Dispatch<React.SetStateAction<string>>;
  selectedDepartment: SelectState;
  setSelectedDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  hideTermedPositions: boolean;
  setHideTermedPositions: React.Dispatch<React.SetStateAction<boolean>>;
  positions?: IPositionDetails[];
  search: Types.InputState;
  setSearch: React.Dispatch<React.SetStateAction<Types.InputState>>;
  setCreatePositionModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingInline: boolean;
  setIsCreatingInline: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isFetchingPositions: boolean;
  changedFilters: {
    departments: boolean;
    search: boolean;
    hideTerms: boolean;
  };
}): React.ReactNode => {
  const csvToExport = formatHeadcountCsv({
    positions,
  });
  const searchInputRef = useRef<HTMLInputElement>(null);
  const hasPastEmployees = positions?.some(
    (position) =>
      position.termDate &&
      stringDate.isBefore({
        dateToCheck: position.termDate,
        comparison: stringDate.getStringDate(),
      }),
  );

  return (
    <div className="flex w-full justify-between items-center">
      <div className="max-sm:hidden flex items-center gap-2">
        <SegmentedControl
          name="headcount-view"
          value={headcountView}
          setValue={(value) => {
            setHeadcountView(value);
            if (isCreatingInline) {
              setIsCreatingInline(false);
            }
          }}
          segments={[
            {
              label: (
                <div className="">
                  <ViewColumnsIcon className="size-5" />
                </div>
              ),
              value: 'table',
            },
            {
              label: (
                <div className="">
                  <ChartBarIcon className="size-5 -rotate-90" />
                </div>
              ),
              value: 'timeline',
            },
          ]}
          disabled={(positions && positions.length === 0) || isLoading}
          thin
        />
        <SelectDepartment
          id="department-filter"
          departmentOptions={selectedDepartment}
          setDepartmentOptions={setSelectedDepartment}
          allowSelectAll
          onFocus={() => {
            if (isCreatingInline) {
              setIsCreatingInline(false);
            }
          }}
          disabled={isLoading}
          loading={isFetchingPositions && changedFilters.departments}
          thin
        />
        <div
          className={`flex text-nowrap items-center gap-2 transition-all duration-500 ${hasPastEmployees || hideTermedPositions ? 'opacity-100' : 'opacity-0'}`}
        >
          <Checkbox
            checked={hideTermedPositions}
            toggleValue={() => {
              setHideTermedPositions(!hideTermedPositions);
              setIsCreatingInline(false);
            }}
            label="Hide Past Employees"
            id="hide-past-employees"
            disabled={isLoading}
            thin
            loading={isFetchingPositions && changedFilters.hideTerms}
          />
        </div>
      </div>
      <div className="gap-2 flex max-sm:hidden">
        <Input
          id="search-headcount-input"
          type="search"
          placeholder="Search"
          state={search}
          setState={setSearch}
          className="!w-[200px] mt-[1px]"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              (e.target as HTMLInputElement).blur();
              if (isCreatingInline) {
                setIsCreatingInline(false);
              }
            }
          }}
          ref={searchInputRef}
          thin
          loading={isFetchingPositions && changedFilters.search}
        />
        <Button
          id="create-position-button"
          onClick={() => {
            setCreatePositionModalIsOpen(true);
            if (isCreatingInline) {
              setIsCreatingInline(false);
            }
          }}
          className="!w-fit !px-[7px] !py-[7px] "
        >
          <PlusIcon className="size-4 stroke-2" />
        </Button>
        <ExportData
          id={`download-csv`}
          data={csvToExport}
          filename={`positions-${stringDate.format(stringDate.getStringDate(), 'mm-dd-yyyy')}.csv`}
          className="max-sm:hidden"
          thin
          disabled={isLoading || isFetchingPositions}
        />
      </div>
    </div>
  );
};

export default HeadcountToolbar;
