import Store from '~/store';
import { hydrationSlice } from '~/store/hydrationSlice';
import request from '~/utils/request';
import { userSlice } from '~/store/userSlice';
import { authSlice } from '~/store/authSlice';
import { IOrganizationState, organizationSlice } from '~/store/organizationSlice';
import z from 'zod';
import { IAPIResponse } from './types';
import { IUser } from '~/pages/Settings/Users';
import { scenarioSlice } from '~/store/scenarioSlice';
import { getScenarios } from '~/services/parallel/scenarios';
import { ZStringDate } from '~/utils/stringDate/types';
import * as api from '~/services/parallel';
import { integrationsSlice } from '~/store/integrationsSlice';
import { getStringDate } from './stringDate';

export enum IUserRole {
  USER = 'user',
  SUPPORT = 'support',
  LINK = 'link',
}

const ZUserResponseData = z.object({
  data: z.object({
    uuid: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    email: z.string(),
    name: z.string(),
    role: z.nativeEnum(IUserRole),
    permissions: z.array(
      z.object({
        uuid: z.string(),
        userUuid: z.string(),
        role: z.string(),
        organizationUuid: z.string(),
        organization: z.object({
          name: z.string(),
          uuid: z.string(),
        }),
        isActive: z.boolean(),
        departmentAccessList: z.array(z.string()),
      }),
    ),
    preferences: z.object({
      createdAt: z.string(),
      updatedAt: z.string(),
      primaryOrganizationUuid: z.string(),
      sideMenuExpanded: z.boolean(),
      userUuid: z.string(),
      defaultGraphStartDate: ZStringDate.nullable(),
      defaultGraphEndDate: ZStringDate.nullable(),
    }),
  }),
});

const ZOrganizationResponseData = z.object({
  data: z.object({
    configuration: z.object({
      companyStartDate: z.string().optional().nullable(),
      isModelBuilderVisibleToUser: z.boolean().optional().nullable(),
      dashboardConfiguration: z
        .object({
          metrics: z.array(z.string()),
          graphs: z.array(z.string()),
          levers: z.array(z.string()),
        })
        .optional()
        .nullable(),
      contractsEnabled: z.boolean(),
      ratiosEnabled: z.boolean(),
      monthsOutToForecast: z.number(),
      autoPullDay: z.number(),
    }),
  }),
});

const ZDepartmentsResponseData = z.object({
  data: z.array(
    z.object({
      uuid: z.string().uuid(),
      departmentUuid: z.string().uuid(),
      name: z.string(),
      organizationUuid: z.string(),
      createdAt: z.string(),
      updatedAt: z.string(),
    }),
  ),
});

export default async (store: typeof Store): Promise<void> => {
  const {
    _hydration: { hydrated },
  } = store.getState();

  const hydrateStore = async (): Promise<void> => {
    // If there is no access token, the user is logged out
    if (!localStorage.getItem('accessToken')) return;

    const userResponse = (await request({
      url: '/users/me',
      method: 'GET',
    })) as IAPIResponse<IUser>;
    const parsedUserData = ZUserResponseData.parse(userResponse.data);

    if (userResponse.status !== 200) throw new Error('Failed to fetch user data');

    store.dispatch(
      userSlice.actions.update({
        uuid: parsedUserData.data.uuid,
        name: parsedUserData.data.name,
        email: parsedUserData.data.email,
        preferences: parsedUserData.data.preferences,
        permissions: parsedUserData.data.permissions[0],
        role: parsedUserData.data.role,
      }),
    );

    store.dispatch(authSlice.actions.login());

    let primaryOrg: { name: string; uuid: string } | undefined;
    // If org isn't set, fallback to the first org in the user's permissions
    if (userResponse.data.data.role === 'user') {
      primaryOrg = parsedUserData.data.permissions.find(
        (permission) => permission.organizationUuid === parsedUserData.data.preferences.primaryOrganizationUuid,
      )?.organization;
    } else {
      if (parsedUserData.data.preferences.primaryOrganizationUuid) {
        const organizations = (await request({
          url: `/organizations/${parsedUserData.data.preferences.primaryOrganizationUuid}`,
          method: 'GET',
        })) as IAPIResponse<IOrganizationState>;
        primaryOrg = organizations.data.data;
      } else {
        const organizations = (await request({
          url: `/organizations`,
          method: 'GET',
        })) as IAPIResponse<IOrganizationState[]>;
        primaryOrg = organizations.data.data[0];
      }
    }

    if (!primaryOrg) throw new Error('Primary organization not found');

    const [organizationResponse, departmentsResponse, scenariosResponse, integrationsResponse] = await Promise.all([
      request({
        url: `/organizations/${primaryOrg.uuid}`,
        method: 'GET',
      }),
      request({
        url: `/organizations/${primaryOrg.uuid}/groups`,
        method: 'GET',
        params: {
          scenarioUuid: store.getState().scenario.activeScenarioUuid ?? undefined,
        },
      }),
      getScenarios({ organizationUuid: primaryOrg.uuid }),
      api.integrations.list({ organizationUuid: primaryOrg.uuid }),
    ]);

    const parsedOrganizationData = ZOrganizationResponseData.parse(organizationResponse.data);

    const parsedDepartmentsData = ZDepartmentsResponseData.parse(departmentsResponse.data);

    store.dispatch(
      organizationSlice.actions.update({
        ...primaryOrg,
        configuration: {
          companyStartDate: parsedOrganizationData.data.configuration.companyStartDate ?? '',
          isModelBuilderVisibleToUser: parsedOrganizationData.data.configuration.isModelBuilderVisibleToUser ?? false,
          dashboardConfiguration: parsedOrganizationData.data.configuration.dashboardConfiguration ?? {
            metrics: [],
            graphs: [],
            levers: [],
          },
          contractsEnabled: parsedOrganizationData.data.configuration.contractsEnabled,
          ratiosEnabled: parsedOrganizationData.data.configuration.ratiosEnabled,
          monthsOutToForecast: parsedOrganizationData.data.configuration.monthsOutToForecast,
          autoPullDay: parsedOrganizationData.data.configuration.autoPullDay,
        },
        departments: parsedDepartmentsData.data,
      }),
    );

    store.dispatch(scenarioSlice.actions.updateScenarioList(scenariosResponse));

    if (integrationsResponse.length) {
      store.dispatch(
        integrationsSlice.actions.update({
          lastPulled: integrationsResponse[0].lastPulled ? getStringDate(integrationsResponse[0].lastPulled) : null,
          isPulling: integrationsResponse[0].isPulling,
          source: integrationsResponse[0].source?.slug,
        }),
      );
    }

    store.dispatch(hydrationSlice.actions.setAsHydrated());
  };

  return new Promise((resolve) => {
    if (!localStorage.getItem('accessToken')) resolve();

    window.addEventListener('reduxHydrated', async () => {
      resolve();
    });

    if (!hydrated) {
      hydrateStore();
    } else {
      resolve();
    }
  });
};
