/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Node, mergeAttributes } from '@tiptap/core';
import logger from '~/utils/logger';

/**
 * Loom Node Extension
 *
 * This extension allows embedding Loom videos directly into the editor.
 * Videos are stored in markdown format as: !loom[videoId](url)
 *
 * Usage:
 * - Paste a Loom URL directly into the editor
 * - The URL will be converted to markdown format for storage
 * - When rendered, the markdown is converted to an embedded video
 *
 * Example:
 * Paste: https://www.loom.com/share/abc123
 * Stored as: !loom[abc123](https://www.loom.com/share/abc123)
 * Renders as: <iframe> with proper embedding
 */
const Loom = Node.create({
  name: 'loom',
  group: 'block',
  atom: true,
  priority: 1000,

  addAttributes() {
    return {
      videoId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-video-id'),
        renderHTML: (attributes) => ({
          'data-video-id': attributes.videoId,
        }),
      },
      url: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-url'),
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="loom-video"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(
      { 'data-type': 'loom-video', class: 'loom-video-container' },
      this.options.HTMLAttributes,
      HTMLAttributes,
    );

    return [
      'div',
      attrs,
      [
        'iframe',
        {
          src: `https://www.loom.com/embed/${HTMLAttributes.videoId}`,
          frameborder: '0',
          allowfullscreen: 'true',
          style: 'width: 100%; aspect-ratio: 16/9;',
          onload: 'this.style.opacity=1',
        },
      ],
    ];
  },

  addPasteRules() {
    return [
      {
        find: /@?https:\/\/(www\.)?loom\.com\/share\/([a-zA-Z0-9]+)/g,
        handler: ({ match, state, chain }) => {
          const videoId = match[2];
          const url = match[0].replace(/^@/, ''); // Remove leading @ if present

          // Validate videoId format
          if (!/^[a-zA-Z0-9]+$/.test(videoId)) {
            logger.error(new Error(`Invalid Loom video ID format: ${videoId}`));
            return;
          }

          // Get text before the paste position
          const { from } = state.selection;
          const textBefore = state.doc.textBetween(Math.max(0, from - 100), from);

          // Only convert to embed if it's not already part of a markdown link
          if (!textBefore.includes('[')) {
            chain()
              .insertContent({
                type: this.name,
                attrs: { videoId, url },
                marks: [
                  {
                    type: 'markdown',
                    attrs: {
                      text: `!loom[${videoId}](${url})`,
                    },
                  },
                ],
              })
              .run();
          }
        },
      },
    ];
  },

  // Add toMarkdown method to handle markdown serialization
  addStorage() {
    return {
      toMarkdown: (state: any, node: any) => {
        state.write(`!loom[${node.attrs.videoId}](${node.attrs.url})`);
        state.closeBlock(node.type);
      },
    };
  },
});

export default Loom;
