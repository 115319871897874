import React, { ReactElement, useEffect, useState } from 'react';
import SideMenu from '~/components/SideMenu';
import store, { State } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as utils from '~/pages/ProtectedRoute/utils/';
import { Channel } from 'pusher-js';
import ScenarioMode from '~/components/ScenarioMode';
import toast, { Toaster } from 'react-hot-toast';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import waitForStoreRehydration from '~/utils/waitForStoreRehydration';
import FullPageLoading from '~/components/FullPageLoading';
import ChangeLogModal from '~/components/ChangeLogModal';
import ScenarioIdleTimer from '~/components/ScenarioIdleTimer/ScenarioIdleTimer';
import { update } from '~/store/integrationsSlice';
import RazzleDazzle from '~/components/RazzleDazzle/RazzleDazzle';
import Drawer from '~/components/Drawer';
import { useFeatureFlagPosthog } from '~/utils/hooks/useFeatureFlag';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { setActiveTab } from '~/store/drawerContentSlice';
import { setCurrentHelpPage } from '~/store/drawerContentSlice';
import { organizationSlice } from '~/store/organizationSlice';

const ProtectedRoute = (): ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user);
  const isLoggedIn = useSelector((state: State) => state.auth.isLoggedIn);
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const location = useLocation();
  const navigate = useNavigate();
  const [showRefresh, setShowRefresh] = useState<boolean>(false);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const integrations = useSelector((state: State) => state.integrations);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showChangeLogModal, setShowChangeLogModal] = useState<boolean>(false);
  const isRazzleDazzleEnabled = useFeatureFlagPosthog('razzle_dazzle');
  const { queryParams, removeQueryParams } = useQueryParams();
  const { currentPageId } = useSelector((state: State) => state.drawerContent.helpCenter);
  const sideMenuExpanded = useSelector((state: State) => state.user.preferences.sideMenuExpanded);

  useEffect(() => {
    const commandOEventListener = (e: KeyboardEvent): void => {
      if ((e.metaKey && e.key === 'o') || (e.ctrlKey && e.key === 'o')) {
        e.preventDefault();
        setShowChangeLogModal((prev) => !prev);
      }
    };
    window.addEventListener('keydown', commandOEventListener);
    return () => window.removeEventListener('keydown', commandOEventListener);
  }, []);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await waitForStoreRehydration(store);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  utils.useInitializePusher();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        throw new Error('Loading failed');
      }
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, []);

  /**
   * Listen for NEW_DEPLOYMENT events and show the refresh button if one comes through.
   */
  useEffect(() => {
    let channel: Channel | null = null;
    if (pusher) {
      channel = pusher.subscribe('ALL');
      channel.bind('NEW_DEPLOYMENT', () => {
        setShowRefresh(true);
      });
    }
    return () => {
      if (channel) channel.disconnect();
    };
  }, [pusher]);

  useEffect(() => {
    let userNotificationChannel: Channel | null = null;
    if (organizationUuid) {
      if (pusher) {
        userNotificationChannel = pusher.subscribe(organizationUuid);

        userNotificationChannel.bind(
          'dashboard-configuration-updated',
          (data: { metrics: string[]; graphs: string[]; levers: string[] }) => {
            const organizationState = store.getState().organization;
            store.dispatch(
              organizationSlice.actions.update({
                ...organizationState,
                configuration: {
                  ...organizationState.configuration,
                  dashboardConfiguration: data,
                },
              }),
            );
          },
        );

        userNotificationChannel.bind(
          'notification-created',
          (
            data: {
              userUuid: string;
              organizationUuid: string;
              type: string;
              context: { title: string };
            }[],
          ) => {
            if (location.pathname !== '/ratios') {
              data.map((item) => {
                if (item.userUuid === user.uuid) {
                  toast.custom(
                    (t) => (
                      <div className="flex flex-row bg-white text-nowrap gap-2 shadow-md px-4 py-1 justify-center items-center rounded">
                        <Typography weight="semibold">{item.context.title.replace('@', ':')}</Typography>
                        <Typography>ratio impacted</Typography>
                        <Button
                          fill="clear"
                          onClick={() => {
                            navigate('/ratios');
                            toast.dismiss();
                          }}
                          className="hover:underline !w-fit !px-0"
                        >
                          View
                        </Button>
                        <Button
                          fill="clear"
                          onClick={() => toast.dismiss(t.id)}
                          className="!p-0 !w-fit  !text-neutral-100 !hover:text-neutral-400"
                        >
                          <XMarkIcon className="h-5 w-5 stroke-2" />
                        </Button>
                      </div>
                    ),
                    { duration: 6000 },
                  );
                }
              });
            }
          },
        );
      }
    }
    return () => {
      if (userNotificationChannel) {
        userNotificationChannel.unbind('notification-created');
        userNotificationChannel.unbind('dashboard-configuration-updated');
      }
    };
  }, [pusher, organizationUuid, user.uuid, location.pathname, navigate]);

  useEffect(() => {
    let actualsPulledChannel: Channel | null = null;
    if (pusher && organizationUuid) {
      actualsPulledChannel = pusher.subscribe(`${organizationUuid}-${activeScenarioUuid ?? ''}`);
      actualsPulledChannel.bind('integrations-finished-pulling', async (data: { success: boolean }) => {
        dispatch(update({ ...integrations, isPulling: false }));

        toast.custom(
          () => (
            <div className="flex flex-row bg-white text-nowrap gap-2 shadow-md px-4 py-2 justify-center items-center rounded">
              <Typography>
                {data.success
                  ? `Actuals pulled${!activeScenarioUuid ? ', monthly recap email sent' : ''}`
                  : 'There was an error pulling your actuals'}
              </Typography>
            </div>
          ),
          { duration: 6000 },
        );
      });
    }

    return () => {
      if (actualsPulledChannel) actualsPulledChannel.unbind('integrations-finished-pulling');
    };
  }, [pusher, organizationUuid, activeScenarioUuid]);

  /**
   * Initialize datadog with user information upon login.
   */
  useEffect(() => {
    if (user.uuid && isLoggedIn) {
      utils.userTracking({ user, isLoggedIn: isLoggedIn });
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    if (queryParams.get('helpCenterPostId')) {
      dispatch(setActiveTab('help-center'));
      if (currentPageId !== queryParams.get('helpCenterPostId')) {
        dispatch(setCurrentHelpPage(queryParams.get('helpCenterPostId') as string));
      }
      removeQueryParams(['helpCenterPostId']);
    }
  }, [queryParams, currentPageId, dispatch]);

  if (isLoggedIn) {
    return (
      <>
        <div
          className={`group flex h-screen overflow-hidden transition-[padding] ${activeScenarioUuid ? 'p-[7px]' : ''}`}
        >
          {/* Side Menu */}
          <SideMenu showRefresh={showRefresh} setShowChangeLogModal={setShowChangeLogModal} />
          {/* Main Content (Flexible) */}
          <div
            className={`flex-grow overflow-y-auto overflow-x-hidden min-w-0 scrollbar transition-[margin] duration-150 ${
              sideMenuExpanded ? 'ml-[240px]' : 'ml-[72px]'
            }`}
          >
            {isLoading ? (
              <FullPageLoading isVisible color="green" text="" opacity="1" size="size-[75px]" isStatic />
            ) : (
              <Outlet />
            )}
          </div>
          {/* Right Drawer */}
          <Drawer />
        </div>

        {/* Absolute positioned elements */}
        <ChangeLogModal isOpen={showChangeLogModal} onClose={() => setShowChangeLogModal(false)} />
        <ScenarioIdleTimer />
        <ScenarioMode />
        {isRazzleDazzleEnabled && <RazzleDazzle />}
        <Toaster
          toastOptions={{
            className: 'toaster',
            custom: {
              style: {
                backgroundColor: '#383935',
                color: '#FFFFFF',
              },
            },
            style: {
              backgroundColor: '#383935',
              color: '#FFFFFF',
            },
          }}
        />
      </>
    );
  }

  return (
    <Navigate
      to={`/auth/login?redirectUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
      replace
    />
  );
};

export default ProtectedRoute;
