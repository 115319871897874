import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import formatCurrency from '~/utils/formatCurrency';
import UpcomingChangePopover from './UpcomingChangePopover';
import * as stringDate from '~/utils/stringDate';

const CellTotalComp = React.memo(
  ({
    id,
    isActive,
    isScenario,
    updateCompensation,
    value,
    positionUuid,
    changeHistory,
  }: {
    id: string;
    isActive: boolean;
    isScenario: boolean;
    updateCompensation: () => void;
    value: string;
    positionUuid: string;
    changeHistory: {
      value: number;
      effectiveAt: string;
    }[];
  }): React.ReactNode => {
    const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
    const hasUpcomingChanges = [...changeHistory].some(
      (item) =>
        item.effectiveAt !== 'onHire' &&
        stringDate.isAfter({
          dateToCheck: item.effectiveAt,
          comparison: stringDate.getStringDate(),
        }),
    );

    return (
      <div
        title={!hideSensitiveData ? formatCurrency(value, false) : undefined}
        className={`
        flex flex-row gap-4 group/cell-total-comp items-center justify-end select-none ${isActive ? 'cursor-pointer' : 'text-neutral-100 cursor-default'} 
      `}
        data-testid={`${id}-${positionUuid}-container`}
      >
        {hasUpcomingChanges && <UpcomingChangePopover id={`${id}-${positionUuid}-upcoming-change`} />}
        {hideSensitiveData ? (
          <div className={`cursor-default ${isScenario && 'text-blue-400'}`}>*********</div>
        ) : (
          <div
            onClick={updateCompensation}
            className={`${isActive && 'group-hover/cell-total-comp:text-green-400 group-hover/cell-total-comp:underline group-hover/cell-total-comp:underline-offset-4'} ${isActive ? isScenario && 'text-blue-400' : 'text-neutral-100'}`}
            data-testid={`${id}-${positionUuid}`}
          >
            {formatCurrency(value, false)}
          </div>
        )}
      </div>
    );
  },
);

CellTotalComp.displayName = 'CellTotalComp';

export default CellTotalComp;
