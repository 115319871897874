import React, { useRef, useEffect } from 'react';
import Spinner from '../Spinner';

interface Props {
  checked: boolean;
  isIndeterminate?: boolean;
  /** @deprecated Use onChange instead */
  toggleValue?: () => void;
  onChange?: (value: boolean) => void;
  className?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  thin?: boolean;
  loading?: boolean;
}

const Checkbox = ({
  checked,
  isIndeterminate,
  toggleValue,
  onChange,
  className,
  id,
  label,
  disabled,
  thin,
  loading,
}: Props): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!isIndeterminate;
    }
  }, [isIndeterminate]);

  const handleChange = (newChecked: boolean): void => {
    // Support both handlers, preferring onChange if both are provided
    if (onChange) {
      onChange(newChecked);
    } else if (toggleValue) {
      toggleValue();
    }
  };

  return (
    <label
      htmlFor={id}
      className={`flex items-center ml-2 cursor-pointer ${className}${disabled && ' !cursor-default text-neutral-100'}`}
    >
      <input
        id={id}
        data-testid={id}
        disabled={disabled}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
        className={`cursor-pointer border border-green-400 rounded hover:bg-neutral-15 hover:border-green-500 indeterminate:bg-green-400 indeterminate:hover:bg-green-500 indeterminate:focus:bg-green-500 checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-500 focus:ring-green-500 ${thin ? 'size-3.5' : 'size-4'} disabled:!cursor-default disabled:!text-neutral-100'}`}
        type="checkbox"
        ref={inputRef}
      />
      {label && <span className={`ml-2 ${thin ? 'text-xs' : 'text-base'}`}>{label}</span>}
      {loading && (
        <div className="ml-2">
          <Spinner strokeColor={'#AAB7A4'} size={16} />
        </div>
      )}
    </label>
  );
};

export default Checkbox;
