import React from 'react';
import Checkbox from '~/components/Checkbox';
import { Select } from '~/components/UncontrolledComponents/Select';
import SearchInput from '~/components/Formulas/SearchInput/SearchInput';
import Button from '~/components/Button';
import { CATEGORY_OPTIONS } from './ExpenseForm/formConstants';
import { ModalState } from '../ExpensesPage';
import { PlusIcon } from '@heroicons/react/24/outline';

const ExpensesToolbar = ({
  selectedCategory,
  setSelectedCategory,
  hidePastExpenses,
  setHidePastExpenses,
  setModalState,
  hasPastExpenses,
}: {
  selectedCategory: string;
  setSelectedCategory: (value: string | boolean | null) => void;
  hidePastExpenses: boolean;
  setHidePastExpenses: (value: string | boolean | null) => void;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  hasPastExpenses: boolean;
}): React.ReactNode => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-2">
        <div className="w-[200px] flex justify-center z-[29]">
          <Select
            id="select-category"
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value);
            }}
            options={[{ label: 'All Categories', value: 'all' }, ...CATEGORY_OPTIONS]}
            placeholder="All Categories"
            thin
          />
        </div>
        {hasPastExpenses && (
          <div className="flex text-nowrap items-center gap-2">
            <Checkbox
              checked={hidePastExpenses}
              onChange={(value) => {
                setHidePastExpenses(value);
              }}
              label="Hide Past Expenses"
              id="hide-past-expenses"
              thin
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <SearchInput thin />
        <Button
          onClick={() => {
            setModalState({ type: 'create', formulaUuid: null });
          }}
          id="new-expense-button"
          className="!w-fit !px-[7px] !py-[7px] "
        >
          <PlusIcon className="size-4 stroke-2" />
        </Button>
      </div>
    </div>
  );
};

export default ExpensesToolbar;
