import React, { useContext } from 'react';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { ChatContext } from '../../context/ChatContext';
import { useSelector } from 'react-redux';
import type { State } from '~/store';
import * as api from '~/services/parallel';

const RevenueModelerPrompt = (): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { setSelectedConversationUuid, setAllConversationsDict } = useContext(ChatContext);
  const createNewRevenueBuild = async (): Promise<void> => {
    const conversation = await api.chat.newRevenueBuild({ organizationUuid });
    setSelectedConversationUuid(conversation.uuid);
    setAllConversationsDict((prev) => ({ ...prev, [conversation.uuid]: conversation }));
  };

  return (
    <div className="border border-red-50 rounded-xl py-4 px-4 flex flex-col items-center justify-center gap-2 text-center w-[350px] mt-4">
      <Typography size="sm" weight="semibold">
        Revenue Model Builder
      </Typography>
      <Typography size="sm" color="secondary">
        Start getting answers to your revenue questions with our AI-powered model builder.
      </Typography>
      <Button fill="clear" className="!w-fit !px-0" onClick={createNewRevenueBuild}>
        Get Started
      </Button>
    </div>
  );
};

export default RevenueModelerPrompt;
