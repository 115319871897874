import type { ExpenseFrequencyEnum } from '~/services/parallel/formulas.types';
import type { ExpenseDriverEnum } from '~/services/parallel/formulas.types';
import { employmentTypeToLabel, IEmploymentType } from '~/services/parallel/headcount.types';
import type { SegmentType } from './ExpenseForm';
import type { RadioInputState } from '~/components/RadioInput/RadioInput.types';

export const CATEGORY_OPTIONS = [
  {
    label: 'Total Compensation',
    value: 'Total Compensation',
  },
  {
    label: 'People & Facilities',
    value: 'People & Facilities',
  },
  {
    label: 'COGS',
    value: 'Cost of Goods Sold',
  },
  {
    label: 'Software',
    value: 'Software',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const EXPENSE_TYPE_OPTIONS = [
  {
    label: 'Set Cost',
    value: 'setCost',
    segmentClassName: '!max-w-[120px]',
    labelClassName: '!max-w-[112px]',
  },
  {
    label: 'Headcount Driven',
    value: 'headcountDriven',
  },
  {
    label: 'Custom',
    value: 'custom',
    segmentClassName: '!max-w-[120px]',
    labelClassName: '!max-w-[112px]',
  },
];

export const HEADCOUNT_DRIVER_OPTIONS = [
  {
    label: 'Dollar Amount per Employee',
    value: 'headcountFixed',
  },
  {
    label: 'Percent of Monthly Compensation',
    value: 'headcountPercentCompensation',
  },
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    value: IEmploymentType.FullTime,
    label: employmentTypeToLabel[IEmploymentType.FullTime],
  },
  {
    value: IEmploymentType.PartTime,
    label: employmentTypeToLabel[IEmploymentType.PartTime],
  },
  {
    value: IEmploymentType.Contractor,
    label: employmentTypeToLabel[IEmploymentType.Contractor],
  },
];

export const FREQUENCY_OPTIONS = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Annually',
    value: 'annually',
  },
  {
    label: 'One-time',
    value: 'oneTime',
  },
  {
    label: 'On-hire',
    value: 'onHire',
  },
];

export const getFrequencyOptionsFromSegmentType = (segmentType: SegmentType): RadioInputState['options'] => {
  return FREQUENCY_OPTIONS.filter((option) => {
    if (segmentType === 'headcountDriven') {
      return option.value !== 'oneTime';
    }
    if (segmentType === 'setCost') {
      return option.value !== 'onHire';
    }
    return true;
  });
};

export const getSegmentTypeFromDriver = (driver: ExpenseDriverEnum): SegmentType => {
  if (['headcountFixed', 'headcountPercentCompensation'].includes(driver)) {
    return 'headcountDriven';
  } else if (driver === 'custom') {
    return 'custom';
  } else {
    return 'setCost';
  }
};

export const getCurrencyLabel = ({
  type,
  frequency,
}: {
  type: string;
  frequency?: ExpenseFrequencyEnum | null;
}): string => {
  if (frequency === 'oneTime' && type === 'setCost') return 'One Time Cost';
  if (frequency === 'oneTime') return 'Cost Per Employee';
  if (frequency === 'annually') return 'Annual Cost';
  if (frequency === 'quarterly') return 'Quarterly Cost';
  if (frequency === 'onHire') return 'Cost Per Hire';
  if (type !== 'setCost') return 'Monthly Cost Per Employee';

  return 'Monthly Cost';
};
