import React, { useEffect, useState } from 'react';
import Switch from '~/components/Switch';

const TogglePosition = ({
  positionUuid,
  isActive,
  onToggle,
}: {
  positionUuid: string;
  isActive: boolean;
  onToggle: (isActive: boolean) => Promise<void>;
}): React.ReactNode => {
  const [isPositionActive, setIsPositionActive] = useState(isActive);

  useEffect(() => {
    setIsPositionActive(isActive);
  }, [isActive]);

  return <Switch id={`is-active-switch-${positionUuid}`} checked={isPositionActive} onChange={onToggle} />;
};

export default TogglePosition;
