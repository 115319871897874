import React from 'react';
import { IChangeLogDescription } from '../entity/types';
import FormulaContent from './FormulaContent';
import Typography from '~/components/Typography';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import { IFormula } from '~/services/parallel/formulas.types';

const ChangeCell = ({
  change,
  formulaDictionary,
  isOriginal,
  isField,
}: {
  change: IChangeLogDescription;
  formulaDictionary: Record<string, IFormula>;
  isOriginal: boolean;
  isField: boolean;
}): React.ReactElement => {
  const value = isField ? change.changedField : isOriginal ? change.originalValue : change.newValue;

  if (typeof value !== 'string' && typeof value !== 'object') return <Typography>-</Typography>;

  if (change.changedField === 'formulaRecipe' && typeof value === 'object') {
    return (
      <FormulaContent
        recipe={typeof value === 'object' && value ? value.recipe : null}
        formulaDictionary={formulaDictionary}
      />
    );
  }

  return <Typography>{camelCaseToNormal(typeof value === 'string' ? value : '-')}</Typography>;
};

export default ChangeCell;
