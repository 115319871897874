import { createApi } from '@reduxjs/toolkit/query/react';
import type { IFormula, IFormulaTypeEnum, IPullAccountingActualsParams } from '../../formulas.types';
import { createBaseQuery } from '../baseQuery';
import { IUpdateFormulaRequest, type IUpsertExpenseFormulaRequest } from './requestSchemas';
import { bvaApi } from '../bvaApi';
import { integrationsApi } from '../integrationsApi';

export const formulasApi = createApi({
  reducerPath: 'formulasApi',
  baseQuery: createBaseQuery(),
  tagTypes: ['Formula'],
  endpoints: (builder) => ({
    listFormulas: builder.query<
      IFormula[],
      {
        startDate?: string;
        endDate?: string;
        scenarioUuid?: string;
        includes?: string[];
        types?: IFormulaTypeEnum[];
        formulaUuid?: string;
      }
    >({
      query: (params) => ({
        url: 'formulas',
        params,
      }),
      transformResponse: (response: { data: IFormula[] }) => {
        return response.data;
      },
      providesTags: ['Formula'],
    }),

    upsertExpenseFormula: builder.mutation<IFormula, IUpsertExpenseFormulaRequest>({
      query: (data) => ({
        url: `formulas/expenses/`,
        method: 'POST',
        params: data.query,
        body: data.body,
      }),
      invalidatesTags: ['Formula'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(integrationsApi.util.invalidateTags(['Integration']));
      },
    }),

    softDeleteExpenseFormula: builder.mutation<IFormula, { formulaUuid: string; scenarioUuid?: string }>({
      query: ({ formulaUuid, scenarioUuid }) => ({
        url: `formulas/expenses/${formulaUuid}`,
        method: 'DELETE',
        params: {
          scenarioUuid,
        },
      }),
      invalidatesTags: ['Formula'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(integrationsApi.util.invalidateTags(['Integration']));
      },
    }),

    pullAccountingActuals: builder.mutation<void, IPullAccountingActualsParams>({
      query: (params) => ({
        url: `formulas/update-actuals`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Formula'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(bvaApi.util.invalidateTags(['Bva']));
      },
    }),

    updateFormula: builder.mutation<IFormula, IUpdateFormulaRequest>({
      query: (data) => ({
        url: `formulas/${data.body.formulaUuid}`,
        method: 'PATCH',
        body: data.body,
        params: data.query,
        headers: data.headers,
      }),
      invalidatesTags: ['Formula'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(integrationsApi.util.invalidateTags(['Integration']));
      },
    }),
  }),
});

// Export hooks for usage in components
export const {
  useListFormulasQuery,
  useUpsertExpenseFormulaMutation,
  useSoftDeleteExpenseFormulaMutation,
  usePullAccountingActualsMutation,
  useUpdateFormulaMutation,
} = formulasApi;
