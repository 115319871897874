import store from '~/store';
import { update } from '~/store/scenarioSlice';
import { fetchScenario } from '~/services/parallel/scenarios';

export const handleCreateScenario = async ({ scenarioUuid }: { scenarioUuid: string | null }): Promise<void> => {
  const dispatch = store.dispatch;
  const scenarioState = store.getState().scenario;
  const organizationUuid = store.getState().organization.uuid;
  const userUuid = store.getState().user.uuid;
  if (scenarioState.activeScenarioUuid) return;
  if (!scenarioUuid) return;

  const scenario = await fetchScenario({
    organizationUuid,
    scenarioUuid,
  });

  dispatch(
    update({
      ...scenarioState,
      activeScenarioUuid: scenarioUuid,
      activeScenarioHasChanges: true,
      scenarioLoadingState: 'creating',
      activeScenarioData: {
        type: 'dynamic',
        purpose: 'userCreated',
        uuid: scenarioUuid,
        organizationUuid,
        changeDescription: 'Untitled Scenario',
        createdBy: userUuid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        markedAsStaleAt: null,
        effectiveAt: new Date().toISOString(),
      },
      scenarios: [scenario, ...scenarioState.scenarios],
    }),
  );
};
