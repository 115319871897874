import React, { ReactElement, useEffect, useState } from 'react';
import { useInput } from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import * as stringDate from '~/utils/stringDate';
import DatePicker, { useDatePicker } from '~/components/DatePicker';

const Compensation = ({
  hireDate,
  effectiveAt,
  currentValue,
  updatedCompensations,
  setUpdatedCompensations,
  setCompensationsRequireUpdate,
}: {
  hireDate: string;
  effectiveAt: 'onHire' | string;
  currentValue: number;
  updatedCompensations: {
    effectiveAt: 'onHire' | string;
    value: number;
  }[];
  setUpdatedCompensations: React.Dispatch<
    React.SetStateAction<
      {
        effectiveAt: 'onHire' | string;
        value: number;
      }[]
    >
  >;
  compensationsRequireUpdate: boolean;
  setCompensationsRequireUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const [compensation, setCompensation] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
    value: (currentValue / CENTS_PER_DOLLAR).toString(),
    disabled: true,
  });

  const [effectiveDate, setEffectiveDate] = useDatePicker({
    errorMessage: 'Effective date is required',
    value: { startDate: effectiveAt, endDate: effectiveAt },
    minDate: hireDate,
    disabled: true,
  });

  const [isEditing, setIsEditing] = useState(false);

  const formattedEffectiveAt = effectiveAt === 'onHire' ? hireDate : effectiveAt;

  useEffect(() => {
    if (Number(compensation.value) * CENTS_PER_DOLLAR !== currentValue && compensation.valid) {
      const newCompensations = updatedCompensations.map((existingCompensation) => {
        if (existingCompensation.effectiveAt === effectiveAt) {
          return {
            ...existingCompensation,
            value: Number(compensation.value) * CENTS_PER_DOLLAR,
          };
        }
        return existingCompensation;
      });
      setUpdatedCompensations(newCompensations);
      setCompensationsRequireUpdate(true);
    }
  }, [compensation, setUpdatedCompensations]);

  useEffect(() => {
    if (
      effectiveDate.value.startDate &&
      !stringDate.isSameDate({ date1: effectiveDate.value.startDate, date2: effectiveAt })
    ) {
      const newCompensations = updatedCompensations.map((existingCompensation) => {
        if (stringDate.isSameDate({ date1: existingCompensation.effectiveAt, date2: effectiveAt })) {
          return {
            ...existingCompensation,
            updatedEffectiveDate: effectiveDate.value.startDate,
          };
        }
        return existingCompensation;
      });
      setUpdatedCompensations(newCompensations);
      setCompensationsRequireUpdate(true);
    }
  }, [effectiveDate, setUpdatedCompensations]);

  const handleDeletePayChange = async (): Promise<void> => {
    setUpdatedCompensations((prev) =>
      prev.filter(
        (existingCompensation) =>
          existingCompensation.effectiveAt === 'onHire' ||
          !stringDate.isSameDate({ date1: existingCompensation.effectiveAt, date2: effectiveAt }),
      ),
    );
    setCompensationsRequireUpdate(true);
  };

  const handleEditClick = (): void => {
    setIsEditing(true);
    setCompensation((prev) => ({ ...prev, disabled: false }));
    setEffectiveDate((prev) => ({ ...prev, disabled: effectiveAt === 'onHire' }));
  };

  return (
    <div className={`flex flex-row gap-2 items-start my-1 transition-all`}>
      <div className="w-[46%]">
        <CurrencyInput id={`compensation-${formattedEffectiveAt}`} state={compensation} setState={setCompensation} />
      </div>
      {effectiveAt === 'onHire' ? (
        <div className="w-[46%] py-2 flex justify-center items-center border border-gray-300 rounded bg-neutral-25">
          <Typography id={`compensation-date-${formattedEffectiveAt}`} color="disabled">
            Hire Date
          </Typography>
        </div>
      ) : (
        <div className="w-[46%]">
          <DatePicker id="effectiveDate" state={effectiveDate} setState={setEffectiveDate} />
        </div>
      )}
      {!isEditing && (
        <PencilSquareIcon
          onClick={handleEditClick}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
        />
      )}
      {isEditing && effectiveAt !== 'onHire' && (
        <TrashIcon
          onClick={handleDeletePayChange}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
          data-testid={`delete-compensation-${formattedEffectiveAt}`}
        />
      )}
    </div>
  );
};

export default Compensation;
