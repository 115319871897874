import { IConsolidatedData } from '~/services/parallel/reports.types';
import { invertYValue } from './invertYValue';
import { IDraggableGraphPoint } from './types';

export const generateGraphPoints = ({
  consolidatedData,
  xMultiplier,
  yMultiplier,
  maxY,
}: {
  consolidatedData: IConsolidatedData[];
  xMultiplier: number;
  yMultiplier: number;
  maxY: number;
}): {
  workingModel: IDraggableGraphPoint[];
  activeScenario: IDraggableGraphPoint[];
} => {
  const X_OFFSET = 6;

  let workingModel: IDraggableGraphPoint[] = [];

  if ('workingModel' in consolidatedData[0]) {
    workingModel = consolidatedData.map((data, index) => ({
      id: index.toString(),
      x: isNaN(index * xMultiplier) ? 0 : index * xMultiplier + X_OFFSET,
      y: isNaN(invertYValue({ value: data.workingModel, maxY, yMultiplier }))
        ? 0
        : invertYValue({ value: data.workingModel, maxY, yMultiplier }),
      rotation: 0,
      isDragging: false,
    }));
  }

  let activeScenario = [];

  if ('activeScenario' in consolidatedData[0]) {
    activeScenario = consolidatedData.map((data, index) => ({
      id: index.toString(),
      x: isNaN(index * xMultiplier) ? 0 : index * xMultiplier + X_OFFSET,
      y: isNaN(invertYValue({ value: data.activeScenario, maxY, yMultiplier }))
        ? 0
        : invertYValue({ value: data.activeScenario, maxY, yMultiplier }),
      rotation: 0,
      isDragging: false,
    }));
  } else {
    activeScenario = [...workingModel];
  }

  return { workingModel, activeScenario };
};
