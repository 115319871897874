import { z } from 'zod';
import { IMessageRoleEnum } from './types';

export const ZResponseMetadata = z.object({
  requiredFormulas: z.array(z.string()),
});

export const ZMessage = z.object({
  content: z.string(),
  name: z.string().optional(),
  role: z.nativeEnum(IMessageRoleEnum),
  toolId: z.string().optional(),
  isError: z.boolean().optional(),
  responseMetadata: ZResponseMetadata.optional(),
});

export const ZConversationStatus = z.enum(['ok', 'error']);

export const ZConversation = z.object({
  uuid: z.string(),
  title: z.string(),
  isRead: z.boolean(),
  createdBy: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  messages: z.array(ZMessage),
  status: ZConversationStatus,
  nextPrompts: z.array(z.string()).optional(),
});

export const ZConversationResponse = z.object({
  data: z.object({
    data: ZConversation,
  }),
  status: z.number(),
});
