import type { z } from 'zod';
import type { ZConversation, ZMessage } from './schemas';

export enum IMessageRoleEnum {
  User = 'user',
  Assistant = 'assistant',
  System = 'system',
  Tool = 'tool',
}
export type IMessage = z.infer<typeof ZMessage>;
export type IConversation = z.infer<typeof ZConversation>;
