import type { IUpsertExpenseFormulaRequest } from '~/services/parallel/api/formulas/requestSchemas';
import { ExpenseDriverEnum, ExpenseFrequencyEnum, IFormattingEnum } from '~/services/parallel/formulas.types';
import type { IExpenseFormData } from './ExpenseForm';
import { IEmploymentType } from '~/services/parallel/headcount.types';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { IFormulaSegment } from '~/pages/FinancialModelDeprecated/entity/types';

export const transformExpenseFormForSubmission = ({
  formData,
  scenarioUuid,
  formulaUuid,
  customFormula,
  customVariables,
  allDepartments,
}: {
  formData: IExpenseFormData;
  scenarioUuid?: string;
  formulaUuid?: string;
  customFormula?: IFormulaSegment[];
  customVariables?: Record<string, any>;
  allDepartments: string[];
}): IUpsertExpenseFormulaRequest => {
  const {
    name,
    category,
    departments,
    expenseDriver,
    employmentTypes,
    amount,
    percentage,
    startDate,
    endDate,
    frequency,
    dataSourceUuids,
    segmentType,
    min,
    max,
  } = formData;

  let finalAmount: string | undefined;
  if ([ExpenseDriverEnum.SetCost, ExpenseDriverEnum.HeadcountFixed].includes(expenseDriver as ExpenseDriverEnum)) {
    finalAmount = amount ?? undefined;
  } else if (expenseDriver === ExpenseDriverEnum.HeadcountPercentCompensation) {
    finalAmount = percentage ?? undefined;
  }

  const request: IUpsertExpenseFormulaRequest = {
    query: { scenarioUuid, awaitCalculations: true },
    body: {
      formulaUuid,
      dataSourceUuids,
      formatting: IFormattingEnum.Currency,
      context: {
        tag: category,
        frequency: frequency ?? ExpenseFrequencyEnum.Monthly,
        departments: departments.length === allDepartments.length ? ['ALL'] : departments,
        employmentTypes:
          employmentTypes.length === Object.values(IEmploymentType).length
            ? (['all'] as (IEmploymentType | 'all')[])
            : employmentTypes.map((employmentType) => employmentType as IEmploymentType),
        driver: expenseDriver as ExpenseDriverEnum,
        amount: finalAmount ? parseFloat(finalAmount) * CENTS_PER_DOLLAR : undefined,
      },
      recipe: {
        name,
        startDate,
        endDate: endDate ?? null,
        expression: undefined as string | undefined,
        variables: undefined as Record<string, any> | undefined,
      },
    },
  };

  if (min && expenseDriver === ExpenseDriverEnum.Custom && request.body.recipe) {
    request.body.recipe.min = min;
  }
  if (max && expenseDriver === ExpenseDriverEnum.Custom && request.body.recipe) {
    request.body.recipe.max = max;
  }

  // Handle custom formula
  if (segmentType === 'custom' && customFormula && customFormula.length > 0 && request.body.recipe) {
    const expression = customFormula.map((segment) => segment.textValue).join('');
    request.body.recipe.expression = expression;
    request.body.recipe.variables = customVariables || {};
  }

  return request;
};
